import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Divider,
    Grid,
    InputAdornment,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import _ from 'lodash';

import { useVoluntary } from "../../../../../hooks/curriculum/useVoluntary";
import { formatDateToString, formatStringToDate } from "../../../../../util/Index";
import { MaskInputWrapper } from "../../../../../../_metronic/partials/content/texttinput/MaskInputWrapper";


export default function SectionVoluntary({ errors, setFieldValue, values }: any) {
    const { t } = useTranslation();
    const {
        valueAdd,
        borderColorEdit,
        editMode,
        openRowEdit,
        alert,
        alertMsg,
        open,
        setValues,
        handleClose,
        handleEdit,
        handleSaveEdit,
        handleRemoveExperienciaLaboral,
        handleAddExperienciaLaboral,
        handleclear,
        rows
    } = useVoluntary({ setFieldValue, values });

    const {
        institution,
        name,
        activity_performed,
        start_date,
        end_date
    } = valueAdd;

    const showDatePicker = (idElement: string): void => {
        // @ts-ignore
        document.getElementById(idElement).showPicker();
    };

    const clearInputs = (): void => {
        const inputS: any = document?.getElementById('start_date');
        const inputC: any = document?.getElementById('end_date');

        if (inputS) {
            inputS.value = '';
        }
        if (inputC) {
            inputC.value = '';
        }
    }
    return (
        <>
            <Box sx={{ mb: 3, mt: 5 }}>
                <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                    {t('VOLUNTEERING')}
                </Typography>
            </Box>

            <>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    width={'100%'}
                    sx={{
                        backgroundColor: '#1C447F',
                        pt: 2,
                        pl: 2,
                        pb: 2,
                        pr: 2,
                        borderTopLeftRadius: 6,
                        borderTopRightRadius: 6,
                    }}
                    item
                >
                    <Grid container direction='column' item>
                        <Typography color='white' variant='h5'>
                            {t('Agregar')} {t('VOLUNTEERING')} {editMode ? t(' - modo de edición') : ''}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    width={'100%'}
                    mb={5}
                    sx={{
                        // border: 1,
                        borderBottom: 3,
                        borderColor: '#AFADAD',
                        borderBottomLeftRadius: 6,
                        borderBottomRightRadius: 6,
                        pt: 5,
                        pl: 3,
                        pr: 3,
                        border: `${borderColorEdit}`,
                    }}
                    item
                >
                    <Grid container direction='row' justifyContent='space-between' width={'100%'} item>
                        <Grid xs={12} md={4} mb={5} pr={2} item>
                            <TextField
                                fullWidth
                                id={'nameVolunteering'}
                                name={'name'}
                                label={t('Nombre')}
                                value={name}
                                onChange={(e) => setValues({ ...valueAdd, name: e.target.value })}
                                placeholder={t('VOLUNTEERING_NAME_PLD')}
                                size='medium' />
                        </Grid>
                        <Grid xs={12} md={4} mb={5} pr={2} item>
                            <TextField
                                fullWidth
                                id={'activity_performed'}
                                name={'activity_performed'}
                                label={t('VOLUNTEERING_ACTIVITY')}
                                value={activity_performed}
                                onChange={(e) => setValues({ ...valueAdd, activity_performed: e.target.value })}
                                placeholder={t('VOLUNTEERING_ACTIVITY_PLD')}
                                size='medium' />
                        </Grid>

                        <Grid xs={12} md={4} mb={2} pr={2} item>
                            <TextField
                                fullWidth
                                id={'institution'}
                                name={'institution'}
                                label={t('ENTITY')}
                                value={institution}
                                onChange={(e) => setValues({ ...valueAdd, institution: e.target.value })}
                                placeholder={t('ENTITY_PLD')}
                                size='medium' />
                        </Grid>
                    </Grid>
                    <Grid container direction='row' justifyContent='flex-start' width={'100%'} item>
                        <Grid xs={12} md={4} mb={5} pr={2} item={true}>
                            <MaskInputWrapper
                                fullWidth
                                mask="99/99/9999"
                                id="start_date"
                                name='start_date'
                                label={t('START')}
                                value={start_date}
                                onChange={(e: any) => setValues({ ...valueAdd, start_date: e.target.value })}
                                InputLabelProps={{ shrink: true }}
                                size='medium'
                                placeholder={"DD/MM/YYYY"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <i
                                                className='bi bi bi-calendar2-plus-fill'
                                                style={{
                                                    fontSize: '15px',
                                                }}
                                                onClick={(e) => showDatePicker("start_date")}
                                            ></i>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid xs={12} md={4} mb={5} pr={2} item={true}>
                            <MaskInputWrapper
                                fullWidth
                                id="end_date"
                                name='end_date'
                                label={t('FINISH')}
                                value={end_date}
                                onChange={(e: any) => setValues({ ...valueAdd, end_date: e.target.value })}
                                mask="99/99/9999"
                                InputLabelProps={{ shrink: true }}
                                size='medium'
                                placeholder={"DD/MM/YYYY"}
                            // InputProps={{
                            //     endAdornment: (
                            //         <InputAdornment position='end'>
                            //             <i
                            //                 className='bi bi bi-calendar2-plus-fill'
                            //                 style={{
                            //                     fontSize: '15px',
                            //                 }}
                            //                 onClick={(e) => showDatePicker("end_date")}
                            //             ></i>
                            //         </InputAdornment>
                            //     ),
                            // }}
                            />
                        </Grid>
                    </Grid>


                    <Grid xs={12} mb={3} display='flex' justifyContent='space-between' alignItems='center' item>
                        {editMode ? (
                            <>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
                                    <Button
                                        variant='contained'
                                        onClick={() => {
                                            handleSaveEdit();
                                            clearInputs();
                                        }}
                                        sx={{ width: 90, background: '#0A4396', mr: 1 }}
                                    >
                                        {t('Guardar')}
                                    </Button>

                                    <Button
                                        variant='contained'
                                        sx={{ width: 90, background: '#6c757d', mr: 1 }}
                                        onClick={() => {
                                            handleclear();
                                            clearInputs();
                                        }}
                                    >
                                        {t('Cancelar')}
                                    </Button>
                                </Box>
                            </>
                        ) : (
                            <Button
                                disabled={!name || !activity_performed || !institution || !start_date || !end_date}
                                variant='contained'
                                onClick={handleAddExperienciaLaboral}
                                sx={{ width: 90, background: '#0A4396' }}
                            >
                                {t('Agregar')}
                            </Button>
                        )}
                    </Grid>
                </Grid>
                {rows?.length > 0 && (
                    <Grid xs={12} item>
                        <Typography color='#2B2B2B' variant='h6'>
                            {t('Agregados')}
                        </Typography>
                        <Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 5 }} />
                    </Grid>
                )}
                <Grid xs={12} item>
                    {rows?.map((row, i) => (
                        <Grid xs={12} key={i} item display={openRowEdit === row.id ? 'none' : ''}>
                            <Grid
                                container
                                direction='row'
                                justifyContent='space-between'
                                width={'100%'}
                                sx={{
                                    backgroundColor: '#1C447F',
                                    pt: 2,
                                    pl: 2,
                                    pb: 2,
                                    pr: 2,
                                    borderTopLeftRadius: 6,
                                    borderTopRightRadius: 6,
                                }}
                                item
                            >
                                <Typography color='white' variant='h6'>
                                    {' '}
                                    {t(row.name || '') || ''}
                                </Typography>
                                <Box display='flex' justifyContent='end' alignItems='center'>
                                    <div>
                                        <div className='d-flex justify-content-end flex-shrink-0'>
                                            <button
                                                className='btn btn-icon btn-active-color-secondary btn-sm me-3'
                                                style={{ background: '#C4D2E8' }}
                                                onClick={() => handleEdit(row)}
                                                type="button"
                                            >
                                                <EditIcon />
                                            </button>

                                            <button
                                                className='btn btn-icon btn-active-color-secondary btn-sm'
                                                onClick={() => handleRemoveExperienciaLaboral(row.id || 0)}
                                                style={{ background: '#DD1342' }}
                                                type="button"
                                            >
                                                <DeleteOutlinedIcon style={{ color: 'white' }} />
                                            </button>
                                        </div>
                                    </div>
                                </Box>
                            </Grid>
                            <Grid
                                container
                                direction='row'
                                justifyContent='space-between'
                                width={'100%'}
                                mb={5}
                                sx={{
                                    border: 1,
                                    borderBottom: 3,
                                    borderColor: '#AFADAD',
                                    borderBottomLeftRadius: 6,
                                    borderBottomRightRadius: 6,
                                    pt: 5,
                                    pl: 3,
                                    pr: 3,
                                }}
                                item
                            >

                                <Grid xs={12} md={3} mb={5} mr={5} item>
                                    <Typography>{t('Nombre') + ': ' + (row.name || '')}</Typography>
                                    <Typography>{t('ENTITY') + ': ' + (row.institution || '')}</Typography>
                                    <Typography>{t('VOLUNTEERING_ACTIVITY') + ': ' + (row.activity_performed || '')}</Typography>
                                </Grid>
                                <Grid xs={1} mb={5} sx={{ borderLeft: 2, borderColor: '#AFADAD' }} item />
                                <Grid xs={12} md={2} mb={5} mr={2} item>
                                    <Typography>{t('START') + ': ' + (formatDateToString(row.start_date) || '')}</Typography>
                                    <Typography>{t('FINISH') + ': ' + (formatDateToString(row.end_date) || '')}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    variant={'filled'}
                    severity={alert ? 'success' : 'error'}
                    sx={{ whiteSpace: 'pre-line' }}
                >
                    {alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
                    {t(alertMsg)}
                </Alert>
            </Snackbar>
        </>
    )
}