/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import './AuthLayout.css';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { useTranslation } from 'react-i18next';
import '../../i18n';

interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div hidden={value !== index} id={`full-width-tabpanel-${index}`} {...other}>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `full-width-tab-${index}`,
	};
}

const AuthLayout = () => {
	const { t } = useTranslation();

	useEffect(() => {
		const root = document.getElementById('root');
		if (root) {
			root.style.height = '100%';
		}
		return () => {
			if (root) {
				root.style.height = 'auto';
			}
		};
	}, []);

	const [value, setValue] = useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<>
			<div className='ContainerAuth'>
				<div
					className='headergov d-flex flex-column pt-1 h-40px w-100'
					style={{ background: '#3366C9' }}
				></div>

				<div className='d-flex flex-column flex-lg-row h-100 flex-column-fluid bg-white'>
					<div className='d-flex flex-column flex-lg-row-fluid w-lg-50 pt-10 pb-10 order-2 order-lg-2'>
						<div className='d-flex flex-center flex-lg-row-fluid'>
							<div className='w-100 mt-10 mb-10'>
								<Outlet />
							</div>
						</div>
					</div>

					{/* Cadastro */}
					<div
						className='w-lg-50 bgi-size-cover bgi-position-center order-2 order-lg-2 d-flex flex-column flex-lg-row-fluid'
						style={{ backgroundColor: '#02266C' }}
					>
						<div className='d-flex flex-column flex-lg-row-fluid'>
							<div className='d-flex flex-column flex-lg-row-fluid p-5 col-12'>
								<div className='row '>
									<img
										style={{
											height: 'auto',
											width: '350px',
											display: 'block',
											marginLeft: 'auto',
											marginRight: 'auto',
											marginTop: '90px',
											marginBottom: '100px',
										}}
										src={toAbsoluteUrl('')}
									/>
									<h2
										className='card-title text-white mt-2'
										style={{ textAlign: 'center', fontSize: '25px', marginBottom: '100px' }}
									>
										{t('VIRTUAL CAREER CENTER')}
									</h2>
								</div>
								<div className='row mb-5'>
									<h2 style={{ textAlign: 'center' }} className='text-white'>
										{t('Cadastro')}
									</h2>
									<span className='text-white' style={{ textAlign: 'center' }}>
										{t('Selecione um perfil abaixo para iniciar seu registro')}
										<br></br>
									</span>
								</div>
								<div className='row mt-4'>
									<div
										className='col-sm-8 col-md-6'
										style={{ marginLeft: 'auto', marginRight: 'auto' }}
									>
										<div
											className={`card mb-10`}
											style={{
												borderRadius: '10px',
												background: 'rgba(217, 217, 217, 0.15)',
												borderColor: '#FFFF',
											}}
										>
											<Link to={'/registration'} className='card-text text-white'>
												<div className='card-body'>
													<h2
														className='card-title text-white mt-2'
														style={{ textAlign: 'center', fontSize: '20px' }}
													>
														{t('Sou candidato')}
													</h2>

													<br />
												</div>
											</Link>
										</div>
									</div>
								</div>

								<div className='row'>
									<div
										className='col-sm-8 col-md-6 justify-content-center'
										style={{ marginLeft: 'auto', marginRight: 'auto' }}
									>
										<div
											className={`card mb-10`}
											style={{
												borderRadius: '10px',
												background: 'rgba(217, 217, 217, 0.15)',
												borderColor: '#FFFF',
											}}
										>
											<Link to={'/registro-de-empresa'} className='card-text text-white'>
												<div className='card-body'>
													<h2
														className='card-title text-white mt-2'
														style={{ textAlign: 'center', fontSize: '20px' }}
													>
														{t('Sou empregador')}
													</h2>

													<br />
												</div>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='loginMobile'>
				<div
					className='headergov d-flex flex-column pt-1 h-40px w-100'
					style={{ background: '#3366C9' }}
				></div>
				<AppBar position='static' sx={{ marginTop: '40px', backgroundColor: '#3366C9' }}>
					<Tabs
						value={value}
						onChange={handleChange}
						indicatorColor='secondary'
						textColor='inherit'
						variant='fullWidth'
					>
						<Tab
							sx={{ fontSize: '16px', fontWeight: 'bold' }}
							label={t('Login')}
							{...a11yProps(0)}
						/>
						<Tab
							sx={{ fontSize: '16px', fontWeight: 'bold' }}
							label={t('Cadastro')}
							{...a11yProps(1)}
						/>
					</Tabs>
				</AppBar>
				<TabPanel value={value} index={0}>
					<Box style={{ backgroundColor: 'white', margin: 0, padding: 0, paddingBottom: 100 }}>
						<div className='d-flex flex-column flex-lg-row-fluid w-lg-50 pt-10 pb-10 order-2 order-lg-2'>
							<div className='d-flex flex-center flex-lg-row-fluid'>
								<div className='w-100 mt-10 mb-10'>
									<Outlet />
								</div>
							</div>
						</div>
					</Box>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<>
						<div className='row '>
							<h2
								className='card-title text-white mt-8'
								style={{ textAlign: 'center', fontSize: '25px', marginBottom: '100px' }}
							>
								{t('VIRTUAL CAREER CENTER')}
							</h2>
						</div>
						<div className='row mb-5'>
							<h2 style={{ textAlign: 'center' }} className='text-white'>
								{t('Cadastro')}
							</h2>
							<span className='text-white' style={{ textAlign: 'center' }}>
								{t('Selecione um perfil abaixo para iniciar seu registro')}
								<br></br>
							</span>
						</div>

						<div className='mt-4'>
							<div
								className={`card mb-10`}
								style={{
									borderRadius: '10px',
									background: 'rgba(217, 217, 217, 0.15)',
									borderColor: '#FFFF',
								}}
							>
								<Link to={'/registration'} className='card-text text-white'>
									<div className='card-body'>
										<h2
											className='card-title text-white mt-2'
											style={{ textAlign: 'center', fontSize: '20px' }}
										>
											{t('Sou candidato')}
										</h2>

										<br />
									</div>
								</Link>
							</div>
						</div>

						<div className='row'>
							<div
								className='col-sm-8 col-md-6 justify-content-center'
								style={{ marginLeft: 'auto', marginRight: 'auto' }}
							>
								<div
									className={`card mb-10`}
									style={{
										borderRadius: '10px',
										background: 'rgba(217, 217, 217, 0.15)',
										borderColor: '#FFFF',
									}}
								>
									<Link to={'/registro-de-empresa'} className='card-text text-white'>
										<div className='card-body'>
											<h2
												className='card-title text-white mt-2'
												style={{ textAlign: 'center', fontSize: '20px' }}
											>
												{t('Sou empregador')}
											</h2>

											<br />
										</div>
									</Link>
								</div>
							</div>
						</div>
					</>
				</TabPanel>
			</div>
		</>
	);
};

export { AuthLayout };
