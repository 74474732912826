import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PageTitle } from '../../../_metronic/layout/core';
import styleScss from './styles.module.scss';
import { getUsers } from '../../modules/apps/user-management/users-list/core/_requests';
import PaginationComponent from '../../components/Pagination/Index';
import UseDidMountEffect from '../../modules/auth/core/UseDidMountEffect';
import { LinearProgress } from '@mui/material';
import { RoleEnum } from '../../models/enums/RoleEnum';
import { Alert, Snackbar, AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import MenuAction from '../../components/MenuAction';
import CardComponente from '../../components/CardComponente';

const RegistroManagementPage = () => {
	const { t } = useTranslation();
	const [data, setData]: any[] = useState([]);

	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	//Pagination
	const [total, setTotal] = useState<number>(0);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
		name: '',
		role: 0,
	});
	const [loading, setLoading] = useState(false);

	const tpUserRole = [
		{ id: RoleEnum.ADMIN, name: 'Administrador' },
		{ id: RoleEnum.CANDIDATE, name: 'Candidato' },
/* 		{ id: RoleEnum.COUNSELOR, name: 'Consejero' },
 */		{ id: RoleEnum.EMPLOYER, name: 'Empleador' },
	];

	useEffect(() => {
		setFilter({ ...filter });
	}, []);

	UseDidMountEffect(() => {
		getUsersAPI();
	}, [filter]);

	const changePaginate = (pf: any) => {
		setFilter({ ...filter, page: pf.page, limit: pf.limit });
	};

	const handleClose = () => {
		setOpen(false);
	};

	const getUsersAPI = async () => {
		const mapUserData = (user: any) => {
			return {
				id: user.id,
				empresa_id: user.company_id,
				email: user.email,
				passwordHash: '',
				idHash: '',
				role: user.role,
				active: user.active,
				status: user.status,
				createdAt: user.created_at,
				updatedAt: user.updated_at,
				Role: {
					name: user.Role.name,
				},
				UserComplemento: {
					primer_nome: user.UserComplement.first_name,
					primer_apelido: user.UserComplement.last_name,
				},
				Empresa: user.Company ? { id: user.company_id } : { id: null },
			};
		};

		try {
			setLoading(true);
			const users = await getUsers(
				`page=${filter.page}&limit=${filter.limit}${
					filter.name !== '' ? '&name=' + filter.name : ''
				}${filter.role > 0 ? '&role=' + filter.role : ''}`
			);
			const mappedData = users.data.data.map(mapUserData);
			setTotal(users.data.total);
			setTotalPage(users.data.totalPages);
        	setData(mappedData);
		} catch (error: any) {
			console.error('error', error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<div className='p-10 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<div className='row mb-4'>
					<h3 className={styleScss.titleMobile}>{t('Gestión Registros')}</h3>
					<div className='col-sm-4'>
						<div className='input-group mb-3'>
							<select
								name='documentType'
								className={`form-control ${styleScss.inputSeelectMobile}`}
								id='documentType'
								onChange={(e) => setFilter({ ...filter, page: 1, role: e.target.value })}
							>
								<option value='' defaultChecked>
									{t('Seleccionar usuario')}
								</option>
								{tpUserRole.map((item, i) => (
									<option value={item.id} key={i}>
										{t(item.name)}
									</option>
								))}
							</select>
							<div className='input-group-append'>
								<span className='input-group-text' id='searchCandidato'>
									<i
										className={`bi bi-chevron-compact-down ${styleScss.iconMobile}`}
										style={{ fontSize: '20px' }}
									></i>
								</span>
							</div>
						</div>
					</div>
					<div className='col-sm-4'>
						<div className='input-group mb-3'>
							<input
								type='text'
								className={`form-control ${styleScss.inputSeelectMobile}`}
								placeholder={t('Escriba el nombre/email')}
								aria-label="Recipient's username"
								aria-describedby='basic-addon2'
								onChange={(e) => setFilter({ ...filter, page: 1, name: e.target.value })}
							/>
							<div className='input-group-append'>
								<span className='input-group-text' id='searchCandidato'>
									<i
										className={`bi bi-search ${styleScss.iconMobile}`}
										style={{ fontSize: '20px' }}
									></i>
								</span>
							</div>
						</div>
					</div>
					<div className='col-sm-3'>
						<div className='input-group mb-3'>
							<Link
								to='/admin/nuevo-usuario'
								className={`btn btn-primary btn-lg btn-block ${styleScss.btnNewUser}`}
								style={{ padding: '8px 40px' }}
							>
								{t('Nuevo usuario')}
							</Link>
						</div>
					</div>
				</div>
				{loading ? (
					<LinearProgress className='table-limit' style={{ marginTop: '10px' }} />
				) : (
					<div className={'table-limit table-responsive'} style={{ width: '100%' }}>
						<div className={styleScss.tableWeb}>
							<table id='table-list' className={`table table-striped ${styleScss['tt']}`}>
								<thead className={`${styleScss['th-custom']}`}>
									<tr>
										<th scope='col'>
											<h4>{t('Nombre')}</h4>
										</th>
										<th scope='col'>
											<h4>{t('Apellido')}</h4>
										</th>
										<th scope='col'>
											<h4>{t('Email')}</h4>
										</th>
										<th scope='col'>
											<h4>{t('Estado')}</h4>
										</th>
										<th scope='col'>
											<h4>{t('Activo')}</h4>
										</th>
										<th scope='col'>
											<h4>{t('Rol')}</h4>
										</th>
										<th scope='col'>
											<h4>{t('Acciones')}</h4>
										</th>
									</tr>
								</thead>
								<tbody className={`${styleScss['td-custom']}`}>
									{data.map((x: any, i: number) => (
										<tr key={i} id={i.toString()}>
											<td>{x.UserComplemento?.primer_nome?.toLowerCase()}</td>
											<td>
												{x.UserComplemento?.primer_apelido
													? x.UserComplemento?.primer_apelido?.toLowerCase()
													: ''}
											</td>
											<td>{x.email?.toLowerCase()}</td>
											<td>{t(x.status)}</td>
											<td>{!!x?.active ? t('Sí') : t('No')}</td>
											<td>{t(tpUserRole.find((r) => r.id === x.role)?.name || '')}</td>
											<td>
												<MenuAction
													data={{ id: x.id, active: x.active }}
													setAlert={setAlert}
													setAlertMsg={setAlertMsg}
													setOpen={setOpen}
												/>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						{data.map((x: any, i: any) => (
							<CardComponente
								key={i}
								setAlert={setAlert}
								setAlertMsg={setAlertMsg}
								setOpen={setOpen}
								data={x}
							/>
						))}
					</div>
				)}
				<PaginationComponent
					totalPage={totalPage}
					total={total}
					customClass={''}
					paginate={changePaginate}
				/>
			</div>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
					{t(alertMsg)}
				</Alert>
			</Snackbar>
		</>
	);
};

const AdminRegistroManagementWrapper = () => {
	const { t } = useTranslation();
	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Gestión Registros')}</PageTitle>
			<RegistroManagementPage />
		</>
	);
};

export { AdminRegistroManagementWrapper };
