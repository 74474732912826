import { useContext } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Link, useNavigate } from 'react-router-dom';

import { multiStepContext } from '../../../contexts/VacancyContext';

import { Step1 } from './steps/vacancy-management/Step1';
import { Step2 } from './steps/vacancy-management/Step2';
import { Step3 } from './steps/vacancy-management/Step3';
import { Step4 } from './steps/vacancy-management/Step4';
import { Step5 } from './steps/vacancy-management/Step5';
import { Step6 } from './steps/vacancy-management/Step6';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const steps = ['', '', '', '', '', ''];

export const VacancyManagement = () => {
	const { t } = useTranslation();
	const { currentStep, finalData } = useContext(multiStepContext);
	const navigate = useNavigate();

	const showStep = (step: number) => {
		switch (step) {
			case 1:
				return <Step1 />;
			case 2:
				return <Step2 />;
			case 3:
				return <Step3 />;
			case 4:
				return <Step4 />;
			case 5:
				return <Step5 />;
			/* case 6:
				return <Step6 />; */
		}
	};

	const handleSave = () => {
		alert('¡Guardado exitosamente!!');
	};

	return (
		<Box sx={{ width: '100%', background: 'white', padding: 4, borderRadius: 2, marginTop:'40px' }}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3, marginTop:'0px' }}>
				<Box>
					<div className='row'>
						<div className='col-sm-2'>
							<button
								onClick={() => {
									navigate(-1);
								}}
								className='btn btn-primary'
								style={{ background: '#C4D2E8', borderRadius: '4px', padding: '6px 15px' }}
							>
								<i
									className='bi bi-arrow-left'
									style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
								></i>
							</button>
						</div>
						<div className='col-sm-10'>
							<Typography variant='h5' sx={{ fontWeight: 'bold' }}>
								{t('Información básica')}
							</Typography>
							<Typography sx={{ color: '#888888' }}>
								{t('Diligencie los campos con información de la vacante')}
							</Typography>
						</div>
					</div>
				</Box>
			</Box>
			<Stepper style={{ width: '100%' }} activeStep={currentStep - 1} orientation='horizontal'>
				<Step>
					<StepLabel></StepLabel>
				</Step>
				<Step>
					<StepLabel></StepLabel>
				</Step>
				<Step>
					<StepLabel></StepLabel>
				</Step>
				<Step>
					<StepLabel></StepLabel>
				</Step>
				<Step>
					<StepLabel></StepLabel>
				</Step>
				{/* <Step>
					<StepLabel></StepLabel>
				</Step> */}
			</Stepper>
			<Box mt={5}>{showStep(currentStep)}</Box>
		</Box>
	);
};
