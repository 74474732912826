import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import _ from 'lodash';

import { MESES, TYPES_EVENTO } from "../../../../../constants/constants";
import { useWorkshop } from "../../../../../hooks/curriculum/useWorkshop";
import { CurricullumSectionProps } from "./CurricullumFormTypes";


export default function SectionWorkshop({ errors, setFieldValue, values, typeParticipants }: CurricullumSectionProps) {
    const { t } = useTranslation();
    const {
        valueAdd,
        borderColorEdit,
        editMode,
        openRowEdit,
        alert,
        alertMsg,
        open,
        setValues,
        handleClose,
        handleEdit,
        handleSaveEdit,
        handleRemoveExperienciaLaboral,
        handleAddExperienciaLaboral,
        handleclear,
        rows,
    } = useWorkshop({ setFieldValue, values });

    const {
        event_name,
        organizer,
        date,
        dateMonth,
        dateYear,
        type_of_participation,
    } = valueAdd;


    const getListYears = (): JSX.Element[] => {
        const menuItems: JSX.Element[] = []
        const limitYear = new Date().getFullYear();
        for (let index = limitYear; index >= 1950; index--) {
            menuItems.push(<MenuItem value={index} key={`get_year_${index}_${new Date().getMilliseconds()}`}>{index}</MenuItem>)
        }
        return menuItems.map(menu => menu)
    }

    const formatTypeParticipation = (id: any) => {
        const founded: any = typeParticipants?.find((v: any) => v.id == id);
        return founded?.[`${t('FIELD_WORKSHOP_TYPE')}`] || '';
    }
    return (
        <>
            <Box sx={{ mb: 3, mt: 5 }}>
                <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                    {t('WORKSHOPS')}
                </Typography>
            </Box>

            <>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    width={'100%'}
                    sx={{
                        backgroundColor: '#1C447F',
                        pt: 2,
                        pl: 2,
                        pb: 2,
                        pr: 2,
                        borderTopLeftRadius: 6,
                        borderTopRightRadius: 6,
                    }}
                    item
                >
                    <Grid container direction='column' item>
                        <Typography color='white' variant='h5'>
                            {t('Agregar')} {t('WORKSHOPS')} {editMode ? t(' - modo de edición') : ''}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    width={'100%'}
                    mb={5}
                    sx={{
                        // border: 1,
                        borderBottom: 3,
                        borderColor: '#AFADAD',
                        borderBottomLeftRadius: 6,
                        borderBottomRightRadius: 6,
                        pt: 5,
                        pl: 3,
                        pr: 3,
                        border: `${borderColorEdit}`,
                    }}
                    item
                >
                    <Grid container direction='row' justifyContent='space-between' width={'100%'} item>
                        <Grid xs={12} md={4} mb={5} pr={2} item>
                            <TextField
                                fullWidth
                                id={'nameWorkShop'}
                                name={'event_name'}
                                label={t('EVENT_NAME')}
                                value={event_name}
                                onChange={(e) => setValues({ ...valueAdd, event_name: e.target.value })}
                                placeholder={t('EVENT_NAME')}
                                size='medium' />
                        </Grid>
                        <Grid xs={12} md={4} mb={5} pr={2} item>
                            <TextField
                                fullWidth
                                id={'organizer'}
                                name={'organizer'}
                                label={t('ORGANIZER')}
                                value={organizer}
                                onChange={(e) => setValues({ ...valueAdd, organizer: e.target.value })}
                                placeholder={t('ORGANIZER')}
                                size='medium' />
                        </Grid>
                        <Grid xs={12} md={4} mb={2} pr={2} item>
                            <FormControl
                                sx={{ minWidth: '100%' }}
                                size='medium'
                            >
                                <InputLabel id={'type'}>{t('PARTICIPATION_TYPE')}</InputLabel>
                                <Select
                                    labelId={'type_of_participation'}
                                    id={'type_of_participation'}
                                    name="type_of_participation"
                                    label={t('PARTICIPATION_TYPE')}
                                    value={type_of_participation}
                                    onChange={(e) => setValues({ ...valueAdd, type_of_participation: e.target.value })}
                                >
                                    <MenuItem value={''}>{t('PARTICIPATION_TYPE')}</MenuItem>
                                    {typeParticipants?.map((item: any) => <MenuItem value={item.id} key={`key-paricipant-type-${item.id}`}>{item[`${t('FIELD_WORKSHOP_TYPE')}`]}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' justifyContent='flex-date' width={'100%'} item>
                        <Grid xs={12} md={4} mb={5} pr={2} item>
                            <Grid xs={12} md={12} mb={2} pr={2} item>
                                <InputLabel id={'fecha_Retiro'}>{t('Fecha')}</InputLabel>
                            </Grid>
                            <Grid direction='row' justifyContent='flex-date' width={'100%'} mb={5} pr={2} container>
                                <Grid xs={12} md={6} mb={2} pr={2} item>
                                    <FormControl
                                        sx={{ minWidth: '100%' }}
                                        size='medium'
                                    >
                                        <InputLabel id={'dateMonth'}>{t('MONTH')}</InputLabel>
                                        <Select
                                            labelId={'dateMonth'}
                                            id={'dateMonth'}
                                            label={t('MONTH')}
                                            value={dateMonth}
                                            onChange={(e) => setValues({ ...valueAdd, dateMonth: e.target.value })}
                                        >
                                            <MenuItem value=''>{t('MONTH')}</MenuItem>
                                            {MESES.map((item) => <MenuItem value={item.val} key={`get_month_${item.val}_${new Date().getMilliseconds()}`}>{item.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={6} mb={2} pr={2} item>
                                    <FormControl
                                        sx={{ minWidth: '100%' }}
                                        size='medium'
                                    >
                                        <InputLabel id={'fecha_Retiro'}>{t('YEAR')}</InputLabel>
                                        <Select
                                            labelId={'fecha_Retiro_y'}
                                            id={'fecha_Retiro_y'}
                                            label={t('YEAR')}
                                            value={dateYear || 0}
                                            onChange={(e) => setValues({ ...valueAdd, dateYear: e.target.value })}
                                            onBlur={(e) => setValues({ ...valueAdd, dateYear: e.target.value })}
                                        >

                                            <MenuItem value={0}>{t('YEAR')}</MenuItem>
                                            {getListYears()}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid xs={12} mb={3} display='flex' justifyContent='space-between' alignItems='center' item>
                        {editMode ? (
                            <>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
                                    <Button
                                        variant='contained'
                                        onClick={handleSaveEdit}
                                        sx={{ width: 90, background: '#0A4396', mr: 1 }}
                                    >
                                        {t('Guardar')}
                                    </Button>

                                    <Button
                                        variant='contained'
                                        sx={{ width: 90, background: '#6c757d', mr: 1 }}
                                        onClick={handleclear}
                                    >
                                        {t('Cancelar')}
                                    </Button>
                                </Box>
                            </>
                        ) : (
                            <Button
                                disabled={!event_name || !organizer || !dateMonth || !dateYear || !type_of_participation}
                                variant='contained'
                                onClick={() => {handleAddExperienciaLaboral()}}
                                sx={{ width: 90, background: '#0A4396' }}
                            >
                                {t('Agregar')}
                            </Button>
                        )}
                    </Grid>
                </Grid>
                {rows?.length > 0 && (
                    <Grid xs={12} item>
                        <Typography color='#2B2B2B' variant='h6'>
                            {t('Agregados')}
                        </Typography>
                        <Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 5 }} />
                    </Grid>
                )}
                <Grid xs={12} item>
                    {rows?.map((row, i) => (
                        <Grid xs={12} key={i} item display={openRowEdit === row.id ? 'none' : ''}>
                            <Grid
                                container
                                direction='row'
                                justifyContent='space-between'
                                width={'100%'}
                                sx={{
                                    backgroundColor: '#1C447F',
                                    pt: 2,
                                    pl: 2,
                                    pb: 2,
                                    pr: 2,
                                    borderTopLeftRadius: 6,
                                    borderTopRightRadius: 6,
                                }}
                                item
                            >
                                <Typography color='white' variant='h6'>
                                    {' '}
                                    {t(row.event_name || '') || ''}
                                </Typography>
                                <Box display='flex' justifyContent='end' alignItems='center'>
                                    <div>
                                        <div className='d-flex justify-content-end flex-shrink-0'>
                                            <button
                                                className='btn btn-icon btn-active-color-secondary btn-sm me-3'
                                                style={{ background: '#C4D2E8' }}
                                                onClick={() => handleEdit(row)}
                                                type="button"
                                            >
                                                <EditIcon />
                                            </button>

                                            <button
                                                className='btn btn-icon btn-active-color-secondary btn-sm'
                                                onClick={() => handleRemoveExperienciaLaboral(row.id || 0)}
                                                style={{ background: '#DD1342' }}
                                                type="button"
                                            >
                                                <DeleteOutlinedIcon style={{ color: 'white' }} />
                                            </button>
                                        </div>
                                    </div>
                                </Box>
                            </Grid>
                            <Grid
                                container
                                direction='row'
                                justifyContent='space-between'
                                width={'100%'}
                                mb={5}
                                sx={{
                                    border: 1,
                                    borderBottom: 3,
                                    borderColor: '#AFADAD',
                                    borderBottomLeftRadius: 6,
                                    borderBottomRightRadius: 6,
                                    pt: 5,
                                    pl: 3,
                                    pr: 3,
                                }}
                                item
                            >

                                <Grid xs={12} md={3} mb={5} mr={5} item>
                                    <Typography>{t('ORGANIZER') + ': ' + (row.organizer || '')}</Typography>
                                    <Typography>{t('EVENT_NAME') + ': ' + (row.event_name || '')}</Typography>
                                </Grid>
                                <Grid xs={1} mb={5} sx={{ borderLeft: 2, borderColor: '#AFADAD' }} item />
                                <Grid xs={12} md={2} mb={5} mr={2} item>
                                    <Typography>{t('Fecha') + ': ' + (row.date || '')}</Typography>
                                    <Typography>{t('PARTICIPATION_TYPE') + ': ' + (formatTypeParticipation(row.type_of_participation) || '')}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    variant={'filled'}
                    severity={alert ? 'success' : 'error'}
                    sx={{ whiteSpace: 'pre-line' }}
                >
                    {alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
                    {t(alertMsg)}
                </Alert>
            </Snackbar>
        </>
    )
}