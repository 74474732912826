import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import CurriculumContext from '../contexts/CurriculumContext';
import VacancyContext from '../contexts/VacancyContext';
import { AdminAreaWrapper } from '../pages/admin/AdminAreaWrapper';
import { EmployerAreaWrapper } from '../pages/employer/EmployerAreaWrapper';
import { ProvisionalAreaWrapper } from '../pages/employer/ProvisionalAreaWrapper';
import { CounselorAreaWrapper } from '../pages/counselor/CounselorAreaWrapper';
import { EmployerMatchingCandidateWrapper } from '../pages/employer/EmployerMatchingCandidateWrapper';
import { CandidateAreaWrapper } from '../pages/candidate/CandidateAreaWrapper';
import { AdminCandidateManagementWrapper } from '../pages/admin/AdminCandidateManagementWrapper';
import { AdminCompanyManagementWrapper } from '../pages/admin/AdminCompanyManagementWrapper';
import { AdminRegistroManagementWrapper } from '../pages/admin/AdminRegistroManagementWrapper';
import { CandidateMatchingVacancyWrapper } from '../pages/candidate/CandidateMatchingVacancyWrapper';
import { CandidateRequestedVacancyWrapper } from '../pages/candidate/CandidateRequestedVacancyWrapper';
import { CandidateSearchVacantes } from '../pages/candidate/CandidateSearchVacantes';
import CustomCalendarComponent from '../components/CustomCalendar/Index';
import * as authHelper from '../modules/auth/core/AuthHelpers';
import { RoleEnum } from '../models/enums/RoleEnum';
import { AuthPage } from '../modules/auth';
import { AdminCreateUserWrapper } from '../pages/admin/AdminCreateUserWrapper';
import ResumeComponent from '../components/Resume/Index';
import ResumeCandidatoComponent from '../components/ResumeCandidato/Index';
import VacancyComponent from '../components/Vacancy/Index';
import { EmployerManagementVacancyWrapper } from '../pages/employer/EmployerManagementVacancyWrapper';
import { EmployerRequestedFeedbackCandidatoWrapper } from '../pages/employer/EmployerRequestedFeedbackCandidatoWrapper';
import { EmployerRequestedCandidatoVacancyWrapper } from '../pages/employer/EmployerRequestedCandidatoVacancyWrapper';
import { EmployerRequestedCandidatoPostularWrapper } from '../pages/employer/EmployerRequestedCandidatoPostularWrapper';
import { CounselorRequestedCandidatosWrapper } from '../pages/counselor/CounselorRequestedCandidatosWrapper';
import Profile from '../pages/profile/Profile';
import CompanyEditContext from '../contexts/CompanyEditContext';

const PrivateRoutes = () => {
	// const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
	// const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
	// const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
	// const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
	// const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
	// const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

	const { user } = authHelper.getAuth() || {};
	console.log('user', user?.role);
	console.log('user', user?.empresa_id);
	const role = user?.role;
	const hasCompany = user?.empresa_id ? true : false;

	const RoutesAdmin = () => {
		return (
			<Routes>
				<Route element={<MasterLayout />}>
					<Route path='auth/*' element={<Navigate to='/admin/area' />} />
					<Route path='admin/area' element={<AdminAreaWrapper />} />
					<Route path='admin/gestion-candidatos' element={<AdminCandidateManagementWrapper />} />
					<Route path='admin/gestion-empresas' element={<AdminCompanyManagementWrapper />} />
					<Route path='admin/gestion-registros' element={<AdminRegistroManagementWrapper />} />
					<Route path='admin/nuevo-usuario' element={<AdminCreateUserWrapper />} />
					<Route path='admin/editar-usuario/:id' element={<AdminCreateUserWrapper />} />
					<Route path='admin/registro-de-empresa/:id' element={<CompanyEditContext />} />
					<Route path='profile' element={<Profile />} />
				</Route>
			</Routes>
		);
	};

	const RoutesCandidate = () => {
		return (
			<Routes>
				<Route element={<MasterLayout />}>
					<Route path='auth/*' element={<Navigate to='/candidato/area' />} />
					<Route path='candidato/area' element={<CandidateAreaWrapper />} />
					<Route path='candidato/matching-vacantes' element={<CandidateMatchingVacancyWrapper />} />
					<Route path='candidato/search-vacantes' element={<CandidateSearchVacantes />} />
					<Route path='candidato/search-vacantes/:vacante' element={<CandidateSearchVacantes />} />
					<Route
						path='candidato/postuladas-vacantes'
						element={<CandidateRequestedVacancyWrapper />}
					/>
					<Route path='candidato/crear-hoja-vida/:id' element={<CurriculumContext />} />
					<Route path='candidato/citas-programadas' element={<CustomCalendarComponent />} />
					<Route path='candidato/vacante/:id' element={<VacancyComponent />} />
					<Route path='profile' element={<Profile />} />
				</Route>
			</Routes>
		);
	};

	const RoutesCounselor = () => {
		return (
			<Routes>
				<Route element={<MasterLayout />}>
					<Route path='auth/*' element={<Navigate to='/consejero/area' />} />
					<Route path='consejero/area' element={<CounselorAreaWrapper />} />
					<Route path='consejero/nuevo-usuario' element={<AdminCreateUserWrapper />} />
					<Route
						path='consejero/gestion-candidatos'
						element={<CounselorRequestedCandidatosWrapper />}
					/>
					<Route path='consejero/gestion-empresas' element={<AdminCompanyManagementWrapper />} />
					<Route path='consejero/registro-de-empresa/:id' element={<CompanyEditContext />} />
					<Route path='consejero/gestion-de-vacante' element={<VacancyContext />} />
					<Route path='consejero/gestion-vacantes' element={<EmployerManagementVacancyWrapper />} />
					<Route path='consejero/gestion-de-vacante/:id' element={<VacancyContext />} />
					<Route
						path='consejero/candidatos-postula/:id_vacante'
						element={<EmployerRequestedCandidatoPostularWrapper />}
					/>
					<Route
						path='consejero/hoja-vida/:id/:id_empresa/:id_vacancy'
						element={<ResumeComponent />}
					/>
					<Route
						path='consejero/matching-candidatos/:id'
						element={<EmployerMatchingCandidateWrapper />}
					/>
					<Route path='consejero/crear-hoja-vida/:id' element={<CurriculumContext />} />
					<Route path='consejero/hoja-vida/:id' element={<ResumeComponent />} />
					<Route path='consejero/crear-hoja-vida/:id' element={<CurriculumContext />} />
					<Route path='profile' element={<Profile />} />
				</Route>
			</Routes>
		);
	};

	const RoutesEmployer = () => {
		return (
			<Routes>
				<Route element={<MasterLayout />}>
					<Route path='auth/*' element={<Navigate to='/empleador/area' />} />
					<Route path='empleador/area' element={<EmployerAreaWrapper />} />
					<Route
						path='empleador/matching-candidatos'
						element={<EmployerMatchingCandidateWrapper />}
					/>
					<Route
						path='empleador/matching-candidatos/:id'
						element={<EmployerMatchingCandidateWrapper />}
					/>
					<Route
						path='empleador/gestion-candidatos/:id_empresa'
						element={<EmployerRequestedCandidatoVacancyWrapper />}
					/>
					<Route
						path='empleador/feedback-candidatos/:id_empresa'
						element={<EmployerRequestedFeedbackCandidatoWrapper />}
					/>
					<Route
						path='empleador/candidatos-postula/:id_vacante'
						element={<EmployerRequestedCandidatoPostularWrapper />}
					/>
					<Route path='empleador/gestion-vacantes' element={<EmployerManagementVacancyWrapper />} />
					<Route
						path='empleador/hoja-vida/:id/:id_empresa/:id_vacancy'
						element={<ResumeComponent />}
					/>
					<Route
						path='empleador/entrevista/:id/:id_empresa/:id_vacancy'
						element={<ResumeCandidatoComponent />}
					/>
					<Route path='empleador/gestion-de-vacante' element={<VacancyContext />} />
					<Route path='empleador/gestion-de-vacante/:id' element={<VacancyContext />} />
					<Route path='profile' element={<Profile />} />
				</Route>
			</Routes>
		);
	};
	const RoutesProvisional = () => {
		return (
			<Routes>
				<Route element={<MasterLayout />}>
					<Route path='auth/*' element={<Navigate to='/empleador/area' />} />
					<Route path='empleador/area' element={<ProvisionalAreaWrapper />} />

					<Route path='empleador/registro-de-empresa/:id' element={<CompanyEditContext />} />
					<Route path='profile' element={<Profile />} />
				</Route>
			</Routes>
		);
	};
	const RoutesWithoutRole = () => {
		return (
			<Routes>
				<Route path='auth/*' element={<AuthPage />} />
			</Routes>
		);
	};

	switch (role) {
		case RoleEnum.ADMIN:
			if (hasCompany) {
				return <RoutesEmployer />;
			} else {
				return <RoutesAdmin />;
			}
		case RoleEnum.CANDIDATE:
			return <RoutesCandidate />;
		/* case RoleEnum.COUNSELOR:
			return <RoutesCounselor />; */
		case RoleEnum.EMPLOYER:
			return <RoutesEmployer />;
		case RoleEnum.PROVISIONAL:
			return <RoutesProvisional />;
		default:
			return <RoutesWithoutRole />;
	}

	//return (
	// <Routes>
	//   <Route element={<MasterLayout />}>
	//     <RoutesAdmin />
	//     {/* Redirect to Dashboard after success login/registartion */}
	//     {/* <Route path='auth/*' element={<Navigate to='/empleador-area' />} /> */}
	//     {/* Pages */}
	//     {/* <Route path='dashboard' element={<DashboardWrapper />} />
	//     <Route path='builder' element={<BuilderPageWrapper />} />
	//     <Route path='menu-test' element={<MenuTestPage />} />
	//     <Route path='cadastro' element={<CurriculumContext />} />
	//     <Route path='gestion-de-vacante' element={<VacancyContext />} />
	//     <Route path='registro-de-empresa' element={<CompanyContext />} />
	//     <Route path='admin-area' element={<AdminAreaWrapper />}/>
	//     <Route path='empleador-area' element={<EmployerAreaWrapper />}/>
	//     <Route path='consejero-area' element={<CounselorAreaWrapper />}/>
	//     <Route path='candidato-area' element={<CandidateAreaWrapper />} />
	//     <Route path='gestion-vacantes' element={<EmployerVacancyManagementWrapper />}/>
	//     <Route path='gestion-candidatos' element={<AdminCandidateManagementWrapper />}/>
	//     <Route path='candidato-matching-vacantes' element={<CandidateMatchingVacancyWrapper />}/>
	//     <Route path='candidato-postuladas-vacantes' element={<CandidateRequestedVacancyWrapper />}/>
	//     <Route path='candidato-crear-hoja-vida' element={<CurriculumContext />}/>
	//     <Route path='candidato-citas-programadas' element={<CustomCalendarComponent />}/> */}

	//     {/* Lazy Modules */}
	//     <Route
	//       path='crafted/pages/profile/*'
	//       element={
	//         <SuspensedView>
	//           <ProfilePage />
	//         </SuspensedView>
	//       }
	//     />
	//     <Route
	//       path='crafted/pages/wizards/*'
	//       element={
	//         <SuspensedView>
	//           <WizardsPage />
	//         </SuspensedView>
	//       }
	//     />
	//     <Route
	//       path='crafted/widgets/*'
	//       element={
	//         <SuspensedView>
	//           <WidgetsPage />
	//         </SuspensedView>
	//       }
	//     />
	//     <Route
	//       path='crafted/account/*'
	//       element={
	//         <SuspensedView>
	//           <AccountPage />
	//         </SuspensedView>
	//       }
	//     />
	//     <Route
	//       path='apps/chat/*'
	//       element={
	//         <SuspensedView>
	//           <ChatPage />
	//         </SuspensedView>
	//       }
	//     />
	//     <Route
	//       path='apps/user-management/*'
	//       element={
	//         <SuspensedView>
	//           <UsersPage />
	//         </SuspensedView>
	//       }
	//     />
	//     {/* Page Not Found */}
	//     <Route path='*' element={<Navigate to='/error/404' />} />
	//   </Route>
	// </Routes>
	//)
};

// const SuspensedView: FC<WithChildren> = ({ children }) => {
//   const baseColor = getCSSVariableValue('--kt-primary')
//   TopBarProgress.config({
//     barColors: {
//       '0': baseColor,
//     },
//     barThickness: 1,
//     shadowBlur: 5,
//   })
//   return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
// }

export { PrivateRoutes };
