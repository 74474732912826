import { border, height } from '@mui/system';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PageTitle } from '../../../_metronic/layout/core';
import styleScss from './styles.module.scss';
import { CardModel } from '../../models/CardModel';
import { getVacanteMatching, getCurriculoPDF } from '../../modules/auth/core/_requests';
import { getMeetsByCandidate } from '../../modules/apps/user-management/users-list/core/_requests';
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Snackbar,
	TextField,
	Typography,
} from '@mui/material';
import { auto } from '@popperjs/core';
import { useAuth } from '../../../app/modules/auth';
import Chatbot from '../../components/Chatbot/index';
import { useTranslation } from 'react-i18next';
const CandidateAreaPage = () => {
	const { t } = useTranslation();
	const { currentUser, auth } = useAuth();
	const [listScheduledMeetings, setListScheduledMeetings]: any[] = useState([]);
	const [listMatch, setListMatch]: any[] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingPDF, setLoadingPDF] = useState(false);
	const [msg, setMsg] = useState(false);
	const [open, setOpen] = useState(false);
	const [msgAlert, setMsgAlert] = useState('');
	const [isError, setIsError] = useState(false);
	const [meets, setMeets]: any[] = useState([]);
	const [search, setSearch] = useState('');
	const [idHash, setIdHash] = useState('');

	const handleClose = () => {
		setOpen(false);
	};

	async function getVacantes() {
		setLoading(true);
		try {
			const { data } = await getVacanteMatching();
			console.log('vacantes', data);
			setListMatch(data);
			//setIdHash(vacantes.data[0].userHash.idHash);
			setLoading(false);
		} catch (error: any) {
			console.log('Erro: ', error);
			console.error(error.response.data.message);
			setMsgAlert(error.response.data.message);
			setLoading(false);
		}
	}

	async function getCurriculo() {
		setLoadingPDF(true);
		try {
			const response = await getCurriculoPDF();

			const url = window.URL.createObjectURL(
				new Blob([response.data], { type: 'application/pdf' })
			);
			const link = document.createElement('a');
			link.href = url;
			//link.setAttribute('download', `hoja_de_vida_.pdf`); //Opção para download direto do PDF
			link.setAttribute('target', '_blank'); // Abre o PDF em uma nova aba
			link.setAttribute('rel', 'noopener noreferrer'); // Adiciona o atributo rel para segurança
			document.body.appendChild(link);
			link.click();
			setLoadingPDF(false);
		} catch (error: any) {
			setIsError(true);
			setOpen(true);
			setMsgAlert('¡Completa tu hoja de vida!');
			setLoadingPDF(false);
		}
	}

	const listDashboard: CardModel[] = [
		{
			title: 'Dashboard',
			content: 'Veja os números do mercado de trabalho da sua cidade.',
			action:
				'https://lookerstudio.google.com/u/0/reporting/8f7dc586-9e97-4d12-afe3-4753790971b9/page/p_4ymnosdz6c?params=%7B%22df208%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580' +
				idHash +
				'%22%7D',
		},
	];

	async function loadMeets(idUser: any) {
		// await getMeetsByCandidate(`id_candidate=${idUser}`).then((response) => {
		// 	setMeets(response.data);
		// });
	}

	useEffect(() => {
		let idUser = currentUser?.user?.id;
		loadMeets(idUser);
		getVacantes();
	}, []);

	return (
		<>
			<div className='row mt-8'>
				<div className='col-sm-8'>
					<div className='row d-flex align-items-stretch'>
						<div className='col-sm-6 d-flex h-100'>
							<div
								className={`card mb-10 mt-10 ${styleScss['dark-blue-card']}`}
								style={{ borderRadius: '10px' }}
								key={100}
							>
								<div className='card-body mt-5'>
									<div className={`${styleScss['top-icon-vacancy']}`}>
										<span className='svg-icon svg-icon-2 svg-icon-lg-1'>
											<svg
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
												className='mh-50px'
											>
												<path
													d='M3 2H10C10.6 2 11 2.4 11 3V10C11 10.6 10.6 11 10 11H3C2.4 11 2 10.6 2 10V3C2 2.4 2.4 2 3 2Z'
													fill='white'
												></path>
												<path
													opacity='0.3'
													d='M14 2H21C21.6 2 22 2.4 22 3V10C22 10.6 21.6 11 21 11H14C13.4 11 13 10.6 13 10V3C13 2.4 13.4 2 14 2Z'
													fill='white'
												></path>
												<path
													opacity='0.3'
													d='M3 13H10C10.6 13 11 13.4 11 14V21C11 21.6 10.6 22 10 22H3C2.4 22 2 21.6 2 21V14C2 13.4 2.4 13 3 13Z'
													fill='white'
												></path>
												<path
													opacity='0.3'
													d='M14 13H21C21.6 13 22 13.4 22 14V21C22 21.6 21.6 22 21 22H14C13.4 22 13 21.6 13 21V14C13 13.4 13.4 13 14 13Z'
													fill='white'
												></path>
											</svg>
										</span>
									</div>
									<h2 className='card-title text-white mt-4'>{t('Minhas Vagas')}</h2>
									<p className='card-text' style={{ fontSize: '15px', fontWeight: '500' }}>
										{t('Veja os processos seletivos dos quais você já está participando.')}
									</p>
									<Link
										to={'/candidato/postuladas-vacantes'}
										className={`btn btn-lg btn-block ${styleScss['btn-white']}`}
										style={{ padding: '8px 40px' }}
									>
										{t('VER')}
									</Link>
								</div>
							</div>
						</div>
						<div className='col-sm-6 d-flex h-100'>
							<div
								className={`card mb-10 mt-10 ${styleScss['dark-blue-card']} flex-grow-1`}
								style={{ borderRadius: '10px' }}
								key={100}
							>
								<div className='card-body mt-5 mb-5'>
									<h2 className='card-title text-white'>{t('Procurar vagas disponíveis')}</h2>
									<TextField
										sx={{
											'& label.Mui-focused': {
												color: 'white',
											},
											backgroundColor: '#FFF',
											border: 'transparent',
											borderRadius: '10px',
											marginBottom: '10px',
										}}
										fullWidth
										required
										id='busca_vacante'
										name='busca_vacante'
										placeholder={t('Nome da vaga')}
										size='medium'
										onChange={(e) => {
											setSearch(e.target.value);
										}}
									/>
									<Link
										to={`/candidato/search-vacantes/${search}`}
										className={`btn btn-lg btn-block ${styleScss['btn-white']}`}
										style={{ padding: '8px 40px' }}
									>
										{t('VER')}
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-sm-12 mt-10 d-flex h-100'>
							<div
								className={`card mb-10 ${styleScss['card']} flex-grow-1`}
								style={{ borderRadius: '10px' }}
							>
								<div className={`${styleScss['top-icon']}`}>
									<i className={`bi bi-file-earmark-text-fill ${styleScss['icon-top']}`}></i>
								</div>
								<div className='card-body mt-5'>
									<h2 className='card-title'>{t('Criar Currículo')}</h2>
									<p className='card-text' style={{ fontSize: '15px', fontWeight: '500' }}>
										{t(
											'Complete suas informações pessoais, profissionais e acadêmicas para gerar seu currículo.'
										)}
									</p>
									<Link
										to={`/candidato/crear-hoja-vida/${currentUser?.user?.id}`}
										className='btn btn-primary btn-lg btn-block'
										style={{ padding: '8px 40px' }}
									>
										{t('IR')}
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className='row d-flex align-items-stretch'>
						<div className='col-sm-6 d-flex h-100'>
							{listDashboard.map((x: CardModel, i: number) => (
								<div
									className={`card mb-5 ${styleScss['blue-card']} flex-grow-1`}
									key={i}
									id={i.toString()}
								>
									{x.action ? (
										<a href={x.action} target='_blank' rel='noopener noreferrer'>
											<div className='card-body'>
												<i
													className={`bi bi-rocket-takeoff-fill ${styleScss['icon-custom-white']}`}
												></i>
												<h2 className='card-title text-white mt-2'>{t(x.title)}</h2>
												<p className='card-text text-white'>{t(x.content)}</p>
											</div>
										</a>
									) : (
										<div className='card-body'>
											<i
												className={`bi bi-rocket-takeoff-fill ${styleScss['icon-custom-white']}`}
											></i>
											<h2 className='card-title text-white mt-2'>{t(x.title)}</h2>
											<p className='card-text text-white'>{t(x.content)}</p>
										</div>
									)}
								</div>
							))}
						</div>
						<div className='col-sm-6 d-flex h-100'>
							<div
								className={`card ${styleScss['blue-card']} flex-grow-1`}
								style={{ borderRadius: '10px' }}
							>
								{loadingPDF ? (
									<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
										<CircularProgress sx={{ color: 'white' }} size={100} />
									</Box>
								) : (
									<div className='card-body mt-10 cursor-pointer' onClick={getCurriculo}>
										<i className={`bi bi-file-person-fill ${styleScss['icon-custom-white']}`}></i>
										<br />
										<h2 className='card-title text-white mt-2'>{t('Currículo')}</h2>
										<p
											className='card-text text-white'
											style={{ fontSize: '15px', fontWeight: '500' }}
										>
											{t('Baixe o seu CV em PDF')}
										</p>
										<br />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className='col-sm-4 d-flex flex-column'>
					<div className={`card ${styleScss['card']} flex-grow-1`} style={{ borderRadius: '10px' }}>
						<div className={`${styleScss['top-icon']}`}>
							<i className={`bi bi-calendar-check ${styleScss['icon-top']}`}></i>
						</div>
						<div className='card-body mt-5'>
							<h2 className='card-title'>{t('DEU MATCH!')}</h2>
							<p className='card-text' style={{ fontSize: '15px', fontWeight: '500' }}>
								{t('Estas são as vagas com as quais você tem mais afinidade')}.
							</p>
							{loading ? (
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<CircularProgress />
								</Box>
							) : listMatch.length === 0 ? (
								<Typography variant='h6'>{t(msgAlert)}</Typography>
							) : (
								<>
									{listMatch.slice(0, 6).map((x: any, i: any) => (
										<Link to={`/candidato/vacante/${x.vacancy_id}`} key={i} id={x.vacancy_id}>
											<div
												className='mb-2'
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: '10px',
												}}
											>
												<div className={`${styleScss['box']}`}>
													<p className={`${styleScss['txt-center']}`}>{x.matching}%</p>
												</div>
												<div className='mt-3'>
													<p className='text-primary'>
														<b>{t(x.job_title)}</b>
														<br />
														<span className='text-black' style={{ fontWeight: '500' }}>
															{x.company_name}
														</span>
													</p>
												</div>
											</div>
										</Link>
									))}
									<Link
										to='/candidato/matching-vacantes'
										className='btn btn-primary mt-4'
										style={{ padding: '8px 40px' }}
									>
										{t('VER DETALHES')}
									</Link>
								</>
							)}
						</div>
					</div>
				</div>
				{/* Descomentar

					<div className='row'>
						
					</div>
*/}
				{/* <div className='row'>
						{
							<div className='col-sm-8'>
								<div className={`card mb-10 ${styleScss['card']}`} style={{ borderRadius: '10px' }}>
									<div className={`${styleScss['top-icon']}`}>
										<i className={`bi bi-calendar2-week-fill ${styleScss['icon-top']}`}></i>
									</div>
									<div className='card-body mt-5'>
										<h2 className='card-title'>{t('Agenda de Compromissos')}</h2>
										<p className='card-text' style={{ fontSize: '15px', fontWeight: '500' }}>
											{t('Não perca os horários agendados.')}
										</p>
										<div
											className='row'
											style={{
												overflowX: 'auto',
												overflowY: 'hidden',
												flexWrap: 'nowrap',
											}}
										>
											{meets.map((x: any, i: any) => (
												<div
													onClick={() => window.open(x.link_event, '_blank')}
													className='col-sm-12 d-flex'
													key={i}
													id={x.id}
													style={{ cursor: 'pointer' }}
												>
													<div className={`${styleScss['box-aside']}`}>
														<p className={`${styleScss['txt-center']}`}>
															{new Date(x.inicio)
																.toJSON()
																.slice(5, 10)
																.split('-')
																.reverse()
																.join('/')}
														</p>
													</div>
													<div>
														<p className='text-primary'>
															{x.primer_nome} {x.segundo_nome ?? ''}
														</p>
														<p>{new Date(x.inicio).toString().substring(16, 21)}</p>
													</div>
												</div>
											))}
										</div>
										<br />
										<Link
											to={'/candidato/citas-programadas'}
											className='btn btn-primary btn-lg btn-block'
											style={{ padding: '8px 40px' }}
										>
											{t('VER')}
										</Link>
									</div>
								</div>
							</div>
						}
					</div> */}

				{/*

						<div className='col-sm-8'>
							<div className={`card mb-10 ${styleScss['card']}`} style={{ borderRadius: '10px' }}>
								<div className={`${styleScss['top-icon']}`}>
									<i className={`bi bi-calendar2-week-fill ${styleScss['icon-top']}`}></i>
								</div>
								<div className='card-body mt-5'>
									<h2 className='card-title'>Citas programadas</h2>
									<p className='card-text' style={{ fontSize: '15px', fontWeight: '500' }}>
										Calendario de tutoría y entrevistas.
									</p>
									<div
										className='row'
										style={{
											overflowX: 'auto',
											overflowY: 'hidden',
											flexWrap: 'nowrap',
										}}
									>
										{meets.map((x: any, i: any) => (
											<div onClick={() => window.open(x.link_event, '_blank')} className='col-sm-4' key={i} id={x.id} style={{cursor: 'pointer'}}>
												<div className={`${styleScss['box-aside']}`}>
													<p className={`${styleScss['txt-center']}`}>{new Date(x.inicio).toJSON().slice(5,10).split('-').reverse().join('/')}</p>
												</div>
												<div>
													<p className='text-primary'>{x.primer_nome} {x.segundo_nome ?? ""}</p>
													<p>{new Date(x.inicio).toString().substring(16,21)}</p>
												</div>
											</div>
										))}
									</div>
									<br />
									<Link
										to={'/candidato/citas-programadas'}
										className='btn btn-primary btn-lg btn-block'
										style={{ padding: '8px 40px' }}
									>
										Ver
									</Link>
								</div>
							</div>
						</div>
					</div>
*/}
			</div>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					variant='filled'
					severity={isError ? 'error' : 'success'}
					sx={{ width: '100%' }}
				>
					{t(msgAlert)}
				</Alert>
			</Snackbar>
		</>
	);
};

const CandidateAreaWrapper = () => {
	const { t } = useTranslation();
	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Área Candidato')}</PageTitle>
			<CandidateAreaPage />
		</>
	);
};

export { CandidateAreaWrapper };
