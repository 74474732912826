import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import HelpIcon from '@mui/icons-material/Help';
import clsx from 'clsx';
import { useAuth } from '../../modules/auth/core/Auth';
import { useNavigate, useParams } from 'react-router-dom';
import { PageTitle } from '../../../_metronic/layout/core';
import { RoleEnum } from '../../models/enums/RoleEnum';
import {
	createUserPost,
	getAllActiveCompanies,
	getUserDataById,
	getCompanies,
	updateUserPut,
} from '../../modules/apps/user-management/users-list/core/_requests';
import {
	formatMaskPhoneNumber12Digits,
	initValue,
	isValidFieldRequired,
	regExpPhoneNumber12Digits,
	setValueOrDefault,
} from '../../util/Index';
import { Alert, Snackbar, AlertTitle } from '@mui/material';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const AdminCreateUserPage = () => {
	const { currentUser, auth } = useAuth();
	const { id } = useParams();
	const [editMode, setEditMode] = useState(false);
	const [loading, setLoading] = useState(false);
	const [listCompanies, setListCompanies] = useState([{ id: 0, company_name: '', status: false }]);
	const [role, setRole] = useState('1');
	const navigate = useNavigate();

	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const handleClose = () => {
		setOpen(false);
	};

	const [documentTypeSelected, setDocumentTypeSelected] = useState('');
	const [maxDocumentFieldLenght, setMaxDocumentFieldLenght] = useState(0);
	const [minDocumentFieldLenght, setMinDocumentFieldLenght] = useState(0);
	const [documentTypeField, setDocumentTypeField] = useState('text');

	useEffect(() => {
		if (id !== undefined && parseInt(id) > 0) getFieldsEditMode();
		else getFieldsCreateMode();
	}, []);

	useEffect(() => {
		console.log('Passou');
		if (auth?.user?.role !== RoleEnum.EMPLOYER) {
			ddlTpUserRole.splice(0, ddlTpUserRole.length, { id: RoleEnum.CANDIDATE, name: 'Candidato' });
		}
		console.log(ddlTpUserRole);
	}, [auth?.user?.role]);

	const getFieldsEditMode = async () => {
		setEditMode(true);
		await getAllCompaniesAPI().then((e) => getUserAPI(e));
	};

	const getFieldsCreateMode = async () => {
		setEditMode(false);
		await getAllCompaniesAPI();
	};

	const getUserAPI = async (e: any) => {
		try {
			setLoading(true);
			const user = await getUserDataById(`id=${id}`);
			console.log('user data', user.data);
			//formik.setFieldValue('firstName', user.data?.UserComplemento?.primer_nome);
			// let phoneFormated = initValue(user.data?.UserComplemento?.celular)
			// 	?.replace(/\D/g, '')
			// 	?.match(matchPhoneNumber12Digits());
			// if (phoneFormated !== null)
			// 	phoneFormated = '+' + phoneFormated[1] + '-' + phoneFormated[2] + '-' + phoneFormated[3];
			setInitialValues({
				...initialValues,
				firstName: initValue(user.data?.UserComplement?.first_name),
				documentType: initValue(user.data?.UserComplement?.document_type),
				documentNumber: initValue(user.data?.UserComplement?.document_number?.replace(/\D/g, '')),
				//secondName: initValue(user.data?.UserComplement?.last_name),
				firstNickname: initValue(user.data?.UserComplement?.last_name || ''),
				//secondNickname: initValue(user.data?.UserComplement?.second_nickname || ''),
				roleType: initValue(user.data?.role),
				email: initValue(user.data?.email),
				phoneNumber: formatMaskPhoneNumber12Digits(initValue(user.data?.UserComplement?.phone_number)),
				gender: initValue(user.data?.UserComplement?.gender),
				active: setValueOrDefault(user.data?.active, 'boolean'),
				company: initValue(user.data?.company_id),
				/* company: isValidFieldRequired(user.data?.company_id)
					? (e.find((x: any) => x.id === user.data?.company_id)?.razon_social as string)
					: '', */
			});
			
			changeRole(user.data?.role);
			setDocumentTypeSelected(initValue(user.data?.UserComplement?.document_type));
			validationSchemaByTpDoc();
		} catch (error: any) {
			console.error('error', error);
		} finally {
			setLoading(false);
		}
	};

	const getAllCompaniesAPI = async () => {
		try {
			const companies = await getAllActiveCompanies();
			console.log('4444, companies', companies);
			setListCompanies(companies.data);
			return companies.data;
		} catch (error: any) {
			console.error('error', error);
		}
	};
	// /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#f5f5f9',
			color: 'rgba(0, 0, 0, 0.87)',
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(12),
			border: '1px solid #dadde9',
		},
	}));

	const ddlTipoDocument = [
		/* { id: 'CC', name: 'Cédula de Ciudadanía' },
		{ id: 'TI', name: 'Tarjeta de Identidad' },
		{ id: 'CE', name: 'Cédula de Extranjería' },
		{ id: 'PA', name: 'Pasaporte' },
		{ id: 'PEP', name: 'Permiso Especial de Permanencia' },
		{ id: 'PT', name: 'Permiso por Protección Temporal' }, */
		{ id: 'CPF', name: 'CPF' },
		{ id: 'CNPJ', name: 'CNPJ' },
	];

	const ddlTpUserRole = [
		{ id: RoleEnum.ADMIN, name: 'Administrador' },
		{ id: RoleEnum.CANDIDATE, name: 'Candidato' },
		/* { id: RoleEnum.COUNSELOR, name: 'Consejero' }, */
		{ id: RoleEnum.EMPLOYER, name: 'Empleador' },
	];

	// const userSchema =
	// 	role === '4'
	// 		? Yup.object().shape({
	// 				email: Yup.string()
	// 					.email('Formato de correo electrónico incorrecto')
	// 					.min(3, 'Mínimo 3 símbolos')
	// 					.max(50, 'Máximo 50 símbolos')
	// 					.required('Campo requerido'),
	// 				documentType: Yup.string()
	// 					.test('len', 'Seleccione un tipo de documento', (val) => {
	// 						return (val as string) === '' ? false : true;
	// 					})
	// 					.required('Campo requerido'),
	// 				roleType: Yup.string()
	// 					.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
	// 						return (val as string) === '' ? false : true;
	// 					})
	// 					.required('Campo requerido'),
	// 				active: Yup.string()
	// 					.test('len', 'Seleccione si el usuario está activo', (val) => {
	// 						return (val as string) === '' ? false : true;
	// 					})
	// 					.required('Campo requerido'),
	// 				documentNumber: Yup.number().required('Campo es requerido'),
	// 				firstName: Yup.string().required('Campo requerido'),
	// 				secondName: Yup.string(),
	// 				firstNickname: Yup.string().required('Campo requerido'),
	// 				secondNickname: Yup.string(),
	// 				gender: Yup.string()
	// 					.test('len', 'Seleccione un género', (val) => {
	// 						return val === '' ? false : true;
	// 					})
	// 					.required('Campo requerido'),
	// 				phoneNumber: Yup.string()
	// 					.transform((value) =>
	// 						value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
	// 					)
	// 					.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
	// 					.required('Campo requerido'),
	// 				company: Yup.string()
	// 					.test('len', 'Seleccione una empresa', (val) => {
	// 						console.log('val', val);
	// 						return val === undefined || val === '' || val === null ? false : true;
	// 					})
	// 					.required('Campo requerido')
	// 					.nullable(),
	// 		  })
	// 		: Yup.object().shape({
	// 				email: Yup.string()
	// 					.email('Formato de correo electrónico incorrecto')
	// 					.min(3, 'Mínimo 3 símbolos')
	// 					.max(50, 'Máximo 50 símbolos')
	// 					.required('Campo requerido'),
	// 				documentType: Yup.string()
	// 					.test('len', 'Seleccione un tipo de documento', (val) => {
	// 						return (val as string) === '' ? false : true;
	// 					})
	// 					.required('Campo requerido'),
	// 				roleType: Yup.string()
	// 					.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
	// 						return (val as string) === '' ? false : true;
	// 					})
	// 					.required('Campo requerido'),
	// 				active: Yup.string()
	// 					.test('len', 'Seleccione si el usuario está activo', (val) => {
	// 						return (val as string) === '' ? false : true;
	// 					})
	// 					.required('Campo requerido'),
	// 				documentNumber: Yup.number().required('Campo requerido'),
	// 				firstName: Yup.string().required('Campo requerido'),
	// 				secondName: Yup.string(),
	// 				firstNickname: Yup.string().required('Campo requerido'),
	// 				secondNickname: Yup.string(),
	// 				gender: Yup.string()
	// 					.test('len', 'Seleccione un género', (val) => {
	// 						return val === '' ? false : true;
	// 					})
	// 					.required('Campo requerido'),
	// 				phoneNumber: Yup.string()
	// 					.transform((value) =>
	// 						value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
	// 					)
	// 					.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
	// 					.required('Campo requerido'),
	// 				company: Yup.string().nullable(),
	// 		  });
	
	const validationSchemaByTpDoc = () => {
		switch (documentTypeSelected) {
			case 'CC':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(5);
				setMaxDocumentFieldLenght(10);
				return role === '4'
					? Yup.object().shape({
							email: Yup.string()
								.email(`${t('Formato de correo electrónico incorrecto')}`)
								.min(3,(`${t('Mínimo 3 símbolos')}`))
								.max(50, (`${t('Máximo 50 símbolos')}`))
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.string()
								.matches(/^[0-9]+$/, 'Deben ser solo números')
								.required('Numero de Documento es requerido')
								.min(5, 'Debe tener al menos 5 dígitos')
								.max(10, 'Debe tener 10 o menos dígitos'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							company: Yup.string()
								.test('len', 'Seleccione una empresa', (val) => {
									console.log('val', val);
									return val === undefined || val === '' || val === null ? false : true;
								})
								.required('Campo requerido')
								.nullable(),
					  })
					: role === '3'
					? Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.test(
									'domain',
									'El correo electrónico debe tener el dominio @bogotatrabaja.gov.co o @desarrolloeconomico.gov.co',
									(value) => {
										if (value) {
											const allowedDomains = [
												'@bogotatrabaja.gov.co',
												'@desarrolloeconomico.gov.co',
											];
											return allowedDomains.some((domain) => value.endsWith(domain));
										}
										return true;
									}
								)
								.required('Campo requerido'),
							/* 
							.test(
									'domain',
									'El correo electrónico debe tener el dominio @bogotatrabaja.gov.co o @desarrolloeconomico.gov.co',
									(value) => {
										if (value) {
											return value.endsWith('@bogotatrabaja.gov.co');
										}
										return true;
									}
								)
								*/
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.string()
								.matches(/^[0-9]+$/, 'Deben ser solo números')
								.required('Numero de Documento es requerido')
								.min(5, 'Debe tener al menos 5 dígitos')
								.max(10, 'Debe tener 10 o menos dígitos'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							// company: Yup.string()
							// 	.test('len', 'Seleccione una empresa', (val) => {
							// 		console.log('val', val);
							// 		return val === undefined || val === '' || val === null ? false : true;
							// 	})
							// 	.required('Campo requerido')
							// 	.nullable(),
					  })
					: Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.string()
								.matches(/^[0-9]+$/, 'Deben ser solo números')
								.required('Numero de Documento es requerido')
								.min(5, 'Debe tener al menos 5 dígitos')
								.max(10, 'Debe tener 10 o menos dígitos'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							company: Yup.string().nullable(),
					  });
			case 'TI':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(0);
				setMaxDocumentFieldLenght(12);
				return role === '4'
					? Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.string()
								.matches(/^[A-Za-z0-9]*$/, 'Deben ser solo números y letras')
								.required('Numero de Documento es requerido')
								.max(12, 'Debe tener 12 o menos dígitos'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							company: Yup.string()
								.test('len', 'Seleccione una empresa', (val) => {
									console.log('val', val);
									return val === undefined || val === '' || val === null ? false : true;
								})
								.required('Campo requerido')
								.nullable(),
					  })
					: role === '3'
					? Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.test(
									'domain',
									'El correo electrónico debe tener el dominio @bogotatrabaja.gov.co o @desarrolloeconomico.gov.co',
									(value) => {
										if (value) {
											const allowedDomains = [
												'@bogotatrabaja.gov.co',
												'@desarrolloeconomico.gov.co',
											];
											return allowedDomains.some((domain) => value.endsWith(domain));
										}
										return true;
									}
								)
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.string()
								.matches(/^[0-9]+$/, 'Deben ser solo números')
								.required('Numero de Documento es requerido')
								.min(5, 'Debe tener al menos 5 dígitos')
								.max(10, 'Debe tener 10 o menos dígitos'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							// company: Yup.string()
							// 	.test('len', 'Seleccione una empresa', (val) => {
							// 		console.log('val', val);
							// 		return val === undefined || val === '' || val === null ? false : true;
							// 	})
							// 	.required('Campo requerido')
							// 	.nullable(),
					  })
					: Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.string()
								.matches(/^[A-Za-z0-9]*$/, 'Deben ser solo números y letras')
								.required('Numero de Documento es requerido')
								.max(12, 'Debe tener 12 o menos dígitos'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							company: Yup.string().nullable(),
					  });
			case 'CE':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(9);
				setMaxDocumentFieldLenght(10);
				return role === '4'
					? Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.string()
								.matches(/^[0-9]+$/, 'Deben ser solo números')
								.required('Numero de Documento es requerido')
								.min(9, 'Debe tener al menos 9 dígitos')
								.max(10, 'Debe tener 10 o menos dígitos'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							company: Yup.string()
								.test('len', 'Seleccione una empresa', (val) => {
									console.log('val', val);
									return val === undefined || val === '' || val === null ? false : true;
								})
								.required('Campo requerido')
								.nullable(),
					  })
					: role === '3'
					? Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.test(
									'domain',
									'El correo electrónico debe tener el dominio @bogotatrabaja.gov.co o @desarrolloeconomico.gov.co',
									(value) => {
										if (value) {
											const allowedDomains = [
												'@bogotatrabaja.gov.co',
												'@desarrolloeconomico.gov.co',
											];
											return allowedDomains.some((domain) => value.endsWith(domain));
										}
										return true;
									}
								)
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.string()
								.matches(/^[0-9]+$/, 'Deben ser solo números')
								.required('Numero de Documento es requerido')
								.min(5, 'Debe tener al menos 5 dígitos')
								.max(10, 'Debe tener 10 o menos dígitos'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							// company: Yup.string()
							// 	.test('len', 'Seleccione una empresa', (val) => {
							// 		console.log('val', val);
							// 		return val === undefined || val === '' || val === null ? false : true;
							// 	})
							// 	.required('Campo requerido')
							// 	.nullable(),
					  })
					: Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.string()
								.matches(/^[0-9]+$/, 'Deben ser solo números')
								.required('Numero de Documento es requerido')
								.min(9, 'Debe tener al menos 9 dígitos')
								.max(10, 'Debe tener 10 o menos dígitos'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							company: Yup.string().nullable(),
					  });
			case 'PA':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(4);
				setMaxDocumentFieldLenght(7);
				return role === '4'
					? Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.string()
								.matches(/^[A-Za-z0-9]*$/, 'Deben ser solo números y letras')
								.required('Numero de Documento es requerido')
								.min(4, 'Debe tener al menos 4 dígitos')
								.max(7, 'Debe tener 7 o menos dígitos'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							company: Yup.string()
								.test('len', 'Seleccione una empresa', (val) => {
									console.log('val', val);
									return val === undefined || val === '' || val === null ? false : true;
								})
								.required('Campo requerido')
								.nullable(),
					  })
					: role === '3'
					? Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.test(
									'domain',
									'El correo electrónico debe tener el dominio @bogotatrabaja.gov.co o @desarrolloeconomico.gov.co',
									(value) => {
										if (value) {
											const allowedDomains = [
												'@bogotatrabaja.gov.co',
												'@desarrolloeconomico.gov.co',
											];
											return allowedDomains.some((domain) => value.endsWith(domain));
										}
										return true;
									}
								)
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.string()
								.matches(/^[0-9]+$/, 'Deben ser solo números')
								.required('Numero de Documento es requerido')
								.min(5, 'Debe tener al menos 5 dígitos')
								.max(10, 'Debe tener 10 o menos dígitos'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							// company: Yup.string()
							// 	.test('len', 'Seleccione una empresa', (val) => {
							// 		console.log('val', val);
							// 		return val === undefined || val === '' || val === null ? false : true;
							// 	})
							// 	.required('Campo requerido')
							// 	.nullable(),
					  })
					: Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.string()
								.matches(/^[A-Za-z0-9]*$/, 'Deben ser solo números y letras')
								.required('Numero de Documento es requerido')
								.min(4, 'Debe tener al menos 4 dígitos')
								.max(7, 'Debe tener 7 o menos dígitos'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							company: Yup.string().nullable(),
					  });
			case 'PEP':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(15);
				setMaxDocumentFieldLenght(15);
				return role === '4'
					? Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.string()
								.matches(/^[0-9]+$/, 'Deben ser solo números')
								.required('Numero de Documento es requerido')
								.min(15, 'Debe tener al menos 15 dígitos')
								.max(15, 'Debe tener 15 o menos dígitos'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							company: Yup.string()
								.test('len', 'Seleccione una empresa', (val) => {
									console.log('val', val);
									return val === undefined || val === '' || val === null ? false : true;
								})
								.required('Campo requerido')
								.nullable(),
					  })
					: role === '3'
					? Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.test(
									'domain',
									'El correo electrónico debe tener el dominio @bogotatrabaja.gov.co o @desarrolloeconomico.gov.co',
									(value) => {
										if (value) {
											const allowedDomains = [
												'@bogotatrabaja.gov.co',
												'@desarrolloeconomico.gov.co',
											];
											return allowedDomains.some((domain) => value.endsWith(domain));
										}
										return true;
									}
								)
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.string()
								.matches(/^[0-9]+$/, 'Deben ser solo números')
								.required('Numero de Documento es requerido')
								.min(5, 'Debe tener al menos 5 dígitos')
								.max(10, 'Debe tener 10 o menos dígitos'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							// company: Yup.string()
							// 	.test('len', 'Seleccione una empresa', (val) => {
							// 		console.log('val', val);
							// 		return val === undefined || val === '' || val === null ? false : true;
							// 	})
							// 	.required('Campo requerido')
							// 	.nullable(),
					  })
					: Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.string()
								.matches(/^[0-9]+$/, 'Deben ser solo números')
								.required('Numero de Documento es requerido')
								.min(15, 'Debe tener al menos 15 dígitos')
								.max(15, 'Debe tener 15 o menos dígitos'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							company: Yup.string().nullable(),
					  });
			case 'PT':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(6);
				setMaxDocumentFieldLenght(9);
				return role === '4'
					? Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.string()
								.matches(/^[0-9]+$/, 'Deben ser solo números')
								.required('Numero de Documento es requerido')
								.min(6, 'Debe tener al menos 6 dígitos')
								.max(9, 'Debe tener 9 o menos dígitos'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							company: Yup.string()
								.test('len', 'Seleccione una empresa', (val) => {
									console.log('val', val);
									return val === undefined || val === '' || val === null ? false : true;
								})
								.required('Campo requerido')
								.nullable(),
					  })
					: role === '3'
					? Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.test(
									'domain',
									'El correo electrónico debe tener el dominio @bogotatrabaja.gov.co o @desarrolloeconomico.gov.co',
									(value) => {
										if (value) {
											const allowedDomains = [
												'@bogotatrabaja.gov.co',
												'@desarrolloeconomico.gov.co',
											];
											return allowedDomains.some((domain) => value.endsWith(domain));
										}
										return true;
									}
								)
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.string()
								.matches(/^[0-9]+$/, 'Deben ser solo números')
								.required('Numero de Documento es requerido')
								.min(5, 'Debe tener al menos 5 dígitos')
								.max(10, 'Debe tener 10 o menos dígitos'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							// company: Yup.string()
							// 	.test('len', 'Seleccione una empresa', (val) => {
							// 		console.log('val', val);
							// 		return val === undefined || val === '' || val === null ? false : true;
							// 	})
							// 	.required('Campo requerido')
							// 	.nullable(),
					  })
					: Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.string()
								.matches(/^[0-9]+$/, 'Deben ser solo números')
								.required('Numero de Documento es requerido')
								.min(6, 'Debe tener al menos 6 dígitos')
								.max(9, 'Debe tener 9 o menos dígitos'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							company: Yup.string().nullable(),
					  });
			default:
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(0);
				setMaxDocumentFieldLenght(20);
				return role === '4'
					? Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.number().required('Campo es requerido'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							company: Yup.string()
								.test('len', 'Seleccione una empresa', (val) => {
									console.log('val', val);
									return val === undefined || val === '' || val === null ? false : true;
								})
								.required('Campo requerido')
								.nullable(),
					  })
					: role === '3'
					? Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.test(
									'domain',
									'El correo electrónico debe tener el dominio @bogotatrabaja.gov.co o @desarrolloeconomico.gov.co',
									(value) => {
										if (value) {
											const allowedDomains = [
												'@bogotatrabaja.gov.co',
												'@desarrolloeconomico.gov.co',
											];
											return allowedDomains.some((domain) => value.endsWith(domain));
										}
										return true;
									}
								)
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.string()
								.matches(/^[0-9]+$/, 'Deben ser solo números')
								.required('Numero de Documento es requerido')
								.min(5, 'Debe tener al menos 5 dígitos')
								.max(10, 'Debe tener 10 o menos dígitos'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							// company: Yup.string()
							// 	.test('len', 'Seleccione una empresa', (val) => {
							// 		console.log('val', val);
							// 		return val === undefined || val === '' || val === null ? false : true;
							// 	})
							// 	.required('Campo requerido')
							// 	.nullable(),
					  })
					: Yup.object().shape({
							email: Yup.string()
								.email('Formato de correo electrónico incorrecto')
								.min(3, 'Mínimo 3 símbolos')
								.max(50, 'Máximo 50 símbolos')
								.required('Campo requerido'),
							documentType: Yup.string()
								.test('len', 'Seleccione un tipo de documento', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							roleType: Yup.string()
								.test('len', 'Seleccione un tipo de usuario (Rol)', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							active: Yup.string()
								.test('len', 'Seleccione si el usuario está activo', (val) => {
									return (val as string) === '' ? false : true;
								})
								.required('Campo requerido'),
							documentNumber: Yup.number().required('Campo requerido'),
							firstName: Yup.string().required('Campo requerido'),
							secondName: Yup.string(),
							firstNickname: Yup.string().required('Campo requerido'),
							secondNickname: Yup.string(),
							gender: Yup.string()
								.test('len', 'Seleccione un género', (val) => {
									return val === '' ? false : true;
								})
								.required('Campo requerido'),
							phoneNumber: Yup.string()
								.transform((value) =>
									value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
								)
								.matches(regExpPhoneNumber12Digits(), 'Teléfono de contacto no es válido')
								.required('Campo requerido'),
							company: Yup.string().nullable(),
					  });
		}
	};

	const [initialValues, setInitialValues] = useState({
		documentType: '',
		documentNumber: '',
		firstName: '',
		secondName: '',
		firstNickname: '',
		secondNickname: '',
		gender: '',
		phoneNumber: '',
		email: '',
		roleType: '',
		active: '',
		company: '',
	});

	const changeRole = (e: any) => {
		formik.setFieldValue('roleType', e);
		setRole(e.toString());
	};

	const formik = useFormik({
		initialValues,
		validationSchema: validationSchemaByTpDoc,
		initialTouched: {
			documentType: true,
			documentNumber: true,
			firstName: true,
			secondName: true,
			firstNickname: true,
			secondNickname: true,
			gender: true,
			phoneNumber: true,
			email: true,
			roleType: true,
			active: true,
			company: true,
		},
		validateOnMount: true,
		validateOnBlur: true,
		enableReinitialize: true,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			try {
				let objFinal = {
					id: id,
					company_id: values?.company || null,
					email: values.email,
					document_type: values.documentType,
					document_number: values.documentNumber.replace(/\D/g, ''),
					role: values.roleType,
					active: Boolean(values.active),
					//status: values.status,
					first_name: values.firstName,
					last_name: `${values.firstNickname} ${values.secondNickname}`.trim(),
					phone_number: values.phoneNumber.replaceAll('-', '').replaceAll('+', '').trim(),
					gender: values.gender,
				};

				console.log('submitOBJ', objFinal);
				setLoading(true);
				setSubmitting(true);
				if (editMode) {
					await updateUserPut(objFinal);
					setLoading(false);
					setSubmitting(false);
					setOpen(true);
					setAlert(true);
					setAlertMsg(`¡Usuario actualizado con éxito!`);
					setTimeout(() => {
						setOpen(false);
						navigate(-1);
					}, 2000);
				} else {
					delete objFinal.id;
					await createUserPost(objFinal);
					setLoading(false);
					setSubmitting(false);
					setOpen(true);
					setAlert(true);
					setAlertMsg(`¡Usuario creado con éxito!`);
					setTimeout(() => {
						setOpen(false);
						navigate(-1);
					}, 2000);
				}
			} catch (error: any) {
				console.error(error.response.data.error);
				setSubmitting(false);
				setLoading(false);
				setOpen(true);
				setAlert(false);
				setAlertMsg(error.response.data.error);
				setTimeout(() => {
					setOpen(false);
				}, 5000);
			}
		},
	});

	return (
		<>
			<div className='p-10 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<h1>{editMode ? t('Editar Usuario') : t('Nuevo Usuario')}</h1>
				{editMode && <span>{formik.values.firstName}</span>}
				<form onSubmit={formik.handleSubmit} noValidate>
					<div className='row mt-10 g-2'>
						<div className='col-lg-3'>
							<div className='form-floating'>
								<select
									{...formik.getFieldProps('documentType')}
									onChange={(e) => {
										formik.handleChange(e);
										formik.setFieldValue('documentNumber', '');
										setDocumentTypeSelected(e.target.value);
									}}
									className={clsx(
										'form-select bg-transparent',
										{ 'is-invalid': formik.touched.documentType && formik.errors.documentType },
										{
											'is-valid': formik.touched.documentType && !formik.errors.documentType,
										}
									)}
									id='documentType'
									aria-label='documentType'
									style={{ paddingBottom: '2px' }}
								>
									<option defaultChecked value={''}>
										{t('Seleccione...')}
									</option>
									{ddlTipoDocument.map(({ id, name }, i) => (
										<option value={id} key={i}>
											{t(name)}
										</option>
									))}
								</select>
								<label htmlFor='documentType'>{t('Tipo de documento')}</label>
								{formik.touched.documentType && formik.errors.documentType && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{t(formik.errors.documentType)}</span>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className='col-lg-3'>
							<div className='form-floating'>
								<input
									{...formik.getFieldProps('documentNumber')}
									onChange={(e) => {
										if (e.target.value.length <= maxDocumentFieldLenght) formik.handleChange(e);
									}}
									className={clsx(
										'form-control bg-transparent',
										{ 'is-invalid': formik.touched.documentNumber && formik.errors.documentNumber },
										{
											'is-valid': formik.touched.documentNumber && !formik.errors.documentNumber,
										}
									)}
									type={documentTypeField}
									id='documentNumber'
									name='documentNumber'
									placeholder='Escriba el número de documento'
									disabled={!isValidFieldRequired(formik.values.documentType)}
								/>
								<label htmlFor='documentNumber'>{t('Número de documento')}</label>
								{!isValidFieldRequired(formik.values.documentType) ? (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{t('Primero seleccione el tipo de documento')}</span>
										</div>
									</div>
								) : formik.touched.documentNumber && formik.errors.documentNumber ? (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{t(formik.errors.documentNumber)}</span>
										</div>
									</div>
								) : (
									<></>
								)}
							</div>
						</div>
						<div className='col-lg-3'>
							<div className='form-floating'>
								<select
									{...formik.getFieldProps('roleType')}
									className={clsx(
										'form-select bg-transparent',
										{ 'is-invalid': formik.touched.roleType && formik.errors.roleType },
										{
											'is-valid': formik.touched.roleType && !formik.errors.roleType,
										}
									)}
									id='roleType'
									aria-label='roleType'
									style={{ paddingBottom: '2px' }}
									onChange={(e) => changeRole(e.target.value)}
								>
									<option defaultChecked value={''}>
										{t('Seleccione...')}
									</option>
									{ddlTpUserRole.map(({ id, name }, i) => {
										if (auth?.user?.role !== RoleEnum.EMPLOYER && id !== RoleEnum.CANDIDATE) {
											return null; // Ignora a opção que não atende à condição
										}
										return (
											<option value={id} key={i}>
												{t(name)}
											</option>
										);
									})}
								</select>
								<label htmlFor='roleType'>{t('Tipo de usuario (Rol)')}</label>
								{formik.touched.roleType && formik.errors.roleType && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.roleType}</span>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className='col-lg-3'>
							<div className='form-floating'>
								<select
									{...formik.getFieldProps('active')}
									className={clsx(
										'form-select bg-transparent',
										{ 'is-invalid': formik.touched.active && formik.errors.active },
										{
											'is-valid': formik.touched.active && !formik.errors.active,
										}
									)}
									id='active'
									aria-label='active'
									style={{ paddingBottom: '2px' }}
								>
									<option defaultChecked value={''}>
										{t('Seleccione...')}
									</option>
									<option value={'true'}>{t('Activo')}</option>
									<option value={'false'}>{t('Inactivo')}</option>
								</select>
								<label htmlFor='active'>{t('Estado')}</label>
								{formik.touched.active && formik.errors.active && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{t(formik.errors.active)}</span>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
					{(parseInt(formik.values.roleType) === 1 || parseInt(formik.values.roleType) === 4) && (
						<div className='row mt-10 g-2'>
							<div className='col-lg-6'>
								<div className='form-floating'>
									<select
										{...formik.getFieldProps('company')}
										className={clsx(
											'form-select bg-transparent',
											{ 'is-invalid': formik.touched.company && formik.errors.company },
											{
												'is-valid': formik.touched.company && !formik.errors.company,
											}
										)}
										style={{ paddingBottom: '2px' }}
										id='company'
										aria-label='company'
									>
										<option defaultChecked value={''}>
											{t('Seleccione...')}
										</option>
										{listCompanies.map((value, index) => (
											<option value={value.id} key={index}>
												{value.company_name}
											</option>
										))}
									</select>
									<label htmlFor='company'>{t('Empresa')}</label>
									{formik.touched.company && formik.errors.company && (
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<span role='alert'>{t(formik.errors.company)}</span>
											</div>
										</div>
									)}
								</div>
								{/* <FormControl
									className='form-floating'
									sx={{ minWidth: '100%' }}
									size='medium'
									error={formik.touched.company && Boolean(formik.errors.company)}
								>
									<Autocomplete
										{...formik.getFieldProps('company')}
										sx={{
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: formik.errors.company ? '#DC3545' : '',
											},
										}}
										id='Buscar empresa'
										disablePortal
										// getOptionLabel={(option) => option.razon_social}
										// options={listCompanies}
										onChange={(e, newValue) => {
											console.log('new', newValue);
											formik.setFieldValue('company', newValue);
										}}
										options={listCompanies.map((option: any) => option?.razon_social)}
										renderInput={(params) => <TextField {...params} label='Buscar empresa' />}
									/>
									{formik.errors.company && (
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<span role='alert'>{formik.errors.company}</span>
											</div>
										</div>
									)}
								</FormControl> */}
							</div>
						</div>
					)}

					<div className='row mt-10 g-2'>
						<div className='col-lg-3'>
							<div className='form-floating'>
								<input
									{...formik.getFieldProps('firstName')}
									className={clsx(
										'form-control bg-transparent',
										{ 'is-invalid': formik.touched.firstName && formik.errors.firstName },
										{
											'is-valid': formik.touched.firstName && !formik.errors.firstName,
										}
									)}
									type='text'
									id='firstName'
									name='firstName'
									placeholder={t('Ingrese el primer nombre')}
									value={formik.values.firstName}
								/>
								<label htmlFor='firstName'>{t('Primer nombre')}</label>
								{formik.touched.firstName && formik.errors.firstName && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{t(formik.errors.firstName)}</span>
										</div>
									</div>
								)}
							</div>
						</div>
						{/* <div className='col-lg-3'>
							<div className='form-floating'>
								<input
									{...formik.getFieldProps('secondName')}
									className={clsx('form-control bg-transparent')}
									type='text'
									id='secondName'
									name='secondName'
									placeholder={t('Ingrese el segundo nombre')}
								/>
								<label htmlFor='secondName'>{t('Segundo nombre')}</label>
							</div>
						</div> */}
						<div className='col-lg-3'>
							<div className='form-floating'>
								<input
									{...formik.getFieldProps('firstNickname')}
									className={clsx(
										'form-control bg-transparent',
										{ 'is-invalid': formik.touched.firstNickname && formik.errors.firstNickname },
										{
											'is-valid': formik.touched.firstNickname && !formik.errors.firstNickname,
										}
									)}
									type='text'
									id='firstNickname'
									name='firstNickname'
									placeholder={t('Ingrese el primer apellido')}
								/>
								<label htmlFor='firstNickname'>{t('Primer apellido')}</label>
								{formik.touched.firstNickname && formik.errors.firstNickname && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{t(formik.errors.firstNickname)}</span>
										</div>
									</div>
								)}
							</div>
						</div>
						{/* <div className='col-lg-3'>
							<div className='form-floating'>
								<input
									{...formik.getFieldProps('secondNickname')}
									className={clsx('form-control bg-transparent')}
									type='text'
									id='secondNickname'
									name='secondNickname'
									placeholder={t('Ingrese el segundo apellido')}
								/>
								<label htmlFor='secondNickname'>{t('Segundo apellido')}</label>
							</div>
						</div> */}
					</div>
					<div className='row mt-10 g-2'>
						<div className='col-lg-4'>
							<div className='form-floating'>
								<select
									{...formik.getFieldProps('gender')}
									className={clsx(
										'form-select bg-transparent',
										{ 'is-invalid': formik.touched.gender && formik.errors.gender },
										{
											'is-valid': formik.touched.gender && !formik.errors.gender,
										}
									)}
									style={{ paddingBottom: '2px' }}
									id='gender'
									aria-label='genero'
								>
									<option defaultChecked value={''}>
										{t('Seleccione...')}
									</option>
									<option value='Masculino'>{t('Hombre')}</option>
									<option value='Feminino'>{t('Mujer')}</option>
									<option value='Intersexo'>{t('Intersexo')}</option>
								</select>
								<label htmlFor='gender'>{t('Género')}</label>
								{formik.touched.gender && formik.errors.gender && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{t(formik.errors.gender)}</span>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className='col-lg-4'>
							<div className='form-floating'>
								<input
									{...formik.getFieldProps('phoneNumber')}
									className={clsx(
										'form-control bg-transparent',
										{ 'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber },
										{
											'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
										}
									)}
									type='text'
									id='phoneNumber'
									name='phoneNumber'
									placeholder={t('Ingrese un teléfono de contacto')}
									onChange={(e) => {
										// let x = e.currentTarget.value
										// 	.replace(/\D/g, '')
										// 	.match(matchPhoneNumber12Digits());
										// if (x !== null) e.currentTarget.value = '+' + x[1] + '-' + x[2] + '-' + x[3];
										e.currentTarget.value = formatMaskPhoneNumber12Digits(e.currentTarget.value);
										formik.setFieldValue('phoneNumber', e.currentTarget.value);
									}}
								/>
								<label htmlFor='phoneNumber'>{t('Teléfono de contacto')}</label>
								{formik.touched.phoneNumber && formik.errors.phoneNumber && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{t(formik.errors.phoneNumber)}</span>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className='col-lg-3'>
							<div className='form-floating'>
								<input
									type='text'
									placeholder={t('Ingrese un correo electrónico')}
									{...formik.getFieldProps('email')}
									className={clsx(
										'form-control bg-transparent',
										{ 'is-invalid': formik.touched.email && formik.errors.email },
										{
											'is-valid': formik.touched.email && !formik.errors.email,
										}
									)}
									name='email'
									id='email'
								/>
								<label htmlFor='email'>{t('Correo electrónico')}</label>
								{formik.touched.email && formik.errors.email && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{t(formik.errors.email)}</span>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className='col-lg-1' style={{ marginTop: '20px' }}>
							<HtmlTooltip
								style={{ textAlign: 'center' }}
								title={
									<>
										<Typography color='inherit'>
											{t('Para un perfil de consejero, el correo electrónico debe contener el dominio')}
											<em>@bogotatrabaja.gov.co</em> o <em>@desarrolloeconomico.gov.co</em>
										</Typography>
									</>
								}
							>
								<HelpIcon style={{ color: '#153E7B' }} />
							</HtmlTooltip>
						</div>
						<div className='row'>
							<div className=' col-12 mt-12 d-flex justify-content-end'>
								<button
									type='button'
									onClick={() => {
										navigate(-1);
									}}
									className='btn btn-secondary'
									style={{ marginRight: '10px' }}
								>
									{t('Cancelar')}
								</button>
								<button
									type='submit'
									className='btn btn-primary'
									disabled={formik.isSubmitting || !formik.isValid}
								>
									{!loading && <>{editMode ? t('Actualizar') : t('Registrar')}</>}
									{loading && (
										<span className='indicator-progress' style={{ display: 'block' }}>
											{t('Espere ...')}
											<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
										</span>
									)}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
					{t(alertMsg)}
				</Alert>
			</Snackbar>
		</>
	);
};

const AdminCreateUserWrapper = () => {
	const { t } = useTranslation();
	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Nuevo Usuario')}</PageTitle>
			<AdminCreateUserPage />
		</>
	);
};

export { AdminCreateUserWrapper };
