import React from "react";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import _ from 'lodash';
import { isValidFieldRequired } from "../../../../../util/Index";
import { MESES } from "../../../../../constants/constants";
import { useCertification } from "../../../../../hooks/curriculum/useCertification";
import { CurricullumSectionProps } from "./CurricullumFormTypes";

export default function SectionCertification({ errors, setFieldValue, values }: CurricullumSectionProps) {
    const { t } = useTranslation();
    const {
        valueAdd,
        borderColorEdit,
        editMode,
        openRowEdit,
        alert,
        alertMsg,
        open,
        setValues,
        handleClose,
        handleEdit,
        handleSaveEdit,
        handleRemoveExperienciaLaboral,
        handleAddExperienciaLaboral,
        handleclear,
        rows,
        isSaving
    } = useCertification({ setFieldValue, values });

    const {
        name,
        institute,
        credential_id,
        credential_url,
        emite_date,
        expiration_date,
        dateEmissionMonth,
        dateEmissionYear,
        dateExpirationMonth,
        dateExpirationYear,
    } = valueAdd;


    const getListYears = () => {
        const menuItems: JSX.Element[] = []
        const limitYear = new Date().getFullYear();
        for (let index = limitYear; index >= 1950; index--) {
            menuItems.push(<MenuItem value={index} key={`get_year_${index}_${new Date().getMilliseconds()}`}>{index}</MenuItem>)
        }
        return menuItems.map(menu => menu)
    }

    return (
        <>
            <Box sx={{ mb: 3, mt: 5 }}>
                <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                    {t('LICENSE_CERTIFICATION')}
                </Typography>
            </Box>
            <Grid
                container
                direction='row'
                justifyContent='space-between'
                width={'100%'}
                sx={{
                    backgroundColor: '#1C447F',
                    pt: 2,
                    pl: 2,
                    pb: 2,
                    pr: 2,
                    borderTopLeftRadius: 6,
                    borderTopRightRadius: 6,
                }}
                item
            >
                <Grid container direction='column' item>
                    <Typography color='white' variant='h5'>
                        {t('Agregar')} {t('LICENSE_CERTIFICATION')} {editMode ? t(' - modo de edición') : ''}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                id={"formCert"}
                container
                direction='row'
                justifyContent='space-between'
                width={'100%'}
                mb={5}
                sx={{
                    // border: 1,
                    borderBottom: 3,
                    borderColor: '#AFADAD',
                    borderBottomLeftRadius: 6,
                    borderBottomRightRadius: 6,
                    pt: 5,
                    pl: 3,
                    pr: 3,
                    border: `${borderColorEdit}`,
                }}
                item
            >
                <Grid container direction='row' justifyContent='space-between' width={'100%'} item>
                    <Grid xs={12} md={6} mb={5} pr={2} item>
                        <TextField
                            fullWidth
                            required
                            id={'nameCert'}
                            name={'name'}
                            label={t('CERTIFICATION')}
                            value={name || ''}
                            onChange={(e) => setValues({ ...valueAdd, name: e.target.value })}
                            error={isSaving && !isValidFieldRequired(name)}
                            placeholder={t('CERTIFICATION')}
                            size='medium'
                            inputProps={{
                                maxLength: 80
                            }}
                            // error={isSaving && !isValidFieldRequired(cargo)}
                            helperText={isSaving && !isValidFieldRequired(name) ? t('Campo requerido') : ''}
                        />
                    </Grid>
                    <Grid xs={12} md={6} mb={5} item>
                        <TextField
                            fullWidth
                            required
                            id={'institute'}
                            name={'institute'}
                            label={t('EMITER_INSTITUTION')}
                            value={institute || ''}
                            onChange={(e) => setValues({ ...valueAdd, institute: e.target.value })}
                            error={isSaving && !isValidFieldRequired(institute)}
                            placeholder={t('Escriba la institución')}
                            size='medium'
                            inputProps={{
                                maxLength: 80
                            }}
                            helperText={isSaving && !isValidFieldRequired(institute) ? t('Campo requerido') : ''}
                        />
                    </Grid>
                    <Grid xs={12} md={6} mb={5} pr={2} item>
                        <TextField
                            fullWidth
                            required
                            id={'credential_id'}
                            name={'credential_id'}
                            label={t('CREDENTIAL_ID')}
                            value={credential_id || ''}
                            onChange={(e) => setValues({ ...valueAdd, credential_id: e.target.value })}
                            error={isSaving && !isValidFieldRequired(credential_id)}
                            placeholder={t('CREDENTIAL_ID')}
                            size='medium'
                            inputProps={{
                                maxLength: 80
                            }}
                            helperText={isSaving && !isValidFieldRequired(credential_id) ? t('Campo requerido') : ''}
                        />
                    </Grid>
                    <Grid xs={12} md={6} mb={5} item>
                        <TextField
                            fullWidth
                            id={'credential_url'}
                            name={'credential_url'}
                            label={t('CERTICATE_URL')}
                            value={credential_url || ''}
                            onChange={(e) => setValues({ ...valueAdd, credential_url: e.target.value })}
                            placeholder={t('CERTICATE_URL')}
                            size='medium'
                            inputProps={{
                                maxLength: 80
                            }}
                        />
                    </Grid>

                    <Grid container direction='row' justifyContent='flex-start' width={'100%'} item>
                        <Grid xs={12} md={4} mb={5} pr={2} item>
                            <Grid xs={12} md={12} mb={2} pr={2} item>
                                <InputLabel id={'fecha_Retiro'}>{t('EMITE_AT')}</InputLabel>
                            </Grid>
                            <Grid direction='row' justifyContent='flex-start' width={'100%'} mb={5} pr={2} container>
                                <Grid xs={12} md={6} mb={2} pr={2} item>
                                    <FormControl
                                        sx={{ minWidth: '100%' }}
                                        size='medium'
                                        error={isSaving && !isValidFieldRequired(dateEmissionMonth)}
                                    >
                                        <InputLabel id={'dateEmissionMonth'}>{t('MONTH')}</InputLabel>
                                        <Select
                                            labelId={'dateEmissionMonth'}
                                            id={'dateEmissionMonth'}
                                            label={t('MONTH')}
                                            value={dateEmissionMonth || ''}
                                            onChange={(e) => setValues({ ...valueAdd, dateEmissionMonth: e.target.value })}
                                        >
                                            <MenuItem value=''>{t('MONTH')}</MenuItem>
                                            {MESES.map((item) => <MenuItem value={item.val} key={`get_month_${item.val}_${new Date().getMilliseconds()}`}>{item.name}</MenuItem>)}
                                        </Select>
                                        {isSaving && !isValidFieldRequired(dateEmissionMonth) && (
                                            <FormHelperText>
                                                {isSaving && !isValidFieldRequired(dateEmissionMonth) ? t('Campo requerido') : ''}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={6} mb={2} pr={2} item>
                                    <FormControl
                                        sx={{ minWidth: '100%' }}
                                        size='medium'
                                        error={isSaving && !isValidFieldRequired(dateEmissionYear)}
                                    >
                                        <InputLabel id={'dateEmissionYear'}>{t('YEAR')}</InputLabel>
                                        <Select
                                            labelId={'dateEmissionYear'}
                                            id={'dateEmissionYear'}
                                            label={t('YEAR')}
                                            value={dateEmissionYear || 0}
                                            onChange={(e) => setValues({ ...valueAdd, dateEmissionYear: e.target.value })}
                                            onBlur={(e) => setValues({ ...valueAdd, dateEmissionYear: e.target.value })}
                                        >

                                            <MenuItem value={0}>{t('YEAR')}</MenuItem>
                                            {getListYears()}
                                        </Select>
                                        {isSaving && !isValidFieldRequired(dateEmissionYear) && (
                                            <FormHelperText>
                                                {isSaving && !isValidFieldRequired(dateEmissionYear) ? t('Campo requerido') : ''}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={4} mb={5} pr={2} item>
                            <Grid xs={12} md={12} mb={2} pr={2} item>
                                <InputLabel id={'fecha_Retiro'}>{t('EXPIRES_AT')}</InputLabel>
                            </Grid>
                            <Grid direction='row' justifyContent='flex-start' width={'100%'} mb={5} pr={2} container>
                                <Grid xs={12} md={6} mb={2} pr={2} item>
                                    <FormControl
                                        sx={{ minWidth: '100%' }}
                                        size='medium'
                                        error={isSaving && !isValidFieldRequired(dateExpirationMonth)}
                                    >
                                        <InputLabel id={'dateExpirationMonth'}>{t('MONTH')}</InputLabel>
                                        <Select
                                            labelId={'dateExpirationMonth'}
                                            id={'dateExpirationMonth'}
                                            label={t('MONTH')}
                                            value={dateExpirationMonth || ''}
                                            onChange={(e) => setValues({ ...valueAdd, dateExpirationMonth: e.target.value })}
                                        >
                                            <MenuItem value={''}>{t('MONTH')}</MenuItem>
                                            {MESES.map((item) => <MenuItem value={item.val} key={`get_month_${item.val}_${new Date().getMilliseconds()}`}>{item.name}</MenuItem>)}
                                        </Select>
                                        {isSaving && !isValidFieldRequired(dateExpirationMonth) && (
                                            <FormHelperText>
                                                {isSaving && !isValidFieldRequired(dateExpirationMonth) ? t('Campo requerido') : ''}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={6} mb={2} pr={2} item>
                                    <FormControl
                                        sx={{ minWidth: '100%' }}
                                        size='medium'
                                        error={isSaving && !isValidFieldRequired(dateExpirationYear)}
                                    >
                                        <InputLabel id={'dateExpirationYear'}>{t('YEAR')}</InputLabel>
                                        <Select
                                            labelId={'dateExpirationYear'}
                                            id={'dateExpirationYear'}
                                            label={t('YEAR')}
                                            value={dateExpirationYear || ''}
                                            onChange={(e) => setValues({ ...valueAdd, dateExpirationYear: e.target.value })}
                                        >
                                            {

                                            }
                                            <MenuItem value={''}>{t('YEAR')}</MenuItem>
                                            {getListYears()}
                                        </Select>
                                        {isSaving && !isValidFieldRequired(dateExpirationYear) && (
                                            <FormHelperText>
                                                {isSaving && !isValidFieldRequired(dateExpirationYear) ? t('Campo requerido') : ''}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} mb={3} display='flex' justifyContent='space-between' alignItems='center' item>
                    {editMode ? (
                        <>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
                                <Button
                                    variant='contained'
                                    onClick={handleSaveEdit}
                                    sx={{ width: 90, background: '#0A4396', mr: 1 }}
                                >
                                    {t('Guardar')}
                                </Button>

                                <Button
                                    variant='contained'
                                    sx={{ width: 90, background: '#6c757d', mr: 1 }}
                                    onClick={handleclear}
                                >
                                    {t('Cancelar')}
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <Button
                            // disabled={tieneExperienciaLaboral === '0' || tieneExperienciaLaboral === ''}
                            variant='contained'
                            onClick={handleAddExperienciaLaboral}
                            sx={{ width: 90, background: '#0A4396' }}
                        >
                            {t('Agregar')}
                        </Button>
                    )}
                </Grid>
            </Grid>
            {rows?.length > 0 && (
                <Grid xs={12} item>
                    <Typography color='#2B2B2B' variant='h6'>
                        {t('Agregados')}
                    </Typography>
                    <Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 5 }} />
                </Grid>
            )}
            <Grid xs={12} item>
                {rows?.map((row, i) => (
                    <Grid xs={12} key={'cert-' + i} item display={openRowEdit === row.id ? 'none' : ''} >
                        <Grid
                            container
                            direction='row'
                            justifyContent='space-between'
                            width={'100%'}
                            sx={{
                                backgroundColor: '#1C447F',
                                pt: 2,
                                pl: 2,
                                pb: 2,
                                pr: 2,
                                borderTopLeftRadius: 6,
                                borderTopRightRadius: 6,
                            }}
                            item
                        >
                            <Typography color='white' variant='h6'>
                                {' '}
                                {t(row.name || '') || ''}
                            </Typography>
                            <Box display='flex' justifyContent='end' alignItems='center'>
                                <div>
                                    <div className='d-flex justify-content-end flex-shrink-0'>
                                        <button
                                            className='btn btn-icon btn-active-color-secondary btn-sm me-3'
                                            style={{ background: '#C4D2E8' }}
                                            onClick={() => handleEdit(row)}
                                            type="button"
                                        >
                                            <EditIcon />
                                        </button>

                                        <button
                                            className='btn btn-icon btn-active-color-secondary btn-sm'
                                            onClick={() => handleRemoveExperienciaLaboral(row.id || 0)}
                                            style={{ background: '#DD1342' }}
                                            type="button"

                                        >
                                            <DeleteOutlinedIcon style={{ color: 'white' }} />
                                        </button>
                                    </div>
                                </div>
                            </Box>
                        </Grid>
                        <Grid
                            container
                            direction='row'
                            justifyContent='space-between'
                            width={'100%'}
                            mb={5}
                            sx={{
                                border: 1,
                                borderBottom: 3,
                                borderColor: '#AFADAD',
                                borderBottomLeftRadius: 6,
                                borderBottomRightRadius: 6,
                                pt: 5,
                                pl: 3,
                                pr: 3,
                            }}
                            item
                        >

                            <Grid xs={12} md={3} mb={5} mr={5} item>
                                <Typography>{(row.name || '')}</Typography>
                                <Typography>{t('CREDENTIAL_ID') + ': ' + (row.credential_id || '')}</Typography>
                                <Typography>{t('CERTICATE_URL') + ': ' + (row.credential_url || '')}</Typography>
                            </Grid>
                            <Grid xs={1} mb={5} sx={{ borderLeft: 2, borderColor: '#AFADAD' }} item />
                            <Grid xs={12} md={2} mb={5} mr={2} item>
                                <Typography>{t('EMITE_AT') + ': ' + (row.emite_date || '')}</Typography>
                                <Typography>{t('EXPIRES_AT') + ': ' + (row.expiration_date || '')}</Typography>


                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    variant={'filled'}
                    severity={alert ? 'success' : 'error'}
                    sx={{ whiteSpace: 'pre-line' }}
                >
                    {alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
                    {t(alertMsg)}
                </Alert>
            </Snackbar>
        </>
    )
}