/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { getUserByToken, login } from '../core/_requests';
import { useAuth } from '../core/Auth';
import { Alert, Snackbar } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { isValidFieldRequired } from '../../../util/Index';

import Select from 'react-select';

import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { UserModel } from '../core/_models';

const documentTypes = [
	{ id: '', value: 'Selecione' },
	{ id: 'EMAIL', value: '1. Email' },
	{ id: 'CC', value: '2. CPF' },
	{ id: 'TI', value: '3. CNPJ' },

	//	{ id: 'CE', value: '4. CÉDULA DE EXTRANJERÍA' },
	//	{ id: 'PA', value: '5. PASAPORTE' },
	//	{ id: 'PEP', value: '6. PERMISO ESPECIAL DE PERMANENCIA' },
	//	{ id: 'PT', value: '7. PERMISO POR PROTECCIÓN TEMPORAL' },
	// { id: 'NIT', value: '8. NÚMERO DE IDENTIFICACIÓN TRUBUTARIA (NIT)' },
];

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

const I18N_STORAGE_KEY = 'i18nextLng';

export function Login() {
	const { t } = useTranslation();
	const [languageSelectec] = useState(localStorage.getItem('language') || navigator.language);
	const [loading, setLoading] = useState(false);
	const [showPass, setShowPass] = useState(false);
	const { saveAuth, setCurrentUser } = useAuth();
	const [open, setOpen] = useState(false);
	const [msgAlert, setMsgAlert] = useState('');
	const [isError, setIsError] = useState(false);
	const [verified, setVerified] = useState(false);
	const [documentLabel, setDocumentLabel] = useState('Email');
	const [documentId, setDocumentId] = useState('EMAIL');
	const handleClose = () => {
		setOpen(false);
	};

	const [documentTypeSelected, setDocumentTypeSelected] = useState('');
	const [maxDocumentFieldLenght, setMaxDocumentFieldLenght] = useState(0);
	const [minDocumentFieldLenght, setMinDocumentFieldLenght] = useState(0);
	const [documentTypeField, setDocumentTypeField] = useState('text');

	const languageOptions = [
		{ value: 'pt-BR', label: 'Brasil', flag: '/media/icons/language/brasil.png' },
		{ value: 'en-US', label: 'Estados Unidos', flag: '/media/icons/language/estados-unidos.png' },
		{ value: 'es', label: 'Espanha', flag: '/media/icons/language/espanha.png' },
	];

	const initialValues = {
		documentType: '',
		numero_doc: '',
		password: '',
	};

	// const loginSchema =
	// 	documentId === '1'
	// 		? Yup.object().shape({
	// 				email: Yup.string()
	// 					.email('Formato de email incorrecto')
	// 					.min(3, 'Mínimo 3 símbolos')
	// 					.max(50, 'Máximo 50 símbolos')
	// 					.required('Email es requerido'),
	// 				password: Yup.string()
	// 					.min(3, 'Mínimo 3 símbolos')
	// 					.max(50, 'Máximo 50 símbolos')
	// 					.required('Contraseña es requerida'),
	// 		  })
	// 		: Yup.object().shape({
	// 				numero_doc: Yup.number().required('Documento es requerido'),
	// 				password: Yup.string()
	// 					.min(3, 'Mínimo 3 símbolos')
	// 					.max(50, 'Máximo 50 símbolos')
	// 					.required('Contraseña es requerida'),
	// 		  });

	// const changeTypeDoc = (values: any) => {
	// 	console.log(typeof values);
	// 	setDocumentId(values);
	// 	formik.setFieldValue('email', '');
	// 	formik.setFieldValue('numero_doc', '');
	// 	if (values === '1') {
	// 		setDocumentLabel('Correo');
	// 	} else {
	// 		setDocumentLabel('Documento');
	// 	}
	// };

	useEffect(() => {
		validationSchemaByTpDoc();
	}, []);

	const validationSchemaByTpDoc = () => {
		switch (documentTypeSelected) {
			case 'CC':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(5);
				setMaxDocumentFieldLenght(10);
				setDocumentLabel('Documento');
				return Yup.object().shape({
					documentType: Yup.string()
						.test('len', 'Seleccione un tipo de documento', (val) => {
							return (val as string) === '' ? false : true;
						})
						.required('Campo requerido'),
					numero_doc: Yup.string()
						.matches(/^[0-9]+$/, 'Deben ser solo números')
						.required('Numero de Documento es requerido')
						.min(5, 'Debe tener al menos 5 dígitos')
						.max(10, 'Debe tener 10 o menos dígitos'),
					password: Yup.string()
						.min(3, 'Mínimo 3 símbolos')
						.max(50, 'Máximo 50 símbolos')
						.required('Contraseña es requerida'),
				});
			case 'TI':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(0);
				setMaxDocumentFieldLenght(12);
				setDocumentLabel('Documento');
				return Yup.object().shape({
					documentType: Yup.string()
						.test('len', 'Seleccione un tipo de documento', (val) => {
							return (val as string) === '' ? false : true;
						})
						.required('Campo requerido'),
					numero_doc: Yup.string()
						.matches(/^[A-Za-z0-9]*$/, 'Deben ser solo números y letras')
						.required('Numero de Documento es requerido')
						.max(12, 'Debe tener 12 o menos dígitos'),
					password: Yup.string()
						.min(3, 'Mínimo 3 símbolos')
						.max(50, 'Máximo 50 símbolos')
						.required('Contraseña es requerida'),
				});
			case 'CE':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(9);
				setMaxDocumentFieldLenght(10);
				setDocumentLabel('Documento');
				return Yup.object().shape({
					documentType: Yup.string()
						.test('len', 'Seleccione un tipo de documento', (val) => {
							return (val as string) === '' ? false : true;
						})
						.required('Campo requerido'),
					numero_doc: Yup.string()
						.matches(/^[0-9]+$/, 'Deben ser solo números')
						.required('Numero de Documento es requerido')
						.min(9, 'Debe tener al menos 9 dígitos')
						.max(10, 'Debe tener 10 o menos dígitos'),
					password: Yup.string()
						.min(3, 'Mínimo 3 símbolos')
						.max(50, 'Máximo 50 símbolos')
						.required('Contraseña es requerida'),
				});
			case 'PA':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(4);
				setMaxDocumentFieldLenght(7);
				setDocumentLabel('Documento');
				return Yup.object().shape({
					documentType: Yup.string()
						.test('len', 'Seleccione un tipo de documento', (val) => {
							return (val as string) === '' ? false : true;
						})
						.required('Campo requerido'),
					numero_doc: Yup.string()
						.matches(/^[A-Za-z0-9]*$/, 'Deben ser solo números y letras')
						.required('Numero de Documento es requerido')
						.min(4, 'Debe tener al menos 4 dígitos')
						.max(7, 'Debe tener 7 o menos dígitos'),
					password: Yup.string()
						.min(3, 'Mínimo 3 símbolos')
						.max(50, 'Máximo 50 símbolos')
						.required('Contraseña es requerida'),
				});
			case 'PEP':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(15);
				setMaxDocumentFieldLenght(15);
				setDocumentLabel('Documento');
				return Yup.object().shape({
					documentType: Yup.string()
						.test('len', 'Seleccione un tipo de documento', (val) => {
							return (val as string) === '' ? false : true;
						})
						.required('Campo requerido'),
					numero_doc: Yup.string()
						.matches(/^[0-9]+$/, 'Deben ser solo números')
						.required('Numero de Documento es requerido')
						.min(15, 'Debe tener al menos 15 dígitos')
						.max(15, 'Debe tener 15 o menos dígitos'),
					password: Yup.string()
						.min(3, 'Mínimo 3 símbolos')
						.max(50, 'Máximo 50 símbolos')
						.required('Contraseña es requerida'),
				});
			case 'PT':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(6);
				setMaxDocumentFieldLenght(9);
				setDocumentLabel('Documento');
				return Yup.object().shape({
					documentType: Yup.string()
						.test('len', 'Seleccione un tipo de documento', (val) => {
							return (val as string) === '' ? false : true;
						})
						.required('Campo requerido'),
					numero_doc: Yup.string()
						.matches(/^[0-9]+$/, 'Deben ser solo números')
						.required('Numero de Documento es requerido')
						.min(6, 'Debe tener al menos 6 dígitos')
						.max(9, 'Debe tener 9 o menos dígitos'),
					password: Yup.string()
						.min(3, 'Mínimo 3 símbolos')
						.max(50, 'Máximo 50 símbolos')
						.required('Contraseña es requerida'),
				});
			case 'EMAIL':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(3);
				setMaxDocumentFieldLenght(50);
				setDocumentLabel('Email');
				return Yup.object().shape({
					documentType: Yup.string()
						.test('len', 'Seleccione un tipo de documento', (val) => {
							return (val as string) === '' ? false : true;
						})
						.required('Campo requerido'),
					numero_doc: Yup.string()
						.email('Formato de email incorrecto')
						.min(3, 'Mínimo 3 símbolos')
						.max(50, 'Máximo 50 símbolos')
						.required('Email es requerido'),
					password: Yup.string()
						.min(3, 'Mínimo 3 símbolos')
						.max(50, 'Máximo 50 símbolos')
						.required('Contraseña es requerida'),
				});
			case 'NIT':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(9);
				setMaxDocumentFieldLenght(9);
				setDocumentLabel('Documento');
				return Yup.object().shape({
					documentType: Yup.string()
						.test('len', 'Seleccione un tipo de documento', (val) => {
							return (val as string) === '' ? false : true;
						})
						.required('Campo requerido'),
					numero_doc: Yup.string()
						.matches(/^[0-9]+$/, 'Deben ser solo números')
						.required('Numero de Documento es requerido')
						.min(9, 'Debe tener al menos 9 dígitos')
						.max(9, 'Debe tener 9 o menos dígitos'),
					password: Yup.string()
						.min(3, 'Mínimo 3 símbolos')
						.max(50, 'Máximo 50 símbolos')
						.required('Contraseña es requerida'),
				});
			default:
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(3);
				setMaxDocumentFieldLenght(50);
				setDocumentLabel('Email');
				return Yup.object().shape({
					documentType: Yup.string()
						.test('len', 'Seleccione un tipo de documento', (val) => {
							return (val as string) === '' ? false : true;
						})
						.required('Campo requerido'),
					numero_doc: Yup.string()
						.email('Formato de email incorrecto')
						.min(3, 'Mínimo 3 símbolos')
						.max(50, 'Máximo 50 símbolos')
						.required('Email es requerido'),
					password: Yup.string()
						.min(3, 'Mínimo 3 símbolos')
						.max(50, 'Máximo 50 símbolos')
						.required('Contraseña es requerida'),
				});
		}
	};

	const formik = useFormik({
		initialValues,
		validationSchema: validationSchemaByTpDoc,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			if (!verified) {
				saveAuth(undefined);
				setStatus('The login details are incorrect');
				setSubmitting(false);
				setLoading(false);
				setOpen(true);
				setIsError(true);
				setMsgAlert(`Completa el recaptcha`);
				return;
			}

			try {
				setLoading(true);
				setSubmitting(true);
				console.log('sub', values);

				const { data: auth } = await login(values.numero_doc, values.password, values.documentType);
				saveAuth(auth);
				if (auth.user) {
					const user: any = {
						...auth.user,
						user: auth.user,

					};
					setCurrentUser(user);
				} else {
					setCurrentUser(undefined);
				}
			} catch (error: any) {
				console.error(error);
				saveAuth(undefined);
				setStatus('The login details are incorrect');
				setSubmitting(false);
				setLoading(false);
				setOpen(true);
				setIsError(true);
				setMsgAlert(`${error.response.data.error}`);
			} finally {
				setLoading(false);
				setSubmitting(false);
			}
		},
	});

	const onChangeCaptcha = () => {
		setVerified(true);
	};

	function handleChangeLanguage(event: any) {
		localStorage.setItem('language', event.value);
		window.location.reload();
	}

	return (
		<div className='d-flex flex-center'>
			<form
				className='form col-9 col-md-6 formFull'
				onSubmit={formik.handleSubmit}
				noValidate
				id='kt_login_signin_form'
			>
				{/* begin::Heading */}
				<div className='text-center mb-11'>
					<h1 className='text-dark fw-bolder mb-3'>{t('Acesso')}</h1>
					<div className='text-black-500 text-center fw-semibold fs-6'>
						{t('Informe seus dados para entrar na plataforma.')}
					</div>
				</div>
				{/* end::Heading */}

				{/* begin::Form group */}
				<div className='fv-row mb-8'>
					{/* begin::Input group */}
					<div className='mb-8'>
						<label className='form-label fs-6 fw-bolder text-dark'>
							{t('Documento de autenticação')}
						</label>
						<div>
							<select
								{...formik.getFieldProps('documentType')}
								onChange={(e) => {
									formik.handleChange(e);
									formik.setFieldValue('numero_doc', '');
									setDocumentTypeSelected(e.target.value);
								}}
								className='form-select inputForm'
								data-kt-select2='true'
								data-placeholder={t('Seleccione un tipo')}
								data-allow-clear='true'
							>
								{documentTypes.map((doc, i) => (
									<option value={doc.id} key={doc.id}>
										{t(doc.value)}
									</option>
								))}
							</select>
						</div>
					</div>

					<label className='form-label fs-6 fw-bolder text-dark'>{t(documentLabel)}</label>
					<input
						placeholder={t(documentLabel)}
						{...formik.getFieldProps('numero_doc')}
						onChange={(e) => {
							if (e.target.value.length <= maxDocumentFieldLenght) formik.handleChange(e);
						}}
						className={clsx(
							'form-control bg-transparent inputForm',
							{ 'is-invalid': formik.touched.numero_doc && formik.errors.numero_doc },
							{ 'is-valid': formik.touched.numero_doc && !formik.errors.numero_doc }
						)}
						type={documentTypeField}
						name='numero_doc'
						autoComplete='off'
					/>
					{formik.touched.numero_doc && formik.errors.numero_doc && (
						<div className='fv-plugins-message-container'>
							<div className='fv-help-block'>
								<span role='alert'>{t(formik.errors.numero_doc)}</span>
							</div>
						</div>
					)}
					{/* </>
					)} */}
				</div>
				{/* end::Form group */}

				{/* begin::Form group */}
				<div className='fv-row mb-3'>
					<label className='form-label fw-bolder text-dark fs-6 mb-0'>{t('Senha')}</label>
					<input
						type={showPass ? 'text' : 'password'}
						placeholder={t('Senha')}
						autoComplete='off'
						{...formik.getFieldProps('password')}
						className={clsx(
							'form-control bg-transparent inputForm',
							{
								'is-invalid': formik.touched.password && formik.errors.password,
							},
							{
								'is-valid': formik.touched.password && !formik.errors.password,
							}
						)}
					/>
					{showPass ? (
						<i
							className='bi bi-eye pe-auto iconMobile'
							style={{
								fontSize: '20px',
								float: 'right',
								marginRight: '40px',
								marginTop: '-30px',
								position: 'relative',
								zIndex: '2',
							}}
							onClick={() => setShowPass(!showPass)}
						></i>
					) : (
						<i
							className='bi bi-eye-slash pe-auto iconMobile'
							style={{
								fontSize: '20px',
								float: 'right',
								marginRight: '40px',
								marginTop: '-30px',
								position: 'relative',
								zIndex: '2',
							}}
							onClick={() => setShowPass(!showPass)}
						></i>
					)}
					{formik.touched.password && formik.errors.password && (
						<div className='fv-plugins-message-container'>
							<div className='fv-help-block'>
								<span role='alert'>{t(formik.errors.password)}</span>
							</div>
						</div>
					)}
				</div>
				{/* end::Form group */}

				<div
					className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mt-6 mb-6'
					style={{ justifyContent: 'center', marginTop: 60 }}
				>
					<ReCAPTCHA
						sitekey='6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
						onChange={onChangeCaptcha}
					/>
				</div>

				{/* begin::Action */}
				<div className='d-grid mb-10'>
					<button
						type='submit'
						id='kt_sign_in_submit'
						className='btn btn-primary btnMobile'
						style={{ background: '#0A4396' }}
						disabled={formik.isSubmitting || !formik.isValid}
					>
						{!loading && (
							<>
								<i className='bi bi-box-arrow-in-right' style={{ fontSize: '20px' }}></i>
								<span className='indicator-label'>{t('Iniciar sessão')}</span>
							</>
						)}
						{loading && (
							<span className='indicator-progress' style={{ display: 'block' }}>
								{t('Aguarde...')}
								<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
							</span>
						)}
					</button>
				</div>
				{/* end::Action */}

				{/* begin::Wrapper */}
				<div className='d-flex flex-row justify-content-between'>
					<div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
						<div />
						{/* begin::Link */}
						<Select
							options={languageOptions}
							styles={{
								control: (base: any) => ({
									...base,
									border: 0,
									width: 100,
									boxShadow: 'none',
									'&:hover': { border: 0 },
								}),
							}}
							onChange={handleChangeLanguage}
							value={languageOptions.find((obj) => obj.value === languageSelectec)}
							formatOptionLabel={({ label, flag }) => (
								<div style={{ display: 'flex', justifyContent: 'center' }}>
									<img src={flag} alt={label} style={{ width: 20, height: 20 }} />
								</div>
							)}
						/>

						{/* end::Link */}
					</div>
					<div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
						<div />
						{/* begin::Link */}
						<Link to='/olvide-contrasena' className='link-primary'>
							{t('Esqueci minha senha')}
						</Link>
						{/* end::Link */}
					</div>
				</div>
				{/* end::Wrapper */}
			</form>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					variant='filled'
					severity={isError ? 'error' : 'success'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{t(msgAlert)}
				</Alert>
			</Snackbar>
		</div>
	);
}
