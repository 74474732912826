import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import { CurricullumSectionProps } from "../../modules/auth/components/sections/curriculum-registration/CurricullumFormTypes";

export type SectionAdditionalCourseType = {
    id?: number;
    institution?: string;
    course?: string | undefined | null;
    start_date?: string | undefined | boolean;
    end_date?: number | string | undefined | null;
    startMonth?: string | undefined | boolean;
    startYear?: string | number | undefined | boolean;
    conclusionMonth?: string | undefined | boolean;
    conclusionYear?: string | number | undefined | boolean;

}

export const useAdditionalCourse = ({ setFieldValue, values }: CurricullumSectionProps) => {
    const { additionalCourses } = values;
    const { t } = useTranslation();
    const [valueAdd, setValueAdd] = useState<SectionAdditionalCourseType>({})
    const [borderColorEdit, setBorderColorEdit] = useState<any>('1px solid');
    const [editMode, setEditMode] = useState(false);

    const [openRowEdit, setOpenRowEdit] = useState<number>(0); const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(true);
    const [alertMsg, setAlertMsg] = useState('');
    const [rows, setRows] = useState<SectionAdditionalCourseType[]>(values.additionalCourses || []);
    const setValues = (val: SectionAdditionalCourseType) => {
        setValueAdd(val);
    }


    useEffect(() => {
        setRows(values.additionalCourses)
    }, [additionalCourses])

    const {
        institution,
        course,
        start_date,
        end_date,
        startMonth,
        startYear,
        conclusionMonth,
        conclusionYear
    } = valueAdd;


    const handleclear = () => {
        setValueAdd({})
        setOpenRowEdit(0);
        setEditMode(false);
    };
    const saveToForm = (rowsAdd: any[]) => {
        setFieldValue('additionalCourses', rowsAdd);
    }
    console.log("values.additionalCourses", values.additionalCourses);

    console.log("values.additionalCourses rows", rows);
    const handleSaveEdit = () => {
        if (editMode) {
            let item = rows.find((x) => x.id === openRowEdit);
            console.log('item edit', item);
            let iRemove = rows.findIndex((x) => x.id === openRowEdit);
            if (handleValidOnSubmit()) {

                let itemsToSave = rows.filter((_, i) => i !== iRemove);
                let d = new Date();
                let m = d.getMinutes();
                let s = d.getSeconds();
                let idRandom = openRowEdit ? openRowEdit : parseInt(`${m}${s}`);

                itemsToSave.push({
                    id: idRandom,
                    institution: institution as string,
                    course: course,
                    start_date: `${startMonth}/${startYear}`,
                    end_date: `${conclusionMonth}/${conclusionYear}`,
                    startMonth: startMonth,
                    startYear: startYear,
                    conclusionMonth,
                    conclusionYear
                });

                setRows(itemsToSave);
                saveToForm(itemsToSave);
                handleclear();
            }
        }
    };

    const handleAddExperienciaLaboral = () => {
        if (handleValidOnSubmit()) {
            const rowsAdd = [
                ...rows,
                {
                    id: rows.length + 1,
                    institution: institution as string,
                    course: course,
                    start_date: `${startMonth}/${startYear}`,
                    end_date: `${conclusionMonth}/${conclusionYear}`,
                    startMonth: startMonth,
                    startYear: startYear,
                    conclusionMonth,
                    conclusionYear

                },
            ]
            setRows(rowsAdd);
            setOpen(true);
            setAlert(true);
            setAlertMsg(`Experiencia laboral Agregado con éxito!`);
            saveToForm(rowsAdd);
            setTimeout(() => {
                setOpen(false);
            }, 7000);
            handleclear();
        }
    };
    const handleRemoveExperienciaLaboral = (id: number) => {
        const list = [...rows];
        list.splice(_.findIndex(list, { id: id }), 1);
        setRows(list);
        saveToForm(list);
    };


    const handleValidOnSubmit = () => {
        let errors: { field: string; error: string }[] = [];



        console.log(errors);
        if (errors.length > 0) {
            setAlert(false);
            setAlertMsg(
                `\ ${t('Diligencie los campos obligatorios')}\n ${errors.map((x) => {
                    return `\n ${t(x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase()))}: ${t(x.error)
                        }`;
                })}`
            );
            setOpen(true);
            return false;
        }
        return true;
    };

    const handleEdit = (item: any) => {
        setEditMode(true);
        setOpenRowEdit(item.id);
        setBorderColorEdit('2px solid blue')
        setTimeout(() => {
            setBorderColorEdit('1px solid')
        }, 4000);
        const doc = document.getElementById("institutionAddCourse");
        doc?.focus({
            preventScroll: false
        });
        let yerEmit;
        let monthEmit;
        let yearExpire;
        let monthExpire;

        if (!item.startMonth && !item.startYear) {
            yerEmit = item.start_date.split("/")?.[1];
            monthEmit = item.start_date.split("/")?.[0];
        }

        if (!item.conclusionMonth && !item.conclusionYear) {
            yearExpire = item.end_date.split("/")?.[1];
            monthExpire = item.end_date.split("/")?.[0];
        }

        console.log("monthEmit", monthEmit)
        setValueAdd({
            id: item.id,
            institution: item.institution as string,
            course: item.course,
            start_date: `${item.startMonth}/${item.startYear}`,
            end_date: `${item.conclusionMonth}/${item.conclusionYear}`,
            startMonth: monthEmit ? monthEmit : item.startMonth,
            startYear: yerEmit ? yerEmit : item.startYear,
            conclusionMonth: monthExpire ? monthExpire : item.conclusionMonth,
            conclusionYear: yearExpire ? yearExpire : item.conclusionYear,
        })

    };
    const handleClose = () => {
        setOpen(false);
    };

    return {
        valueAdd,
        borderColorEdit,
        editMode,
        openRowEdit,
        alert,
        alertMsg,
        open,
        setValues,
        handleClose,
        handleEdit,
        handleSaveEdit,
        handleRemoveExperienciaLaboral,
        handleAddExperienciaLaboral,
        handleclear,
        rows,
    }

}