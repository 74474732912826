import { useTranslation } from 'react-i18next';
import styleScss from './styles.module.scss';
import MenuActionCompanyManagement from '../MenuActionCompanyManagement';
import { Button, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';

export default function RequestVacancyCardComponent({
	data,
	openMenu,
	handleClickMenu,
	anchorEl,
	handleCloseMenu
}: any) {
	const { t } = useTranslation();

	return (
		<div className={styleScss.cardMobile}>
			<div className={styleScss['card-body']}>
				<h5 className={styleScss['card-title']}>
					<strong>{t('Vacante')}: </strong>
					{data.nombre_vacante}
				</h5>
				<p className={styleScss['card-text']}>
					<strong>{t('Empleador')}: </strong>
					{data.Empresa.razon_social}
				</p>
				<p className={styleScss['card-text']}>
					<strong>{t('Descripción')}: </strong>
					{data.Describa_vacante}
				</p>
				<p className={styleScss['card-text']}>
					<strong>{t('Estado')}: </strong>
					{data.status ? t('Aberta') : t('Fechada')}
				</p>
			</div>
			<div className={styleScss.options}>
				<Button
					id='basic-button'
					aria-controls={openMenu ? 'basic-menu' : undefined}
					aria-haspopup='true'
					aria-expanded={openMenu ? 'true' : undefined}
					onClick={(e) => handleClickMenu(e, data.id)}
				>
					<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
				</Button>

				<Menu
					id='basic-menu'
					anchorEl={anchorEl}
					open={openMenu}
					onClose={handleCloseMenu}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
				>
					<MenuItem onClick={handleCloseMenu}>
						<Link to={`/candidato/vacante/${data.id}`}>{t('Ver vacante')}</Link>
					</MenuItem>
				</Menu>
			</div>
		</div>
	);
}
