import React, { useEffect, useMemo, useState } from 'react'
import { CurriculumRegistration } from '../modules/auth/components/CurriculumRegistration'
import { Formik, Form } from 'formik';
import { getLevelLanguages, getCourseTypes, getWorkshopParticipant, getMaritalStatus } from '../modules/auth/core/_requests'
export const multiStepContext = React.createContext()

const CurriculumContext = () => {
  const [currentStep, setStep] = useState(1)
  const [userData, setUserData] = useState([''])
  const [finalData, setFinalData] = useState([''])
  const [data, setData] = useState([])
  const [levelLanguages, setLevelLanguages] = useState([])
  const [courseTypes, setCourseTypes] = useState([])
  const [workshopTypeParticipants, setWorkshopTypeParticipants] = useState([])
  const [listMaritalStatus, setListMaritalStatus] = useState([])


  const [loadedData, setLoadedData] =
    useState(false); /*Used to know if the information has been loaded once (edit mode)*/

  const submitData = () => {
    setFinalData(finalData => [...finalData, userData])
    setUserData('')
  }


  const callServices = async () => {
    try {
      const resLevel = await getLevelLanguages();
      if (resLevel?.data?.length) {
        setLevelLanguages(resLevel.data);
      }
      const resCourseTypes = await getCourseTypes();
      if (resCourseTypes?.data?.length) {
        setCourseTypes(resCourseTypes.data)
      }
      const reswork = await getWorkshopParticipant();
      if (reswork?.data?.length) {
        setWorkshopTypeParticipants(reswork.data);
      }
      const resMatrial = await getMaritalStatus();
      if (resMatrial?.data?.length) {
        setListMaritalStatus(resMatrial.data);
      }
    } catch (e) {
      console.error(e)
    }
  }
  const ls = useMemo(() => {
    callServices();
  }, [])
  useEffect(() => {
  }, [])

  return (
    <div>
      <multiStepContext.Provider
        value={{
          currentStep,
          setStep,
          userData,
          setUserData,
          finalData,
          setFinalData,
          submitData,
          data,
          setData,
          loadedData,
          setLoadedData,
          levelLanguages,
          courseTypes,
          workshopTypeParticipants,
          listMaritalStatus
        }}
      >
        <Formik>
          <Form>
            <CurriculumRegistration />
          </Form>
        </Formik>
      </multiStepContext.Provider>
    </div>
  )
}

export default CurriculumContext