import { FormEvent, useContext, useState, useEffect } from 'react';
import {
	TextField,
	SelectChangeEvent,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
	Button,
	Box,
	Divider,
	FormHelperText,
	InputAdornment,
	Alert,
	Snackbar,
	AlertTitle,
} from '@mui/material';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { multiStepContext } from '../../../../../contexts/CompanyEditContext';
import _ from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { ListMunicipioModel } from '../../../../../models/MunicipioModel';
import {
	initValueString,
	initValue,
	setValueOrDefault,
	regExpPhoneNumber12Digits,
	formatMaskPhoneNumber12Digits,
} from '../../../../../util/Index';
import { updateCompany } from '../../../../apps/user-management/users-list/core/_requests';
import { StringDecoder } from 'string_decoder';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export const Step2 = () => {
	const { t } = useTranslation();
	const { setStep, userData, setUserData, firstUser, setFirstUser, firstCompany, setFirstCompany, companyId } =
		useContext(multiStepContext);
	console.log(firstCompany);
	const [loading, setLoading] = useState(false);
	const [verified, setVerified] = useState(false);
	const navigate = useNavigate();

	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const handleClose = () => {
		setOpen(false);
	};

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		const listRowsComplementos = rowsComplementos.find((obj) => {
			return obj.id === 0;
		});

		if (listRowsComplementos) {
			const list = [...rowsComplementos];
			list.splice(_.findIndex(list, { id: 0 }), 1);
			setRowsComplementos(list);
		}
		//preenche listagem de municipio (cidade) de acordo com o id_departamento
		if (firstCompany.company['departamento']) {
			const listRowsMunicipio = ListMunicipioModel.filter(
				(obj) => obj.id_Depar.indexOf(firstCompany.company['departamento']) !== -1
			);
			setDdlMunicipioResidencia(listRowsMunicipio);
		} else {
			setDdlMunicipioResidencia([]);
		}
	}, []);

	const handleAddComplemento = () => {
		setFirstCompany((prevFirstCompany: { complemento: any[] }) => ({
			...prevFirstCompany,
			complemento: [
				...prevFirstCompany.complemento,
				{ tipo_complemento: '', complemento: '' }, // Adicione um novo objeto vazio ao array de complemento
			],
		}));
	};

	const isValidFieldRequired = (field: any) => {
		return field === '' || field === undefined || field === null ? false : true;
	};

	const strDirection = (val: any) => {
		return !isValidFieldRequired(val) ? '' : val;
	};

	const handleDelComplemento = (index: any) => {
		setFirstCompany((prevFirstCompany: { complemento: any[] }) => {
			const updatedComplemento = prevFirstCompany.complemento.filter((item, idx) => idx !== index);
			return {
				...prevFirstCompany,
				complemento: updatedComplemento,
			};
		});
	};

	const ddlPaisResidencia = [
		{ id: 'AF', name: 'Afganistán' },
		{ id: 'AL', name: 'Albania' },
		{ id: 'DE', name: 'Alemania' },
		{ id: 'AD', name: 'Andorra' },
		{ id: 'AO', name: 'Angola' },
		{ id: 'AI', name: 'Anguila' },
		{ id: 'AQ', name: 'Antártida' },
		{ id: 'AG', name: 'Antigua y Barbuda' },
		{ id: 'AN', name: 'Antillas Holandesas' },
		{ id: 'SA', name: 'Arabia Saudita' },
		{ id: 'DZ', name: 'Argelia' },
		{ id: 'AR', name: 'Argentina' },
		{ id: 'AM', name: 'Armenia' },
		{ id: 'AW', name: 'Aruba' },
		{ id: 'MK', name: 'ARY Macedonia' },
		{ id: 'AU', name: 'Australia' },
		{ id: 'AT', name: 'Austria' },
		{ id: 'AZ', name: 'Azerbaiyán' },
		{ id: 'BS', name: 'Bahamas' },
		{ id: 'BH', name: 'Baréin' },
		{ id: 'BD', name: 'Bangladesh' },
		{ id: 'BB', name: 'Barbados' },
		{ id: 'BE', name: 'Bélgica' },
		{ id: 'BZ', name: 'Belice' },
		{ id: 'BJ', name: 'Benín' },
		{ id: 'BM', name: 'Bermudas' },
		{ id: 'BT', name: 'Bután' },
		{ id: 'BY', name: 'Bielorrusia' },
		{ id: 'BO', name: 'Bolivia' },
		{ id: 'BA', name: 'Bosnia y Herzegovina' },
		{ id: 'BW', name: 'Botsuana' },
		{ id: 'BR', name: 'Brasil' },
		{ id: 'BN', name: 'Brunéi' },
		{ id: 'BG', name: 'Bulgaria' },
		{ id: 'BF', name: 'Burkina Faso' },
		{ id: 'BI', name: 'Burundi' },
		{ id: 'CV', name: 'Cabo Verde' },
		{ id: 'KH', name: 'Camboya' },
		{ id: 'CM', name: 'Camerún' },
		{ id: 'CA', name: 'Canadá' },
		{ id: 'QA', name: 'Catar' },
		{ id: 'TD', name: 'Chad' },
		{ id: 'CL', name: 'Chile' },
		{ id: 'CN', name: 'China' },
		{ id: 'CY', name: 'Chipre' },
		{ id: 'VA', name: 'Ciudad del Vaticano' },
		{ id: 'CO', name: 'Colombia' },
		{ id: 'KM', name: 'Comoras' },
		{ id: 'CG', name: 'Congo' },
		{ id: 'KP', name: 'Corea del Norte' },
		{ id: 'KR', name: 'Corea del Sur' },
		{ id: 'CI', name: 'Costa de Marfil' },
		{ id: 'CR', name: 'Costa Rica' },
		{ id: 'HR', name: 'Croacia' },
		{ id: 'CU', name: 'Cuba' },
		{ id: 'DK', name: 'Dinamarca' },
		{ id: 'DM', name: 'Dominica' },
		{ id: 'EC', name: 'Ecuador' },
		{ id: 'EG', name: 'Egipto' },
		{ id: 'SV', name: 'El Salvador' },
		{ id: 'AE', name: 'Emiratos Árabes Unidos' },
		{ id: 'ER', name: 'Eritrea' },
		{ id: 'SK', name: 'Eslovaquia' },
		{ id: 'SI', name: 'Eslovenia' },
		{ id: 'ES', name: 'España' },
		{ id: 'US', name: 'Estados Unidos' },
		{ id: 'EE', name: 'Estonia' },
		{ id: 'ET', name: 'Etiopía' },
		{ id: 'PH', name: 'Filipinas' },
		{ id: 'FI', name: 'Finlandia' },
		{ id: 'FJ', name: 'Fiyi' },
		{ id: 'FR', name: 'Francia' },
		{ id: 'GA', name: 'Gabón' },
		{ id: 'GM', name: 'Gambia' },
		{ id: 'GE', name: 'Georgia' },
		{ id: 'GH', name: 'Ghana' },
		{ id: 'GI', name: 'Gibraltar' },
		{ id: 'GD', name: 'Granada' },
		{ id: 'GR', name: 'Grecia' },
		{ id: 'GL', name: 'Groenlandia' },
		{ id: 'GP', name: 'Guadalupe' },
		{ id: 'GU', name: 'Guam' },
		{ id: 'GT', name: 'Guatemala' },
		{ id: 'GF', name: 'Guayana Francesa' },
		{ id: 'GN', name: 'Guinea' },
		{ id: 'GQ', name: 'Guinea Ecuatorial' },
		{ id: 'GW', name: 'Guinea-Bissau' },
		{ id: 'GY', name: 'Guyana' },
		{ id: 'HT', name: 'Haití' },
		{ id: 'HN', name: 'Honduras' },
		{ id: 'HK', name: 'Hong Kong' },
		{ id: 'HU', name: 'Hungría' },
		{ id: 'IN', name: 'India' },
		{ id: 'ID', name: 'Indonesia' },
		{ id: 'IR', name: 'Irán' },
		{ id: 'IQ', name: 'Iraq' },
		{ id: 'IE', name: 'Irlanda' },
		{ id: 'NF', name: 'Isla Norfolk' },
		{ id: 'IS', name: 'Islandia' },
		{ id: 'KY', name: 'Islas Caimán' },
		{ id: 'CC', name: 'Islas Cocos' },
		{ id: 'CK', name: 'Islas Cook' },
		{ id: 'FO', name: 'Islas Feroe' },
		{ id: 'AX', name: 'Islas Gland' },
		{ id: 'FK', name: 'Islas Malvinas' },
		{ id: 'MP', name: 'Islas Marianas del Norte' },
		{ id: 'MH', name: 'Islas Marshall' },
		{ id: 'PN', name: 'Islas Pitcairn' },
		{ id: 'SB', name: 'Islas Salomón' },
		{ id: 'TC', name: 'Islas Turcas y Caicos' },
		{ id: 'VG', name: 'Islas Vírgenes Británicas' },
		{ id: 'VI', name: 'Islas Vírgenes de los Estados Unidos' },
		{ id: 'IL', name: 'Israel' },
		{ id: 'IT', name: 'Italia' },
		{ id: 'JM', name: 'Jamaica' },
		{ id: 'JP', name: 'Japón' },
		{ id: 'JO', name: 'Jordania' },
		{ id: 'KZ', name: 'Kazajistán' },
		{ id: 'KE', name: 'Kenia' },
		{ id: 'KG', name: 'Kirguistán' },
		{ id: 'KI', name: 'Kiribati' },
		{ id: 'KW', name: 'Kuwait' },
		{ id: 'LA', name: 'Laos' },
		{ id: 'LS', name: 'Lesoto' },
		{ id: 'LV', name: 'Letonia' },
		{ id: 'LB', name: 'Líbano' },
		{ id: 'LR', name: 'Liberia' },
		{ id: 'LY', name: 'Libia' },
		{ id: 'LI', name: 'Liechtenstein' },
		{ id: 'LT', name: 'Lituania' },
		{ id: 'LU', name: 'Luxemburgo' },
		{ id: 'MO', name: 'Macao' },
		{ id: 'MK', name: 'Macedonia del Norte' },
		{ id: 'MG', name: 'Madagascar' },
		{ id: 'MY', name: 'Malasia' },
		{ id: 'MW', name: 'Malawi' },
		{ id: 'MV', name: 'Maldivas' },
		{ id: 'ML', name: 'Malí' },
		{ id: 'MT', name: 'Malta' },
		{ id: 'MA', name: 'Marruecos' },
		{ id: 'MQ', name: 'Martinica' },
		{ id: 'MU', name: 'Mauricio' },
		{ id: 'MR', name: 'Mauritania' },
		{ id: 'YT', name: 'Mayotte' },
		{ id: 'MX', name: 'México' },
		{ id: 'FM', name: 'Micronesia' },
		{ id: 'MD', name: 'Moldavia' },
		{ id: 'MC', name: 'Mónaco' },
		{ id: 'MN', name: 'Mongolia' },
		{ id: 'MS', name: 'Montserrat' },
		{ id: 'MZ', name: 'Mozambique' },
		{ id: 'MM', name: 'Myanmar' },
		{ id: 'NA', name: 'Namibia' },
		{ id: 'NR', name: 'Nauru' },
		{ id: 'NP', name: 'Nepal' },
		{ id: 'NI', name: 'Nicaragua' },
		{ id: 'NE', name: 'Níger' },
		{ id: 'NG', name: 'Nigeria' },
		{ id: 'NU', name: 'Niue' },
		{ id: 'NO', name: 'Noruega' },
		{ id: 'NC', name: 'Nueva Caledonia' },
		{ id: 'NZ', name: 'Nueva Zelanda' },
		{ id: 'OM', name: 'Omán' },
		{ id: 'NL', name: 'Países Bajos' },
		{ id: 'PK', name: 'Pakistán' },
		{ id: 'PW', name: 'Palau' },
		{ id: 'PS', name: 'Palestina' },
		{ id: 'PA', name: 'Panamá' },
		{ id: 'PG', name: 'Papúa Nueva Guinea' },
		{ id: 'PY', name: 'Paraguay' },
		{ id: 'PE', name: 'Perú' },
		{ id: 'PF', name: 'Polinesia Francesa' },
		{ id: 'PL', name: 'Polonia' },
		{ id: 'PT', name: 'Portugal' },
		{ id: 'PR', name: 'Puerto Rico' },
		{ id: 'GB', name: 'Reino Unido' },
		{ id: 'CF', name: 'República Centroafricana' },
		{ id: 'CZ', name: 'República Checa' },
		{ id: 'CD', name: 'República Democrática del Congo' },
		{ id: 'DO', name: 'República Dominicana' },
		{ id: 'RE', name: 'Reunión' },
		{ id: 'RW', name: 'Ruanda' },
		{ id: 'RO', name: 'Rumania' },
		{ id: 'RU', name: 'Rusia' },
		{ id: 'EH', name: 'Sahara Occidental' },
		{ id: 'WS', name: 'Samoa' },
		{ id: 'AS', name: 'Samoa Americana' },
		{ id: 'KN', name: 'San Cristóbal y Nevis' },
		{ id: 'SM', name: 'San Marino' },
		{ id: 'PM', name: 'San Pedro y Miquelón' },
		{ id: 'VC', name: 'San Vicente y las Granadinas' },
		{ id: 'SH', name: 'Santa Helena' },
		{ id: 'LC', name: 'Santa Lucía' },
		{ id: 'ST', name: 'Santo Tomé y Príncipe' },
		{ id: 'SN', name: 'Senegal' },
		{ id: 'CS', name: 'Serbia y Montenegro' },
		{ id: 'SC', name: 'Seychelles' },
		{ id: 'SL', name: 'Sierra Leona' },
		{ id: 'NS', name: 'Sin Definir' },
		{ id: 'SG', name: 'Singapur' },
		{ id: 'SY', name: 'Siria' },
		{ id: 'SO', name: 'Somalia' },
		{ id: 'LK', name: 'Sri Lanka' },
		{ id: 'SZ', name: 'Suazilandia' },
		{ id: 'ZA', name: 'Sudáfrica' },
		{ id: 'SD', name: 'Sudán' },
		{ id: 'SE', name: 'Suecia' },
		{ id: 'CH', name: 'Suiza' },
		{ id: 'SR', name: 'Surinam' },
		{ id: 'SJ', name: 'Svalbard y Jan Mayen' },
		{ id: 'TH', name: 'Tailandia' },
		{ id: 'TW', name: 'Taiwán' },
		{ id: 'TZ', name: 'Tanzania' },
		{ id: 'TJ', name: 'Tayikistán' },
		{ id: 'TL', name: 'Timor Oriental' },
		{ id: 'TG', name: 'Togo' },
		{ id: 'TK', name: 'Tokelau' },
		{ id: 'TO', name: 'Tonga' },
		{ id: 'TT', name: 'Trinidad y Tobago' },
		{ id: 'TN', name: 'Túnez' },
		{ id: 'TM', name: 'Turkmenistán' },
		{ id: 'TR', name: 'Turquía' },
		{ id: 'TV', name: 'Tuvalu' },
		{ id: 'UA', name: 'Ucrania' },
		{ id: 'UG', name: 'Uganda' },
		{ id: 'UY', name: 'Uruguay' },
		{ id: 'UZ', name: 'Uzbekistán' },
		{ id: 'VU', name: 'Vanuatu' },
		{ id: 'VE', name: 'Venezuela' },
		{ id: 'VN', name: 'Vietnam' },
		{ id: 'WF', name: 'Wallis y Futuna' },
		{ id: 'YE', name: 'Yemen' },
		{ id: 'DJ', name: 'Yibuti' },
		{ id: 'ZM', name: 'Zambia' },
		{ id: 'ZW', name: 'Zimbabue' },
	];
	const ddlDepartamentoResidencia = [
		{ id: '91', name: 'AMAZONAS' },
		{ id: '5', name: 'ANTIOQUIA' },
		{ id: '81', name: 'ARAUCA' },
		{ id: '8', name: 'ATLÁNTICO' },
		{ id: '11', name: 'BOGOTÁ, D.C.' },
		{ id: '13', name: 'BOLÍVAR' },
		{ id: '15', name: 'BOYACÁ' },
		{ id: '17', name: 'CALDAS' },
		{ id: '18', name: 'CAQUETÁ' },
		{ id: '85', name: 'CASANARE' },
		{ id: '19', name: 'CAUCA' },
		{ id: '20', name: 'CESAR' },
		{ id: '27', name: 'CHOCÓ' },
		{ id: '23', name: 'CÓRDOBA' },
		{ id: '25', name: 'CUNDINAMARCA' },
		{ id: '94', name: 'GUAINÍA' },
		{ id: '95', name: 'GUAVIARE' },
		{ id: '41', name: 'HUILA' },
		{ id: '44', name: 'LA GUAJIRA' },
		{ id: '47', name: 'MAGDALENA' },
		{ id: '50', name: 'META' },
		{ id: '52', name: 'NARIÑO' },
		{ id: '54', name: 'NORTE DE SANTANDER' },
		{ id: '86', name: 'PUTUMAYO' },
		{ id: '63', name: 'QUINDIO' },
		{ id: '66', name: 'RISARALDA' },
		{ id: '88', name: 'SAN ANDRÉS Y PROVIDENCIA' },
		{ id: '68', name: 'SANTANDER' },
		{ id: '70', name: 'SUCRE' },
		{ id: '73', name: 'TOLIMA' },
		{ id: '76', name: 'VALLE DEL CAUCA' },
		{ id: '97', name: 'VAUPÉS' },
		{ id: '99', name: 'VICHADA' },
	];

	const ddlAgencia = [{ id: '322078', name: 'BOGOTÁ DISTRITO CAPITAL' }];
	const ddlPuntoAtencion = [
		{ id: '380619', name: 'BOGOTÁ, D.C. - ALCALDÍA DE BOGOTÁ D.C - TUNJUELITO' },
		{ id: '380653', name: 'BOGOTÁ, D.C. - BRIGADA MOVIL' },
		{ id: '380528', name: 'BOGOTÁ, D.C. - PLAZA DE MERCADO QUIRIGUA' },
		{
			id: '380526',
			name: 'BOGOTÁ, D.C. - PUNTO DE INFORMACIÓN ALCALDÍA DE BOGOTÁ - CIUDAD BOLIVAR',
		},
		{ id: '380525', name: 'BOGOTÁ, D.C. - PUNTO DE INFORMACIÓN ALCALDÍA DE BOGOTÁ - USAQUEN' },
		{
			id: '380527',
			name: 'BOGOTÁ, D.C. - PUNTO DE INFORMACIÓN ALCALDÍA DE BOGOTÁ D.C - RAFAEL URIBE URIBE',
		},
		{
			id: '380618',
			name: 'BOGOTÁ, D.C. - PUNTO DE INFORMACIÓN ALCALDÍA DE BOGOTÁ D.C - SAN CRISTOBAL SUR',
		},
		{ id: '380534', name: 'BOGOTÁ, D.C. - TUNJUELITO' },
	];
	const ddlViaPrincipalBis = [{ id: 'BIS', name: 'BIS' }];
	const ddlViaPrincipalCuadrante = [
		{ id: 'SUR', name: 'SUR' },
		{ id: 'NORTE', name: 'NORTE' },
		{ id: 'ESTE', name: 'ESTE' },
		{ id: 'OESTE', name: 'OESTE' },
	];
	const ddlUrbanoRural = [
		{ id: '1', name: 'Urbana' },
		{ id: '2', name: 'Rural' },
	];
	const ddlViaPrincipal = [
		{ id: 'CL', name: 'Calle' },
		{ id: 'KR', name: 'Carrera' },
		{ id: 'DG', name: 'Diagonal' },
		{ id: 'TV', name: 'Transversal' },
		{ id: 'AV', name: 'Avenida' },
		{ id: 'AC', name: 'Avenida Calle' },
		{ id: 'AK', name: 'Avenida Carrera' },
		{ id: 'AU', name: 'Autopista' },
		{ id: 'CQ', name: 'Circular' },
		{ id: 'CV', name: 'Circunvalar' },
		{ id: 'CC', name: 'Cuentas Corridas' },
		{ id: 'PJ', name: 'Pasaje' },
		{ id: 'PS', name: 'Paseo' },
		{ id: 'PT', name: 'Peatonal' },
		{ id: 'TC', name: 'Troncal' },
		{ id: 'VT', name: 'Variante' },
		{ id: 'VI', name: 'Via' },
		{ id: 'Otro', name: 'Otro' },
		{ id: 'AGRUPACIÓN', name: 'Agrupación' },
		{ id: 'APTO', name: 'Apartamento' },
		{ id: 'BL', name: 'Bloque' },
		{ id: 'BUL', name: 'Bulevar' },
		{ id: 'BÓDEGA', name: 'Bódega' },
		{ id: 'CARRETERA', name: 'Carretera' },
		{ id: 'CASA', name: 'Casa' },
		{ id: 'COMUNAL', name: 'Comunal' },
		{ id: 'CONJ', name: 'Conjunto' },
		{ id: 'CONSULTORIO', name: 'Consultorio' },
		{ id: 'DEPO', name: 'Déposito' },
		{ id: 'EDIF', name: 'Edificio' },
		{ id: 'ENTRADA', name: 'Entrada' },
		{ id: 'ESQUINA', name: 'Esquina' },
		{ id: 'ETAPA', name: 'Etapa' },
		{ id: 'GJ', name: 'Garaje' },
		{ id: 'INT', name: 'Interior' },
		{ id: 'KM', name: 'Kilómetro' },
		{ id: 'LOCAL', name: 'Local' },
		{ id: 'LOTE', name: 'Lote' },
		{ id: 'MZ', name: 'Manzana' },
		{ id: 'MEZZ', name: 'Mezzanine' },
		{ id: 'MÓDULO', name: 'Módulo' },
		{ id: 'OF', name: 'Oficina' },
		{ id: 'PARCELA', name: 'Parcela' },
		{ id: 'PASEO', name: 'Paseo' },
		{ id: 'PENTHOUSE', name: 'Penthouse' },
		{ id: 'PISO', name: 'Piso' },
		{ id: 'PH', name: 'Propiedad Horizontal' },
		{ id: 'SALON', name: 'Salón' },
		{ id: 'SECTOR', name: 'Sector' },
		{ id: 'SEMISOTANO', name: 'Semisótano' },
		{ id: 'SOLAR', name: 'Solar' },
		{ id: 'SOTANO', name: 'Sótano' },
		{ id: 'SUPERMZ', name: 'Super Manzana' },
		{ id: 'TORRE', name: 'Torre' },
		{ id: 'UN', name: 'Unidad' },
		{ id: 'VEREDA', name: 'Vereda' },
		{ id: 'VIA', name: 'Vía' },
		{ id: 'ZN', name: 'Zona' },
		{ id: 'Otros', name: 'Otros' },
	];
	const ddlViaPrincipalPrimeraLetra = [
		{ id: 'A', name: 'A' },
		{ id: 'B', name: 'B' },
		{ id: 'C', name: 'C' },
		{ id: 'D', name: 'D' },
		{ id: 'E', name: 'E' },
		{ id: 'F', name: 'F' },
		{ id: 'G', name: 'G' },
		{ id: 'H', name: 'H' },
		{ id: 'I', name: 'I' },
		{ id: 'J', name: 'J' },
		{ id: 'K', name: 'K' },
		{ id: 'L', name: 'L' },
		{ id: 'M', name: 'M' },
		{ id: 'N', name: 'N' },
		{ id: 'O', name: 'O' },
		{ id: 'P', name: 'P' },
		{ id: 'Q', name: 'Q' },
		{ id: 'R', name: 'R' },
		{ id: 'S', name: 'S' },
		{ id: 'T', name: 'T' },
		{ id: 'U', name: 'U' },
		{ id: 'V', name: 'V' },
		{ id: 'W', name: 'W' },
		{ id: 'X', name: 'X' },
		{ id: 'Y', name: 'Y' },
		{ id: 'Z', name: 'Z' },
	];
	const ddlComplementoDireccion = [
		{ id: 'AGRUPACIÓN', name: 'Agrupación' },
		{ id: 'APTO', name: 'Apartamento' },
		{ id: 'BL', name: 'Bloque' },
		{ id: 'BÓDEGA', name: 'Bódega' },
		{ id: 'AU', name: 'Autopista' },
		{ id: 'CARRETERA', name: 'Carretera' },
		{ id: 'CASA', name: 'Casa' },
		{ id: 'COMUNAL', name: 'Comunal' },
		{ id: 'CONJ', name: 'Conjunto' },
		{ id: 'CONSULTORIO', name: 'Consultorio' },
		{ id: 'DEPO', name: 'Déposito' },
		{ id: 'EDIF', name: 'Edificio' },
		{ id: 'ENTRADA', name: 'Entrada' },
		{ id: 'ESQUINA', name: 'Esquina' },
		{ id: 'ETAPA', name: 'Etapa' },
		{ id: 'GJ', name: 'Garaje' },
		{ id: 'INT', name: 'Interior' },
		{ id: 'KM', name: 'Kilómetro' },
		{ id: 'LOCAL', name: 'Local' },
		{ id: 'LOTE', name: 'Lote' },
		{ id: 'MZ', name: 'Manzana' },
		{ id: 'MEZZ', name: 'Mezzanine' },
		{ id: 'MÓDULO', name: 'Módulo' },
		{ id: 'OF', name: 'Oficina' },
		{ id: 'PARCELA', name: 'Parcela' },
		{ id: 'PASEO', name: 'Paseo' },
		{ id: 'PENTHOUSE', name: 'Penthouse' },
		{ id: 'PISO', name: 'Piso' },
		{ id: 'PH', name: 'Propiedad Horizontal' },
		{ id: 'SALON', name: 'Salón' },
		{ id: 'SECTOR', name: 'Sector' },
		{ id: 'SEMISOTANO', name: 'Semisótano' },
		{ id: 'SOLAR', name: 'Solar' },
		{ id: 'SOTANO', name: 'Sótano' },
		{ id: 'SUPERMZ', name: 'Super Manzana' },
		{ id: 'TORRE', name: 'Torre' },
		{ id: 'UN', name: 'Unidad' },
		{ id: 'VEREDA', name: 'Vereda' },
		{ id: 'VIA', name: 'Vía' },
		{ id: 'ZN', name: 'Zona' },
	];

	const [dispComple, setDispComple] = useState('none');
	const [rowsComplementos, setRowsComplementos] = useState([{ id: 0, descId: '' }]);
	const [ddlMunicipioResidencia, setDdlMunicipioResidencia] = useState([
		{ id_Depar: '0', id: '0', name: 'Seleccione' },
	]);

	const handleSelect = (e: SelectChangeEvent) => {
		if (e.target.value !== '') {
			const listRowsMunicipio = ListMunicipioModel.filter(
				(obj) => obj.id_Depar.indexOf(e.target.value) !== -1
			);
			setDdlMunicipioResidencia(listRowsMunicipio);
		} else {
			setDdlMunicipioResidencia([]);
		}
	};
	const handleDelete = (id: number) => {
		const list = [...rowsComplementos];
		list.splice(_.findIndex(list, { id: id }), 1);
		setRowsComplementos(list);
		if (list.length === 0) {
			setDispComple('none');
		}
	};
	const handleChange = () => {
		setRowsComplementos([
			...rowsComplementos,
			{
				id: rowsComplementos.length + 1,
				descId: 'tipo_complemento' + (rowsComplementos.length + 1),
			},
		]);
		setDispComple('block');
	};
	const handleDirectionValue = () => {
		let val =
			strDirection(firstCompany.company['local']) +
			' ' +
			strDirection(firstCompany.company['id_coordenada']) +
			' ' +
			strDirection(firstCompany.company['id_coordenada_02']) +
			' ' +
			strDirection(firstCompany.company['id_coordenada_03']) +
			' ' +
			strDirection(firstCompany.company['id_coordenada_04']) +
			' ' +
			strDirection(firstCompany.company['id_coordenada_05']) +
			' ' +
			strDirection(firstCompany.company['id_coordenada_06']) +
			' ' +
			strDirection(firstCompany.company['id_coordenada_07']) +
			' ' +
			strDirection(firstCompany.company['id_coordenada_08']) +
			' ' +
			strDirection(firstCompany.company['id_coordenada_09']) +
			', ';

		if (
			firstCompany['complemento'] !== null &&
			firstCompany['complemento'] !== undefined &&
			(firstCompany['complemento'] as Array<any>).length > 0
		) {
			(firstCompany['complemento'] as Array<any>).forEach((e) => {
				let x =
					strDirection(
						setValueOrDefault(
							ddlComplementoDireccion.find((y) => y.id === e['tipo_complemento'])?.name,
							'string'
						)
					) +
					' ' +
					strDirection(setValueOrDefault(e['complemento'], 'string')) +
					' ';
				val = val + x;
			});
		}

		return val.trimEnd() === '' ? '$' : val.trimEnd() + '';
	};

	async function handleSubmit(event: FormEvent) {
		event.preventDefault();
		try {
			if (handleValidOnSubmit()) {
				firstCompany.company.telefono = firstCompany.company?.telefono
					?.replace(/[^\d]/g, '')
					?.replaceAll('-', '')
					?.replaceAll('+', '');
				const companyBody = {
					firstCompany,
					firstUser,
				};
				console.log('bodySubmit', companyBody);

				const companyBodyUpdatedFormat = {
					firstCompany: {
						company: {
							id: companyId,
							company_name: firstCompany.company.razon_social,
							document_type: firstCompany.company.tipo_documento,
							document_number: firstCompany.company.numero_documento,
							phone_number: firstCompany.company.telefono,
							company_type: firstCompany.company.naturaleza,
							is_headquarters: firstCompany.company.pertence === '1',
							company_description: firstCompany.company.descricion_employer,
							street: firstCompany.company.direccion_residencia,
							complement: firstCompany.company.tipo_complemento,
							city: firstCompany.company.municipio,
							state: firstCompany.company.departamento,
							district: firstCompany.company.localidad,
							country: firstCompany.company.pais,
							latitude: firstCompany.company.latitud,
							longitude: firstCompany.company.longitud,
							number_employees: Number(firstCompany.company.Tamano_empleados) || null,
						},
					}
				}

				await updateCompany(companyBodyUpdatedFormat);
				setLoading(true);
				setAlert(true);
				setOpen(true);
				setAlertMsg('¡Registro editado con éxito!');
				setTimeout(() => {
					navigate(-1);
				}, 4000);
			}
		} catch (error: any) {
			setOpen(true);
			setAlert(false);
			setAlertMsg(`${error.response.data.error}`);
		}
	}

	const handleValidOnSubmit = (): boolean => {
		let errors: { field: string; error: string }[] = [];
		if (!isValidFieldRequired(firstCompany.company['pertence']))
			errors.push({ field: 'Pertenece a', error: 'Campo requerido' });

		if (!isValidFieldRequired(handleDirectionValue().trim().replaceAll(',', '')))
			errors.push({ field: 'Dirección sede', error: 'Campo requerido' });

		if (
			!isValidFieldRequired(firstCompany.company['telefono']) ||
			!isValidPhoneNumber(firstCompany.company['telefono'])
		)
			errors.push({
				field: 'Teléfono',
				error: msgValidFieldPhoneNumber(firstCompany.company['telefono']),
			});

		if (!isValidFieldRequired(firstCompany.company['departamento']))
			errors.push({ field: 'Departamento', error: 'Campo requerido' });

		if (!isValidFieldRequired(firstCompany.company['municipio']))
			errors.push({ field: 'Ciudad', error: 'Campo requerido' });

		if (!isValidFieldRequired(firstCompany.company['punta_atencion']))
			errors.push({ field: 'Punto de atención', error: 'Campo requerido' });

		console.log('error', errors);

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\n${t('Diligencie los campos obligatorios')}\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${
						t(x.error)
					}`;
				})}`
			);
			setOpen(true);
			setTimeout(() => {
				setOpen(false);
			}, 5000);
			return false;
		}

		return true;
	};

	const isValidPhoneNumber = (e: string): boolean => {
		if (
			e
				?.replace(/[^\d]/g, '')
				?.replaceAll('-', '')
				?.replaceAll('+', '')
				?.match(regExpPhoneNumber12Digits())
		)
			return true;

		return false;
	};

	const msgValidFieldPhoneNumber = (e: string) => {
		if (!isValidFieldRequired(e)) return `${t('Campo requerido')}`;

		if (!isValidPhoneNumber(e)) return `${t('Teléfono de contacto no es válido')}`;

		return '';
	};
	return (
		<>
			<Typography variant='h6' sx={{ fontWeight: 'bold', mb: 3 }}>
				{t('Datos de la compañía')}
			</Typography>
			<Grid container direction='row' item={true}>
				<Grid container direction='row' justifyContent='space-around' width={'100%'}>
					<Grid xs={12} sm={6} mb={5} pr={2} item={true}>
						<TextField
							fullWidth
							id='nombre_sede'
							name='nombre_sede'
							label={t('Nombre de la sede')}
							placeholder={t('Ingrese el nombre')}
							value={firstCompany.company['nombre_sede']}
							onChange={(e) =>
								setFirstCompany({
									...firstCompany,
									company: { ...firstCompany.company, nombre_sede: e.target.value },
								})
							}
							size='medium'
						/>
					</Grid>
					<Grid xs={12} sm={6} mb={5} pr={2} item={true}>
						<FormControl
							sx={{ minWidth: '100%' }}
							size='medium'
							error={!isValidFieldRequired(firstCompany.company['pertence'])}
						>
							<InputLabel required id='pertence'>
								{t('Pertenece a zona:')}
							</InputLabel>
							<Select
								required
								labelId='pertence'
								id='pertence'
								label={t('Pertenece a zona:')}
								value={firstCompany.company['pertence']}
								onChange={(e) =>
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, pertence: e.target.value },
									})
								}
							>
								<MenuItem value=''>{t('Seleccione...')}</MenuItem>
								{ddlUrbanoRural.map(({ id, name }, i) => (
									<MenuItem key={i} value={id}>
										{t(name)}
									</MenuItem>
								))}
							</Select>
							{!isValidFieldRequired(firstCompany.company['pertence']) && (
								<FormHelperText>
									{!isValidFieldRequired(firstCompany.company['pertence']) ? t('Campo requerido') : ''}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
				</Grid>
				{/* <Grid xs={4} mb={5} pr={2}>
					<FormControl sx={{ minWidth: '100%' }} size='medium'>
						<InputLabel id='tipo_complemento'>Tipo de complemento</InputLabel>
						<Select
							labelId='tipo_complemento'
							id='tipo_complemento'
							label='Tipo de complemento'
							value={firstCompany.complemento[0]['tipo_complemento']}
							onChange={(e) =>
								setFirstCompany((prevFirstCompany: { complemento: any[] }) => ({
									...prevFirstCompany,
									complemento: [
										{
											...prevFirstCompany.complemento[0], // Mantendo os valores anteriores do primeiro item no array
											tipo_complemento: e.target.value, // Atualizando o valor de tipo_complemento do primeiro item no array
										},
										...prevFirstCompany.complemento.slice(1), // Mantendo os itens restantes no array de complementos
									],
								}))
							}
						>
							{ddlComplementoDireccion.map(({ id, name }) => (
								<MenuItem value={id}>{name}</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid xs={4} mb={5} pr={2}>
					<TextField
						fullWidth
						required
						id='complemento'
						name='complemento'
						label='Complemento'
						value={firstCompany.complemento[0]['complemento']}
						onChange={(e) =>
							setFirstCompany((prevFirstCompany: { complemento: any[] }) => ({
								...prevFirstCompany,
								complemento: [
									{
										...prevFirstCompany.complemento[0], // Mantendo os valores anteriores do primeiro item no array
										complemento: e.target.value, // Atualizando o valor de tipo_complemento do primeiro item no array
									},
									...prevFirstCompany.complemento.slice(1), // Mantendo os itens restantes no array de complementos
								],
							}))
						}
						placeholder='Ingrese su complemento'
						size='medium'
					/>
				</Grid>
				<Grid xs={2} mb={5} pr={2}>
					<Button
						fullWidth
						variant='contained'
						onClick={handleChange}
						sx={{ background: '#C4D2E8', color: 'black', fontSize: 10 }}
					>
						Nuevo complemento +
					</Button>
				</Grid> */}
				<Grid xs={6} sm={2} mb={3} item={true} />
				<Grid container direction='row' item={true}>
					<Box sx={{ mb: 3, width: '100%' }}>
						<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
							{t('Dirección')}
						</Typography>
					</Box>
				</Grid>
				<Grid container direction='row' item={true}>
					<Grid xs={6} sm={1} mb={5} pr={2} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							{/* <InputLabel id='local'>Sección Principal</InputLabel> */}
							<Select
								labelId='local'
								id='local'
								// label='Sección Principal'
								value={firstCompany.company['local']}
								onChange={(e) =>
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, local: e.target.value },
									})
								}
								inputProps={{ 'aria-label': 'Without label' }}
							>
								<MenuItem value=''>{t('Seleccione...')}</MenuItem>
								{ddlViaPrincipal.map(({ id, name }, i) => (
									<MenuItem key={i} value={id}>
										{t(name)}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid xs={6} sm={1} mb={5} pr={2} item={true}>
						<TextField
							fullWidth
							required
							id='id_coordenada'
							name='id_coordenada'
							label=''
							value={firstCompany.company['id_coordenada']}
							onChange={(e) =>
								setFirstCompany({
									...firstCompany,
									company: { ...firstCompany.company, id_coordenada: e.target.value },
								})
							}
							size='medium'
						/>
					</Grid>
					<Grid xs={6} sm={1} mb={5} pr={2} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							<InputLabel id='id_coordenada_02'></InputLabel>
							<Select
								labelId='id_coordenada_02'
								id='id_coordenada_02'
								label=''
								value={firstCompany.company['id_coordenada_02']}
								onChange={(e) =>
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, id_coordenada_02: e.target.value },
									})
								}
							>
								<MenuItem value=''>{t('Seleccione...')}</MenuItem>
								{ddlViaPrincipalPrimeraLetra.map(({ id, name }, i) => (
									<MenuItem key={i} value={id}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid xs={6} sm={1} mb={5} pr={2} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							<InputLabel id='id_coordenada_03'></InputLabel>
							<Select
								labelId='id_coordenada_03'
								id='id_coordenada_03'
								label=''
								value={firstCompany.company['id_coordenada_03']}
								onChange={(e) =>
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, id_coordenada_03: e.target.value },
									})
								}
							>
								<MenuItem value=''>{t('Seleccione...')}</MenuItem>
								{ddlViaPrincipalBis.map(({ id, name }, i) => (
									<MenuItem key={i} value={id}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid xs={6} sm={1} mb={5} pr={2} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							<InputLabel id='id_coordenada_04'></InputLabel>
							<Select
								labelId='id_coordenada_04'
								id='id_coordenada_04'
								label=''
								value={firstCompany.company['id_coordenada_04']}
								onChange={(e) =>
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, id_coordenada_04: e.target.value },
									})
								}
							>
								<MenuItem value=''>{t('Seleccione...')}</MenuItem>
								{ddlViaPrincipalPrimeraLetra.map(({ id, name }, i) => (
									<MenuItem key={i} value={id}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid xs={6} sm={1} mb={5} pr={2} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							<InputLabel id='id_coordenada_05'></InputLabel>
							<Select
								labelId='id_coordenada_05'
								id='id_coordenada_05'
								label=''
								value={firstCompany.company['id_coordenada_05']}
								onChange={(e) =>
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, id_coordenada_05: e.target.value },
									})
								}
							>
								<MenuItem value=''>{t('Seleccione...')}</MenuItem>
								{ddlViaPrincipalCuadrante.map(({ id, name }, i) => (
									<MenuItem key={i} value={id}>
										{t(name)}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid xs={12} sm={0.2} mb={5} item={true}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center', // Centraliza horizontalmente
								alignItems: 'center', // Centraliza verticalmente
								paddingTop: 1,
								fontWeight: 'bold',
								height: '100%', // Define a altura para que o alinhamento vertical funcione
							}}
						>
							#
						</div>
					</Grid>
					<Grid xs={6} sm={1} mb={5} pr={2} item={true}>
						<TextField
							fullWidth
							required
							id='id_coordenada_06'
							name='id_coordenada_06'
							label=''
							value={firstCompany.company['id_coordenada_06']}
							onChange={(e) =>
								setFirstCompany({
									...firstCompany,
									company: { ...firstCompany.company, id_coordenada_06: e.target.value },
								})
							}
							size='medium'
							type='number'
							InputProps={{ inputProps: { min: 0 } }}
						/>
					</Grid>
					<Grid xs={6} sm={1} mb={5} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							<InputLabel id='id_coordenada_07'></InputLabel>
							<Select
								labelId='id_coordenada_07'
								id='id_coordenada_07'
								label=''
								value={firstCompany.company['id_coordenada_07']}
								onChange={(e) =>
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, id_coordenada_07: e.target.value },
									})
								}
							>
								<MenuItem value=''>{t('Seleccione...')}</MenuItem>
								{ddlViaPrincipalPrimeraLetra.map(({ id, name }, i) => (
									<MenuItem key={i} value={id}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid xs={12} sm={0.2} mb={5} item={true}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center', // Centraliza horizontalmente
								alignItems: 'center', // Centraliza verticalmente
								paddingTop: 5,
								fontWeight: 'bold',
								height: '100%', // Define a altura para que o alinhamento vertical funcione
							}}
						>
							-
						</div>
					</Grid>
					<Grid xs={6} sm={1} mb={5} pr={2} item={true}>
						<TextField
							fullWidth
							required
							id='id_coordenada_08'
							name='id_coordenada_08'
							label=''
							value={firstCompany.company['id_coordenada_08']}
							onChange={(e) =>
								setFirstCompany({
									...firstCompany,
									company: { ...firstCompany.company, id_coordenada_08: e.target.value },
								})
							}
							size='medium'
							type='number'
							InputProps={{ inputProps: { min: 0 } }}
						/>
					</Grid>
					<Grid xs={6} sm={1} mb={5} pr={2} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							<InputLabel id='id_coordenada_09'></InputLabel>
							<Select
								labelId='id_coordenada_09'
								id='id_coordenada_09'
								label=''
								value={firstCompany.company['id_coordenada_09']}
								onChange={(e) =>
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, id_coordenada_09: e.target.value },
									})
								}
							>
								<MenuItem value=''>{t('Seleccione...')}</MenuItem>
								{ddlViaPrincipalCuadrante.map(({ id, name }, i) => (
									<MenuItem key={i} value={id}>
										{t(name)}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
				<Grid xs={12} item={true}>
					{firstCompany.complemento.map(
						(row: any, key: any) =>
							row.tipo_complemento !== '' && (
								<Grid xs={12} key={key}>
									<Grid container direction='row' justifyContent='space-between' width={'100%'}>
										<Grid xs={isSmallScreen ? 12 : 4} mb={5} pr={2}>
											<FormControl sx={{ minWidth: '100%' }} size='medium'>
												<InputLabel id={'tipo_complemento' + key}>{t('Tipo de complemento')}</InputLabel>
												<Select
													labelId={'tipo_complemento' + key}
													id={'tipo_complemento' + key}
													label={t('Tipo de complemento')}
													value={firstCompany.complemento[key]['tipo_complemento']}
													onChange={(e) =>
														setFirstCompany((prevFirstCompany: { complemento: any[] }) => {
															const updatedComplemento = prevFirstCompany.complemento.map(
																(item, idx) => {
																	if (idx === key) {
																		// Corrigi o uso da variável 'index' para 'key'
																		return {
																			...item,
																			tipo_complemento: e.target.value,
																		};
																	} else {
																		return item;
																	}
																}
															);
															return {
																...prevFirstCompany,
																complemento: updatedComplemento,
															};
														})
													}
												>
													<MenuItem value='' key=''>
														{t('Seleccione...')}
													</MenuItem>
													{ddlComplementoDireccion.map(({ id, name }) => (
														<MenuItem key={id} value={id}>
															{t(name)}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid xs={isSmallScreen ? 12 : 4} mb={5} pr={2}>
											<TextField
												fullWidth
												id={'complemento' + key}
												name='complemento'
												label='Complemento'
												value={firstCompany.complemento[key]['complemento']}
												onChange={(e) =>
													setFirstCompany((prevFirstCompany: { complemento: any[] }) => {
														const updatedComplemento = prevFirstCompany.complemento.map(
															(item, idx) => {
																if (idx === key) {
																	// Corrigi o uso da variável 'index' para 'key'
																	return {
																		...item,
																		complemento: e.target.value,
																	};
																} else {
																	return item;
																}
															}
														);
														return {
															...prevFirstCompany,
															complemento: updatedComplemento,
														};
													})
												}
												size='medium'
											/>
										</Grid>
										{key === 0 ? (
											<Grid xs={isSmallScreen ? 12 : 3} mb={5} pr={2}>
												<Button
													fullWidth
													variant='contained'
													onClick={handleAddComplemento}
													style={{ width: isSmallScreen ? '100%' : '400px', height: '50px' }}
												>
													{t('Nuevo complemento +')}
												</Button>
											</Grid>
										) : (
											<Grid xs={isSmallScreen ? 12 : 3} mb={5} pr={2}>
												<Button
													fullWidth
													variant='contained'
													onClick={() => handleDelComplemento(key)}
													style={{ width: isSmallScreen ? '100%' : '400px', height: '50px' }}
												>
													{t('Excluir complemento -')}
												</Button>
											</Grid>
										)}
										<Grid xs={1} mb={5} />
									</Grid>
								</Grid>
							)
					)}
				</Grid>
				<Grid container direction='row' item={true}>
					<Grid xs={12} sm={4} mb={5} pr={2} item={true}>
						<TextField
							type='number'
							fullWidth
							id='codigo_postal'
							name='codigo_postal'
							label={t('Código postal')}
							value={firstCompany.company['codigo_postal']}
							onChange={(e) =>
								setFirstCompany({
									...firstCompany,
									company: { ...firstCompany.company, codigo_postal: e.target.value },
								})
							}
							size='medium'
						/>
					</Grid>
					<Grid xs={12} sm={7} mb={5} pr={2} item={true}>
						<TextField
							fullWidth
							required
							id='direccion_residencia'
							name='direccion_residencia'
							label={t('Dirección sede')}
							value={handleDirectionValue()}
							onChange={(e) =>
								setFirstCompany({
									...firstCompany,
									company: { ...firstCompany.company, direccion_residencia: e.target.value },
								})
							}
							size='medium'
							error={!isValidFieldRequired(handleDirectionValue())}
							helperText={!isValidFieldRequired(handleDirectionValue()) ? t('Campo requerido') : ''}
						/>
					</Grid>
				</Grid>

				<Grid container direction='row' item={true}>
					<Grid xs={12} sm={3} mb={5} pr={2} item={true}>
						<OverlayTrigger
							placement='left'
							delay={{ show: 250, hide: 400 }}
							overlay={(props) => (
								<Tooltip id='button-tooltip' {...props}>
									{t('Código de país + Código de área + Número de teléfono')}
								</Tooltip>
							)}
						>
							<TextField
								fullWidth
								required
								id='telefono'
								name='telefono'
								label={t('Teléfono')}
								placeholder={t('Ingrese teléfono')}
								value={firstCompany.company['telefono']}
								onChange={(e) => {
									let val = e.target.value
										?.replaceAll(/[^\d]/g, '')
										?.replaceAll('-', '')
										?.replaceAll('+', '');
									let reg = /[0-9]|\./;
									if (reg.test(val) || val === '') {
										setFirstCompany({
											...firstCompany,
											company: {
												...firstCompany.company,
												telefono: formatMaskPhoneNumber12Digits(val),
											},
										});
									}
								}}
								size='medium'
								error={
									!isValidFieldRequired(firstCompany.company['telefono']) ||
									!isValidPhoneNumber(firstCompany.company['telefono'])
								}
								helperText={t(msgValidFieldPhoneNumber(firstCompany.company['telefono']))}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<i
												className='bi bi-question-circle-fill'
												style={{ color: '#2266cc', marginLeft: '5px' }}
											></i>
										</InputAdornment>
									),
								}}
								inputProps={{
									maxLength: 12,
								}}
							/>
						</OverlayTrigger>
					</Grid>
					<Grid xs={12} sm={3} mb={5} pr={2} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							<InputLabel required id='pais'>
								{t('País')}
							</InputLabel>
							<Select
								readOnly
								labelId='pais'
								id='pais'
								label={t('País')}
								value={firstCompany.company['pais']}
								onChange={(e) =>
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, pais: e.target.value },
									})
								}
							>
								<MenuItem value=''>{t('Seleccione...')}</MenuItem>
								{ddlPaisResidencia.map(({ id, name }, i) => (
									<MenuItem key={i} value={id}>
										{name} 
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid xs={12} sm={3} mb={5} pr={2} item={true}>
						<FormControl
							sx={{ minWidth: '100%' }}
							size='medium'
							error={!isValidFieldRequired(firstCompany.company['departamento'])}
						>
							<InputLabel required id='departamento'>
								{t('Departamento')}
							</InputLabel>
							<Select
								required
								labelId='departamento'
								id='departamento'
								label={t('Departamento')}
								value={firstCompany.company['departamento']}
								onChange={(e) => {
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, departamento: e.target.value },
									});
									handleSelect(e);
								}}
							>
								<MenuItem value=''>{t('Seleccione...')}</MenuItem>
								{ddlDepartamentoResidencia.map(({ id, name }, i) => (
									<MenuItem key={i} value={id}>
										{name}
									</MenuItem>
								))}
							</Select>
							{!isValidFieldRequired(firstCompany.company['departamento']) && (
								<FormHelperText>
									{!isValidFieldRequired(firstCompany.company['departamento'])
										? t('Campo requerido')
										: ''}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid xs={12} sm={3} mb={5} item={true}>
						<FormControl
							sx={{ minWidth: '100%' }}
							size='medium'
							error={!isValidFieldRequired(firstCompany.company['municipio'])}
						>
							<InputLabel required id='municipio'>
								{t('Ciudad')}
							</InputLabel>
							<Select
								required
								labelId='municipio'
								id='municipio'
								label={t('Municipio')}
								value={firstCompany.company['municipio']}
								onChange={(e) =>
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, municipio: e.target.value },
									})
								}
							>
								<MenuItem value=''>{t('Seleccione...')}</MenuItem>
								{ddlMunicipioResidencia.map(({ id, name }, i) => (
									<MenuItem key={i} value={id}>
										{name}
									</MenuItem>
								))}
							</Select>
							{!isValidFieldRequired(firstCompany.company['municipio']) && (
								<FormHelperText>
									{!isValidFieldRequired(firstCompany.company['municipio'])
										? t('Campo requerido')
										: ''}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
				</Grid>

				{/* <Grid xs={1} mb={5} item={true} /> */}

				<Grid container direction='row' item={true}>
					<Grid xs={12} sm={4} mb={5} pr={2} item={true}>
						<FormControl sx={{ minWidth: '100%' }} size='medium'>
							<InputLabel required id='prestador_principal'>
								{t('Prestador principal')}
							</InputLabel>
							<Select
								readOnly
								labelId='prestador_principal'
								id='prestador_principal'
								label={t('Prestador principal')}
								value={firstCompany.company['prestador_principal']}
								onChange={(e) =>
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, prestador_principal: e.target.value },
									})
								}
							>
								<MenuItem value=''>{t('Seleccione...')}</MenuItem>
								{ddlAgencia.map(({ id, name }, i) => (
									<MenuItem key={i} value={id}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid xs={12} sm={5} mb={5} pr={2} item={true}>
						<FormControl
							sx={{ minWidth: '100%' }}
							size='medium'
							error={!isValidFieldRequired(firstCompany.company['punta_atencion'])}
						>
							<InputLabel required id='punta_atencion'>
								{t('Punto de atención')}
							</InputLabel>
							<Select
								labelId='punta_atencion'
								id='punta_atencion'
								label={t('Punto de atención')}
								value={firstCompany.company['punta_atencion']}
								onChange={(e) =>
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, punta_atencion: e.target.value },
									})
								}
							>
								<MenuItem value=''>{t('Seleccione...')}</MenuItem>
								{ddlPuntoAtencion.map(({ id, name }, i) => (
									<MenuItem key={i} value={id}>
										{name}
									</MenuItem>
								))}
							</Select>
							{!isValidFieldRequired(firstCompany.company['punta_atencion']) && (
								<FormHelperText>
									{!isValidFieldRequired(firstCompany.company['punta_atencion'])
										? t('Campo requerido')
										: ''}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
				</Grid>
			</Grid>
			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
					onClick={() => setStep(1)}
				>
					{t('Atrás')}
				</Button>
				{/*<Box sx={{ flex: '1 1 auto' }} />*/}
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90 }}
					onClick={handleSubmit}
				>
					{t('Enviar')}
				</Button>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
					{t(alertMsg)}
				</Alert>
			</Snackbar>
		</>
	);
};
