import { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import VerifiedIcon from '@mui/icons-material/Verified';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { useTranslation } from 'react-i18next';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { CompanyStatusEnum } from '../../models/enums/CompanyStatusEnum';
import { RoleEnum } from '../../models/enums/RoleEnum';

export default function MenuActionCompanyManagement(props: any) {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const { data, auth, updateActivationCompany } = props;

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClick = (e: any) => {
		setAnchorEl(e.currentTarget);
	};

	return (
		<>
			<Button onClick={handleClick}>
				<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
			</Button>
			<Menu
				id='card-actions-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={handleClose}>
					<Link
						to={
							auth?.user?.role !== RoleEnum.ADMIN
								? `/admin/registro-de-empresa/${data.id}`
								: `/consejero/registro-de-empresa/${data.id}`
						}
					>
						<BorderColorIcon />
						&nbsp; {t('Editar')}
					</Link>
				</MenuItem>

				<MenuItem onClick={handleClose}>
					{!!data?.active ? (
						<a className='pointer' onClick={() => updateActivationCompany(data.id, false)}>
							<ToggleOffIcon />
							&nbsp; {t('Inactivar')}
						</a>
					) : (
						<a className='pointer' onClick={() => updateActivationCompany(data.id, true)}>
							<ToggleOnIcon />
							&nbsp; {t('Activar')}
						</a>
					)}
				</MenuItem>
				{data.status !== CompanyStatusEnum.APPROVED && data.status !== CompanyStatusEnum.REJECTED && (
					<MenuItem onClick={handleClose}>
						<a className='pointer' onClick={() => props.handleOpenRechazada(data.id)}>
							<UnpublishedIcon />
							&nbsp; {t('Rechazar')}
						</a>
					</MenuItem>
				)}
				{data.status !== CompanyStatusEnum.APPROVED && (
					<MenuItem onClick={handleClose}>
						<a className='pointer' onClick={() => props.handleOpenAprobada(data.id)}>
							<VerifiedIcon />
							&nbsp; {t('Aprobar')}
						</a>
					</MenuItem>
				)}
				{!!data?.CompanyFiles?.length && <MenuItem onClick={handleClose}>
					<a className='pointer' onClick={() => props.documentsZip(data.id)}>
						<FileDownloadIcon />
						&nbsp; {t('Descargar Docs')}
					</a>
				</MenuItem>}
				{auth?.user?.role !== 3 ? (
					<MenuItem onClick={handleClose}>
						<a className='pointer' onClick={() => props.handleOpenModal(data.id)}>
							<DeleteIcon />
							&nbsp; {t('Borrar')}
						</a>
					</MenuItem>
				) : (
					<></>
				)}
			</Menu>
		</>
	);
}