import React from "react";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import _ from 'lodash';
import { getErrorFormik, isValidFieldRequired } from "../../../../../util/Index";
import { MESES } from "../../../../../constants/constants";
import { useExperience } from "../../../../../hooks/curriculum/useExperience";
import { CurricullumSectionProps } from "./CurricullumFormTypes";

export default function SectionExpierence({ errors, setFieldValue, values }: CurricullumSectionProps) {
    const { t } = useTranslation();
    const {
        valueAdd,
        borderColorEdit,
        editMode,
        openRowEdit,
        alert,
        alertMsg,
        open,
        setValues,
        handleClose,
        handleEdit,
        handleSaveEdit,
        handleRemoveExperienciaLaboral,
        handleAddExperienciaLaboral,
        handleclear,
        rows,
        isSaving
    } = useExperience({ setFieldValue, values });

    const {
        position,
        company,
        activity,
        currentJob,
        fechaRetiroMonth,
        fechaRetiroYear,
        fechaIngressoMoth,
        fechaIngressoYear,
        start_date,
        end_date } = valueAdd;


    const getListYears = () => {
        const menuItems: JSX.Element[] = []
        for (let index = new Date().getFullYear(); index >= 1950; index--) {
            menuItems.push(<MenuItem value={index} key={`get_year_${index}_${new Date().getMilliseconds()}`}>{index}</MenuItem>)

        }
        return menuItems.map(menu => menu)
    }

    return (
        <>
            <Box sx={{ mb: 3, mt: 5 }}>
                <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                    {t('EXPIRENCE_HEAD')}
                </Typography>
            </Box>
            <Grid
                container
                direction='row'
                justifyContent='space-between'
                width={'100%'}
                sx={{
                    backgroundColor: '#1C447F',
                    pt: 2,
                    pl: 2,
                    pb: 2,
                    pr: 2,
                    borderTopLeftRadius: 6,
                    borderTopRightRadius: 6,
                }}
                item
            >
                <Grid container direction='column' item>
                    <Typography color='white' variant='h5'>
                        {t('Agregar experiencia laboral')}{editMode ? t(' - modo de edición') : ''}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                direction='row'
                justifyContent='space-between'
                width={'100%'}
                mb={5}
                sx={{
                    // border: 1,
                    borderBottom: 3,
                    borderColor: '#AFADAD',
                    borderBottomLeftRadius: 6,
                    borderBottomRightRadius: 6,
                    pt: 5,
                    pl: 3,
                    pr: 3,
                    border: `${borderColorEdit}`,
                }}
                item
            >
                <Grid container direction='row' justifyContent='space-between' width={'100%'} item>
                    <Grid xs={12} md={6} mb={5} pr={2} item>
                        <TextField
                            fullWidth
                            required
                            id={'positionExperience'}
                            name={'position'}
                            label={t('POSITION')}
                            value={position || ''}
                            onChange={(e) => setValues({ ...valueAdd, position: e.target.value })}
                            error={isSaving && !isValidFieldRequired(position)}
                            placeholder={t('Ingresse el position')}
                            size='medium'
                        // error={isSaving && !isValidFieldRequired(position)}
                        // helperText={isSaving && !isValidFieldRequired(position) ? t('Campo requerido') : ''}
                        />
                    </Grid>
                    <Grid xs={12} md={6} mb={5} item>
                        <TextField
                            fullWidth
                            required
                            id={'company'}
                            name={'company'}
                            label={t('COMPANY')}
                            value={company || ''}
                            onChange={(e) => setValues({ ...valueAdd, company: e.target.value })}
                            error={isSaving && !isValidFieldRequired(company)}
                            placeholder={t('COMPANY')}
                            size='medium'
                        // error={isSaving && !isValidFieldRequired(company)}
                        // helperText={isSaving && !isValidFieldRequired(company) ? t('Campo requerido') : ''}
                        />
                    </Grid>
                    <Grid xs={12} mb={5} pr={2} item>
                        <TextField
                            fullWidth
                            multiline
                            rows={2}
                            placeholder={t('Descrição')}
                            id='activity'
                            name='activity'
                            label={t('Descrição')}
                            value={activity || ''}
                            onChange={(e) => setValues({ ...valueAdd, activity: e.target.value })}
                            size='medium'
                            error={isSaving && !isValidFieldRequired(activity)}
                            helperText={
                                getErrorFormik(errors, 'activity') ? t('Campo requerido') : ''
                            }
                            inputProps={{
                                minLength: 150,
                                maxLength: 320
                            }}
                        />
                    </Grid>
                    <Grid xs={12} md={12} mb={5} pr={2} item>
                        <FormControlLabel
                            value={true}
                            control={
                                <Checkbox
                                    id='currentJob'
                                    name='currentJob'
                                    color='default'
                                    checked={!!currentJob}
                                    value={true}
                                    onChange={(e) => setValues({ ...valueAdd, currentJob: e.target.checked })}
                                />
                            }
                            label={t('¿Este es su empleo actual?')}
                            labelPlacement='end'
                        />
                    </Grid>
                    <Grid container direction='row' justifyContent='flex-start' width={'100%'} item>
                        <Grid xs={12} md={4} mb={5} pr={2} item>
                            <Grid xs={12} md={12} mb={2} pr={2} item>
                                <InputLabel id={'end_date'}>{t('Fecha de ingreso')}</InputLabel>
                            </Grid>
                            <Grid direction='row' justifyContent='flex-start' width={'100%'} mb={5} pr={2} container >
                                <Grid xs={12} md={6} mb={2} pr={2} item>
                                    <FormControl
                                        sx={{ minWidth: '100%' }}
                                        size='medium'
                                        error={isSaving && !isValidFieldRequired(fechaIngressoMoth)}
                                    >
                                        <InputLabel id={'fechaIngressoMoth'}>{t('MONTH')}</InputLabel>
                                        <Select
                                            labelId={'fechaIngressoMoth'}
                                            id={'fechaIngressoMoth'}
                                            label={t('MONTH')}
                                            value={fechaIngressoMoth || ''}
                                            onChange={(e) => setValues({ ...valueAdd, fechaIngressoMoth: e.target.value })}
                                        >
                                            {

                                            }
                                            <MenuItem value=''>{t('MONTH')}</MenuItem>
                                            {MESES.map((item) => <MenuItem value={item.val} key={`get_month_${item.val}_${new Date().getMilliseconds()}`}>{t(`${item.i18n}`)}</MenuItem>)}
                                        </Select>
                                        {isSaving && !isValidFieldRequired(fechaIngressoMoth) && (
                                            <FormHelperText>
                                                {isSaving && !isValidFieldRequired(fechaIngressoMoth) ? t('Campo requerido') : ''}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={6} mb={2} pr={2} item>
                                    <FormControl
                                        sx={{ minWidth: '100%' }}
                                        size='medium'
                                        error={isSaving && !isValidFieldRequired(fechaIngressoYear)}
                                    >
                                        <InputLabel id={'fechaIngressoYear'}>{t('YEAR')}</InputLabel>
                                        <Select
                                            labelId={'fechaIngressoYear'}
                                            id={'fechaIngressoYear'}
                                            label={t('YEAR')}
                                            value={fechaIngressoYear || 0}
                                            onChange={(e) => setValues({ ...valueAdd, fechaIngressoYear: e.target.value })}
                                            onBlur={(e) => setValues({ ...valueAdd, fechaIngressoYear: e.target.value })}
                                        >

                                            <MenuItem value={0}>{t('YEAR')}</MenuItem>
                                            {getListYears()}
                                        </Select>
                                        {isSaving && !isValidFieldRequired(fechaIngressoYear) && (
                                            <FormHelperText>
                                                {isSaving && !isValidFieldRequired(fechaIngressoYear) ? t('Campo requerido') : ''}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        {!currentJob && (
                            <Grid xs={12} md={4} mb={5} pr={2} item>
                                <Grid xs={12} md={12} mb={2} pr={2} item>
                                    <InputLabel id={'end_date'}>{t('Fecha de ingreso')}</InputLabel>
                                </Grid>
                                <Grid direction='row' justifyContent='flex-start' width={'100%'} mb={5} pr={2} container>
                                    <Grid xs={12} md={6} mb={2} pr={2} item>
                                        <FormControl
                                            sx={{ minWidth: '100%' }}
                                            size='medium'
                                            error={isSaving && !isValidFieldRequired(fechaRetiroMonth)}
                                        >
                                            <InputLabel id={'end_date'}>{t('MONTH')}</InputLabel>
                                            <Select
                                                labelId={'end_date_y'}
                                                id={'end_date_y'}
                                                label={t('MONTH')}
                                                value={fechaRetiroMonth || ''}
                                                onChange={(e) => setValues({ ...valueAdd, fechaRetiroMonth: e.target.value })}
                                            >
                                                {

                                                }
                                                <MenuItem value={''}>{t('MONTH')}</MenuItem>
                                                {MESES.map((item) => <MenuItem value={item.val} key={`get_month_${item.val}_${new Date().getMilliseconds()}`}>{t(`${item.i18n}`)}</MenuItem>)}
                                            </Select>
                                            {isSaving && !isValidFieldRequired(fechaRetiroMonth) && (
                                                <FormHelperText>
                                                    {isSaving && !isValidFieldRequired(fechaRetiroMonth) ? t('Campo requerido') : ''}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={6} mb={2} pr={2} item>
                                        <FormControl
                                            sx={{ minWidth: '100%' }}
                                            size='medium'
                                            error={isSaving && !isValidFieldRequired(fechaRetiroYear)}
                                        >
                                            <InputLabel id={'end_date'}>{t('YEAR')}</InputLabel>
                                            <Select
                                                labelId={'end_date_y'}
                                                id={'end_date_y'}
                                                label={t('YEAR')}
                                                value={fechaRetiroYear || ''}
                                                onChange={(e) => setValues({ ...valueAdd, fechaRetiroYear: e.target.value })}
                                            >
                                                {

                                                }
                                                <MenuItem value={''}>{t('YEAR')}</MenuItem>
                                                {getListYears()}
                                            </Select>
                                            {isSaving && !isValidFieldRequired(fechaRetiroYear) && (
                                                <FormHelperText>
                                                    {isSaving && !isValidFieldRequired(fechaRetiroYear) ? t('Campo requerido') : ''}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid xs={12} mb={3} display='flex' justifyContent='space-between' alignItems='center' item>
                    {editMode ? (
                        <>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
                                <Button
                                    variant='contained'
                                    onClick={handleSaveEdit}
                                    sx={{ width: 90, background: '#0A4396', mr: 1 }}
                                >
                                    {t('SAVE')}
                                </Button>

                                <Button
                                    variant='contained'
                                    sx={{ width: 90, background: '#6c757d', mr: 1 }}
                                    onClick={handleclear}
                                >
                                    {t('Cancelar')}
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <Button
                            // disabled={tieneExperienciaLaboral === '0' || tieneExperienciaLaboral === ''}
                            variant='contained'
                            onClick={handleAddExperienciaLaboral}
                            sx={{ width: 90, background: '#0A4396' }}
                        >
                            {t('Agregar')}
                        </Button>
                    )}
                </Grid>
            </Grid>
            {rows?.length > 0 && (
                <Grid xs={12} item>
                    <Typography color='#2B2B2B' variant='h6'>
                        {t('Agregados')}
                    </Typography>
                    <Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 5 }} />
                </Grid>
            )}
            <Grid xs={12} item>
                {rows?.map((row, i) => (
                    <Grid xs={12} key={i} item display={openRowEdit === row?.id ? 'none' : ''}>
                        <Grid
                            container
                            direction='row'
                            justifyContent='space-between'
                            width={'100%'}
                            sx={{
                                backgroundColor: '#1C447F',
                                pt: 2,
                                pl: 2,
                                pb: 2,
                                pr: 2,
                                borderTopLeftRadius: 6,
                                borderTopRightRadius: 6,
                            }}
                            item
                        >
                            <Typography color='white' variant='h6'>
                                {' '}
                                {t(row?.position || '') || ''}
                            </Typography>
                            <Box display='flex' justifyContent='end' alignItems='center'>
                                <div>
                                    <div className='d-flex justify-content-end flex-shrink-0'>
                                        <button
                                            className='btn btn-icon btn-active-color-secondary btn-sm me-3'
                                            style={{ background: '#C4D2E8' }}
                                            onClick={() => handleEdit(row)}
                                            type="button"
                                        >
                                            <EditIcon />
                                        </button>

                                        <button
                                            className='btn btn-icon btn-active-color-secondary btn-sm'
                                            onClick={() => handleRemoveExperienciaLaboral(row?.id || 0)}
                                            style={{ background: '#DD1342' }}
                                            type="button"
                                        >
                                            <DeleteOutlinedIcon style={{ color: 'white' }} />
                                        </button>
                                    </div>
                                </div>
                            </Box>
                        </Grid>
                        <Grid
                            container
                            direction='row'
                            justifyContent='space-between'
                            width={'100%'}
                            mb={5}
                            sx={{
                                border: 1,
                                borderBottom: 3,
                                borderColor: '#AFADAD',
                                borderBottomLeftRadius: 6,
                                borderBottomRightRadius: 6,
                                pt: 5,
                                pl: 3,
                                pr: 3,
                            }}
                            item
                        >

                            <Grid xs={12} md={3} mb={5} mr={5} item>
                                <Typography>{t('Nombre de la empresa: ') + (row?.company || '')}</Typography>
                                <Typography>{t('Fecha de ingreso: ') + (row?.start_date || '')}</Typography>
                                <Typography>{t('Fecha de retiro: ') + (row?.end_date || t('CURRENT'))}</Typography>
                            </Grid>
                            <Grid xs={1} mb={5} sx={{ borderLeft: 2, borderColor: '#AFADAD' }} item />
                            <Grid xs={12} md={2} mb={5} mr={2} item>
                                <Typography>{t('Funciones y logros: ') + (row?.activity || '')}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    variant={'filled'}
                    severity={alert ? 'success' : 'error'}
                    sx={{ whiteSpace: 'pre-line' }}
                >
                    {alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
                    {t(alertMsg)}
                </Alert>
            </Snackbar>
        </>
    )
}