import { useTranslation } from "react-i18next";
import { RoleEnum } from "../../models/enums/RoleEnum";
import MenuAction from "../MenuAction";
import styleScss from './styles.module.scss'

export default function CardComponente({ data, setAlert, setAlertMsg, setOpen }: any) {
	const { t } = useTranslation();

    const tpUserRole = [
		{ id: RoleEnum.ADMIN, name: 'Administrador' },
		{ id: RoleEnum.CANDIDATE, name: 'Candidato' },
/* 		{ id: RoleEnum.COUNSELOR, name: 'Consejero' },
 */		{ id: RoleEnum.EMPLOYER, name: 'Empleador' },
	];

	return (
		<div className={styleScss.cardMobile}>
			<div className={styleScss['card-body']}>
				<h5 className={styleScss['card-title']}>
					<strong>{t('Nombre')}:</strong> {data.UserComplemento?.primer_nome?.toLowerCase()}
				</h5>
				<p className={styleScss['card-text']}>
					<strong>{t('Sobrenome')}:</strong> {data.UserComplemento?.primer_apelido?.toLowerCase()}
				</p>
				<p className={styleScss['card-text']}><strong>E-mail:</strong> {data.email?.toLowerCase()}</p>
				<p className={styleScss['card-text']}>
					<strong>{t('Estado')}:</strong> {data.status} | <strong>Ativo:</strong> {data.active ? 'Sí' : 'No'}
				</p>
				<p className={styleScss['card-text']}>
					<strong>{t('Rol')}:</strong>
					{tpUserRole.find((r) => r.id === data.role)?.name || ''}
				</p>
			</div>
			<div className={styleScss.options}>
				<MenuAction
					data={{ id: data.id, active: data.active }}
					setAlert={setAlert}
					setAlertMsg={setAlertMsg}
					setOpen={setOpen}
				/>
			</div>
		</div>
	);
}
