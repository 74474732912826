import { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
	TextField,
	SelectChangeEvent,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Box,
	Button,
	Divider,
	Typography,
	FormHelperText,
	Snackbar,
	Alert,
	AlertTitle,
	InputAdornment,
	Stack,
	IconButton,
	ListItem,
	Chip,
	Paper,
} from '@mui/material';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { multiStepContext } from '../../../../../contexts/CompanyContext';
import { isValidFieldRequired } from '../../../../../util/Index';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import ImageIcon from '@mui/icons-material/Image';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { getVerifyCompanyDocumentExists } from '../../../core/_requests';
import { getLocalidad } from '../../../../../modules/apps/user-management/users-list/core/_requests';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const documentTypes = ['CC', 'CE', 'PEP', 'PA', 'TI', 'PT'];
const ddltipo = [
	{ id: '23', name: 'Procesador de texto (Ejemplo: Word)' },
	{ id: '24', name: 'Hoja de cálculo (Ejemplo: Excel)' },
	{ id: '25', name: 'Multimedia (Ejemplo: PowerPoint)' },
	{ id: '26', name: 'Otros' },
];
const ddlTipoDocument = [
	{id: 'CS', name: 'Contrato social'},
	{id: 'ES', name: 'Estatuto social'},
	{id: 'CRE', name: 'Certificado de registro empresarial'},
	{id: 'CNPJ', name: 'CNPJ'},
];

const ddlNaturalezaEmpresa = [
	{ id: '1', name: 'Mixta' },
	{ id: '2', name: 'Privada' },
	{ id: '3', name: 'Pública' },
];
const isHeadquarters = [ 
	{ id: '1', name: 'Sim' },
	{ id: '2', name: 'Não' },
];
const ddlTipoEmpresa = [
	{ id: 1, name: 'MEI (Microempreendedor Individual)' },
	{ id: 2, name: 'EIRELI (Empresa Individual de Responsabilidade Limitada)' },
	{ id: 3, name: 'Empresário Individual' },
	{ id: 4, name: 'Sociedade Limitada (LTDA)' },
	{ id: 5, name: 'Sociedade Anônima (S/A)' },
	{ id: 6, name: 'Sociedade Simples' },
	{ id: 7, name: 'Sociedade Empresária Limitada' },
	{ id: 8, name: 'Cooperativas' },
	{ id: 9, name: 'Sociedade em Nome Coletivo' },
	{ id: 10, name: 'Sociedade em Comandita Simples' },
	{ id: 11, name: 'Sociedade em Comandita por Ações' },
	{ id: 12, name: 'ME (Microempresa)' },
	{ id: 13, name: 'EPP (Empresa de Pequeno Porte)' },
];
const ddlTamanioEmpre = [
	{ id: '5', name: 'Unipersonal' },
	{ id: '1', name: 'Microempresa - Entre 2 e 10 trabalhadores' },
	{ id: '2', name: 'Pequena empresa - Entre 11 e 50 trabalhadores' },
	{ id: '3', name: 'Mediana empresa - Entre 51 e 200 trabalhadores' },
	{ id: '4', name: 'Grande  - Maior que 200 trabalhadores' },
];
export const Step1 = () => {
	const {
		setStep,
		userData,
		setUserData,
		firstUser,
		setFirstUser,
		firstCompany,
		setFirstCompany,
		attachments,
		setAttachments,
	} = useContext(multiStepContext);
	const [documentType, setDocumentType] = useState('');
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const [files, setFiles] = useState<any[]>([]);

	const [companyExists, setCompanyExists] = useState(false);

	const [maxDocumentFieldLenght, setMaxDocumentFieldLenght] = useState(0);
	const [minDocumentFieldLenght, setMinDocumentFieldLenght] = useState(0);
	const [documentTypeField, setDocumentTypeField] = useState('text');

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	console.log(firstCompany);

	const handleChange = (event: SelectChangeEvent) => {
		setDocumentType(event.target.value);
	};

	const fetchData = async () => {
		// const localidad = await getLocalidad();
		// setUserData({
		// 	...userData,
		// 	ddlLocalidad: localidad.data,
		// });
	};
	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		console.log('e', e);
		if (e.target.files) {
			const chosenFiles = Array.prototype.slice.call(e.target.files);
			console.log('files', files);
			console.log('chose', chosenFiles);
			const uploaded = [...files];
			chosenFiles.some((x) => {
				if (uploaded.findIndex((f) => f.name === x.name) === -1) {
					uploaded.push(x);
				}
			});

			// let dataForm = new FormData();
			// console.log('fileList', fileList);
			// if (e.target.files) {
			// 	for (let i = 0; i < e.target.files.length; ++i) {
			// 		console.log('entrou for', e.target.files[i]);
			// 		const file = e.target.files[i];
			// 		dataForm.append(`file-${i}`, file, file.name);
			// 	}
			// }

			if (e.target.files) {
				if (Array.prototype.slice.call(attachments).length + e.target.files.length > 5) {
					setAlert(false);
					setAlertMsg(
						'El número de archivos seleccionados e insertados supera los 5 archivos permitidos.'
					);
					setOpen(true);
					setTimeout(() => {
						setOpen(false);
					}, 5000);
					return;
				} else {
					for (let index = 0; index < e.target.files.length; index++) {
						const element = e.target.files[index];
						if (attachments.find((x: any) => x.name === element.name)) continue;
						setAttachments((old: any) => [...old, element]);
					}
				}
			}
			// console.log('data', dataForm);

			// chosenFiles.forEach((x) => {
			// 	arr.push({ name: x.name, type: x.type }); //TODO: inserir ID depois q salvar no banco
			// 	console.log('x', x);
			// });
			// console.log('arr', arr);
			// const arrFinal = [...files, arr];
			// arr.push(files);
			// console.log('arrFinal', arrFinal);
			setFiles(uploaded);
			setUserData({ ...userData, file: uploaded });
		}
	};

	const handleDeleteFile = (fileName: string) => {
		const idRemove = Array.prototype.slice.call(attachments).findIndex((x) => x.name === fileName);
		const itemsToSave = Array.prototype.slice.call(attachments).filter((_, i) => i !== idRemove);
		setAttachments(itemsToSave);
		setUserData({ ...userData, file: itemsToSave });
	};

	useEffect(() => {
		validationSchemaByTpDoc(firstCompany.company.tipo_documento);
		if (
			userData['ddlLocalidad'] === null ||
			userData['ddlLocalidad'] === undefined ||
			(userData['ddlLocalidad'] as Array<any>).length === 0
		) {
			fetchData();
		}
	}, []);

	const getIconFile = (fileName: string) => {
		switch (fileName?.toLowerCase()) {
			case 'img':
			case 'png':
			case 'jpg':
				return <ImageIcon />;
			case 'pdf':
				return <PictureAsPdfIcon />;
			case 'txt':
				return <TextSnippetIcon />;
			case 'xls':
			case 'xlsx':
			case 'csv':
				return <BorderAllIcon />;
			default:
				return <ArticleIcon />;
		}
	};

	function goBack() {
		window.history.back();
	}

	const handleNext = async () => {
		let errors: { field: string; error: string }[] = [];
		if (!isValidFieldRequired(firstCompany.company['numero_documento']))
			errors.push({ field: 'numero_documento', error: 'Campo requerido' });
		else {
			if (validLengthDocumentField(firstCompany.company['numero_documento']) !== '')
				errors.push({
					field: 'numero_documento',
					error: validLengthDocumentField(firstCompany.company['numero_documento']),
				});
		}

		if (!isValidFieldRequired(firstCompany.company['tipo_documento']))
			errors.push({ field: 'tipo_documento', error: 'Campo requerido' });

		if (!isValidFieldRequired(firstCompany.company['razon_social']))
			errors.push({ field: 'razon_social', error: 'Campo requerido' });

		if (!isValidFieldRequired(firstCompany.company['naturaleza']))
			errors.push({ field: 'naturaleza', error: 'Campo requerido' });

		if (!isValidFieldRequired(firstCompany.company['tipo']))
			errors.push({ field: 'tipo', error: 'Campo requerido' });

		if (!isValidFieldRequired(firstCompany.company['Tamano_empleados']))
			errors.push({ field: 'Tamano_empleados', error: 'Campo requerido' });
		if (firstCompany.company['descricion_employer'] !== '') {
			if (firstCompany.company['descricion_employer'].length < 100)
				errors.push({
					field: 'descricion_employer',
					error: 'El campo debe tener al menos 100 caracteres',
				});
		}
		console.log(errors);

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\n ${t('Rellene los campos obligatorios')}\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${t(
						x.error
					)}`;
				})}`
			);
			setOpen(true);
			setTimeout(() => {
				setOpen(false);
			}, 5000);
			return;
		}

		if (isValidFieldRequired(firstCompany.company['numero_documento'])) {
			const exists = await verifyCompanyDocumentExists(firstCompany.company['numero_documento']);
			if (!exists) {
				setStep(2);
				setCompanyExists(false);
				return;
			}

			setCompanyExists(true);
			console.log('exist', exists);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const downloadFile = (name: string) => {
		// text content
		const texts = ['line 1', 'line 2', 'line 3'];

		// file object
		const file = new Blob(texts, { type: 'text/plain' });

		// anchor link
		const element = document.createElement('a');
		element.href = URL.createObjectURL(file);
		element.download = '100ideas-' + Date.now() + '.txt';

		// simulate link click
		document.body.appendChild(element); // Required for this to work in FireFox
		element.click();

		// const fileSelected = files.filter(x => x.name === name);
	};

	const verifyCompanyDocumentExists = async (documentNumber: string) => {
		try {
			const response = await getVerifyCompanyDocumentExists(`document=${documentNumber}`);
			return response.data.exists;
		} catch (error: any) {
			console.log(`error`, error.response);
			setAlert(false);
			setAlertMsg(error.response.data.error);
			setOpen(true);
			return error.response.data.exists;
		}
	};

	//validation for company document
	const validationSchemaByTpDoc = (type: string) => {
		switch (type) {
			case 'CC':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(5);
				setMaxDocumentFieldLenght(10);
				break;
			case 'TI':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(0);
				setMaxDocumentFieldLenght(12);
				break;
			case 'CE':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(9);
				setMaxDocumentFieldLenght(10);
				break;
			case 'NIT':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(9);
				setMaxDocumentFieldLenght(9);
				break;
			default:
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(0);
				setMaxDocumentFieldLenght(20);
		}
	};

	//validation for company document
	const validLengthDocumentField = (document: string) => {
		if (document.length < minDocumentFieldLenght)
			return `${t('Debe tener al menos')} ${minDocumentFieldLenght} ${t('dígitos')}`;

		if (document.length < minDocumentFieldLenght)
			return `${t('Debe tener')} ${maxDocumentFieldLenght} ${t('o menos dígitos')}`;

		if (documentTypeField === 'text')
			if (!document.match(/^[A-Za-z0-9]*$/)) return `${t('Deben ser solo números y letras')}`;

		if (documentTypeField === 'number')
			if (!document.match(/^[0-9]+$/)) return `${t('Deben ser solo números')}`;

		return '';
	};

	const msgProfile = (value: string) => {
		if (value === undefined || value === '') return `${t('Campo requerido')}\n\n0/1200`;

		if (value.length < 300)
			return `${t('¡El campo debe tener al menos 100 caracteres!')}\n\n${value.length}/1200`;

		return `${value.length}/1200`;
	};

	const isValidProfile = (value: string) => {
		if (value === undefined || value === '') return false;

		if (value.length < 100) return false;

		return true;
	};

	const { t } = useTranslation();

	return (
		<>
			<Typography variant='h6' sx={{ fontWeight: 'bold', mb: 3 }}>
				{t('Información del empleador')}
			</Typography>
			<Grid container direction='row' justifyContent='space-between' width={'100%'} item={true}>
				<Grid xs={isSmallScreen ? 12 : 6} mb={5} pr={2} item={true}>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={!isValidFieldRequired(firstCompany.company['tipo_documento'])}
					>
						<InputLabel id='tipo_documento'>{t('Tipo de documento')}</InputLabel>
						<Select
							required
							sx={{ fontSize: isSmallScreen ? '16px' : '13px' }}
							inputProps={{
								style: { fontSize: isSmallScreen ? '20px' : '16px' }, // Aumenta o tamanho do label
							}}
							labelId='tipo_documento'
							id='tipo_documento'
							label={t('Tipo de documento')}
							value={firstCompany.company['tipo_documento']}
							onChange={(e) => {
								firstCompany.company['numero_documento'] = '';
								validationSchemaByTpDoc(e.target.value);
								setFirstCompany({
									...firstCompany,
									company: { ...firstCompany.company, tipo_documento: e.target.value },
								});
							}}
						>
							<MenuItem value=''>{t('Seleccione...')}</MenuItem>
							{ddlTipoDocument.map(({ id, name }, i) => (
								<MenuItem key={i} value={id}>
									{t(name)}
								</MenuItem>
							))}
						</Select>
						{!isValidFieldRequired(firstCompany.company['tipo_documento']) && (
							<FormHelperText>
								{!isValidFieldRequired(firstCompany.company['tipo_documento'])
									? t('Campo requerido')
									: ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>

				<Grid xs={isSmallScreen ? 12 : 6} mb={5} pr={2} item={true}>
					<TextField
						fullWidth
						required
						id='numero_documento'
						name='numero_documento'
						label={t('Número de documento')}
						placeholder={t('Ingrese un número')}
						value={firstCompany.company['numero_documento']}
						onKeyUp={() => setCompanyExists(false)}
						type={documentTypeField}
						size='medium'
						inputProps={{
							maxLength: maxDocumentFieldLenght,
							minLength: minDocumentFieldLenght,
						}}
						onChange={(e) => {
							if (e.target.value.length <= maxDocumentFieldLenght)
								setFirstCompany({
									...firstCompany,
									company: { ...firstCompany.company, numero_documento: e.target.value },
								});
						}}
						error={
							!isValidFieldRequired(firstCompany.company['numero_documento']) ||
							companyExists ||
							validLengthDocumentField(firstCompany.company['numero_documento']) !== ''
						}
						helperText={
							!isValidFieldRequired(firstCompany.company['tipo_documento'])
								? t('Primero seleccione el tipo de documento')
								: !isValidFieldRequired(firstCompany.company['numero_documento'])
								? t('Campo requerido')
								: companyExists
								? t('¡Empresa ya registrada!')
								: validLengthDocumentField(firstCompany.company['numero_documento']) !== ''
								? validLengthDocumentField(firstCompany.company['numero_documento'])
								: ''
						}
						disabled={!isValidFieldRequired(firstCompany.company['tipo_documento'])}
					/>
				</Grid>
				<Grid xs={isSmallScreen ? 12 : 6} mb={5} pr={2} item={true}>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={!isValidFieldRequired(firstCompany.company['naturaleza'])}
					>
						<InputLabel id='naturaleza'>{t('Naturaleza')}</InputLabel>
						<Select
							labelId='naturaleza'
							id='naturaleza'
							label={t('Naturaleza')}
							value={firstCompany.company['naturaleza']}
							onChange={(e) =>
								setFirstCompany({
									...firstCompany,
									company: { ...firstCompany.company, naturaleza: e.target.value },
								})
							}
						>
							<MenuItem value=''>{t('Seleccione...')}</MenuItem>
							{ddlNaturalezaEmpresa.map(({ id, name }, i) => (
								<MenuItem key={i} value={id}>
									{t(name)}
								</MenuItem>
							))}
						</Select>
						{!isValidFieldRequired(firstCompany.company['naturaleza']) && (
							<FormHelperText>
								{!isValidFieldRequired(firstCompany.company['naturaleza'])
									? t('Campo requerido')
									: ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				{/* <Grid xs={3} mb={5} pr={2} item={true}> */}
				{/*
          <FormControl sx={{ minWidth: '100%' }} size='medium'>
            <InputLabel id="sector">Sector</InputLabel>
            <Select
            labelId="sector"
            id="sector"
            label="Sector"
            value={userData['sector']}
            onChange={(e) => setUserData({ ...userData, "sector": e.target.value })}
            >
           {ddltipo.map(({ id, name }) => (
                <MenuItem value={id}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>   
           */}
				{/* </Grid> */}

				<Grid xs={isSmallScreen ? 12 : 6} mb={5} pr={2} item={true}>
					<TextField
						fullWidth
						required
						id='razon_social'
						name='razon_social'
						label={t('Razón social')}
						placeholder={t('Ingrese la razón social')}
						value={firstCompany.company['razon_social']}
						onChange={(e) =>
							setFirstCompany({
								...firstCompany,
								company: { ...firstCompany.company, razon_social: e.target.value },
							})
						}
						size='medium'
						error={!isValidFieldRequired(firstCompany.company['razon_social'])}
						helperText={
							!isValidFieldRequired(firstCompany.company['razon_social'])
								? t('Campo requerido')
								: ''
						}
					/>
				</Grid>
				<Grid xs={isSmallScreen ? 12 : 6} mb={5} pr={2} item={true}>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={!isValidFieldRequired(firstCompany.company['Tamano_empleados'])}
					>
						<InputLabel id='Tamano_empleados'>{t('Tamaño por número de empleados')}</InputLabel>
						<Select
							labelId='Tamano_empleados'
							id='Tamano_empleados'
							label={t('Tamaño por número de empleados')}
							value={firstCompany.company['Tamano_empleados']}
							onChange={(e) =>
								setFirstCompany({
									...firstCompany,
									company: { ...firstCompany.company, Tamano_empleados: e.target.value },
								})
							}
						>
							<MenuItem value=''>{t('Seleccione...')}</MenuItem>
							{ddlTamanioEmpre.map(({ id, name }, i) => (
								<MenuItem key={i} value={id}>
									{t(name)}
								</MenuItem>
							))}
						</Select>
						{!isValidFieldRequired(firstCompany.company['Tamano_empleados']) && (
							<FormHelperText>
								{!isValidFieldRequired(firstCompany.company['Tamano_empleados'])
									? t('Campo requerido')
									: ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>

				<Grid xs={isSmallScreen ? 12 : 6} mb={5} pr={2} item={true}>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={!isValidFieldRequired(firstCompany.company['tipo'])}
					>
						<InputLabel id='tipo'>{t('Tipo')}</InputLabel>
						<Select
							labelId='tipo'
							id='tipo'
							label={t('Tipo')}
							value={firstCompany.company['tipo']}
							onChange={(e) =>
								setFirstCompany({
									...firstCompany,
									company: { ...firstCompany.company, tipo: e.target.value },
								})
							}
						>
							<MenuItem value=''>{t('Seleccione...')}</MenuItem>
							{ddlTipoEmpresa.map(({ id, name }, i) => (
								<MenuItem key={id} value={name}>
									{t(name)}
								</MenuItem>
							))}
						</Select>
						{!isValidFieldRequired(firstCompany.company['tipo']) && (
							<FormHelperText>
								{!isValidFieldRequired(firstCompany.company['tipo']) ? t('Campo requerido') : ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>

				<Grid xs={isSmallScreen ? 12 : 6} mb={5} pr={2} item={true}>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={!isValidFieldRequired(firstCompany.company['tipo'])}
					>
						<InputLabel id='tipo'>É matriz?</InputLabel>
						<Select
							labelId='is_headquarters'
							id='is_headquarters'
							label='É Matriz?'
							value={firstCompany.company['is_headquarters']}
							onChange={(e) =>
								setFirstCompany({
									...firstCompany,
									company: { ...firstCompany.company, is_headquarters: e.target.value },
								})
							}
						>
							<MenuItem value=''>{t('Seleccione...')}</MenuItem>
							{isHeadquarters.map(({ id, name }, i) => (
								<MenuItem key={id} value={name}>
									{t(name)}
								</MenuItem>
							))}
						</Select>
						{!isValidFieldRequired(firstCompany.company['tipo']) && (
							<FormHelperText>
								{!isValidFieldRequired(firstCompany.company['tipo']) ? t('Campo requerido') : ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				{/* <Grid xs={3} mb={5} item={true} /> */}
				<Grid xs={isSmallScreen ? 12 : 6} mb={5} pr={2} item={true} mt={1}>
					<Button variant='contained' component='label' startIcon={<AttachFileIcon />}>
						{t('Subir archivo')}
						<input hidden multiple type='file' onChange={handleFileChange} />
					</Button>
				</Grid>
				{/* <Grid xs={1} mb={5} pr={2} item={true}></Grid> */}
				<Grid xs={12} mb={5} item>
					<OverlayTrigger
						placement='top'
						delay={{ show: 250, hide: 400 }}
						overlay={(props) => (
							<Tooltip id='button-tooltip' {...props}>
								{t(
									'El texto que debe incluirse como ejemplo es: Somos una Empresa SAS con 10 años de creación, dedicada a confeccionar ropa de un negocio familiar de cerca de 60 empleados, que tiene fábricas en Suba y Usme. Nos caracterizamos por trabajar con satélites y nos esforzamos por tener un modelo de trabajo colaborativo.'
								)}
							</Tooltip>
						)}
					>
						<FormControl sx={{ minWidth: '100%' }}>
							<TextField
								fullWidth
								id='descricion_employer'
								name='descricion_employer'
								label={t('Descripción de la compañía')}
								value={firstCompany.company['descricion_employer']}
								onChange={(e) =>
									setFirstCompany({
										...firstCompany,
										company: { ...firstCompany.company, descricion_employer: e.target.value },
									})
								}
								multiline
								rows={4}
								placeholder={t('Descripción de la compañía')}
								inputProps={{ maxLength: 1200, minLength: 100 }}
								error={!isValidProfile(firstCompany.company['descricion_employer'])}
								helperText={msgProfile(firstCompany.company['descricion_employer'])}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<i className='bi bi-question-circle-fill' style={{ color: '#2266cc' }}></i>
										</InputAdornment>
									),
								}}
								FormHelperTextProps={{ style: { wordWrap: 'break-word' } }}
							/>
						</FormControl>
					</OverlayTrigger>
				</Grid>
				{Array.prototype.slice.call(attachments).length > 0 && (
					<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} mb={2}>
						{Array.prototype.slice.call(attachments).map((x: any, i: number) => (
							<Grid item xs={'auto'} key={i}>
								<Chip
									key={i}
									icon={getIconFile(x.name?.split('.').pop())}
									label={x.name}
									// onClick={() => {
									// 	console.log(`clicou`);
									// 	downloadFile(x.name);
									// }}
									onDelete={() => {
										console.log(`deleteou`);
										handleDeleteFile(x.name);
									}}
									color='primary'
									variant='outlined'
								/>
							</Grid>
						))}
					</Grid>
				)}
			</Grid>
			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
					onClick={() => goBack()}
				>
					{t('Atrás')}
				</Button>
				{/*<Box sx={{ flex: '1 1 auto' }} />*/}
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90 }}
					onClick={handleNext}
				>
					{t('Siguiente')}
				</Button>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
					{t(alertMsg)}
				</Alert>
			</Snackbar>
		</>
	);
};
