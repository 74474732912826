import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import PTBR from '../locales/pt/pt-br.json';
import ENUS from '../locales/en/en-us.json';
import ES from '../locales/es/es.json';

const resources = {
    'pt-BR': PTBR, 
    'en-US': ENUS,
    'es': ES
}

const language = localStorage.getItem('language') || navigator.language;

i18n
    .use(initReactI18next)
    .init({
        debug: false,
        resources,
        fallbackLng: navigator.language,
        lng: language,
        interpolation: {
            escapeValue: false
        }
    })

export default i18n;