import { useContext } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Link, useNavigate } from 'react-router-dom';

import { multiStepContext } from '../../../contexts/CompanyEditContext';

import { Step1 } from './steps/company-edition/Step1';
import { Step2 } from './steps/company-edition/Step2';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const CompanyEdition = () => {
	const { t } = useTranslation()
	const { currentStep, finalData, companyId } = useContext(multiStepContext);
	const navigate = useNavigate();

	const showStep = (step: number) => {
		switch (step) {
			case 1:
				return <Step1 />;
			case 2:
				return <Step2 />;
		}
	};

	const handleSave = () => {
		alert('¡Guardado exitosamente!!');
	};
	

	return (
		<Box sx={{ width: '100%', background: 'white', padding: 4, borderRadius: 2 }}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
				<Box>
					<div className='row'>
						<div className='col-sm-2'>
							<button
								onClick={() => {
									navigate(-1);
								}}
								className='btn btn-primary'
								style={{ background: '#C4D2E8', borderRadius: '4px', padding: '6px 15px' }}
							>
								<i
									className='bi bi-arrow-left'
									style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
								></i>
							</button>
						</div>

						<div className='col-sm-9'>
							<h2 style={{ display: 'flex', gap: '10px' }}>{t('Datos básicos del empleador')}</h2>
							<span style={{}}>{t('Rellena los campos abajo con los datos de tu empresa.')}</span>
						</div>
					</div>
				</Box>
			</Box>
			<Stepper style={{ width: '100%' }} activeStep={currentStep - 1} orientation='horizontal'>
				<Step>
					<StepLabel></StepLabel>
				</Step>
				<Step>
					<StepLabel></StepLabel>
				</Step>
			</Stepper>
			<Box mt={5}>{showStep(currentStep)}</Box>
		</Box>
	);
};
