import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PageTitle } from '../../../_metronic/layout/core';
import { CardModel } from '../../models/CardModel';
import styleScss from './styles.module.scss';

import { useTranslation } from 'react-i18next'
import '../../i18n';

const AdminAreaPage = () => {
	const { t } = useTranslation()
	const [listScheduledMeetings, setListScheduledMeetings]: any[] = useState([]);

	useEffect(() => {
		let arr: any[] = [];
		for (let index = 0; index < 7; index++) {
			let x = {
				id: index,
				date: `0${index}/0${index}`,
				time: `0${index}:0${index} PM`,
				adviserName: `${t('Nombre del consejero')} ${index}`,
			};
			arr.push(x);
		}
		setListScheduledMeetings(arr);
	}, []);

	const listManage: CardModel[] = [
		{
			title: 'Gestionar registros',
			content: 'Aprobar, excluir, activar, inactivar usuarios',
			action: '/admin/gestion-registros',
		},
		// {
		// 	title: 'Gestionar vacantes',
		// 	content: 'Incluir, excluir, activar, inactivar vacantes.',
		// 	action: '#',
		// },
		// {
		// 	title: 'Gestionar candidatos',
		// 	content: 'Incluir, excluir, activar, inactivar candidatos',
		// 	action: '/admin/gestion-candidatos',
		// },
		{
			title: 'Gestionar empresas',
			content: 'Eliminar, activar, desactivar empresas.',
			action: '/admin/gestion-empresas',
		},
	];
	const listDashboard: CardModel[] = [
		{
			title: 'Dashboard dos Candidatos',
			content: 'Métricas e indicadores do candidato.',
			action:
				'https://lookerstudio.google.com/u/0/reporting/8f7dc586-9e97-4d12-afe3-4753790971b9/page/p_4ymnosdz6c',
		},
		{
			title: 'Dashboard dos empregadores',
			content: 'Métricas e indicadores dos empregadores.',
			action:
				'https://lookerstudio.google.com/u/1/reporting/d2c69850-6964-400b-b3f8-0110b59b99eb/page/p_095goudz6c',
		},
		{
			title: 'Métricas e indicadores',
			content: 'Estatísticas da plataforma.',
			action:
				'https://lookerstudio.google.com/u/1/reporting/b5b00fa7-69da-46b7-810e-74aa1ef7f8ed/page/p_ktyx3bjm7c',
		},
	];

	return (
		<>
			{/* <h2>Área del administrador</h2> */}
			<div className='row mt-15'>
				<div className={`col-sm-4 mt-5`}>
					{listManage.map((x: CardModel, i: number) => (
						<div
							className={`card mb-10 ${styleScss['card']}`}
							style={{ borderRadius: '10px' }}
							key={i}
							id={i.toString()}
						>
							<div className={`${styleScss['top-icon']}`}>
								<i className={`bi bi-calendar-check ${styleScss['icon-top']}`}></i>
							</div>
							<div className='card-body mt-5'>
								<h2 className='card-title'>{t(x.title)}</h2>
								<p className='card-text'>{t(x.content)}</p>
								<Link
									to={x.action!}
									className='btn btn-primary btn-lg btn-block'
									style={{ padding: '8px 40px' }}
								>
									{t(x.title.includes('Gestionar') ? 'Ver mais' : 'Criar')}
								</Link>
							</div>
						</div>
					))}
				</div>
				{/* <div className='col-sm-4 mt-5'>
					<div className={`card mb-10 ${styleScss['card2']}`} style={{ borderRadius: '10px' }}>
						<div className={`${styleScss['top-icon']}`}>
							<i className={`bi bi-calendar-check ${styleScss['icon-top']}`}></i>
						</div>
						<div className='card-body mt-5'>
							<h2 className='card-title'>Citas programadas</h2>
							<p className='card-text'>Reuniones con los consejeros.</p>
							{listScheduledMeetings.map((x: any, i: any) => (
								<div className='mb-3' style={{ display: 'flex', gap: '10px' }} key={i} id={x.id}>
									<div className={`${styleScss['box']}`}>
										<p className={`${styleScss['txt-center']}`}>{x.date}</p>
									</div>
									<div>
										<p className='text-primary'>
											<b>{x.adviserName}</b>
											<br />
											<span className='text-black'>{x.time}</span>
										</p>
									</div>
								</div>
							))}
							<a href='#' className='btn btn-primary mt-4' style={{ padding: '8px 40px' }}>
								Ver todas
							</a>
						</div>
					</div>
				</div> */}
				<div className='col-sm-4 mt-5'>
					{listDashboard.map((x: CardModel, i: number) => (
						<div className={`card mb-5 ${styleScss['blue-card']}`} key={i} id={i.toString()}>
							{x.action ? (
								<a href={x.action} target='_blank' rel='noopener noreferrer'>
									<div className='card-body'>
										<i
											className={`bi bi-rocket-takeoff-fill ${styleScss['icon-custom-white']}`}
										></i>
										<h2 className='card-title text-white mt-2'>{t(x.title)}</h2>
										<p className='card-text text-white'>{t(x.content)}</p>
									</div>
								</a>
							) : (
								<div className='card-body'>
									<i className={`bi bi-rocket-takeoff-fill ${styleScss['icon-custom-white']}`}></i>
									<h2 className='card-title text-white mt-2'>{t(x.title)}</h2>
									<p className='card-text text-white'>{t(x.content)}</p>
								</div>
							)}
						</div>
					))}
				</div>
			</div>
		</>
	);
};

const AdminAreaWrapper = () => {
	const { t } = useTranslation()
	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Área Administrador')}</PageTitle>
			<AdminAreaPage />
		</>
	);
};

export { AdminAreaWrapper };
