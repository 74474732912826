import { FormEvent, useContext, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Snackbar } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useNavigate } from 'react-router-dom';
import { multiStepContext } from '../../../../../hooks/StepContext';
import { register } from '../../../core/_requests';
import { useTranslation } from 'react-i18next';

export function Step3() {
	const { t } = useTranslation();
	const { setStep, firstUser } = useContext(multiStepContext);
	const navigate = useNavigate();

	const [verified, setVerified] = useState(false);
	const [open, setOpen] = useState(false);
	const [msgAlert, setMsgAlert] = useState('');
	const [isError, setIsError] = useState(false);

	const [loading, setLoading] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	async function handleSubmit(event: FormEvent) {
		event.preventDefault();

		setLoading(true);

		console.log(firstUser);

		const dataToSend = {
			user: {
			  email: firstUser.user.email,
			  confirm_email: firstUser.user.confirma_email,
			  password: firstUser.user.password,
			  confirm_password: firstUser.user.confirma_password,
			  role: firstUser.user.role,
			  active: firstUser.user.active,
			  //status: "",
			  company_id: null,
			},
			complement: {
			  document_type: firstUser.complemento.tipo_doc,
			  document_number: firstUser.complemento.numero_doc.toString(),
			  first_name: firstUser.complemento.primer_nome,
			  last_name: firstUser.complemento.segundo_nome || null,
			  birth_date: firstUser.complemento.fecha_nascimiento,
			  zip_code: "",
			  street: "",
			  complement: "",
			  city: firstUser.complemento.ciudad || null,
			  state: "",
			  district: "",
			  country: "",
			  nationality: "",
			  phone_number: firstUser.complemento.celular,
			  security_question: firstUser.complemento.pregunta_seguridad || null,
			  security_answer: firstUser.complemento.respuesta || null,
			  gender: "",
			  latitude: null,
			  longitude: null,
			},
		  };
		
		  try {
			const retUser = await register(dataToSend);

			setIsError(false);
			setMsgAlert(retUser.data.message);
			setOpen(true);
			setTimeout(() => {
				navigate('/auth');
			}, 4000);
		} catch (error: any) {
			setIsError(true);
			setMsgAlert(`${error.response.data.error}`);
			setOpen(true);
		}

		setLoading(false);
	}

	function onChange(value: any) {
		setVerified(true);
	}

	return (
		<Box>
			<div style={{ display: 'flex', justifyContent: 'center', marginTop: 60 }}>
				<ReCAPTCHA sitekey='6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' onChange={onChange} />
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					marginTop: 60,
					marginBottom: 30,
					width: '100%',
					fontSize: 14,
				}}
			>
				<div>
					<div>
					{t('Ao enviar seus dados de registro, você está aceitando a ')}{' '}
						<Link to='' className='link-primary'>
						{t('Política de Privacidade e Tratamento de Dados ')}
						</Link>{' '}
					</div>
					<div>
					{t('Pessoais do portal de empregabilidade ')}{' '}
						<Link to='' className='link-primary'>
						{t('em acordo à Lei Geral de Proteção de Dados.')}	
						</Link>{' '}
					
					</div>
					<div>
					
						<Link to='' className='link-primary'>
						
						</Link>
					</div>
					<div>
						<Link to='' className='link-primary'>
							
						</Link>
					</div>
				</div>
			</div>
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					disabled={loading}
					variant='contained'
					sx={{ mr: 1, width: 90 }}
					onClick={() => setStep(2)}
				>
					{t('Voltar')}
				</Button>
				{/*<Box sx={{ flex: '1 1 auto' }} />*/}
				<Link to='/auth'>
					<Button
						disabled={!verified || loading}
						variant='contained'
						sx={{ mr: 1, width: 90 }}
						onClick={handleSubmit}
					>
						<span style={{ display: 'flex', alignItems: 'center' }}>
							{loading && <CircularProgress size={20} sx={{ marginRight: 2, color: 'white' }} />}
							{t('Enviar')}
						</span>
					</Button>
				</Link>
			</Box>

			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					variant='filled'
					severity={isError ? 'error' : 'success'}
					sx={{ width: '100%' }}
				>
					{t(msgAlert)}
				</Alert>
			</Snackbar>
		</Box>
	);
}
