import React, { useMemo, useState } from 'react';
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Checkbox,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Snackbar,
	TextField,
	Typography,
	Autocomplete,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import _ from 'lodash';
import { IDIOMAS, MESES } from '../../../../../constants/constants';
import { useLanguage } from '../../../../../hooks/curriculum/useLanguage';
import { javascript } from 'webpack';
import DialogMoreInfoLanguage from '../../../../../../_metronic/partials/modals/info-proeficience-language/DialogMoreInfoLanguage';
import { CurricullumFormTypes } from './CurricullumFormTypes';

export default function SectionLanguages({ errors, setFieldValue, values, levelLanguages }: any) {
	const { t } = useTranslation();
	const [openInfo, setOpemInfo] = useState(false);
	const [trad, setTrad] = useState('en');

	const {
		valueAdd,
		borderColorEdit,
		editMode,
		openRowEdit,
		alert,
		alertMsg,
		open,
		setValues,
		handleClose,
		handleEdit,
		handleSaveEdit,
		handleRemoveExperienciaLaboral,
		handleAddExperienciaLaboral,
		handleclear,
		rows,
	} = useLanguage({ setFieldValue, values });

	const { name, level_code } = valueAdd;

	useMemo(() => {
		setTrad(`${t('idiom')}`);
	}, [t]);

	const handleOpenInfo = (bool: boolean): void => {
		setOpemInfo(bool);
	};

	return (
		<>
			<Box sx={{ mb: 3, mt: 5 }}>
				<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
					{t('Idiomas')}
				</Typography>
			</Box>

			<>
				<Grid
					container
					direction='row'
					justifyContent='space-between'
					width={'100%'}
					sx={{
						backgroundColor: '#1C447F',
						pt: 2,
						pl: 2,
						pb: 2,
						pr: 2,
						borderTopLeftRadius: 6,
						borderTopRightRadius: 6,
					}}
					item
				>
					<Grid container direction='column' item>
						<Typography color='white' variant='h5'>
							{t('Agregar')} {t('Idiomas')} {editMode ? t(' - modo de edición') : ''}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					container
					direction='row'
					justifyContent='space-between'
					width={'100%'}
					mb={5}
					sx={{
						// border: 1,
						borderBottom: 3,
						borderColor: '#AFADAD',
						borderBottomLeftRadius: 6,
						borderBottomRightRadius: 6,
						pt: 5,
						pl: 3,
						pr: 3,
						border: `${borderColorEdit}`,
					}}
					item
				>
					<Grid container direction='row' justifyContent='space-between' width={'100%'} item>
						<Grid xs={12} md={6} mb={3} pr={2} item>
							<Autocomplete
								id='languageAdd'
								fullWidth
								autoSelect
								autoHighlight
								options={
									trad == 'pt' ? IDIOMAS['pt'] : trad == 'es' ? IDIOMAS['es'] : IDIOMAS['en']
								}
								filterSelectedOptions
								isOptionEqualToValue={(option, value) => value === option}
								value={name || ''}
								onChange={(event, newValue) => {
									setValues({ ...valueAdd, name: newValue as string });
								}}
								// onInputChange={(event: any, value: any) => {
								//     const cValue = value;
								//     const val = value.split('')
								//     console.log("option", cValue)

								//     console.log(val);
								//     if (val[val?.length - 1]?.includes(';')) {
								//         console.log("valExtraCourses", valExtraCourses)

								//         const v = [
								//             ...valExtraCourses,
								//             ...cValue.replace(";", "")
								//         ]
								//         console.log("vvvvv", v)
								//         handleChangeExtraCourse(v);
								//     }
								// }}

								// style={{ width: 500 }}
								renderInput={(params) => (
									<TextField {...params} label={t('LANGUAGE')} placeholder={t('LANGUAGE')} />
								)}
							/>
						</Grid>
						<Grid xs={12} md={6} mb={3} pr={2} item>
							<FormControl sx={{ minWidth: '100%' }} size='medium'>
								<InputLabel id={'level_code'}>{t('FLUENCE_LEVEL')}</InputLabel>
								<Select
									required
									labelId={'level_code'}
									id={'level_code'}
									label={t('FLUENCE_LEVEL')}
									value={level_code || ''}
									onChange={(e) => {
										setValues({ ...valueAdd, level_code: e.target.value });
									}}
								>
									<MenuItem value=''>{t('FLUENCE_LEVEL')}</MenuItem>
									{levelLanguages.map((item: any) => (
										<MenuItem value={item.id} key={`key-to-test-level-${item.id}`}>
											{item.level_code} - {item[`${t('FIELD_LEVEL')}`]}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>

						<Grid xs={12} md={12} mb={5} pr={2} item>
							<Alert variant='outlined' severity='info'>
								{t('MSG_LEVEL_HELP')}{' '}
								<a href={'#'} onClick={() => setOpemInfo(true)}>
									{t('CLICK_HERE')}{' '}
								</a>{' '}
								{t('UNDERSTAND_LEVEL')}
							</Alert>
						</Grid>
					</Grid>

					<Grid
						xs={12}
						mb={3}
						display='flex'
						justifyContent='space-between'
						alignItems='center'
						item
					>
						{editMode ? (
							<>
								<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
									<Button
										variant='contained'
										onClick={handleSaveEdit}
										sx={{ width: 90, background: '#0A4396', mr: 1 }}
									>
										{t('Guardar')}
									</Button>

									<Button
										variant='contained'
										sx={{ width: 90, background: '#6c757d', mr: 1 }}
										onClick={handleclear}
									>
										{t('Cancelar')}
									</Button>
								</Box>
							</>
						) : (
							<Button
								disabled={!level_code || !name}
								variant='contained'
								onClick={handleAddExperienciaLaboral}
								sx={{ width: 90, background: '#0A4396' }}
							>
								{t('Agregar')}
							</Button>
						)}
					</Grid>
				</Grid>
				{rows.length > 0 && (
					<Grid xs={12} item>
						<Typography color='#2B2B2B' variant='h6'>
							{t('Agregados')}
						</Typography>
						<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 5 }} />
					</Grid>
				)}
				<Grid xs={12} item>
					{rows.map((row, i) => (
						<Grid xs={12} key={i} item display={openRowEdit === row.id ? 'none' : ''}>
							<Grid
								container
								direction='row'
								justifyContent='space-between'
								width={'100%'}
								sx={{
									backgroundColor: '#1C447F',
									pt: 2,
									pl: 2,
									pb: 2,
									pr: 2,
									borderTopLeftRadius: 6,
									borderTopRightRadius: 6,
								}}
								item
							>
								<Typography color='white' variant='h6'>
									{' '}
									{t(row.name || '') || ''}
								</Typography>
								<Typography color='white' variant='h6'>
									{' '}
									{t(row.level_code || '') || ''}
								</Typography>
								<Box display='flex' justifyContent='end' alignItems='center'>
									<div>
										<div className='d-flex justify-content-end flex-shrink-0'>
											<button
												className='btn btn-icon btn-active-color-secondary btn-sm me-3'
												style={{ background: '#C4D2E8' }}
												onClick={() => handleEdit(row)}
												type='button'
											>
												<EditIcon />
											</button>

											<button
												className='btn btn-icon btn-active-color-secondary btn-sm'
												onClick={() => handleRemoveExperienciaLaboral(row.id || 0)}
												style={{ background: '#DD1342' }}
												type='button'
											>
												<DeleteOutlinedIcon style={{ color: 'white' }} />
											</button>
										</div>
									</div>
								</Box>
							</Grid>
							<Grid
								container
								direction='row'
								justifyContent='space-between'
								width={'100%'}
								mb={5}
								sx={{
									border: 1,
									borderBottom: 3,
									borderColor: '#AFADAD',
									borderBottomLeftRadius: 6,
									borderBottomRightRadius: 6,
									pt: 5,
									pl: 3,
									pr: 3,
								}}
								item
							>
								<Grid xs={12} md={12} mb={5} mr={5} item>
									<Typography>{t('LANGUAGE') + ': ' + (row.name || '')}</Typography>
									<Typography>{t('FLUENCE_LEVEL') + ': ' + (row.level_code || '')}</Typography>
								</Grid>
							</Grid>
						</Grid>
					))}
				</Grid>
			</>
			<DialogMoreInfoLanguage open={openInfo} setOpen={handleOpenInfo} />
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
					{t(alertMsg)}
				</Alert>
			</Snackbar>
		</>
	);
}
