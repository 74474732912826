import { useState } from 'react';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { ManageAccounts } from '@mui/icons-material';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RoleEnum } from '../../models/enums/RoleEnum';


export default function MenuActionManagementVacancy({
	data,
	setOpen,
	setAlert,
	setFilter,
	filter,
	setAlertMsg,
	auth,
	updateVacancyStatus,
    modalDelete,
}: any) {
    const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = (e: any) => {
		setAnchorEl(e.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const updateUserStatus = async () => {
		try {
			await updateVacancyStatus({ id: data.id, status: !data.status });
			setOpen(true);
			setAlert(true);
			setFilter({ ...filter });
			data.status
				? setAlertMsg(`Vacante inactivada con éxito!`)
				: setAlertMsg(`Vacante activada con éxito!`);
		} catch (error: any) {
			console.error(error.response.data.message);
			setOpen(true);
			setAlert(false);
			setAlertMsg(error.response.data.message);
		} finally {
			setTimeout(() => {
				setOpen(false);
			}, 2000);
		}
	};

	return (
		<>
			<Button onClick={handleClick}>
				<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
			</Button>
			<Menu
				id='card-actions-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={handleClose}>
					<Link
						to={
							auth?.user?.role === RoleEnum.EMPLOYER
								? `/empleador/gestion-de-vacante/${data.id}`
								: `/consejero/gestion-de-vacante/${data.id}`
						}
					>
						<BorderColorIcon />
						&nbsp; {t('Editar')}
					</Link>
				</MenuItem>
				<MenuItem onClick={handleClose}>
					<a className='pointer' onClick={() => modalDelete(data)}>
						<DeleteIcon />
						&nbsp; {t('Borrar')}
					</a>
				</MenuItem>
				<MenuItem onClick={handleClose}>
					<Link
						to={
							auth?.user?.role === RoleEnum.EMPLOYER
								? `/empleador/matching-candidatos/${data.id}`
								: `/consejero/matching-candidatos/${data.id}`
						}
					>
						<EqualizerIcon />
						&nbsp; {t('Matching')}
					</Link>
				</MenuItem>
				<MenuItem onClick={handleClose}>
					<a className='pointer' onClick={updateUserStatus}>
						<ManageAccounts />
						&nbsp; {t('Activar/Inactivar')}
					</a>
				</MenuItem>
			</Menu>
		</>
	);
}
