import { useContext, useEffect, useState } from 'react';
import {
	Box,
	TextField,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Button,
	Grid,
	FormHelperText,
	Snackbar,
	Alert,
	AlertTitle,
	InputAdornment,
} from '@mui/material';
import { multiStepContext } from '../../../../../hooks/StepContext';
import { Form, Formik } from 'formik';
import { object, string, date, number } from 'yup';
import { differenceInYears } from 'date-fns';
import { getVerifyDocumentExists } from '../../../core/_requests';
import { getDateNowToString, isValidFieldRequired } from '../../../../../util/Index';
import { useTranslation } from 'react-i18next';

// const documentTypes = ['CC', 'CE', 'PEP', 'PA', 'TI', 'PT'];
const documentTypes = [
	{ id: 'CC', name: 'CPF' },
	//{ id: 'TI', name: 'Tarjeta de Identidad' },
	//{ id: 'CE', name: 'Cédula de Extranjería' },
	//{ id: 'PA', name: 'Pasaporte' },
	//{ id: 'PEP', name: 'Permiso Especial de Permanencia' },
	//{ id: 'PT', name: 'Permiso por Protección Temporal' },
];

function goBack() {
	window.history.back();
}
export const Step1 = () => {
	const { t } = useTranslation(); 
	const { setStep, firstUser, setFirstUser } = useContext(multiStepContext);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [open, setOpen] = useState(false);

	const [documentTypeSelected, setDocumentTypeSelected] = useState('');
	const [maxDocumentFieldLenght, setMaxDocumentFieldLenght] = useState(0);
	const [minDocumentFieldLenght, setMinDocumentFieldLenght] = useState(0);
	const [documentTypeField, setDocumentTypeField] = useState('text');

	const [initialValues, setInitialValues] = useState({
		complemento: {
			tipo_doc: '',
			numero_doc: '',
			primer_nome: '',
			segundo_nome: '',
			primer_apelido: '',
			segundo_apelido: '',
			fecha_nascimiento: '',
		},
	});

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		setInitialValues(firstUser);
		setDocumentTypeSelected(firstUser.complemento.tipo_doc);
		validationSchemaByTpDoc();
	}, []);

	// console.log(firstUser);

	const handleNext = async (values: any, setErrors: any) => {
		console.log(values);

		const exists = await verifyDocumentExists(values.complemento.numero_doc, setErrors);

		if (!exists) {
			setFirstUser({
				...firstUser,
				complemento: {
					...firstUser.complemento,
					numero_doc: values.complemento.numero_doc,
					tipo_doc: values.complemento.tipo_doc,
					primer_nome: values.complemento.primer_nome,
					segundo_nome: values.complemento.segundo_nome,
					primer_apelido: values.complemento.primer_apelido,
					segundo_apelido: values.complemento.segundo_apelido,
					fecha_nascimiento: values.complemento.fecha_nascimiento,
				},
			});
			setStep(2);
		}
	};

	const verifyDocumentExists = async (documentNumber: string, setErrors: any) => {
		try {
			const response = await getVerifyDocumentExists(`document=${documentNumber}`);
			return response.data.exists;
		} catch (error: any) {
			console.log(`error`, error.response);
			setAlert(false);
			setAlertMsg(error.response.data.error);
			setOpen(true);
			setErrors({
				complemento: {
					numero_doc: error.response.data.error,
				},
			});
			return error.response.data.exists;
		}
	};

	const validationSchemaByTpDoc = () => {
		switch (documentTypeSelected) {
			case 'CC':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(5);
				setMaxDocumentFieldLenght(10);
				return object({
					complemento: object({
						tipo_doc: string()
							.test('len', 'Selecione um tipo de documento', (val) => {
								return (val as string) === '' ? false : true;
							})
							.required('Obrigatorio'),
						numero_doc: string()
							.matches(/^[0-9]+$/, 'Deben ser solo números')
							.required('Obrigatorio')
							.min(5, 'Debe tener al menos 5 dígitos')
							.max(10, 'Debe tener 10 o menos dígitos'),
						primer_nome: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos')
							.required('Obrigatorio'),
						segundo_nome: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						primer_apelido: string()
							.required('Obrigatorio')
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						segundo_apelido: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						fecha_nascimiento: date()
							.required('Obrigatorio')
							.test('dob', 'Solo se permiten personas mayores de 15 años.', (value) => {
								return differenceInYears(new Date(), new Date(value as Date)) >= 15;
							}),
					}),
				});
			case 'TI':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(0);
				setMaxDocumentFieldLenght(12);
				return object({
					complemento: object({
						tipo_doc: string()
							.test('len', 'Selecione um tipo de documento', (val) => {
								return (val as string) === '' ? false : true;
							})
							.required('Obrigatorio'),
						numero_doc: string()
							.matches(/^[A-Za-z0-9]*$/, 'Deben ser solo números y letras')
							.required('Numero de Documento obrigatorio')
							.max(12, 'Debe tener 12 o menos dígitos'),
						primer_nome: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos')
							.required('Obrigatorio'),
						segundo_nome: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						primer_apelido: string()
							.required('Obrigatorio')
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						segundo_apelido: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						fecha_nascimiento: date()
							.required('Obrigatorio')
							.test('dob', 'Solo se permiten personas mayores de 15 años.', (value) => {
								return differenceInYears(new Date(), new Date(value as Date)) >= 15;
							}),
					}),
				});
			case 'CE':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(9);
				setMaxDocumentFieldLenght(10);
				return object({
					complemento: object({
						tipo_doc: string()
							.test('len', 'Selecione um tipo de documento', (val) => {
								return (val as string) === '' ? false : true;
							})
							.required('Obrigatorio'),
						numero_doc: string()
							.matches(/^[0-9]+$/, 'Deben ser solo números')
							.required('Numero de Documento obrigatorio')
							.min(9, 'Debe tener al menos 9 dígitos')
							.max(10, 'Debe tener 10 o menos dígitos'),
						primer_nome: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos')
							.required('Obrigatorio'),
						segundo_nome: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						primer_apelido: string()
							.required('Obrigatorio')
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						segundo_apelido: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						fecha_nascimiento: date()
							.required('Obrigatorio')
							.test('dob', 'Solo se permiten personas mayores de 15 años.', (value) => {
								return differenceInYears(new Date(), new Date(value as Date)) >= 15;
							}),
					}),
				});
			case 'PA':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(4);
				setMaxDocumentFieldLenght(7);
				return object({
					complemento: object({
						tipo_doc: string()
							.test('len', 'Selecione um tipo de documento', (val) => {
								return (val as string) === '' ? false : true;
							})
							.required('Obrigatorio'),
						numero_doc: string()
							.matches(/^[A-Za-z0-9]*$/, 'Deben ser solo números y letras')
							.required('Numero de Documento obrigatorio')
							.min(4, 'Debe tener al menos 4 dígitos')
							.max(7, 'Debe tener 7 o menos dígitos'),
						primer_nome: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos')
							.required('Obrigatorio'),
						segundo_nome: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						primer_apelido: string()
							.required('Obrigatorio')
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						segundo_apelido: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						fecha_nascimiento: date()
							.required('Obrigatorio')
							.test('dob', 'Solo se permiten personas mayores de 15 años.', (value) => {
								return differenceInYears(new Date(), new Date(value as Date)) >= 15;
							}),
					}),
				});
			case 'PEP':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(15);
				setMaxDocumentFieldLenght(15);
				return object({
					complemento: object({
						tipo_doc: string()
							.test('len', 'Selecione um tipo de documento', (val) => {
								return (val as string) === '' ? false : true;
							})
							.required('Obrigatorio'),
						numero_doc: string()
							.matches(/^[0-9]+$/, 'Deben ser solo números')
							.required('Numero de Documento obrigatorio')
							.min(15, 'Debe tener al menos 15 dígitos')
							.max(15, 'Debe tener 15 o menos dígitos'),
						primer_nome: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos')
							.required('Obrigatorio'),
						segundo_nome: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						primer_apelido: string()
							.required('Obrigatorio')
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						segundo_apelido: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						fecha_nascimiento: date()
							.required('Obrigatorio')
							.test('dob', 'Solo se permiten personas mayores de 15 años.', (value) => {
								return differenceInYears(new Date(), new Date(value as Date)) >= 15;
							}),
					}),
				});
			case 'PT':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(6);
				setMaxDocumentFieldLenght(9);
				return object({
					complemento: object({
						tipo_doc: string()
							.test('len', 'Selecione um tipo de documento', (val) => {
								return (val as string) === '' ? false : true;
							})
							.required('Obrigatorio'),
						numero_doc: string()
							.matches(/^[0-9]+$/, 'Deben ser solo números')
							.required('Numero de Documento obrigatorio')
							.min(6, 'Debe tener al menos 6 dígitos')
							.max(9, 'Debe tener 9 o menos dígitos'),
						primer_nome: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos')
							.required('Obrigatorio'),
						segundo_nome: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						primer_apelido: string()
							.required('Obrigatorio')
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						segundo_apelido: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						fecha_nascimiento: date()
							.required('Obrigatorio')
							.test('dob', 'Solo se permiten personas mayores de 15 años.', (value) => {
								return differenceInYears(new Date(), new Date(value as Date)) >= 15;
							}),
					}),
				});
			default:
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(0);
				setMaxDocumentFieldLenght(20);
				return object({
					complemento: object({
						tipo_doc: string()
							.test('len', 'Selecione um tipo de documento', (val) => {
								return (val as string) === '' ? false : true;
							})
							.required('Obrigatorio'),
						numero_doc: string().required('Numero de Documento obrigatorio'),
						primer_nome: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos')
							.required('Obrigatorio'),
						segundo_nome: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						primer_apelido: string()
							.required('Obrigatorio')
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						segundo_apelido: string()
							.matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
						fecha_nascimiento: date()
							.required('Obrigatorio')
							.test('dob', 'Solo se permiten personas mayores de 15 años.', (value) => {
								return differenceInYears(new Date(), new Date(value as Date)) >= 15;
							}),
					}),
				});
		}
	};

	const showDatePickerBirthDate = () => {
		// @ts-ignore
		document.querySelector('#fecha_nascimiento').showPicker();
	};

	return (
		<Box sx={{ '& .MuiTextField-root': { m: 2, width: '95%' } }}>
			<Formik
				validationSchema={validationSchemaByTpDoc}
				initialValues={initialValues}
				validateOnBlur
				validateOnMount
				enableReinitialize
				initialTouched={{
					complemento: {
						tipo_doc: true,
						numero_doc: true,
						primer_nome: true,
						primer_apelido: true,
						fecha_nascimiento: true,
					},
				}}
				onSubmit={() => {}}
			>
				{({ errors, touched, values, handleChange, handleBlur, isValid, setErrors }) => (
					<Form autoComplete='off'>
						<Grid container direction='row' justifyContent='space-between' width={'100%'}>
							<Grid xs={12} md={6}>
								<FormControl
									sx={{ m: 2, minWidth: '95%' }}
									size='medium'
									error={touched.complemento?.tipo_doc && Boolean(errors.complemento?.tipo_doc)}
								>
									<InputLabel>{t('Tipo de documento')}</InputLabel>
									<Select
										name='complemento.tipo_doc'
										label={t('Tipo de documento')}
										onChange={(e) => {
											values.complemento.numero_doc = '';
											handleChange(e);
											setDocumentTypeSelected(e.target.value);
										}}
										onBlur={handleBlur}
										value={values.complemento.tipo_doc}
									>
										<MenuItem value=''>{t('Selecione...')}</MenuItem>
										{documentTypes.map((types, i) => (
											<MenuItem value={types.id} key={i}>
												{types.name}
											</MenuItem>
										))}
									</Select>
									{touched.complemento?.tipo_doc && Boolean(errors.complemento?.tipo_doc) && (
										<FormHelperText>
											{touched.complemento?.tipo_doc ? t(errors.complemento?.tipo_doc || '') : ''}
										</FormHelperText>
									)}
								</FormControl>
							</Grid>
							<Grid xs={12} md={6}>
								<TextField
									fullWidth
									required
									name='complemento.numero_doc'
									label={t('Número de documento')}
									type={documentTypeField}
									size='medium'
									inputProps={{
										maxLength: maxDocumentFieldLenght,
										minLength: minDocumentFieldLenght,
										min: 0,
									}}
									onChange={(e) => {
										if (e.target.value.length <= maxDocumentFieldLenght) handleChange(e);
									}}
									onBlur={handleBlur}
									disabled={!isValidFieldRequired(values.complemento.tipo_doc)}
									value={values.complemento.numero_doc}
									error={touched.complemento?.numero_doc && Boolean(errors.complemento?.numero_doc)}
									helperText={
										!isValidFieldRequired(values.complemento.tipo_doc)
											? t('Primero selecione um tipo de documento')
											: touched.complemento?.numero_doc
											? t(errors.complemento?.numero_doc || '')
											: ''
									}
								/>
							</Grid>
							<Grid xs={12} md={6}>
								<TextField
									fullWidth
									required
									name='complemento.primer_nome'
									label={t('Primeiro nome')}
									type='text'
									inputProps={{ maxLength: 20 }}
									size='medium'
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.complemento.primer_nome}
									error={
										touched.complemento?.primer_nome && Boolean(errors.complemento?.primer_nome)
									}
									helperText={
										touched.complemento?.primer_nome ? t(errors.complemento?.primer_nome || '') : ''
									}
								/>
							</Grid>
							{/* 
							<Grid xs={12} md={6}>
								<TextField
									fullWidth
									name='complemento.segundo_nome'
									label='Segundo nome'
									type='text'
									inputProps={{ maxLength: 30 }}
									size='medium'
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.complemento.segundo_nome}
									error={
										touched.complemento?.segundo_nome && Boolean(errors.complemento?.segundo_nome)
									}
									helperText={
										touched.complemento?.segundo_nome ? errors.complemento?.segundo_nome : ''
									}
								/>
							</Grid>
							*/}
							<Grid xs={12} md={6}>
								<TextField
									fullWidth
									required
									name='complemento.primer_apelido'
									label={t('Sobrenome')}
									type='text'
									inputProps={{ maxLength: 20 }}
									size='medium'
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.complemento.primer_apelido}
									error={
										touched.complemento?.primer_apelido &&
										Boolean(errors.complemento?.primer_apelido)
									}
									helperText={
										touched.complemento?.primer_apelido ? t(errors.complemento?.primer_apelido || '') : ''
									}
								/>
							</Grid>
							{/*
							<Grid xs={12} md={6}>
								<TextField
									fullWidth
									name='complemento.segundo_apelido'
									label='Segundo apelido'
									type='text'
									inputProps={{ maxLength: 20 }}
									size='medium'
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.complemento.segundo_apelido}
									error={
										touched.complemento?.segundo_apelido && Boolean(errors.complemento?.segundo_apelido)
									}
									helperText={
										touched.complemento?.segundo_apelido ? errors.complemento?.segundo_apelido : ''
									}
								/>
							</Grid>
						*/}
							<Grid xs={12} md={6}>
								<TextField
									fullWidth
									required
									id='fecha_nascimiento'
									name='complemento.fecha_nascimiento'
									label={t('Data de nascimento')}
									type='date'
									InputLabelProps={{ shrink: true }}
									size='medium'
									onChange={handleChange}
									onKeyDown={(e) => e.preventDefault()}
									onBlur={handleBlur}
									value={values.complemento.fecha_nascimiento}
									error={
										touched.complemento?.fecha_nascimiento &&
										Boolean(errors.complemento?.fecha_nascimiento)
									}
									helperText={
										touched.complemento?.fecha_nascimiento
											? t(errors.complemento?.fecha_nascimiento || '')
											: ''
									}
									defaultValue='2017-05-24'
									InputProps={{
										endAdornment: (
											<InputAdornment position='end' role='button'>
												<i
													className='bi bi bi-calendar2-plus-fill'
													style={{
														fontSize: '15px',
													}}
													onClick={() => showDatePickerBirthDate()}
												></i>
											</InputAdornment>
										),
										inputProps: {
											max: getDateNowToString(),
											min: '1901-01-01',
										},
									}}
								/>
							</Grid>
						</Grid>
						{/* <Box sx={{ height: '68px' }} /> */}
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'end',
								pt: 2,
							}}
						>
							<Button
								variant='contained'
								sx={{ mr: 1, width: 90, height: 40 }}
								onClick={() => goBack()}
							>
								{t('Voltar')}
							</Button>
							{/*<Box sx={{ flex: '1 1 auto' }} />*/}
							<Button
								variant='contained'
								sx={{ mr: 1, width: 90, height: 40 }}
								onClick={() => handleNext(values, setErrors)}
								disabled={!isValid}
							>
								{t('Próximo')}
							</Button>
						</Box>
					</Form>
				)}
			</Formik>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
					{t(alertMsg)}
				</Alert>
			</Snackbar>
		</Box>
	);
};
