import { useContext, useEffect, useState } from 'react';
import {
	TextField,
	SelectChangeEvent,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Box,
	Button,
	Divider,
	Typography,
	Alert,
	Snackbar,
	AlertTitle,
	FormHelperText,
	InputAdornment,
} from '@mui/material';
import { multiStepContext } from '../../../../../contexts/CompanyContext';
import {
	formatMaskPhoneNumber12Digits,
	isValidFieldRequired,
	matchEmail,
	regExpPhoneNumber12Digits,
} from '../../../../../util/Index';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getVerifyDocumentExists, getVerifyEmailExists } from '../../../core/_requests';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import axios from 'axios';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export const Step3 = () => {
	const { t } = useTranslation();
	const { setStep, userData, setUserData, firstUser, setFirstUser, firstCompany, setFirstCompany } =
		useContext(multiStepContext);
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const [validationsDocuments, setValidationsDocuments] = useState<string[]>([]);

	const [documentExist, setDocumentExists] = useState(false);
	const [emailExists, setEmailExists] = useState(false);

	const [maxDocumentFieldLenght, setMaxDocumentFieldLenght] = useState(0);
	const [minDocumentFieldLenght, setMinDocumentFieldLenght] = useState(0);
	const [documentTypeField, setDocumentTypeField] = useState('text');

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		validationSchemaByTpDoc(firstUser.complemento['tipo_doc']);
	}, []);

	const handleClose = () => {
		setOpen(false);
	};
	const [documentType, setDocumentType] = useState('');

	const ddlTipoDocument = [
		{ id: 'CPF', name: 'CPF' },
		{ id: 'RG', name: 'RG' },
		{ id: 'CNH', name: 'CNH' },
	];

	const ddlPregunta = [
		{ id: '1', name: 'Cor favorita' },
		{ id: '2', name: 'Nome do professor favorito' },
		{ id: '3', name: 'Apelido' },
		{ id: '4', name: 'Nome do meu primeiro animal de estimação' },
	];
	const ddlPaisResidencia = [
		{ id: 'AF', name: 'Afganistán' },
		{ id: 'AL', name: 'Albania' },
		{ id: 'DE', name: 'Alemania' },
		{ id: 'AD', name: 'Andorra' },
		{ id: 'AO', name: 'Angola' },
		{ id: 'AI', name: 'Anguila' },
		{ id: 'AQ', name: 'Antártida' },
		{ id: 'AG', name: 'Antigua y Barbuda' },
		{ id: 'AN', name: 'Antillas Holandesas' },
		{ id: 'SA', name: 'Arabia Saudita' },
		{ id: 'DZ', name: 'Argelia' },
		{ id: 'AR', name: 'Argentina' },
		{ id: 'AM', name: 'Armenia' },
		{ id: 'AW', name: 'Aruba' },
		{ id: 'MK', name: 'ARY Macedonia' },
		{ id: 'AU', name: 'Australia' },
		{ id: 'AT', name: 'Austria' },
		{ id: 'AZ', name: 'Azerbaiyán' },
		{ id: 'BS', name: 'Bahamas' },
		{ id: 'BH', name: 'Baréin' },
		{ id: 'BD', name: 'Bangladesh' },
		{ id: 'BB', name: 'Barbados' },
		{ id: 'BE', name: 'Bélgica' },
		{ id: 'BZ', name: 'Belice' },
		{ id: 'BJ', name: 'Benín' },
		{ id: 'BM', name: 'Bermudas' },
		{ id: 'BT', name: 'Bután' },
		{ id: 'BY', name: 'Bielorrusia' },
		{ id: 'BO', name: 'Bolivia' },
		{ id: 'BA', name: 'Bosnia y Herzegovina' },
		{ id: 'BW', name: 'Botsuana' },
		{ id: 'BR', name: 'Brasil' },
		{ id: 'BN', name: 'Brunéi' },
		{ id: 'BG', name: 'Bulgaria' },
		{ id: 'BF', name: 'Burkina Faso' },
		{ id: 'BI', name: 'Burundi' },
		{ id: 'CV', name: 'Cabo Verde' },
		{ id: 'KH', name: 'Camboya' },
		{ id: 'CM', name: 'Camerún' },
		{ id: 'CA', name: 'Canadá' },
		{ id: 'QA', name: 'Catar' },
		{ id: 'TD', name: 'Chad' },
		{ id: 'CL', name: 'Chile' },
		{ id: 'CN', name: 'China' },
		{ id: 'CY', name: 'Chipre' },
		{ id: 'VA', name: 'Ciudad del Vaticano' },
		{ id: 'CO', name: 'Colombia' },
		{ id: 'KM', name: 'Comoras' },
		{ id: 'CG', name: 'Congo' },
		{ id: 'KP', name: 'Corea del Norte' },
		{ id: 'KR', name: 'Corea del Sur' },
		{ id: 'CI', name: 'Costa de Marfil' },
		{ id: 'CR', name: 'Costa Rica' },
		{ id: 'HR', name: 'Croacia' },
		{ id: 'CU', name: 'Cuba' },
		{ id: 'DK', name: 'Dinamarca' },
		{ id: 'DM', name: 'Dominica' },
		{ id: 'EC', name: 'Ecuador' },
		{ id: 'EG', name: 'Egipto' },
		{ id: 'SV', name: 'El Salvador' },
		{ id: 'AE', name: 'Emiratos Árabes Unidos' },
		{ id: 'ER', name: 'Eritrea' },
		{ id: 'SK', name: 'Eslovaquia' },
		{ id: 'SI', name: 'Eslovenia' },
		{ id: 'ES', name: 'España' },
		{ id: 'US', name: 'Estados Unidos' },
		{ id: 'EE', name: 'Estonia' },
		{ id: 'ET', name: 'Etiopía' },
		{ id: 'PH', name: 'Filipinas' },
		{ id: 'FI', name: 'Finlandia' },
		{ id: 'FJ', name: 'Fiyi' },
		{ id: 'FR', name: 'Francia' },
		{ id: 'GA', name: 'Gabón' },
		{ id: 'GM', name: 'Gambia' },
		{ id: 'GE', name: 'Georgia' },
		{ id: 'GH', name: 'Ghana' },
		{ id: 'GI', name: 'Gibraltar' },
		{ id: 'GD', name: 'Granada' },
		{ id: 'GR', name: 'Grecia' },
		{ id: 'GL', name: 'Groenlandia' },
		{ id: 'GP', name: 'Guadalupe' },
		{ id: 'GU', name: 'Guam' },
		{ id: 'GT', name: 'Guatemala' },
		{ id: 'GF', name: 'Guayana Francesa' },
		{ id: 'GN', name: 'Guinea' },
		{ id: 'GQ', name: 'Guinea Ecuatorial' },
		{ id: 'GW', name: 'Guinea-Bissau' },
		{ id: 'GY', name: 'Guyana' },
		{ id: 'HT', name: 'Haití' },
		{ id: 'HN', name: 'Honduras' },
		{ id: 'HK', name: 'Hong Kong' },
		{ id: 'HU', name: 'Hungría' },
		{ id: 'IN', name: 'India' },
		{ id: 'ID', name: 'Indonesia' },
		{ id: 'IR', name: 'Irán' },
		{ id: 'IQ', name: 'Iraq' },
		{ id: 'IE', name: 'Irlanda' },
		{ id: 'NF', name: 'Isla Norfolk' },
		{ id: 'IS', name: 'Islandia' },
		{ id: 'KY', name: 'Islas Caimán' },
		{ id: 'CC', name: 'Islas Cocos' },
		{ id: 'CK', name: 'Islas Cook' },
		{ id: 'FO', name: 'Islas Feroe' },
		{ id: 'AX', name: 'Islas Gland' },
		{ id: 'FK', name: 'Islas Malvinas' },
		{ id: 'MP', name: 'Islas Marianas del Norte' },
		{ id: 'MH', name: 'Islas Marshall' },
		{ id: 'PN', name: 'Islas Pitcairn' },
		{ id: 'SB', name: 'Islas Salomón' },
		{ id: 'TC', name: 'Islas Turcas y Caicos' },
		{ id: 'VG', name: 'Islas Vírgenes Británicas' },
		{ id: 'VI', name: 'Islas Vírgenes de los Estados Unidos' },
		{ id: 'IL', name: 'Israel' },
		{ id: 'IT', name: 'Italia' },
		{ id: 'JM', name: 'Jamaica' },
		{ id: 'JP', name: 'Japón' },
		{ id: 'JO', name: 'Jordania' },
		{ id: 'KZ', name: 'Kazajistán' },
		{ id: 'KE', name: 'Kenia' },
		{ id: 'KG', name: 'Kirguistán' },
		{ id: 'KI', name: 'Kiribati' },
		{ id: 'KW', name: 'Kuwait' },
		{ id: 'LA', name: 'Laos' },
		{ id: 'LS', name: 'Lesoto' },
		{ id: 'LV', name: 'Letonia' },
		{ id: 'LB', name: 'Líbano' },
		{ id: 'LR', name: 'Liberia' },
		{ id: 'LY', name: 'Libia' },
		{ id: 'LI', name: 'Liechtenstein' },
		{ id: 'LT', name: 'Lituania' },
		{ id: 'LU', name: 'Luxemburgo' },
		{ id: 'MO', name: 'Macao' },
		{ id: 'MK', name: 'Macedonia del Norte' },
		{ id: 'MG', name: 'Madagascar' },
		{ id: 'MY', name: 'Malasia' },
		{ id: 'MW', name: 'Malawi' },
		{ id: 'MV', name: 'Maldivas' },
		{ id: 'ML', name: 'Malí' },
		{ id: 'MT', name: 'Malta' },
		{ id: 'MA', name: 'Marruecos' },
		{ id: 'MQ', name: 'Martinica' },
		{ id: 'MU', name: 'Mauricio' },
		{ id: 'MR', name: 'Mauritania' },
		{ id: 'YT', name: 'Mayotte' },
		{ id: 'MX', name: 'México' },
		{ id: 'FM', name: 'Micronesia' },
		{ id: 'MD', name: 'Moldavia' },
		{ id: 'MC', name: 'Mónaco' },
		{ id: 'MN', name: 'Mongolia' },
		{ id: 'MS', name: 'Montserrat' },
		{ id: 'MZ', name: 'Mozambique' },
		{ id: 'MM', name: 'Myanmar' },
		{ id: 'NA', name: 'Namibia' },
		{ id: 'NR', name: 'Nauru' },
		{ id: 'NP', name: 'Nepal' },
		{ id: 'NI', name: 'Nicaragua' },
		{ id: 'NE', name: 'Níger' },
		{ id: 'NG', name: 'Nigeria' },
		{ id: 'NU', name: 'Niue' },
		{ id: 'NO', name: 'Noruega' },
		{ id: 'NC', name: 'Nueva Caledonia' },
		{ id: 'NZ', name: 'Nueva Zelanda' },
		{ id: 'OM', name: 'Omán' },
		{ id: 'NL', name: 'Países Bajos' },
		{ id: 'PK', name: 'Pakistán' },
		{ id: 'PW', name: 'Palau' },
		{ id: 'PS', name: 'Palestina' },
		{ id: 'PA', name: 'Panamá' },
		{ id: 'PG', name: 'Papúa Nueva Guinea' },
		{ id: 'PY', name: 'Paraguay' },
		{ id: 'PE', name: 'Perú' },
		{ id: 'PF', name: 'Polinesia Francesa' },
		{ id: 'PL', name: 'Polonia' },
		{ id: 'PT', name: 'Portugal' },
		{ id: 'PR', name: 'Puerto Rico' },
		{ id: 'GB', name: 'Reino Unido' },
		{ id: 'CF', name: 'República Centroafricana' },
		{ id: 'CZ', name: 'República Checa' },
		{ id: 'CD', name: 'República Democrática del Congo' },
		{ id: 'DO', name: 'República Dominicana' },
		{ id: 'RE', name: 'Reunión' },
		{ id: 'RW', name: 'Ruanda' },
		{ id: 'RO', name: 'Rumania' },
		{ id: 'RU', name: 'Rusia' },
		{ id: 'EH', name: 'Sahara Occidental' },
		{ id: 'WS', name: 'Samoa' },
		{ id: 'AS', name: 'Samoa Americana' },
		{ id: 'KN', name: 'San Cristóbal y Nevis' },
		{ id: 'SM', name: 'San Marino' },
		{ id: 'PM', name: 'San Pedro y Miquelón' },
		{ id: 'VC', name: 'San Vicente y las Granadinas' },
		{ id: 'SH', name: 'Santa Helena' },
		{ id: 'LC', name: 'Santa Lucía' },
		{ id: 'ST', name: 'Santo Tomé y Príncipe' },
		{ id: 'SN', name: 'Senegal' },
		{ id: 'CS', name: 'Serbia y Montenegro' },
		{ id: 'SC', name: 'Seychelles' },
		{ id: 'SL', name: 'Sierra Leona' },
		{ id: 'NS', name: 'Sin Definir' },
		{ id: 'SG', name: 'Singapur' },
		{ id: 'SY', name: 'Siria' },
		{ id: 'SO', name: 'Somalia' },
		{ id: 'LK', name: 'Sri Lanka' },
		{ id: 'SZ', name: 'Suazilandia' },
		{ id: 'ZA', name: 'Sudáfrica' },
		{ id: 'SD', name: 'Sudán' },
		{ id: 'SE', name: 'Suecia' },
		{ id: 'CH', name: 'Suiza' },
		{ id: 'SR', name: 'Surinam' },
		{ id: 'SJ', name: 'Svalbard y Jan Mayen' },
		{ id: 'TH', name: 'Tailandia' },
		{ id: 'TW', name: 'Taiwán' },
		{ id: 'TZ', name: 'Tanzania' },
		{ id: 'TJ', name: 'Tayikistán' },
		{ id: 'TL', name: 'Timor Oriental' },
		{ id: 'TG', name: 'Togo' },
		{ id: 'TK', name: 'Tokelau' },
		{ id: 'TO', name: 'Tonga' },
		{ id: 'TT', name: 'Trinidad y Tobago' },
		{ id: 'TN', name: 'Túnez' },
		{ id: 'TM', name: 'Turkmenistán' },
		{ id: 'TR', name: 'Turquía' },
		{ id: 'TV', name: 'Tuvalu' },
		{ id: 'UA', name: 'Ucrania' },
		{ id: 'UG', name: 'Uganda' },
		{ id: 'UY', name: 'Uruguay' },
		{ id: 'UZ', name: 'Uzbekistán' },
		{ id: 'VU', name: 'Vanuatu' },
		{ id: 'VE', name: 'Venezuela' },
		{ id: 'VN', name: 'Vietnam' },
		{ id: 'WF', name: 'Wallis y Futuna' },
		{ id: 'YE', name: 'Yemen' },
		{ id: 'DJ', name: 'Yibuti' },
		{ id: 'ZM', name: 'Zambia' },
		{ id: 'ZW', name: 'Zimbabue' },
	];
	const handleChange = (event: SelectChangeEvent) => {
		setDocumentType(event.target.value);
	};

	const isValidPhoneNumber = (e: string): boolean => {
		if (
			e
				?.replace(/[^\d]/g, '')
				?.replaceAll('-', '')
				?.replaceAll('+', '')
				?.match(regExpPhoneNumber12Digits())
		)
			return true;

		return false;
	};

	const isValidEmail = (e: string): boolean => {
		if (e?.match(matchEmail())) return true;

		return false;
	};

	const handleNext = async () => {
		let errors: { field: string; error: string }[] = [];
		if (!isValidFieldRequired(firstUser.complemento['primer_nome']))
			errors.push({ field: 'primer_nombre', error: 'Campo requerido' });

		if (!isValidFieldRequired(firstUser.complemento['primer_apelido']))
			errors.push({ field: 'primer_apellido', error: 'Campo requerido' });

		if (!isValidFieldRequired(firstUser.complemento['tipo_doc']))
			errors.push({ field: 'tipo_documento', error: 'Campo requerido' });

		if (!isValidFieldRequired(firstUser.complemento['numero_doc']))
			errors.push({ field: 'numero_documento', error: 'Campo requerido' });
		else {
			if (validLengthDocumentField(firstUser.complemento['numero_doc']) !== '')
				errors.push({
					field: 'numero_documento',
					error: validLengthDocumentField(firstUser.complemento['numero_doc']),
				});
		}

		if (
			firstUser.user['email'] !== undefined &&
			firstUser.user['email'] !== '' &&
			!isValidEmail(firstUser.user['email'])
		)
			errors.push({ field: 'email', error: 'Correo electrónico no es válido' });

		if (
			firstUser.user['email'] !== undefined &&
			firstUser.user['email'] !== '' &&
			!(firstUser.user['confirma_email'] === firstUser.user['email'])
		)
			errors.push({
				field: 'confirma_correo_electrónico',
				error:
					'El correo electrónico de confirmación debe ser el mismo que se ingresó anteriormente',
			});

		if (
			firstUser.complemento['celular'] !== undefined &&
			firstUser.complemento['celular'] !== '' &&
			!isValidPhoneNumber(firstUser.complemento['celular'])
		)
			errors.push({
				field: 'teléfono',
				error: 'Teléfono de contacto no es válido',
			});

		console.log('error', errors);

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\n${t('Rellene los campos obligatorios')}\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${t(
						x.error
					)}`;
				})}`
			);
			setOpen(true);
			setTimeout(() => {
				setOpen(false);
			}, 5000);
			return;
		}
		let existsDocument = false;
		let existsEmail = false;
		if (isValidFieldRequired(firstUser.complemento['numero_doc']))
			existsDocument = await verifyDocumentExists(firstUser.complemento['numero_doc']);

		if (
			firstUser.user['email'] !== undefined &&
			firstUser.user['email'] !== '' &&
			isValidEmail(firstUser.user['email'])
		)
			existsEmail = await verifyEmailExists(firstUser.user['email']);

		if (!existsDocument && !existsEmail) {
			setStep(4);
			return;
		} else {
			console.log('validation', validationsDocuments);
			setAlert(false);
			setAlertMsg(
				`\n${validationsDocuments.map((x) => {
					return `\n${x}`;
				})}`
			);
			setOpen(true);
			setValidationsDocuments([]);
			return;
		}
	};

	const verifyDocumentExists = async (documentNumber: string) => {
		try {
			const response = await getVerifyDocumentExists(`document=${documentNumber}`);
			setDocumentExists(response.data.exists);
			return response.data.exists;
		} catch (error: any) {
			console.log(`error`, error.response);
			validationsDocuments.push(error.response.data.error);
			setDocumentExists(error.response.data.exists);
			return error.response.data.exists;
		}
	};

	const verifyEmailExists = async (email: string) => {
		try {
			const response = await getVerifyEmailExists(`email=${email}`);
			setEmailExists(response.data.exists);
			return response.data.exists;
		} catch (error: any) {
			console.log(`error`, error.response);
			validationsDocuments.push(error.response.data.error);
			setEmailExists(error.response.data.exists);
			return error.response.data.exists;
		}
	};

	//validation for candidate document
	const validationSchemaByTpDoc = (type: string) => {
		switch (type) {
			case 'CC':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(5);
				setMaxDocumentFieldLenght(10);
				break;
			case 'TI':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(0);
				setMaxDocumentFieldLenght(12);
				break;
			case 'CE':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(9);
				setMaxDocumentFieldLenght(10);
				break;
			case 'PA':
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(4);
				setMaxDocumentFieldLenght(7);
				break;
			case 'PEP':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(15);
				setMaxDocumentFieldLenght(15);
				break;
			case 'PT':
				setDocumentTypeField('number');
				setMinDocumentFieldLenght(6);
				setMaxDocumentFieldLenght(9);
				break;
			default:
				setDocumentTypeField('text');
				setMinDocumentFieldLenght(0);
				setMaxDocumentFieldLenght(20);
		}
	};
	//TODO
	//validation for candidate document
	const validLengthDocumentField = (document: string) => {
		if (document.length < minDocumentFieldLenght)
			return `${t('Debe tener al menos')} ${minDocumentFieldLenght} ${t('dígitos')}`;

		if (document.length < minDocumentFieldLenght)
			return `${t('Debe tener')} ${maxDocumentFieldLenght} ${t('o menos dígitos')}`;

		if (documentTypeField === 'text')
			if (!document.match(/^[A-Za-z0-9]*$/)) return `${t('Deben ser solo números y letras')}`;

		if (documentTypeField === 'number')
			if (!document.match(/^[0-9]+$/)) return `${t('Deben ser solo números')}`;

		return '';
	};

	const fetchAddressByZipCode = async (zipCode: string) => {
		if (!zipCode) return;

		if (zipCode.length < 8) return;

		try {
			const apiKey = process.env.REACT_APP_API_MAPS;
			const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${apiKey}`;

			const { data } = await axios.get(url);
			const { address_components, geometry } = data.results[0] || {};

			if (!data.erro) {
				const addressSchema = {
					rua: address_components[1]?.long_name || '',
					cidade: address_components[3]?.long_name || '',
					state: address_components[4]?.long_name || '',
					municipio: address_components[2]?.long_name || '',
					pais: 'Brasil',
					longitude: geometry?.location.lng || 0,
					latitude: geometry?.location.lat || 0,
				};

				console.log('addressSchema', addressSchema);

				setFirstUser({
					...firstUser,
					complemento: { ...firstUser.complemento, ...addressSchema, cep: zipCode },
				});

				setFirstCompany({
					...firstCompany,
					company: { ...firstCompany.company, pais: addressSchema.pais },
				});

				/* setFirstCompany({
					...firstCompany,
					company: { ...firstCompany.company, ...addressSchema, codigo_postal: zipCode },
				}); */
			} else {
				console.error('CEP não encontrado');
			}
		} catch (error) {
			console.error('Erro ao consultar o CEP', error);
		}
	};

	return (
		<>
			<Box sx={{ mb: 3 }}>
				<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
					{t('Datos del administrador de la cuenta en esta plataforma')}
				</Typography>
			</Box>
			<Grid container direction='row' justifyContent='space-between' width={'100%'} item={true}>
				<Grid xs={isSmallScreen ? 12 : 6} mb={5} pr={2} item={true}>
					<TextField
						fullWidth
						required
						id='primer_nombre'
						name='primer_nombre'
						label={t('Primer nombre')}
						value={firstUser.complemento['primer_nome']}
						onChange={(e) =>
							setFirstUser({
								...firstUser,
								complemento: { ...firstUser.complemento, primer_nome: e.target.value },
							})
						}
						size='medium'
						error={!isValidFieldRequired(firstUser.complemento['primer_nome'])}
						helperText={
							!isValidFieldRequired(firstUser.complemento['primer_nome'])
								? t('Campo requerido')
								: ''
						}
					/>
				</Grid>
				<Grid xs={isSmallScreen ? 12 : 6} mb={5} pr={2} item={true}>
					<TextField
						fullWidth
						required
						id='primer_apelido'
						name='primer_apelido'
						label='Sobrenome'
						value={firstUser.complemento['primer_apelido']}
						onChange={(e) =>
							setFirstUser({
								...firstUser,
								complemento: { ...firstUser.complemento, primer_apelido: e.target.value },
							})
						}
						size='medium'
						error={!isValidFieldRequired(firstUser.complemento['primer_apelido'])}
						helperText={
							!isValidFieldRequired(firstUser.complemento['primer_apelido'])
								? t('Campo requerido')
								: ''
						}
					/>
				</Grid>
				<Grid xs={isSmallScreen ? 12 : 3} mb={5} pr={2} item={true}>
					<TextField
						fullWidth
						required
						id='CEP'
						name='cep'
						label='CEP'
						value={firstUser.complemento['cep']}
						onChange={(e) => {
							setFirstUser({
								...firstUser,
								complemento: { ...firstUser.complemento, cep: e.target.value },
							});
							fetchAddressByZipCode(e.target.value);
						}}
						size='medium'
					/>
				</Grid>
				<Grid xs={isSmallScreen ? 12 : 3} mb={5} pr={2} item={true}>
					<TextField
						fullWidth
						required
						id='rua'
						name='rua'
						label='Rua'
						value={firstUser.complemento['rua']}
						onChange={(e) =>
							setFirstUser({
								...firstUser,
								complemento: { ...firstUser.complemento, rua: e.target.value },
							})
						}
						size='medium'
					/>
				</Grid>
				<Grid xs={isSmallScreen ? 12 : 3} mb={5} pr={2} item={true}>
					<TextField
						fullWidth
						required
						id='cidade'
						name='cidade'
						label='Cidade'
						value={firstUser.complemento['cidade']}
						onChange={(e) =>
							setFirstUser({
								...firstUser,
								complemento: { ...firstUser.complemento, cidade: e.target.value },
							})
						}
						size='medium'
					/>
				</Grid>
				<Grid xs={isSmallScreen ? 12 : 3} mb={5} pr={2} item={true}>
					<TextField
						fullWidth
						required
						id='complemento'
						name='complemento'
						label='Complemento'
						value={firstUser.complemento['complemento']}
						onChange={(e) =>
							setFirstUser({
								...firstUser,
								complemento: { ...firstUser.complemento, complemento: e.target.value },
							})
						}
						size='medium'
					/>
				</Grid>
				<Grid xs={isSmallScreen ? 12 : 3} mb={5} pr={2} item={true}>
					<FormControl sx={{ minWidth: '100%' }} size='medium'>
						<InputLabel required id='pais'>
							{t('País')}
						</InputLabel>
						<Select
							required
							labelId='pais'
							id='pais'
							label={t('País')}
							value={firstCompany.company['pais']}
							disabled={true}
							onChange={(e) =>
								setFirstCompany({
									...firstCompany,
									company: { ...firstCompany.company, pais: e.target.value },
								})
							}
						>
							{/* <MenuItem key={0} value='CO'>
								{t('Colombia')}
							</MenuItem> */}
							<MenuItem value=''>{t('Selecione')}</MenuItem>
							{ddlPaisResidencia.map(({ id, name }, i) => (
								<MenuItem key={id} value={name}>
									{name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid xs={isSmallScreen ? 12 : 3} mb={5} pr={2} item={true}>
					<FormControl
						sx={{ minWidth: '100%' }}
						size='medium'
						error={!isValidFieldRequired(firstUser.complemento['tipo_doc'])}
					>
						<InputLabel id='tipo_documento'>{t('Tipo de documento')}</InputLabel>
						<Select
							required
							labelId='tipo_documento'
							id='tipo_documento'
							label={t('Tipo de documento')}
							value={firstUser.complemento['tipo_doc']}
							onChange={(e) => {
								firstUser.complemento['numero_doc'] = '';
								validationSchemaByTpDoc(e.target.value);
								setFirstUser({
									...firstUser,
									complemento: { ...firstUser.complemento, tipo_doc: e.target.value },
								});
							}}
						>
							<MenuItem value=''>{t('Selecione')}</MenuItem>
							{ddlTipoDocument.map(({ id, name }, i) => (
								<MenuItem key={i} value={id}>
									{name}
								</MenuItem>
							))}
						</Select>
						{!isValidFieldRequired(firstUser.complemento['tipo_doc']) && (
							<FormHelperText>
								{!isValidFieldRequired(firstUser.complemento['tipo_doc'])
									? t('Campo requerido')
									: ''}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
				<Grid xs={isSmallScreen ? 12 : 3} mb={5} pr={2} item={true}>
					<TextField
						fullWidth
						required
						id='numero_documento'
						name='numero_documento'
						label={t('Número de documento')}
						value={firstUser.complemento['numero_doc']}
						onKeyUp={() => setDocumentExists(false)}
						onChange={(e) => {
							if (e.target.value.length <= maxDocumentFieldLenght)
								setFirstUser({
									...firstUser,
									complemento: { ...firstUser.complemento, numero_doc: e.target.value },
								});
						}}
						type={documentTypeField}
						size='medium'
						inputProps={{
							maxLength: maxDocumentFieldLenght,
							minLength: minDocumentFieldLenght,
						}}
						error={
							!isValidFieldRequired(firstUser.complemento['numero_doc']) ||
							documentExist ||
							validLengthDocumentField(firstUser.complemento['numero_doc']) !== ''
						}
						helperText={
							!isValidFieldRequired(firstUser.complemento['tipo_doc'])
								? t('Primero seleccione el tipo de documento')
								: !isValidFieldRequired(firstUser.complemento['numero_doc'])
								? t('Campo requerido')
								: documentExist
								? t('¡Documento ya registrado!')
								: validLengthDocumentField(firstUser.complemento['numero_doc']) !== ''
								? validLengthDocumentField(firstUser.complemento['numero_doc'])
								: ''
						}
						disabled={!isValidFieldRequired(firstUser.complemento['tipo_doc'])}
					/>
				</Grid>{' '}
				<Grid xs={isSmallScreen ? 12 : 3} mb={5} pr={2} item={true}>
					<TextField
						fullWidth
						id='cargo'
						name='cargo'
						label={t('Cargo')}
						value={firstUser.complemento['cargo']}
						onChange={(e) =>
							setFirstUser({
								...firstUser,
								complemento: { ...firstUser.complemento, cargo: e.target.value },
							})
						}
						size='medium'
					/>
				</Grid>
				<Grid xs={isSmallScreen ? 12 : 4} mb={5} pr={2} item={true}>
					<OverlayTrigger
						placement='right'
						delay={{ show: 250, hide: 400 }}
						overlay={(props) => (
							<Tooltip id='button-tooltip' {...props}>
								{t('Este correo electrónico será el usuário de login')}
							</Tooltip>
						)}
					>
						<TextField
							required={firstUser.user['email'] !== undefined && firstUser.user['email'] !== ''}
							fullWidth
							id='correo_eletronico'
							name='correo_eletronico'
							label={t('Correo electrónico')}
							value={firstUser.user['email']}
							onKeyUp={() => setEmailExists(false)}
							onChange={(e) =>
								setFirstUser({
									...firstUser,
									user: { ...firstUser.user, email: e.target.value },
								})
							}
							size='medium'
							error={
								(firstUser.user['email'] !== undefined &&
									firstUser.user['email'] !== '' &&
									!isValidEmail(firstUser.user['email'])) ||
								emailExists
							}
							helperText={
								firstUser.user['email'] !== undefined &&
								firstUser.user['email'] !== '' &&
								!isValidEmail(firstUser.user['email'])
									? t('Correo electrónico no es válido')
									: emailExists
									? t('¡El usuario ya registrado!')
									: ''
							}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<i
											className='bi bi-question-circle-fill'
											style={{ color: '#2266cc', marginLeft: '5px' }}
										></i>
									</InputAdornment>
								),
							}}
						/>
					</OverlayTrigger>
				</Grid>
				<Grid xs={isSmallScreen ? 12 : 4} mb={5} pr={2} item={true}>
					<TextField
						required={firstUser.user['email'] !== undefined && firstUser.user['email'] !== ''}
						fullWidth
						id='correo_eletronico_confirma'
						name='correo_eletronico_confirma'
						label={t('Confirmar correo electrónico')}
						value={firstUser.user['confirma_email']}
						onChange={(e) =>
							setFirstUser({
								...firstUser,
								user: { ...firstUser.user, confirma_email: e.target.value },
							})
						}
						size='medium'
						error={
							firstUser.user['email'] !== undefined &&
							firstUser.user['email'] !== '' &&
							!(firstUser.user['confirma_email'] === firstUser.user['email'])
						}
						helperText={
							firstUser.user['email'] !== undefined &&
							firstUser.user['email'] !== '' &&
							!(firstUser.user['confirma_email'] === firstUser.user['email'])
								? t(
										'El correo electrónico de confirmación debe ser el mismo que se ingresó anteriormente'
								  )
								: ''
						}
					/>
				</Grid>
				<Grid xs={isSmallScreen ? 12 : 4} mb={5} item={true}>
					<OverlayTrigger
						placement='left'
						delay={{ show: 250, hide: 400 }}
						overlay={(props) => (
							<Tooltip id='button-tooltip' {...props}>
								{t('Código de país + Código de area + Numero del teléfono')}
							</Tooltip>
						)}
					>
						<TextField
							required={
								firstUser.complemento['celular'] !== undefined &&
								firstUser.complemento['celular'] !== ''
							}
							fullWidth
							id='telefono'
							name='telefono'
							label={t('Teléfono')}
							value={firstUser.complemento['celular']}
							onChange={(e) => {
								let val = e.target.value
									?.replaceAll(/[^\d]/g, '')
									?.replaceAll('-', '')
									?.replaceAll('+', '');
								let reg = /[0-9]|\./;
								if (reg.test(val) || val === '') {
									setFirstUser({
										...firstUser,
										complemento: {
											...firstUser.complemento,
											celular: formatMaskPhoneNumber12Digits(val),
										},
									});
								}
							}}
							size='medium'
							error={
								firstUser.complemento['celular'] !== undefined &&
								firstUser.complemento['celular'] !== '' &&
								!isValidPhoneNumber(firstUser.complemento['celular'])
							}
							helperText={
								firstUser.complemento['celular'] !== undefined &&
								firstUser.complemento['celular'] !== '' &&
								!isValidPhoneNumber(firstUser.complemento['celular'])
									? t('Teléfono de contacto no es válido')
									: ''
							}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<i
											className='bi bi-question-circle-fill'
											style={{ color: '#2266cc', marginLeft: '5px' }}
										></i>
									</InputAdornment>
								),
							}}
							inputProps={{
								maxLength: 12,
							}}
						/>
					</OverlayTrigger>
				</Grid>
				<Grid xs={4} pr={2} mb={5}>
					<FormControl sx={{ minWidth: '100%' }} size='medium'>
						<InputLabel id='pregunta_seguridad'>Pergunta de segurança</InputLabel>
						<Select
							labelId='pregunta_seguridad'
							id='pregunta_seguridad'
							label='Pergunta de segurança'
							value={firstUser.complemento['pregunta_seguridad']}
							onChange={(e) =>
								setFirstUser({
									...firstUser,
									complemento: { ...firstUser.complemento, pregunta_seguridad: e.target.value },
								})
							}
						>
							<MenuItem value=''>Selecione</MenuItem>
							{ddlPregunta.map(({ id, name }) => (
								<MenuItem value={name}>{name}</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid xs={8} mb={5}>
					<TextField
						fullWidth
						required
						id='respuesta'
						name='respuesta'
						label='Resposta'
						value={firstUser.complemento['respuesta']}
						onChange={(e) =>
							setFirstUser({
								...firstUser,
								complemento: { ...firstUser.complemento, respuesta: e.target.value },
							})
						}
						size='medium'
					/>
				</Grid>
			</Grid>
			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90 }}
					onClick={() => setStep(2)}
				>
					{t('Atrás')}
				</Button>
				{/*<Box sx={{ flex: '1 1 auto' }} />*/}
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90 }}
					onClick={handleNext}
				>
					{t('Siguiente')}
				</Button>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
					{t(alertMsg)}
				</Alert>
			</Snackbar>
		</>
	);
};
