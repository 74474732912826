import { useContext, useMemo, useState } from 'react';
import { TextField, Grid, Box, Button, Divider, Typography } from '@mui/material';
import { multiStepContext } from '../../../../../contexts/VacancyContext';
import { initValue } from '../../../../../util/Index';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { t } from 'i18next';

export const Step3 = () => {
	const { setStep, userData, setUserData } = useContext(multiStepContext);
	const [wordCount, setWordCount] = useState(initValue(userData['Describa_vacante'])?.length || 0);

	class validationForm {
		Describa_vacante: string = initValue(userData['Describa_vacante']);
	}

	const formSchema = useMemo(
		() =>
			Yup.object().shape({
				Describa_vacante: Yup.string()
					.required('Campo requerido')
					.min(500, 'Descripción muy corta, mínimo 500 caracteres')
					.max(2000, 'Descripción muy extensa, máximo 2000 caracteres'),
			}),
		[]
	);

	const formik = useFormik({
		initialValues: new validationForm(),
		validationSchema: formSchema,
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			try {
				setSubmitting(true);
			} catch (error) {
				setSubmitting(false);
				// setStatus('Informaçoes incorretas')
			}
		},
	});

	return (
		<>
			<Box sx={{ mb: 3 }}>
				<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
					{t('Describa los detalles de la vacante')}
				</Typography>
			</Box>
			<Grid container direction='row' justifyContent='space-between' width={'100%'} item={true}>
				<Grid xs={12} mb={5} pr={2} item={true}>
					<TextField
						fullWidth
						{...formik.getFieldProps('Describa_vacante')}
						error={formik.touched.Describa_vacante && Boolean(formik.errors.Describa_vacante)}
						helperText={
							formik.touched.Describa_vacante ? t(formik.errors.Describa_vacante || '') : ''
						}
						required
						label={t('Descripción')}
						placeholder={t('Describa los detalles de la vacante')}
						onChange={(e) => {
							setUserData({ ...userData, Describa_vacante: e.target.value });
							formik.handleChange(e);
							setWordCount(() => e.target.value.length);
						}}
						multiline
						value={initValue(userData['Describa_vacante'])}
						maxRows={6}
						inputProps={{ maxLength: 2000 }}
					/>
					<p>
						{wordCount} / {2000 - wordCount} {t('caracteres restantes')}{' '}
					</p>
				</Grid>
				<Grid xs={6} mb={5} item={true} />
			</Grid>
			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90 }}
					onClick={() => setStep(2)}
				>
					{t('Atrás')}
				</Button>
				{/*<Box sx={{ flex: '1 1 auto' }} />*/}
				<Button
					variant='contained'
					sx={{ background: '#0A4396', width: 90 }}
					onClick={() => {
						formik.handleSubmit();
						if (formik.isValid) setStep(4);
					}}
				>
					{t('Siguiente')}
				</Button>
			</Box>
		</>
	);
};
