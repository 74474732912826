import { useTranslation } from 'react-i18next';
import styleScss from './styles.module.scss';
import MenuActionCompanyManagement from '../MenuActionCompanyManagement';

export default function CompanyCardComponent({
	data,
	setAlert,
	setAlertMsg,
	setOpen,
	auth,
	updateActivationCompany,
	handleOpenRechazada,
	handleOpenAprobada,
	documentsZip,
	handleOpenModal,
}: any) {
	const { t } = useTranslation();

	return (
		<div className={styleScss.cardMobile}>
			<div className={styleScss['card-body']}>
				<h5 className={styleScss['card-title']}>
					<strong>{t('Empresa')}: </strong>
					{data.company_name.toLowerCase()}
				</h5>
				<p className={styleScss['card-text']}>
					<strong>{t('Documento')}: </strong>
					{data?.document_number}
				</p>
				<p className={styleScss['card-text']}>
					<strong>{t('Responsable')}: </strong>
					{data?.Users.length > 0 ? data.Users[0]?.UserComplement?.first_name : ''}
				</p>
				<p className={styleScss['card-text']}>
					<strong>{t('Correo Electrónico')}: </strong>
					{data?.Users.length > 0 ? data.Users[0]?.email : ''}
				</p>
				<p className={styleScss['card-text']}>
					<strong>{t('Vacantes registradas')}: </strong>
					{data?.CompanyVacancies?.length || 0}
				</p>
				<p className={styleScss['card-text']}>
					<strong>{t('Estado')}: </strong>
					{data.status}
				</p>
				<p className={styleScss['card-text']}>
					<strong>{t('Activo')}: </strong>
					{!!data?.active ? t('Sí') : t('No')}
				</p>
			</div>
			<div className={styleScss.options}>
				<MenuActionCompanyManagement
					data={{ id: data.id, active: data.active }}
					setAlert={setAlert}
					setAlertMsg={setAlertMsg}
					setOpen={setOpen}
					auth={auth}
					updateActivationCompany={updateActivationCompany}
					handleOpenRechazada={handleOpenRechazada}
					handleOpenAprobada={handleOpenAprobada}
					documentsZip={documentsZip}
					handleOpenModal={handleOpenModal}
				/>
			</div>
		</div>
	);
}
