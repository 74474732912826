import { FormEvent, useContext, useState } from 'react';
import {
	Alert,
	Grid,
	Button,
	Box,
	Typography,
	Divider,
	Snackbar,
	CircularProgress,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { multiStepContext } from '../../../../../contexts/CompanyContext';
import ReCAPTCHA from 'react-google-recaptcha';
import { createCompany } from '../../../../../modules/apps/user-management/users-list/core/_requests';
import { isValidFieldRequired } from '../../../../../util/Index';
import { uploadCompany } from '../../../core/_requests';
import { useTranslation } from 'react-i18next';

export const Step6 = () => {
	const { t } = useTranslation();
	const {
		setStep,
		userData,
		setUserData,
		firstUser,
		setFirstUser,
		firstCompany,
		setFirstCompany,
		attachments,
	} = useContext(multiStepContext);
	const [documentType, setDocumentType] = useState('');
	const [loading, setLoading] = useState(false);
	const [verified, setVerified] = useState(false);
	const [open, setOpen] = useState(false);
	const [success, setSuccess] = useState(false);
	const [msgAlert, setMsgAlert] = useState('');
	const [isError, setIsError] = useState(false);
	const navigate = useNavigate();

	//console.log('attachments', attachments);
	// async function handleSubmit(event: FormEvent) {
	// 	event.preventDefault();
	// 	const companyBody = {
	// 		firstCompany,
	// 		firstUser,
	// 	};
	// 	try {
	// 		if (companyBody.firstCompany.company.telefono !== undefined)
	// 			companyBody.firstCompany.company.telefono = (
	// 				companyBody.firstCompany.company.telefono as string
	// 			)
	// 				.trim()
	// 				.replaceAll('+', '')
	// 				.replaceAll('-', '');
	// 		if (companyBody.firstUser.complemento.celular !== undefined)
	// 			companyBody.firstUser.complemento.celular = isValidFieldRequired(
	// 				companyBody.firstUser.complemento?.celular
	// 			)
	// 				? (companyBody.firstUser.complemento?.celular as string)
	// 						.trim()
	// 						.replaceAll('+', '')
	// 						.replaceAll('-', '')
	// 				: '';
	// 		setLoading(true);
	// 		const response = await createCompany(companyBody);
	// 		if (Object.keys(attachments).length > 0) {
	// 			const res = uploadCompanys(response.id);
	// 		} else {
	// 			console.log('response subdepois', response);
	// 			setIsError(false);
	// 			setMsgAlert('¡Email enviado con éxito!');
	// 			setOpen(true);
	// 			setLoading(false);
	// 			setSuccess(true);
	// 			setTimeout(() => {
	// 				navigate('/auth');
	// 			}, 15000);
	// 		}
	// 	} catch (error: any) {
	// 		setOpen(true);
	// 		setIsError(true);
	// 		setMsgAlert(`${error.response.data.error}`);
	// 	}
	// }

	async function handleSubmit(event: FormEvent) {
		event.preventDefault();

		console.log('firstCompany', firstCompany);
		console.log('firstUser', firstUser);

		// Criação do objeto `companyBody` conforme o schema de validação
		const companyBody = {
			firstCompany: {
				company: {
					company_name: firstCompany.company.nombre_sede?.trim(),
				
				business_name: firstCompany.company.nombre_sede?.trim(),
				document_type: firstCompany.company.tipo_documento?.trim(),
				document_number: firstCompany.company.numero_documento?.trim(),
				phone_number: firstCompany.company.telefono?.trim().replaceAll('+', '').replaceAll('-', ''),
				company_type: firstCompany.company.razon_social?.trim(),
				is_headquarters: firstCompany.company.is_headquarters == 'Sim' ? true : false,
				company_description: firstCompany.company.descricion_employer?.trim(),
				street: firstCompany.company.direccion_residencia?.trim(),
				complement: firstCompany.complemento[0]?.complemento,
				city: firstCompany.company.municipio?.trim(),
				state: firstCompany.company.departamento?.trim(),
				district: firstCompany.company.municipio?.trim(),
				country: firstCompany.company.pais?.trim(),
				latitude: firstCompany.company.latitud || null,
				longitude: firstCompany.company.longitud || null,
				number_employees: firstCompany.company.Tamano_empleados || null
			},
			},
			firstUser: {
				user: {
					email: firstUser.user.email?.trim(),
					confirma_email: firstUser.user.confirma_email?.trim(),
					role: firstUser.user.role,
					active: firstUser.user.active,
				},
				complement: {
					document_type: firstUser.complemento.tipo_doc?.trim(),
					document_number: firstUser.complemento.numero_doc?.trim(),
					first_name: firstUser.complemento.primer_nome?.trim(),
					last_name: firstUser.complemento.primer_apelido?.trim(),
					birth_date: null,
					zip_code: firstUser.complemento.cep?.trim(),
					street: firstUser.complemento.rua?.trim(),
					complement: firstUser.complemento.complemento?.trim(),
					city: firstUser.complemento.cidade?.trim(),
					// state: firstUser.complement.state?.trim(),
					// district: firstUser.complement.district?.trim(),
					// country: firstUser.complement.country?.trim(),
					// latitude: firstUser.complement.latitude || null,
					// longitude: firstUser.complement.longitude || null,
					nationality: firstUser.complemento.nacionalidade?.trim(),
					phone_number: firstUser.complemento.celular,
					security_question: firstUser.complemento.security_question?.trim(),
					security_answer: firstUser.complemento.security_answer?.trim(),
					// gender: firstUser.complement.gender?.trim(),
				},
			},
		};

		console.log('companyBodycompanyBodycompanyBodycompanyBodycompanyBody', companyBody);

		try {
			setLoading(true);
			const response = await createCompany(companyBody);
			if (Object.keys(attachments).length > 0) {
				const res = uploadCompanys(response.id);
				console.log('resposta upload', res)
			} else {
				console.log('response subdepois', response);
				setIsError(false);
				setMsgAlert('¡Email enviado con éxito!');
				setOpen(true);
				setLoading(false);
				setSuccess(true);
				setTimeout(() => {
					navigate('/auth');
				}, 15000);
			}
		} catch (error: any) {
			setOpen(true);
			setIsError(true);
			setMsgAlert(`${error.response.data.error}`);
		}
	}

	const uploadCompanys = (id: string) => {
		try {
			let teminou = 0;
			attachments.forEach(async (element: any) => {
				let dataForm = new FormData();
				dataForm.append(`company_id`, id);
				dataForm.append(`file`, element, element.name);
				console.log('Elemento do anexo o nome do desgraçado', element.name)
				console.log('Elemento do anexo', element)
				const res = await uploadCompany(dataForm);

				console.log('response anexo', res);
				teminou++;
				if (Object.keys(attachments).length === teminou) {
					setIsError(false);
					setMsgAlert('¡Email enviado con éxito!');
					setOpen(true);
					setLoading(false);
					setSuccess(true);
					setTimeout(() => {
						navigate('/auth');
					}, 15000);
				}
			});
		} catch (error: any) {
			setOpen(true);
			setIsError(true);
			setMsgAlert(`${error.response.data.error}`);
		}
	};
	const handleClose = () => {
		setOpen(false);
	};

	function onChange(value: any) {
		setVerified(true);
		console.log(success);
	}

	return !success ? (
		<>
			<Grid
				container
				direction='row'
				justifyContent='space-between'
				width='100%'
				sx={{
					backgroundColor: '#1C447F',
					pt: 2,
					pl: 2,
					pb: 2,
					pr: 2,
					borderTopLeftRadius: 6,
					borderTopRightRadius: 6,
				}}
				item
			>
				<Grid container direction='column' item>
					<Typography color='white' variant='h5'>
						{t('Confirma tu registro')}
					</Typography>
				</Grid>

				<Box display='flex' justifyContent='end' alignItems='center'></Box>
			</Grid>

			<Grid xs={12} mb={5} item>
				<div style={{ display: 'flex', justifyContent: 'center', marginTop: 60 }}>
					<ReCAPTCHA
						sitekey='6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
						hl='es'
						onChange={onChange}
					/>
				</div>
			</Grid>
			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90 }}
					onClick={() => setStep(5)}
				>
					{t('Atrás')}
				</Button>

				<Link to='/auth'>
					<Button
						variant='contained'
						disabled={!verified || loading}
						sx={{ mr: 1, width: 90 }}
						onClick={handleSubmit}
					>
						<span style={{ display: 'flex', alignItems: 'center' }}>
							{loading && <CircularProgress size={20} sx={{ marginRight: 2, color: 'white' }} />}
							{t('Enviar')}
						</span>
					</Button>
				</Link>
			</Box>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					variant='filled'
					severity={isError ? 'error' : 'success'}
					sx={{ width: '100%' }}
				>
					{t(msgAlert)}
				</Alert>
			</Snackbar>
		</>
	) : (
		<div
			className='mb-3'
			style={{
				alignContent: 'center',
				textAlign: 'center',
				//marginTop: '15%',
				color: '#3366C8',
				fontWeight: 'bold',
			}}
		>
			<div className='card' style={{ width: '100%' }}>
				<i
					className='bi bi-envelope-check card-img-top mt-5'
					style={{ fontSize: '100px', color: '#60C98C' }}
				></i>
				<div className='card-body'>
					<p className='card-text' style={{ fontSize: '16pt' }}>
						{t(
							'Su solicitación de cadastro está en analisis, muy pronto usted recibirá un correo con sus credenciales de acceso!'
						)}{' '}
						<br />
						{t('Por favor realice el trámite enviado al correo electrónico:')}{' '}
						{firstUser.user.email}
					</p>
				</div>
			</div>
		</div>
	);
};
