import axios from 'axios';
import { AuthModel, UserModel } from './_models';
import { CurricullumFormTypes } from '../components/sections/curriculum-registration/CurricullumFormTypes';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`;
export const LOGIN_URL = `${API_URL}/auth`;
export const REGISTER_URL = `${API_URL}/new_user`;
export const RESET_PASSWORD_URL = `${API_URL}/send_new_pass`;
export const CANDIDATO_MATCHING = `${API_URL}/matching_candidates`;
export const VACANTE_MATCHING = `${API_URL}/vacantes`;
export const CURRICULO_PDF = `${API_URL}/cv_pdf`;
export const VERIFY_DOCUMENT = `${API_URL}/user/verifyDocument`;
export const VERIFY_EMAIL = `${API_URL}/user/verifyEmail`;
export const VERIFY_COMPANY_DOCUMENT = `${API_URL}/company_verify_document`;
export const UPLOAD_COMPANY = `${API_URL}/company_upload`;
export const UPLOAD_PDF = `${API_URL}/upload_cv`;
export const GET_CURRICULUM = `${API_URL}/get_cv_data`;

export const GET_LANGUAGES_LEVEL_LIST = `${API_URL}/level_language`;
export const GET_COURSE_TYPES_LIST = `${API_URL}/course_types`;
export const GET_WORKSHOP_TYPES_LIST = `${API_URL}/workshop_participants`;
export const GET_MARITAL_TYPES_LIST = `${API_URL}/marital_status`;
export const CURRICULO_REQUEST = `${API_URL}/insert_cv_data`;



// Server should return AuthModel
export function login(numero_doc: string, password: string, tipo_doc: string) {
	const loginData: any = {};

	if (tipo_doc === 'EMAIL') {
		console.log('entrou if');
		loginData.email = numero_doc;
	} else {
		loginData.numero_doc = numero_doc;
		console.log('entrou else');
	}

	// if (email) {
	// 	loginData.email = email;
	// }

	// if (numero_doc) {
	// 	loginData.numero_doc = numero_doc;
	// }

	loginData.password = password;

	return axios.post<AuthModel>(LOGIN_URL, loginData);
}

// Server should return AuthModel
export function register(data: any) {
	return axios.post(REGISTER_URL, data);
}

// Server should return object => { result: boolean } (Is Email in DB)
export async function resetPassword(data: any) {
	const res = await axios.post(RESET_PASSWORD_URL, data);
	console.log(res);
	return res;
}

export function getUserByToken(token: string) {
	return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
		api_token: token,
	});
}

export async function getCandidatoMatching() {
	return axios.get(CANDIDATO_MATCHING);
}

export async function getVacanteMatching() {
	return axios.get(VACANTE_MATCHING);
}

export async function getCurriculoPDF() {
	const response = axios.get(CURRICULO_PDF, { responseType: 'blob' });

	return response;
}

export async function getCandidatoMatchingByVacancy(query: string) {
	console.log(`${CANDIDATO_MATCHING}?${query}`);
	return axios.get(`${CANDIDATO_MATCHING}?${query}`).then((d: any) => d);
}

export async function getVerifyDocumentExists(query: string) {
	console.log(`${VERIFY_DOCUMENT}?${query}`);
	return axios.get(`${VERIFY_DOCUMENT}?${query}`).then((d: any) => d);
}

export async function getVerifyEmailExists(query: string) {
	console.log(`${VERIFY_EMAIL}?${query}`);
	return axios.get(`${VERIFY_EMAIL}?${query}`).then((d: any) => d);
}

export async function getVerifyCompanyDocumentExists(query: string) {
	console.log(`${VERIFY_COMPANY_DOCUMENT}?${query}`);
	return axios.get(`${VERIFY_COMPANY_DOCUMENT}?${query}`).then((d: any) => d);
}

export async function uploadCompany(data: any) {
	return axios.post(UPLOAD_COMPANY, data);
}

export async function uploadCurriculum(data: any) {
	const res = await axios.post(UPLOAD_PDF, data,);
	console.log(res);
	return res?.data;
}

export async function getLevelLanguages(data: any) {
	const res = await axios.get(GET_LANGUAGES_LEVEL_LIST, data);
	console.log(res);
	return res;
}

export async function getCourseTypes(data: any) {
	const res = await axios.get(GET_COURSE_TYPES_LIST, data);
	console.log(res);
	return res;
}
export async function getMaritalStatus() {
	const res = await axios.get(GET_MARITAL_TYPES_LIST);
	console.log(res);
	return res;
}
export async function getWorkshopParticipant() {
	const res = await axios.get(GET_WORKSHOP_TYPES_LIST);
	console.log(res);
	return res;
}

export async function createUpdateCurriculum(data: any) {
	const res = axios.post(CURRICULO_REQUEST, data);
	console.log(res);
	return res;
}

export async function getCurriculo() {
	const res = await axios.get(GET_CURRICULUM);
	console.log(res);
	return res.data;
}