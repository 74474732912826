export const ListMunicipioModel = [ 
    { "id_Depar": "5", "id": "5001", "name": "MEDELLÍN" },
   { "id_Depar": "5", "id": "5002", "name": "ABEJORRAL" },
   { "id_Depar": "5", "id": "5004", "name": "ABRIAQUÍ" },
   { "id_Depar": "5", "id": "5021", "name": "ALEJANDRÍA" },
   { "id_Depar": "5", "id": "5030", "name": "AMAGÁ" },
   { "id_Depar": "5", "id": "5031", "name": "AMALFI" },
   { "id_Depar": "5", "id": "5034", "name": "ANDES" },
   { "id_Depar": "5", "id": "5036", "name": "ANGELÓPOLIS" },
   { "id_Depar": "5", "id": "5038", "name": "ANGOSTURA" },
   { "id_Depar": "5", "id": "5040", "name": "ANORÍ" },
   { "id_Depar": "5", "id": "5042", "name": "SANTA FÉ DE ANTIOQUIA" },
   { "id_Depar": "5", "id": "5044", "name": "ANZÁ" },
   { "id_Depar": "5", "id": "5045", "name": "APARTADÓ" },
   { "id_Depar": "5", "id": "5051", "name": "ARBOLETES" },
   { "id_Depar": "5", "id": "5055", "name": "ARGELIA" },
   { "id_Depar": "5", "id": "5059", "name": "ARMENIA" },
   { "id_Depar": "5", "id": "5079", "name": "BARBOSA" },
   { "id_Depar": "5", "id": "5086", "name": "BELMIRA" },
   { "id_Depar": "5", "id": "5088", "name": "BELLO" },
   { "id_Depar": "5", "id": "5091", "name": "BETANIA" },
   { "id_Depar": "5", "id": "5093", "name": "BETULIA" },
   { "id_Depar": "5", "id": "5101", "name": "CIUDAD BOLÍVAR" },
   { "id_Depar": "5", "id": "5107", "name": "BRICEÑO" },
   { "id_Depar": "5", "id": "5113", "name": "BURITICÁ" },
   { "id_Depar": "5", "id": "5120", "name": "CÁCERES" },
   { "id_Depar": "5", "id": "5125", "name": "CAICEDO" },
   { "id_Depar": "5", "id": "5129", "name": "CALDAS" },
   { "id_Depar": "5", "id": "5134", "name": "CAMPAMENTO" },
   { "id_Depar": "5", "id": "5138", "name": "CAÑASGORDAS" },
   { "id_Depar": "5", "id": "5142", "name": "CARACOLÍ" },
   { "id_Depar": "5", "id": "5145", "name": "CARAMANTA" },
   { "id_Depar": "5", "id": "5147", "name": "CAREPA" },
   { "id_Depar": "5", "id": "5148", "name": "EL CARMEN DE VIBORAL" },
   { "id_Depar": "5", "id": "5150", "name": "CAROLINA" },
   { "id_Depar": "5", "id": "5154", "name": "CAUCASIA" },
   { "id_Depar": "5", "id": "5172", "name": "CHIGORODÓ" },
   { "id_Depar": "5", "id": "5190", "name": "CISNEROS" },
   { "id_Depar": "5", "id": "5197", "name": "COCORNÁ" },
   { "id_Depar": "5", "id": "5206", "name": "CONCEPCIÓN" },
   { "id_Depar": "5", "id": "5209", "name": "CONCORDIA" },
   { "id_Depar": "5", "id": "5212", "name": "COPACABANA" },
   { "id_Depar": "5", "id": "5234", "name": "DABEIBA" },
   { "id_Depar": "5", "id": "5237", "name": "DONMATÍAS" },
   { "id_Depar": "5", "id": "5240", "name": "EBÉJICO" },
   { "id_Depar": "5", "id": "5250", "name": "EL BAGRE" },
   { "id_Depar": "5", "id": "5264", "name": "ENTRERRÍOS" },
   { "id_Depar": "5", "id": "5266", "name": "ENVIGADO" },
   { "id_Depar": "5", "id": "5282", "name": "FREDONIA" },
   { "id_Depar": "5", "id": "5284", "name": "FRONTINO" },
   { "id_Depar": "5", "id": "5306", "name": "GIRALDO" },
   { "id_Depar": "5", "id": "5308", "name": "GIRARDOTA" },
   { "id_Depar": "5", "id": "5310", "name": "GÓMEZ PLATA" },
   { "id_Depar": "5", "id": "5313", "name": "GRANADA" },
   { "id_Depar": "5", "id": "5315", "name": "GUADALUPE" },
   { "id_Depar": "5", "id": "5318", "name": "GUARNE" },
   { "id_Depar": "5", "id": "5321", "name": "GUATAPÉ" },
   { "id_Depar": "5", "id": "5347", "name": "HELICONIA" },
   { "id_Depar": "5", "id": "5353", "name": "HISPANIA" },
   { "id_Depar": "5", "id": "5360", "name": "ITAGÜÍ" },
   { "id_Depar": "5", "id": "5361", "name": "ITUANGO" },
   { "id_Depar": "5", "id": "5364", "name": "JARDÍN" },
   { "id_Depar": "5", "id": "5368", "name": "JERICÓ" },
   { "id_Depar": "5", "id": "5376", "name": "LA CEJA" },
   { "id_Depar": "5", "id": "5380", "name": "LA ESTRELLA" },
   { "id_Depar": "5", "id": "5390", "name": "LA PINTADA" },
   { "id_Depar": "5", "id": "5400", "name": "LA UNIÓN" },
   { "id_Depar": "5", "id": "5411", "name": "LIBORINA" },
   { "id_Depar": "5", "id": "5425", "name": "MACEO" },
   { "id_Depar": "5", "id": "5440", "name": "MARINILLA" },
   { "id_Depar": "5", "id": "5467", "name": "MONTEBELLO" },
   { "id_Depar": "5", "id": "5475", "name": "MURINDÓ" },
   { "id_Depar": "5", "id": "5480", "name": "MUTATÁ" },
   { "id_Depar": "5", "id": "5483", "name": "NARIÑO" },
   { "id_Depar": "5", "id": "5490", "name": "NECOCLÍ" },
   { "id_Depar": "5", "id": "5495", "name": "NECHÍ" },
   { "id_Depar": "5", "id": "5501", "name": "OLAYA" },
   { "id_Depar": "5", "id": "5541", "name": "PEÑOL" },
   { "id_Depar": "5", "id": "5543", "name": "PEQUE" },
   { "id_Depar": "5", "id": "5576", "name": "PUEBLORRICO" },
   { "id_Depar": "5", "id": "5579", "name": "PUERTO BERRÍO" },
   { "id_Depar": "5", "id": "5585", "name": "PUERTO NARE" },
   { "id_Depar": "5", "id": "5591", "name": "PUERTO TRIUNFO" },
   { "id_Depar": "5", "id": "5604", "name": "REMEDIOS" },
   { "id_Depar": "5", "id": "5607", "name": "RETIRO" },
   { "id_Depar": "5", "id": "5615", "name": "RIONEGRO" },
   { "id_Depar": "5", "id": "5628", "name": "SABANALARGA" },
   { "id_Depar": "5", "id": "5631", "name": "SABANETA" },
   { "id_Depar": "5", "id": "5642", "name": "SALGAR" },
   { "id_Depar": "5", "id": "5647", "name": "SAN ANDRÉS DE CUERQUÍA" },
   { "id_Depar": "5", "id": "5649", "name": "SAN CARLOS" },
   { "id_Depar": "5", "id": "5652", "name": "SAN FRANCISCO" },
   { "id_Depar": "5", "id": "5656", "name": "SAN JERÓNIMO" },
   { "id_Depar": "5", "id": "5658", "name": "SAN JOSÉ DE LA MONTAÑA" },
   { "id_Depar": "5", "id": "5659", "name": "SAN JUAN DE URABÁ" },
   { "id_Depar": "5", "id": "5660", "name": "SAN LUIS" },
   { "id_Depar": "5", "id": "5664", "name": "SAN PEDRO DE LOS MILAGROS" },
   { "id_Depar": "5", "id": "5665", "name": "SAN PEDRO DE URABÁ" },
   { "id_Depar": "5", "id": "5667", "name": "SAN RAFAEL" },
   { "id_Depar": "5", "id": "5670", "name": "SAN ROQUE" },
   { "id_Depar": "5", "id": "5674", "name": "SAN VICENTE FERRER" },
   { "id_Depar": "5", "id": "5679", "name": "SANTA BÁRBARA" },
   { "id_Depar": "5", "id": "5686", "name": "SANTA ROSA DE OSOS" },
   { "id_Depar": "5", "id": "5690", "name": "SANTO DOMINGO" },
   { "id_Depar": "5", "id": "5697", "name": "EL SANTUARIO" },
   { "id_Depar": "5", "id": "5736", "name": "SEGOVIA" },
   { "id_Depar": "5", "id": "5756", "name": "SONSÓN" },
   { "id_Depar": "5", "id": "5761", "name": "SOPETRÁN" },
   { "id_Depar": "5", "id": "5789", "name": "TÁMESIS" },
   { "id_Depar": "5", "id": "5790", "name": "TARAZÁ" },
   { "id_Depar": "5", "id": "5792", "name": "TARSO" },
   { "id_Depar": "5", "id": "5809", "name": "TITIRIBÍ" },
   { "id_Depar": "5", "id": "5819", "name": "TOLEDO" },
   { "id_Depar": "5", "id": "5837", "name": "TURBO" },
   { "id_Depar": "5", "id": "5842", "name": "URAMITA" },
   { "id_Depar": "5", "id": "5847", "name": "URRAO" },
   { "id_Depar": "5", "id": "5854", "name": "VALDIVIA" },
   { "id_Depar": "5", "id": "5856", "name": "VALPARAÍSO" },
   { "id_Depar": "5", "id": "5858", "name": "VEGACHÍ" },
   { "id_Depar": "5", "id": "5861", "name": "VENECIA" },
   { "id_Depar": "5", "id": "5873", "name": "VIGÍA DEL FUERTE" },
   { "id_Depar": "5", "id": "5885", "name": "YALÍ" },
   { "id_Depar": "5", "id": "5887", "name": "YARUMAL" },
   { "id_Depar": "5", "id": "5890", "name": "YOLOMBÓ" },
   { "id_Depar": "5", "id": "5893", "name": "YONDÓ" },
   { "id_Depar": "5", "id": "5895", "name": "ZARAGOZA" },
   { "id_Depar": "8", "id": "8001", "name": "BARRANQUILLA" },
   { "id_Depar": "8", "id": "8078", "name": "BARANOA" },
   { "id_Depar": "8", "id": "8137", "name": "CAMPO DE LA CRUZ" },
   { "id_Depar": "8", "id": "8141", "name": "CANDELARIA" },
   { "id_Depar": "8", "id": "8296", "name": "GALAPA" },
   { "id_Depar": "8", "id": "8372", "name": "JUAN DE ACOSTA" },
   { "id_Depar": "8", "id": "8421", "name": "LURUACO" },
   { "id_Depar": "8", "id": "8433", "name": "MALAMBO" },
   { "id_Depar": "8", "id": "8436", "name": "MANATÍ" },
   { "id_Depar": "8", "id": "8520", "name": "PALMAR DE VARELA" },
   { "id_Depar": "8", "id": "8549", "name": "PIOJÓ" },
   { "id_Depar": "8", "id": "8558", "name": "POLONUEVO" },
   { "id_Depar": "8", "id": "8560", "name": "PONEDERA" },
   { "id_Depar": "8", "id": "8573", "name": "PUERTO COLOMBIA" },
   { "id_Depar": "8", "id": "8606", "name": "REPELÓN" },
   { "id_Depar": "8", "id": "8634", "name": "SABANAGRANDE" },
   { "id_Depar": "8", "id": "8638", "name": "SABANALARGA" },
   { "id_Depar": "8", "id": "8675", "name": "SANTA LUCÍA" },
   { "id_Depar": "8", "id": "8685", "name": "SANTO TOMÁS" },
   { "id_Depar": "8", "id": "8758", "name": "SOLEDAD" },
   { "id_Depar": "8", "id": "8770", "name": "SUAN" },
   { "id_Depar": "8", "id": "8832", "name": "TUBARÁ" },
   { "id_Depar": "8", "id": "8849", "name": "USIACURÍ" },
   { "id_Depar": "11", "id": "11001", "name": "BOGOTÁ, D.C." },
   { "id_Depar": "13", "id": "13001", "name": "CARTAGENA DE INDIAS" },
   { "id_Depar": "13", "id": "13006", "name": "ACHÍ" },
   { "id_Depar": "13", "id": "13030", "name": "ALTOS DEL ROSARIO" },
   { "id_Depar": "13", "id": "13042", "name": "ARENAL" },
   { "id_Depar": "13", "id": "13052", "name": "ARJONA" },
   { "id_Depar": "13", "id": "13062", "name": "ARROYOHONDO" },
   { "id_Depar": "13", "id": "13074", "name": "BARRANCO DE LOBA" },
   { "id_Depar": "13", "id": "13140", "name": "CALAMAR" },
   { "id_Depar": "13", "id": "13160", "name": "CANTAGALLO" },
   { "id_Depar": "13", "id": "13188", "name": "CICUCO" },
   { "id_Depar": "13", "id": "13212", "name": "CÓRDOBA" },
   { "id_Depar": "13", "id": "13222", "name": "CLEMENCIA" },
   { "id_Depar": "13", "id": "13244", "name": "EL CARMEN DE BOLÍVAR" },
   { "id_Depar": "13", "id": "13248", "name": "EL GUAMO" },
   { "id_Depar": "13", "id": "13268", "name": "EL PEÑÓN" },
   { "id_Depar": "13", "id": "13300", "name": "HATILLO DE LOBA" },
   { "id_Depar": "13", "id": "13430", "name": "MAGANGUÉ" },
   { "id_Depar": "13", "id": "13433", "name": "MAHATES" },
   { "id_Depar": "13", "id": "13440", "name": "MARGARITA" },
   { "id_Depar": "13", "id": "13442", "name": "MARÍA LA BAJA" },
   { "id_Depar": "13", "id": "13458", "name": "MONTECRISTO" },
   { "id_Depar": "13", "id": "13468", "name": "SANTA CRUZ DE MOMPOX" },
   { "id_Depar": "13", "id": "13473", "name": "MORALES" },
   { "id_Depar": "13", "id": "13490", "name": "NOROSÍ" },
   { "id_Depar": "13", "id": "13549", "name": "PINILLOS" },
   { "id_Depar": "13", "id": "13580", "name": "REGIDOR" },
   { "id_Depar": "13", "id": "13600", "name": "RÍO VIEJO" },
   { "id_Depar": "13", "id": "13620", "name": "SAN CRISTÓBAL" },
   { "id_Depar": "13", "id": "13647", "name": "SAN ESTANISLAO" },
   { "id_Depar": "13", "id": "13650", "name": "SAN FERNANDO" },
   { "id_Depar": "13", "id": "13654", "name": "SAN JACINTO" },
   { "id_Depar": "13", "id": "13655", "name": "SAN JACINTO DEL CAUCA" },
   { "id_Depar": "13", "id": "13657", "name": "SAN JUAN NEPOMUCENO" },
   { "id_Depar": "13", "id": "13667", "name": "SAN MARTÍN DE LOBA" },
   { "id_Depar": "13", "id": "13670", "name": "SAN PABLO" },
   { "id_Depar": "13", "id": "13673", "name": "SANTA CATALINA" },
   { "id_Depar": "13", "id": "13683", "name": "SANTA ROSA" },
   { "id_Depar": "13", "id": "13688", "name": "SANTA ROSA DEL SUR" },
   { "id_Depar": "13", "id": "13744", "name": "SIMITÍ" },
   { "id_Depar": "13", "id": "13760", "name": "SOPLAVIENTO" },
   { "id_Depar": "13", "id": "13780", "name": "TALAIGUA NUEVO" },
   { "id_Depar": "13", "id": "13810", "name": "TIQUISIO" },
   { "id_Depar": "13", "id": "13836", "name": "TURBACO" },
   { "id_Depar": "13", "id": "13838", "name": "TURBANÁ" },
   { "id_Depar": "13", "id": "13873", "name": "VILLANUEVA" },
   { "id_Depar": "13", "id": "13894", "name": "ZAMBRANO" },
   { "id_Depar": "15", "id": "15001", "name": "TUNJA" },
   { "id_Depar": "15", "id": "15022", "name": "ALMEIDA" },
   { "id_Depar": "15", "id": "15047", "name": "AQUITANIA" },
   { "id_Depar": "15", "id": "15051", "name": "ARCABUCO" },
   { "id_Depar": "15", "id": "15087", "name": "BELÉN" },
   { "id_Depar": "15", "id": "15090", "name": "BERBEO" },
   { "id_Depar": "15", "id": "15092", "name": "BETÉITIVA" },
   { "id_Depar": "15", "id": "15097", "name": "BOAVITA" },
   { "id_Depar": "15", "id": "15104", "name": "BOYACÁ" },
   { "id_Depar": "15", "id": "15106", "name": "BRICEÑO" },
   { "id_Depar": "15", "id": "15109", "name": "BUENAVISTA" },
   { "id_Depar": "15", "id": "15114", "name": "BUSBANZÁ" },
   { "id_Depar": "15", "id": "15131", "name": "CALDAS" },
   { "id_Depar": "15", "id": "15135", "name": "CAMPOHERMOSO" },
   { "id_Depar": "15", "id": "15162", "name": "CERINZA" },
   { "id_Depar": "15", "id": "15172", "name": "CHINAVITA" },
   { "id_Depar": "15", "id": "15176", "name": "CHIQUINQUIRÁ" },
   { "id_Depar": "15", "id": "15180", "name": "CHISCAS" },
   { "id_Depar": "15", "id": "15183", "name": "CHITA" },
   { "id_Depar": "15", "id": "15185", "name": "CHITARAQUE" },
   { "id_Depar": "15", "id": "15187", "name": "CHIVATÁ" },
   { "id_Depar": "15", "id": "15189", "name": "CIÉNEGA" },
   { "id_Depar": "15", "id": "15204", "name": "CÓMBITA" },
   { "id_Depar": "15", "id": "15212", "name": "COPER" },
   { "id_Depar": "15", "id": "15215", "name": "CORRALES" },
   { "id_Depar": "15", "id": "15218", "name": "COVARACHÍA" },
   { "id_Depar": "15", "id": "15223", "name": "CUBARÁ" },
   { "id_Depar": "19", "id": "19318", "name": "GUAPI" },
   { "id_Depar": "15", "id": "15224", "name": "CUCAITA" },
   { "id_Depar": "15", "id": "15226", "name": "CUÍTIVA" },
   { "id_Depar": "15", "id": "15232", "name": "CHÍQUIZA" },
   { "id_Depar": "15", "id": "15236", "name": "CHIVOR" },
   { "id_Depar": "15", "id": "15238", "name": "DUITAMA" },
   { "id_Depar": "15", "id": "15244", "name": "EL COCUY" },
   { "id_Depar": "15", "id": "15248", "name": "EL ESPINO" },
   { "id_Depar": "15", "id": "15272", "name": "FIRAVITOBA" },
   { "id_Depar": "15", "id": "15276", "name": "FLORESTA" },
   { "id_Depar": "15", "id": "15293", "name": "GACHANTIVÁ" },
   { "id_Depar": "15", "id": "15296", "name": "GÁMEZA" },
   { "id_Depar": "15", "id": "15299", "name": "GARAGOA" },
   { "id_Depar": "15", "id": "15317", "name": "GUACAMAYAS" },
   { "id_Depar": "15", "id": "15322", "name": "GUATEQUE" },
   { "id_Depar": "15", "id": "15325", "name": "GUAYATÁ" },
   { "id_Depar": "15", "id": "15332", "name": "GÜICÁN DE LA SIERRA" },
   { "id_Depar": "15", "id": "15362", "name": "IZA" },
   { "id_Depar": "15", "id": "15367", "name": "JENESANO" },
   { "id_Depar": "15", "id": "15368", "name": "JERICÓ" },
   { "id_Depar": "15", "id": "15377", "name": "LABRANZAGRANDE" },
   { "id_Depar": "15", "id": "15380", "name": "LA CAPILLA" },
   { "id_Depar": "15", "id": "15401", "name": "LA VICTORIA" },
   { "id_Depar": "15", "id": "15403", "name": "LA UVITA" },
   { "id_Depar": "15", "id": "15407", "name": "VILLA DE LEYVA" },
   { "id_Depar": "15", "id": "15425", "name": "MACANAL" },
   { "id_Depar": "15", "id": "15442", "name": "MARIPÍ" },
   { "id_Depar": "15", "id": "15455", "name": "MIRAFLORES" },
   { "id_Depar": "15", "id": "15464", "name": "MONGUA" },
   { "id_Depar": "15", "id": "15466", "name": "MONGUÍ" },
   { "id_Depar": "15", "id": "15469", "name": "MONIQUIRÁ" },
   { "id_Depar": "15", "id": "15476", "name": "MOTAVITA" },
   { "id_Depar": "15", "id": "15480", "name": "MUZO" },
   { "id_Depar": "15", "id": "15491", "name": "NOBSA" },
   { "id_Depar": "15", "id": "15494", "name": "NUEVO COLÓN" },
   { "id_Depar": "15", "id": "15500", "name": "OICATÁ" },
   { "id_Depar": "15", "id": "15507", "name": "OTANCHE" },
   { "id_Depar": "15", "id": "15511", "name": "PACHAVITA" },
   { "id_Depar": "15", "id": "15514", "name": "PÁEZ" },
   { "id_Depar": "15", "id": "15516", "name": "PAIPA" },
   { "id_Depar": "15", "id": "15518", "name": "PAJARITO" },
   { "id_Depar": "15", "id": "15522", "name": "PANQUEBA" },
   { "id_Depar": "15", "id": "15531", "name": "PAUNA" },
   { "id_Depar": "15", "id": "15533", "name": "PAYA" },
   { "id_Depar": "15", "id": "15537", "name": "PAZ DE RÍO" },
   { "id_Depar": "15", "id": "15542", "name": "PESCA" },
   { "id_Depar": "15", "id": "15550", "name": "PISBA" },
   { "id_Depar": "15", "id": "15572", "name": "PUERTO BOYACÁ" },
   { "id_Depar": "15", "id": "15580", "name": "QUÍPAMA" },
   { "id_Depar": "15", "id": "15599", "name": "RAMIRIQUÍ" },
   { "id_Depar": "15", "id": "15600", "name": "RÁQUIRA" },
   { "id_Depar": "15", "id": "15621", "name": "RONDÓN" },
   { "id_Depar": "15", "id": "15632", "name": "SABOYÁ" },
   { "id_Depar": "15", "id": "15638", "name": "SÁCHICA" },
   { "id_Depar": "15", "id": "15646", "name": "SAMACÁ" },
   { "id_Depar": "15", "id": "15660", "name": "SAN EDUARDO" },
   { "id_Depar": "15", "id": "15664", "name": "SAN JOSÉ DE PARE" },
   { "id_Depar": "15", "id": "15667", "name": "SAN LUIS DE GACENO" },
   { "id_Depar": "15", "id": "15673", "name": "SAN MATEO" },
   { "id_Depar": "15", "id": "15676", "name": "SAN MIGUEL DE SEMA" },
   { "id_Depar": "15", "id": "15681", "name": "SAN PABLO DE BORBUR" },
   { "id_Depar": "15", "id": "15686", "name": "SANTANA" },
   { "id_Depar": "15", "id": "15690", "name": "SANTA MARÍA" },
   { "id_Depar": "15", "id": "15693", "name": "SANTA ROSA DE VITERBO" },
   { "id_Depar": "15", "id": "15696", "name": "SANTA SOFÍA" },
   { "id_Depar": "15", "id": "15720", "name": "SATIVANORTE" },
   { "id_Depar": "15", "id": "15723", "name": "SATIVASUR" },
   { "id_Depar": "15", "id": "15740", "name": "SIACHOQUE" },
   { "id_Depar": "15", "id": "15753", "name": "SOATÁ" },
   { "id_Depar": "15", "id": "15755", "name": "SOCOTÁ" },
   { "id_Depar": "15", "id": "15757", "name": "SOCHA" },
   { "id_Depar": "15", "id": "15759", "name": "SOGAMOSO" },
   { "id_Depar": "15", "id": "15761", "name": "SOMONDOCO" },
   { "id_Depar": "15", "id": "15762", "name": "SORA" },
   { "id_Depar": "15", "id": "15763", "name": "SOTAQUIRÁ" },
   { "id_Depar": "15", "id": "15764", "name": "SORACÁ" },
   { "id_Depar": "15", "id": "15774", "name": "SUSACÓN" },
   { "id_Depar": "15", "id": "15776", "name": "SUTAMARCHÁN" },
   { "id_Depar": "15", "id": "15778", "name": "SUTATENZA" },
   { "id_Depar": "15", "id": "15790", "name": "TASCO" },
   { "id_Depar": "15", "id": "15798", "name": "TENZA" },
   { "id_Depar": "15", "id": "15804", "name": "TIBANÁ" },
   { "id_Depar": "15", "id": "15806", "name": "TIBASOSA" },
   { "id_Depar": "15", "id": "15808", "name": "TINJACÁ" },
   { "id_Depar": "15", "id": "15810", "name": "TIPACOQUE" },
   { "id_Depar": "15", "id": "15814", "name": "TOCA" },
   { "id_Depar": "15", "id": "15816", "name": "TOGÜÍ" },
   { "id_Depar": "15", "id": "15820", "name": "TÓPAGA" },
   { "id_Depar": "15", "id": "15822", "name": "TOTA" },
   { "id_Depar": "15", "id": "15832", "name": "TUNUNGUÁ" },
   { "id_Depar": "15", "id": "15835", "name": "TURMEQUÉ" },
   { "id_Depar": "15", "id": "15837", "name": "TUTA" },
   { "id_Depar": "15", "id": "15839", "name": "TUTAZÁ" },
   { "id_Depar": "15", "id": "15842", "name": "ÚMBITA" },
   { "id_Depar": "15", "id": "15861", "name": "VENTAQUEMADA" },
   { "id_Depar": "15", "id": "15879", "name": "VIRACACHÁ" },
   { "id_Depar": "15", "id": "15897", "name": "ZETAQUIRA" },
   { "id_Depar": "17", "id": "17001", "name": "MANIZALES" },
   { "id_Depar": "17", "id": "17013", "name": "AGUADAS" },
   { "id_Depar": "17", "id": "17042", "name": "ANSERMA" },
   { "id_Depar": "17", "id": "17050", "name": "ARANZAZU" },
   { "id_Depar": "17", "id": "17088", "name": "BELALCÁZAR" },
   { "id_Depar": "17", "id": "17174", "name": "CHINCHINÁ" },
   { "id_Depar": "17", "id": "17272", "name": "FILADELFIA" },
   { "id_Depar": "17", "id": "17380", "name": "LA DORADA" },
   { "id_Depar": "17", "id": "17388", "name": "LA MERCED" },
   { "id_Depar": "17", "id": "17433", "name": "MANZANARES" },
   { "id_Depar": "17", "id": "17442", "name": "MARMATO" },
   { "id_Depar": "17", "id": "17444", "name": "MARQUETALIA" },
   { "id_Depar": "17", "id": "17446", "name": "MARULANDA" },
   { "id_Depar": "17", "id": "17486", "name": "NEIRA" },
   { "id_Depar": "17", "id": "17495", "name": "NORCASIA" },
   { "id_Depar": "17", "id": "17513", "name": "PÁCORA" },
   { "id_Depar": "17", "id": "17524", "name": "PALESTINA" },
   { "id_Depar": "17", "id": "17541", "name": "PENSILVANIA" },
   { "id_Depar": "17", "id": "17614", "name": "RIOSUCIO" },
   { "id_Depar": "17", "id": "17616", "name": "RISARALDA" },
   { "id_Depar": "17", "id": "17653", "name": "SALAMINA" },
   { "id_Depar": "17", "id": "17662", "name": "SAMANÁ" },
   { "id_Depar": "17", "id": "17665", "name": "SAN JOSÉ" },
   { "id_Depar": "17", "id": "17777", "name": "SUPÍA" },
   { "id_Depar": "17", "id": "17867", "name": "VICTORIA" },
   { "id_Depar": "17", "id": "17873", "name": "VILLAMARÍA" },
   { "id_Depar": "17", "id": "17877", "name": "VITERBO" },
   { "id_Depar": "18", "id": "18001", "name": "FLORENCIA" },
   { "id_Depar": "18", "id": "18029", "name": "ALBANIA" },
   { "id_Depar": "18", "id": "18094", "name": "BELÉN DE LOS ANDAQUÍES" },
   { "id_Depar": "18", "id": "18150", "name": "CARTAGENA DEL CHAIRÁ" },
   { "id_Depar": "18", "id": "18205", "name": "CURILLO" },
   { "id_Depar": "18", "id": "18247", "name": "EL DONCELLO" },
   { "id_Depar": "18", "id": "18256", "name": "EL PAUJÍL" },
   { "id_Depar": "18", "id": "18410", "name": "LA MONTAÑITA" },
   { "id_Depar": "18", "id": "18460", "name": "MILÁN" },
   { "id_Depar": "18", "id": "18479", "name": "MORELIA" },
   { "id_Depar": "18", "id": "18592", "name": "PUERTO RICO" },
   { "id_Depar": "18", "id": "18610", "name": "SAN JOSÉ DEL FRAGUA" },
   { "id_Depar": "18", "id": "18753", "name": "SAN VICENTE DEL CAGUÁN" },
   { "id_Depar": "18", "id": "18756", "name": "SOLANO" },
   { "id_Depar": "18", "id": "18785", "name": "SOLITA" },
   { "id_Depar": "18", "id": "18860", "name": "VALPARAÍSO" },
   { "id_Depar": "19", "id": "19001", "name": "POPAYÁN" },
   { "id_Depar": "19", "id": "19022", "name": "ALMAGUER" },
   { "id_Depar": "19", "id": "19050", "name": "ARGELIA" },
   { "id_Depar": "19", "id": "19075", "name": "BALBOA" },
   { "id_Depar": "19", "id": "19100", "name": "BOLÍVAR" },
   { "id_Depar": "19", "id": "19110", "name": "BUENOS AIRES" },
   { "id_Depar": "19", "id": "19130", "name": "CAJIBÍO" },
   { "id_Depar": "19", "id": "19137", "name": "CALDONO" },
   { "id_Depar": "19", "id": "19142", "name": "CALOTO" },
   { "id_Depar": "19", "id": "19212", "name": "CORINTO" },
   { "id_Depar": "19", "id": "19256", "name": "EL TAMBO" },
   { "id_Depar": "19", "id": "19290", "name": "FLORENCIA" },
   { "id_Depar": "19", "id": "19300", "name": "GUACHENÉ" },
   { "id_Depar": "19", "id": "19355", "name": "INZÁ" },
   { "id_Depar": "19", "id": "19364", "name": "JAMBALÓ" },
   { "id_Depar": "19", "id": "19392", "name": "LA SIERRA" },
   { "id_Depar": "19", "id": "19397", "name": "LA VEGA" },
   { "id_Depar": "19", "id": "19418", "name": "LÓPEZ DE MICAY" },
   { "id_Depar": "19", "id": "19450", "name": "MERCADERES" },
   { "id_Depar": "19", "id": "19455", "name": "MIRANDA" },
   { "id_Depar": "19", "id": "19473", "name": "MORALES" },
   { "id_Depar": "19", "id": "19513", "name": "PADILLA" },
   { "id_Depar": "19", "id": "19517", "name": "PÁEZ" },
   { "id_Depar": "19", "id": "19532", "name": "PATÍA" },
   { "id_Depar": "19", "id": "19533", "name": "PIAMONTE" },
   { "id_Depar": "19", "id": "19548", "name": "PIENDAMÓ - TUNÍA" },
   { "id_Depar": "19", "id": "19573", "name": "PUERTO TEJADA" },
   { "id_Depar": "19", "id": "19585", "name": "PURACÉ" },
   { "id_Depar": "19", "id": "19622", "name": "ROSAS" },
   { "id_Depar": "19", "id": "19693", "name": "SAN SEBASTIÁN" },
   { "id_Depar": "19", "id": "19698", "name": "SANTANDER DE QUILICHAO" },
   { "id_Depar": "19", "id": "19701", "name": "SANTA ROSA" },
   { "id_Depar": "19", "id": "19743", "name": "SILVIA" },
   { "id_Depar": "19", "id": "19760", "name": "SOTARÁ PAISPAMBA" },
   { "id_Depar": "19", "id": "19780", "name": "SUÁREZ" },
   { "id_Depar": "19", "id": "19785", "name": "SUCRE" },
   { "id_Depar": "19", "id": "19807", "name": "TIMBÍO" },
   { "id_Depar": "19", "id": "19809", "name": "TIMBIQUÍ" },
   { "id_Depar": "19", "id": "19821", "name": "TORIBÍO" },
   { "id_Depar": "19", "id": "19824", "name": "TOTORÓ" },
   { "id_Depar": "19", "id": "19845", "name": "VILLA RICA" },
   { "id_Depar": "20", "id": "20001", "name": "VALLEDUPAR" },
   { "id_Depar": "20", "id": "20011", "name": "AGUACHICA" },
   { "id_Depar": "20", "id": "20013", "name": "AGUSTÍN CODAZZI" },
   { "id_Depar": "20", "id": "20032", "name": "ASTREA" },
   { "id_Depar": "20", "id": "20045", "name": "BECERRIL" },
   { "id_Depar": "20", "id": "20060", "name": "BOSCONIA" },
   { "id_Depar": "20", "id": "20175", "name": "CHIMICHAGUA" },
   { "id_Depar": "20", "id": "20178", "name": "CHIRIGUANÁ" },
   { "id_Depar": "20", "id": "20228", "name": "CURUMANÍ" },
   { "id_Depar": "20", "id": "20238", "name": "EL COPEY" },
   { "id_Depar": "20", "id": "20250", "name": "EL PASO" },
   { "id_Depar": "20", "id": "20295", "name": "GAMARRA" },
   { "id_Depar": "20", "id": "20310", "name": "GONZÁLEZ" },
   { "id_Depar": "20", "id": "20383", "name": "LA GLORIA" },
   { "id_Depar": "20", "id": "20400", "name": "LA JAGUA DE IBIRICO" },
   { "id_Depar": "20", "id": "20443", "name": "MANAURE BALCÓN DEL CESAR" },
   { "id_Depar": "20", "id": "20517", "name": "PAILITAS" },
   { "id_Depar": "20", "id": "20550", "name": "PELAYA" },
   { "id_Depar": "20", "id": "20570", "name": "PUEBLO BELLO" },
   { "id_Depar": "20", "id": "20614", "name": "RÍO DE ORO" },
   { "id_Depar": "20", "id": "20621", "name": "LA PAZ" },
   { "id_Depar": "20", "id": "20710", "name": "SAN ALBERTO" },
   { "id_Depar": "20", "id": "20750", "name": "SAN DIEGO" },
   { "id_Depar": "20", "id": "20770", "name": "SAN MARTÍN" },
   { "id_Depar": "20", "id": "20787", "name": "TAMALAMEQUE" },
   { "id_Depar": "23", "id": "23001", "name": "MONTERÍA" },
   { "id_Depar": "23", "id": "23068", "name": "AYAPEL" },
   { "id_Depar": "23", "id": "23079", "name": "BUENAVISTA" },
   { "id_Depar": "23", "id": "23090", "name": "CANALETE" },
   { "id_Depar": "23", "id": "23162", "name": "CERETÉ" },
   { "id_Depar": "23", "id": "23168", "name": "CHIMÁ" },
   { "id_Depar": "23", "id": "23182", "name": "CHINÚ" },
   { "id_Depar": "23", "id": "23189", "name": "CIÉNAGA DE ORO" },
   { "id_Depar": "23", "id": "23300", "name": "COTORRA" },
   { "id_Depar": "23", "id": "23350", "name": "LA APARTADA" },
   { "id_Depar": "23", "id": "23417", "name": "LORICA" },
   { "id_Depar": "23", "id": "23419", "name": "LOS CÓRDOBAS" },
   { "id_Depar": "23", "id": "23464", "name": "MOMIL" },
   { "id_Depar": "23", "id": "23466", "name": "MONTELÍBANO" },
   { "id_Depar": "23", "id": "23500", "name": "MOÑITOS" },
   { "id_Depar": "23", "id": "23555", "name": "PLANETA RICA" },
   { "id_Depar": "23", "id": "23570", "name": "PUEBLO NUEVO" },
   { "id_Depar": "23", "id": "23574", "name": "PUERTO ESCONDIDO" },
   { "id_Depar": "23", "id": "23580", "name": "PUERTO LIBERTADOR" },
   { "id_Depar": "23", "id": "23586", "name": "PURÍSIMA DE LA CONCEPCIÓN" },
   { "id_Depar": "23", "id": "23660", "name": "SAHAGÚN" },
   { "id_Depar": "23", "id": "23670", "name": "SAN ANDRÉS DE SOTAVENTO" },
   { "id_Depar": "23", "id": "23672", "name": "SAN ANTERO" },
   { "id_Depar": "23", "id": "23675", "name": "SAN BERNARDO DEL VIENTO" },
   { "id_Depar": "23", "id": "23678", "name": "SAN CARLOS" },
   { "id_Depar": "23", "id": "23682", "name": "SAN JOSÉ DE URÉ" },
   { "id_Depar": "23", "id": "23686", "name": "SAN PELAYO" },
   { "id_Depar": "23", "id": "23807", "name": "TIERRALTA" },
   { "id_Depar": "23", "id": "23815", "name": "TUCHÍN" },
   { "id_Depar": "23", "id": "23855", "name": "VALENCIA" },
   { "id_Depar": "25", "id": "25001", "name": "AGUA DE DIOS" },
   { "id_Depar": "25", "id": "25019", "name": "ALBÁN" },
   { "id_Depar": "25", "id": "25035", "name": "ANAPOIMA" },
   { "id_Depar": "25", "id": "25040", "name": "ANOLAIMA" },
   { "id_Depar": "25", "id": "25053", "name": "ARBELÁEZ" },
   { "id_Depar": "25", "id": "25086", "name": "BELTRÁN" },
   { "id_Depar": "25", "id": "25095", "name": "BITUIMA" },
   { "id_Depar": "25", "id": "25099", "name": "BOJACÁ" },
   { "id_Depar": "25", "id": "25120", "name": "CABRERA" },
   { "id_Depar": "25", "id": "25123", "name": "CACHIPAY" },
   { "id_Depar": "25", "id": "25126", "name": "CAJICÁ" },
   { "id_Depar": "25", "id": "25148", "name": "CAPARRAPÍ" },
   { "id_Depar": "25", "id": "25151", "name": "CÁQUEZA" },
   { "id_Depar": "25", "id": "25154", "name": "CARMEN DE CARUPA" },
   { "id_Depar": "25", "id": "25168", "name": "CHAGUANÍ" },
   { "id_Depar": "25", "id": "25175", "name": "CHÍA" },
   { "id_Depar": "25", "id": "25178", "name": "CHIPAQUE" },
   { "id_Depar": "25", "id": "25181", "name": "CHOACHÍ" },
   { "id_Depar": "25", "id": "25183", "name": "CHOCONTÁ" },
   { "id_Depar": "25", "id": "25200", "name": "COGUA" },
   { "id_Depar": "25", "id": "25214", "name": "COTA" },
   { "id_Depar": "25", "id": "25224", "name": "CUCUNUBÁ" },
   { "id_Depar": "25", "id": "25245", "name": "EL COLEGIO" },
   { "id_Depar": "25", "id": "25258", "name": "EL PEÑÓN" },
   { "id_Depar": "25", "id": "25260", "name": "EL ROSAL" },
   { "id_Depar": "25", "id": "25269", "name": "FACATATIVÁ" },
   { "id_Depar": "25", "id": "25279", "name": "FÓMEQUE" },
   { "id_Depar": "25", "id": "25281", "name": "FOSCA" },
   { "id_Depar": "25", "id": "25286", "name": "FUNZA" },
   { "id_Depar": "25", "id": "25288", "name": "FÚQUENE" },
   { "id_Depar": "25", "id": "25290", "name": "FUSAGASUGÁ" },
   { "id_Depar": "25", "id": "25293", "name": "GACHALÁ" },
   { "id_Depar": "25", "id": "25295", "name": "GACHANCIPÁ" },
   { "id_Depar": "25", "id": "25297", "name": "GACHETÁ" },
   { "id_Depar": "25", "id": "25299", "name": "GAMA" },
   { "id_Depar": "25", "id": "25307", "name": "GIRARDOT" },
   { "id_Depar": "25", "id": "25312", "name": "GRANADA" },
   { "id_Depar": "25", "id": "25317", "name": "GUACHETÁ" },
   { "id_Depar": "25", "id": "25320", "name": "GUADUAS" },
   { "id_Depar": "25", "id": "25322", "name": "GUASCA" },
   { "id_Depar": "25", "id": "25324", "name": "GUATAQUÍ" },
   { "id_Depar": "25", "id": "25326", "name": "GUATAVITA" },
   { "id_Depar": "25", "id": "25328", "name": "GUAYABAL DE SÍQUIMA" },
   { "id_Depar": "25", "id": "25335", "name": "GUAYABETAL" },
   { "id_Depar": "25", "id": "25339", "name": "GUTIÉRREZ" },
   { "id_Depar": "25", "id": "25368", "name": "JERUSALÉN" },
   { "id_Depar": "25", "id": "25372", "name": "JUNÍN" },
   { "id_Depar": "25", "id": "25377", "name": "LA CALERA" },
   { "id_Depar": "25", "id": "25386", "name": "LA MESA" },
   { "id_Depar": "25", "id": "25394", "name": "LA PALMA" },
   { "id_Depar": "25", "id": "25398", "name": "LA PEÑA" },
   { "id_Depar": "25", "id": "25402", "name": "LA VEGA" },
   { "id_Depar": "25", "id": "25407", "name": "LENGUAZAQUE" },
   { "id_Depar": "25", "id": "25426", "name": "MACHETÁ" },
   { "id_Depar": "25", "id": "25430", "name": "MADRID" },
   { "id_Depar": "25", "id": "25436", "name": "MANTA" },
   { "id_Depar": "25", "id": "25438", "name": "MEDINA" },
   { "id_Depar": "25", "id": "25473", "name": "MOSQUERA" },
   { "id_Depar": "25", "id": "25483", "name": "NARIÑO" },
   { "id_Depar": "25", "id": "25486", "name": "NEMOCÓN" },
   { "id_Depar": "25", "id": "25488", "name": "NILO" },
   { "id_Depar": "25", "id": "25489", "name": "NIMAIMA" },
   { "id_Depar": "25", "id": "25491", "name": "NOCAIMA" },
   { "id_Depar": "25", "id": "25506", "name": "VENECIA" },
   { "id_Depar": "25", "id": "25513", "name": "PACHO" },
   { "id_Depar": "25", "id": "25518", "name": "PAIME" },
   { "id_Depar": "25", "id": "25524", "name": "PANDI" },
   { "id_Depar": "25", "id": "25530", "name": "PARATEBUENO" },
   { "id_Depar": "25", "id": "25535", "name": "PASCA" },
   { "id_Depar": "25", "id": "25572", "name": "PUERTO SALGAR" },
   { "id_Depar": "25", "id": "25580", "name": "PULÍ" },
   { "id_Depar": "25", "id": "25592", "name": "QUEBRADANEGRA" },
   { "id_Depar": "25", "id": "25594", "name": "QUETAME" },
   { "id_Depar": "25", "id": "25596", "name": "QUIPILE" },
   { "id_Depar": "25", "id": "25599", "name": "APULO" },
   { "id_Depar": "25", "id": "25612", "name": "RICAURTE" },
   { "id_Depar": "25", "id": "25645", "name": "SAN ANTONIO DEL TEQUENDAMA" },
   { "id_Depar": "25", "id": "25649", "name": "SAN BERNARDO" },
   { "id_Depar": "25", "id": "25653", "name": "SAN CAYETANO" },
   { "id_Depar": "25", "id": "25658", "name": "SAN FRANCISCO" },
   { "id_Depar": "25", "id": "25662", "name": "SAN JUAN DE RIOSECO" },
   { "id_Depar": "25", "id": "25718", "name": "SASAIMA" },
   { "id_Depar": "25", "id": "25736", "name": "SESQUILÉ" },
   { "id_Depar": "25", "id": "25740", "name": "SIBATÉ" },
   { "id_Depar": "25", "id": "25743", "name": "SILVANIA" },
   { "id_Depar": "25", "id": "25745", "name": "SIMIJACA" },
   { "id_Depar": "25", "id": "25754", "name": "SOACHA" },
   { "id_Depar": "25", "id": "25758", "name": "SOPÓ" },
   { "id_Depar": "25", "id": "25769", "name": "SUBACHOQUE" },
   { "id_Depar": "25", "id": "25772", "name": "SUESCA" },
   { "id_Depar": "25", "id": "25777", "name": "SUPATÁ" },
   { "id_Depar": "25", "id": "25779", "name": "SUSA" },
   { "id_Depar": "25", "id": "25781", "name": "SUTATAUSA" },
   { "id_Depar": "25", "id": "25785", "name": "TABIO" },
   { "id_Depar": "25", "id": "25793", "name": "TAUSA" },
   { "id_Depar": "25", "id": "25797", "name": "TENA" },
   { "id_Depar": "25", "id": "25799", "name": "TENJO" },
   { "id_Depar": "25", "id": "25805", "name": "TIBACUY" },
   { "id_Depar": "25", "id": "25807", "name": "TIBIRITA" },
   { "id_Depar": "25", "id": "25815", "name": "TOCAIMA" },
   { "id_Depar": "25", "id": "25817", "name": "TOCANCIPÁ" },
   { "id_Depar": "25", "id": "25823", "name": "TOPAIPÍ" },
   { "id_Depar": "25", "id": "25839", "name": "UBALÁ" },
   { "id_Depar": "25", "id": "25841", "name": "UBAQUE" },
   { "id_Depar": "25", "id": "25843", "name": "VILLA DE SAN DIEGO DE UBATÉ" },
   { "id_Depar": "25", "id": "25845", "name": "UNE" },
   { "id_Depar": "25", "id": "25851", "name": "ÚTICA" },
   { "id_Depar": "25", "id": "25862", "name": "VERGARA" },
   { "id_Depar": "25", "id": "25867", "name": "VIANÍ" },
   { "id_Depar": "25", "id": "25871", "name": "VILLAGÓMEZ" },
   { "id_Depar": "25", "id": "25873", "name": "VILLAPINZÓN" },
   { "id_Depar": "25", "id": "25875", "name": "VILLETA" },
   { "id_Depar": "25", "id": "25878", "name": "VIOTÁ" },
   { "id_Depar": "25", "id": "25885", "name": "YACOPÍ" },
   { "id_Depar": "25", "id": "25898", "name": "ZIPACÓN" },
   { "id_Depar": "25", "id": "25899", "name": "ZIPAQUIRÁ" },
   { "id_Depar": "27", "id": "27001", "name": "QUIBDÓ" },
   { "id_Depar": "27", "id": "27006", "name": "ACANDÍ" },
   { "id_Depar": "27", "id": "27025", "name": "ALTO BAUDÓ" },
   { "id_Depar": "27", "id": "27050", "name": "ATRATO" },
   { "id_Depar": "27", "id": "27073", "name": "BAGADÓ" },
   { "id_Depar": "27", "id": "27075", "name": "BAHÍA SOLANO" },
   { "id_Depar": "27", "id": "27077", "name": "BAJO BAUDÓ" },
   { "id_Depar": "27", "id": "27099", "name": "BOJAYÁ" },
   { "id_Depar": "27", "id": "27135", "name": "EL CANTÓN DEL SAN PABLO" },
   { "id_Depar": "27", "id": "27150", "name": "CARMEN DEL DARIÉN" },
   { "id_Depar": "27", "id": "27160", "name": "CÉRTEGUI" },
   { "id_Depar": "27", "id": "27205", "name": "CONDOTO" },
   { "id_Depar": "27", "id": "27245", "name": "EL CARMEN DE ATRATO" },
   { "id_Depar": "27", "id": "27250", "name": "EL LITORAL DEL SAN JUAN" },
   { "id_Depar": "27", "id": "27361", "name": "ISTMINA" },
   { "id_Depar": "27", "id": "27372", "name": "JURADÓ" },
   { "id_Depar": "27", "id": "27413", "name": "LLORÓ" },
   { "id_Depar": "27", "id": "27425", "name": "MEDIO ATRATO" },
   { "id_Depar": "27", "id": "27430", "name": "MEDIO BAUDÓ" },
   { "id_Depar": "27", "id": "27450", "name": "MEDIO SAN JUAN" },
   { "id_Depar": "27", "id": "27491", "name": "NÓVITA" },
   { "id_Depar": "27", "id": "27495", "name": "NUQUÍ" },
   { "id_Depar": "27", "id": "27580", "name": "RÍO IRÓ" },
   { "id_Depar": "27", "id": "27600", "name": "RÍO QUITO" },
   { "id_Depar": "27", "id": "27615", "name": "RIOSUCIO" },
   { "id_Depar": "27", "id": "27660", "name": "SAN JOSÉ DEL PALMAR" },
   { "id_Depar": "27", "id": "27745", "name": "SIPÍ" },
   { "id_Depar": "27", "id": "27787", "name": "TADÓ" },
   { "id_Depar": "27", "id": "27800", "name": "UNGUÍA" },
   { "id_Depar": "27", "id": "27810", "name": "UNIÓN PANAMERICANA" },
   { "id_Depar": "41", "id": "41001", "name": "NEIVA" },
   { "id_Depar": "41", "id": "41006", "name": "ACEVEDO" },
   { "id_Depar": "41", "id": "41013", "name": "AGRADO" },
   { "id_Depar": "41", "id": "41016", "name": "AIPE" },
   { "id_Depar": "41", "id": "41020", "name": "ALGECIRAS" },
   { "id_Depar": "41", "id": "41026", "name": "ALTAMIRA" },
   { "id_Depar": "41", "id": "41078", "name": "BARAYA" },
   { "id_Depar": "41", "id": "41132", "name": "CAMPOALEGRE" },
   { "id_Depar": "41", "id": "41206", "name": "COLOMBIA" },
   { "id_Depar": "41", "id": "41244", "name": "ELÍAS" },
   { "id_Depar": "41", "id": "41298", "name": "GARZÓN" },
   { "id_Depar": "41", "id": "41306", "name": "GIGANTE" },
   { "id_Depar": "41", "id": "41319", "name": "GUADALUPE" },
   { "id_Depar": "41", "id": "41349", "name": "HOBO" },
   { "id_Depar": "41", "id": "41357", "name": "ÍQUIRA" },
   { "id_Depar": "41", "id": "41359", "name": "ISNOS" },
   { "id_Depar": "41", "id": "41378", "name": "LA ARGENTINA" },
   { "id_Depar": "41", "id": "41396", "name": "LA PLATA" },
   { "id_Depar": "41", "id": "41483", "name": "NÁTAGA" },
   { "id_Depar": "41", "id": "41503", "name": "OPORAPA" },
   { "id_Depar": "41", "id": "41518", "name": "PAICOL" },
   { "id_Depar": "41", "id": "41524", "name": "PALERMO" },
   { "id_Depar": "41", "id": "41530", "name": "PALESTINA" },
   { "id_Depar": "41", "id": "41548", "name": "PITAL" },
   { "id_Depar": "41", "id": "41551", "name": "PITALITO" },
   { "id_Depar": "41", "id": "41615", "name": "RIVERA" },
   { "id_Depar": "41", "id": "41660", "name": "SALADOBLANCO" },
   { "id_Depar": "41", "id": "41668", "name": "SAN AGUSTÍN" },
   { "id_Depar": "41", "id": "41676", "name": "SANTA MARÍA" },
   { "id_Depar": "41", "id": "41770", "name": "SUAZA" },
   { "id_Depar": "41", "id": "41791", "name": "TARQUI" },
   { "id_Depar": "41", "id": "41797", "name": "TESALIA" },
   { "id_Depar": "41", "id": "41799", "name": "TELLO" },
   { "id_Depar": "41", "id": "41801", "name": "TERUEL" },
   { "id_Depar": "41", "id": "41807", "name": "TIMANÁ" },
   { "id_Depar": "41", "id": "41872", "name": "VILLAVIEJA" },
   { "id_Depar": "41", "id": "41885", "name": "YAGUARÁ" },
   { "id_Depar": "44", "id": "44001", "name": "RIOHACHA" },
   { "id_Depar": "44", "id": "44035", "name": "ALBANIA" },
   { "id_Depar": "44", "id": "44078", "name": "BARRANCAS" },
   { "id_Depar": "44", "id": "44090", "name": "DIBULLA" },
   { "id_Depar": "44", "id": "44098", "name": "DISTRACCIÓN" },
   { "id_Depar": "44", "id": "44110", "name": "EL MOLINO" },
   { "id_Depar": "44", "id": "44279", "name": "FONSECA" },
   { "id_Depar": "44", "id": "44378", "name": "HATONUEVO" },
   { "id_Depar": "44", "id": "44420", "name": "LA JAGUA DEL PILAR" },
   { "id_Depar": "44", "id": "44430", "name": "MAICAO" },
   { "id_Depar": "44", "id": "44560", "name": "MANAURE" },
   { "id_Depar": "44", "id": "44650", "name": "SAN JUAN DEL CESAR" },
   { "id_Depar": "44", "id": "44847", "name": "URIBIA" },
   { "id_Depar": "44", "id": "44855", "name": "URUMITA" },
   { "id_Depar": "44", "id": "44874", "name": "VILLANUEVA" },
   { "id_Depar": "47", "id": "47001", "name": "SANTA MARTA" },
   { "id_Depar": "47", "id": "47030", "name": "ALGARROBO" },
   { "id_Depar": "47", "id": "47053", "name": "ARACATACA" },
   { "id_Depar": "47", "id": "47058", "name": "ARIGUANÍ" },
   { "id_Depar": "47", "id": "47161", "name": "CERRO DE SAN ANTONIO" },
   { "id_Depar": "47", "id": "47170", "name": "CHIVOLO" },
   { "id_Depar": "47", "id": "47189", "name": "CIÉNAGA" },
   { "id_Depar": "47", "id": "47205", "name": "CONCORDIA" },
   { "id_Depar": "47", "id": "47245", "name": "EL BANCO" },
   { "id_Depar": "47", "id": "47258", "name": "EL PIÑÓN" },
   { "id_Depar": "47", "id": "47268", "name": "EL RETÉN" },
   { "id_Depar": "47", "id": "47288", "name": "FUNDACIÓN" },
   { "id_Depar": "47", "id": "47318", "name": "GUAMAL" },
   { "id_Depar": "47", "id": "47460", "name": "NUEVA GRANADA" },
   { "id_Depar": "47", "id": "47541", "name": "PEDRAZA" },
   { "id_Depar": "47", "id": "47545", "name": "PIJIÑO DEL CARMEN" },
   { "id_Depar": "47", "id": "47551", "name": "PIVIJAY" },
   { "id_Depar": "47", "id": "47555", "name": "PLATO" },
   { "id_Depar": "47", "id": "47570", "name": "PUEBLOVIEJO" },
   { "id_Depar": "47", "id": "47605", "name": "REMOLINO" },
   { "id_Depar": "47", "id": "47660", "name": "SABANAS DE SAN ÁNGEL" },
   { "id_Depar": "47", "id": "47675", "name": "SALAMINA" },
   { "id_Depar": "47", "id": "47692", "name": "SAN SEBASTIÁN DE BUENAVISTA" },
   { "id_Depar": "47", "id": "47703", "name": "SAN ZENÓN" },
   { "id_Depar": "47", "id": "47707", "name": "SANTA ANA" },
   { "id_Depar": "47", "id": "47720", "name": "SANTA BÁRBARA DE PINTO" },
   { "id_Depar": "47", "id": "47745", "name": "SITIONUEVO" },
   { "id_Depar": "47", "id": "47798", "name": "TENERIFE" },
   { "id_Depar": "47", "id": "47960", "name": "ZAPAYÁN" },
   { "id_Depar": "47", "id": "47980", "name": "ZONA BANANERA" },
   { "id_Depar": "50", "id": "50001", "name": "VILLAVICENCIO" },
   { "id_Depar": "50", "id": "50006", "name": "ACACÍAS" },
   { "id_Depar": "50", "id": "50110", "name": "BARRANCA DE UPÍA" },
   { "id_Depar": "50", "id": "50124", "name": "CABUYARO" },
   { "id_Depar": "50", "id": "50150", "name": "CASTILLA LA NUEVA" },
   { "id_Depar": "50", "id": "50223", "name": "CUBARRAL" },
   { "id_Depar": "50", "id": "50226", "name": "CUMARAL" },
   { "id_Depar": "50", "id": "50245", "name": "EL CALVARIO" },
   { "id_Depar": "50", "id": "50251", "name": "EL CASTILLO" },
   { "id_Depar": "50", "id": "50270", "name": "EL DORADO" },
   { "id_Depar": "50", "id": "50287", "name": "FUENTE DE ORO" },
   { "id_Depar": "50", "id": "50313", "name": "GRANADA" },
   { "id_Depar": "50", "id": "50318", "name": "GUAMAL" },
   { "id_Depar": "50", "id": "50325", "name": "MAPIRIPÁN" },
   { "id_Depar": "50", "id": "50330", "name": "MESETAS" },
   { "id_Depar": "50", "id": "50350", "name": "LA MACARENA" },
   { "id_Depar": "50", "id": "50370", "name": "URIBE" },
   { "id_Depar": "50", "id": "50400", "name": "LEJANÍAS" },
   { "id_Depar": "50", "id": "50450", "name": "PUERTO CONCORDIA" },
   { "id_Depar": "50", "id": "50568", "name": "PUERTO GAITÁN" },
   { "id_Depar": "50", "id": "50573", "name": "PUERTO LÓPEZ" },
   { "id_Depar": "50", "id": "50577", "name": "PUERTO LLERAS" },
   { "id_Depar": "50", "id": "50590", "name": "PUERTO RICO" },
   { "id_Depar": "50", "id": "50606", "name": "RESTREPO" },
   { "id_Depar": "50", "id": "50680", "name": "SAN CARLOS DE GUAROA" },
   { "id_Depar": "50", "id": "50683", "name": "SAN JUAN DE ARAMA" },
   { "id_Depar": "50", "id": "50686", "name": "SAN JUANITO" },
   { "id_Depar": "50", "id": "50689", "name": "SAN MARTÍN" },
   { "id_Depar": "50", "id": "50711", "name": "VISTAHERMOSA" },
   { "id_Depar": "52", "id": "52001", "name": "PASTO" },
   { "id_Depar": "52", "id": "52019", "name": "ALBÁN" },
   { "id_Depar": "52", "id": "52022", "name": "ALDANA" },
   { "id_Depar": "52", "id": "52036", "name": "ANCUYA" },
   { "id_Depar": "52", "id": "52051", "name": "ARBOLEDA" },
   { "id_Depar": "52", "id": "52079", "name": "BARBACOAS" },
   { "id_Depar": "52", "id": "52083", "name": "BELÉN" },
   { "id_Depar": "52", "id": "52110", "name": "BUESACO" },
   { "id_Depar": "52", "id": "52203", "name": "COLÓN" },
   { "id_Depar": "52", "id": "52207", "name": "CONSACÁ" },
   { "id_Depar": "52", "id": "52210", "name": "CONTADERO" },
   { "id_Depar": "52", "id": "52215", "name": "CÓRDOBA" },
   { "id_Depar": "52", "id": "52224", "name": "CUASPUD CARLOSAMA" },
   { "id_Depar": "52", "id": "52227", "name": "CUMBAL" },
   { "id_Depar": "52", "id": "52233", "name": "CUMBITARA" },
   { "id_Depar": "52", "id": "52240", "name": "CHACHAGÜÍ" },
   { "id_Depar": "52", "id": "52250", "name": "EL CHARCO" },
   { "id_Depar": "52", "id": "52254", "name": "EL PEÑOL" },
   { "id_Depar": "52", "id": "52256", "name": "EL ROSARIO" },
   { "id_Depar": "52", "id": "52258", "name": "EL TABLÓN DE GÓMEZ" },
   { "id_Depar": "52", "id": "52260", "name": "EL TAMBO" },
   { "id_Depar": "52", "id": "52287", "name": "FUNES" },
   { "id_Depar": "52", "id": "52317", "name": "GUACHUCAL" },
   { "id_Depar": "52", "id": "52320", "name": "GUAITARILLA" },
   { "id_Depar": "52", "id": "52323", "name": "GUALMATÁN" },
   { "id_Depar": "52", "id": "52352", "name": "ILES" },
   { "id_Depar": "52", "id": "52354", "name": "IMUÉS" },
   { "id_Depar": "52", "id": "52356", "name": "IPIALES" },
   { "id_Depar": "52", "id": "52378", "name": "LA CRUZ" },
   { "id_Depar": "52", "id": "52381", "name": "LA FLORIDA" },
   { "id_Depar": "52", "id": "52385", "name": "LA LLANADA" },
   { "id_Depar": "52", "id": "52390", "name": "LA TOLA" },
   { "id_Depar": "52", "id": "52399", "name": "LA UNIÓN" },
   { "id_Depar": "52", "id": "52405", "name": "LEIVA" },
   { "id_Depar": "52", "id": "52411", "name": "LINARES" },
   { "id_Depar": "52", "id": "52418", "name": "LOS ANDES" },
   { "id_Depar": "52", "id": "52427", "name": "MAGÜÍ" },
   { "id_Depar": "52", "id": "52435", "name": "MALLAMA" },
   { "id_Depar": "52", "id": "52473", "name": "MOSQUERA" },
   { "id_Depar": "52", "id": "52480", "name": "NARIÑO" },
   { "id_Depar": "52", "id": "52490", "name": "OLAYA HERRERA" },
   { "id_Depar": "52", "id": "52506", "name": "OSPINA" },
   { "id_Depar": "52", "id": "52520", "name": "FRANCISCO PIZARRO" },
   { "id_Depar": "52", "id": "52540", "name": "POLICARPA" },
   { "id_Depar": "52", "id": "52560", "name": "POTOSÍ" },
   { "id_Depar": "52", "id": "52565", "name": "PROVIDENCIA" },
   { "id_Depar": "52", "id": "52573", "name": "PUERRES" },
   { "id_Depar": "52", "id": "52585", "name": "PUPIALES" },
   { "id_Depar": "52", "id": "52612", "name": "RICAURTE" },
   { "id_Depar": "52", "id": "52621", "name": "ROBERTO PAYÁN" },
   { "id_Depar": "52", "id": "52678", "name": "SAMANIEGO" },
   { "id_Depar": "52", "id": "52683", "name": "SANDONÁ" },
   { "id_Depar": "52", "id": "52685", "name": "SAN BERNARDO" },
   { "id_Depar": "52", "id": "52687", "name": "SAN LORENZO" },
   { "id_Depar": "52", "id": "52693", "name": "SAN PABLO" },
   { "id_Depar": "52", "id": "52694", "name": "SAN PEDRO DE CARTAGO" },
   { "id_Depar": "52", "id": "52696", "name": "SANTA BÁRBARA" },
   { "id_Depar": "52", "id": "52699", "name": "SANTACRUZ" },
   { "id_Depar": "52", "id": "52720", "name": "SAPUYES" },
   { "id_Depar": "52", "id": "52786", "name": "TAMINANGO" },
   { "id_Depar": "52", "id": "52788", "name": "TANGUA" },
   { "id_Depar": "52", "id": "52835", "name": "SAN ANDRÉS DE TUMACO" },
   { "id_Depar": "52", "id": "52838", "name": "TÚQUERRES" },
   { "id_Depar": "52", "id": "52885", "name": "YACUANQUER" },
   { "id_Depar": "54", "id": "54001", "name": "SAN JOSÉ DE CÚCUTA" },
   { "id_Depar": "54", "id": "54003", "name": "ÁBREGO" },
   { "id_Depar": "54", "id": "54051", "name": "ARBOLEDAS" },
   { "id_Depar": "54", "id": "54099", "name": "BOCHALEMA" },
   { "id_Depar": "54", "id": "54109", "name": "BUCARASICA" },
   { "id_Depar": "54", "id": "54125", "name": "CÁCOTA" },
   { "id_Depar": "54", "id": "54128", "name": "CÁCHIRA" },
   { "id_Depar": "54", "id": "54172", "name": "CHINÁCOTA" },
   { "id_Depar": "54", "id": "54174", "name": "CHITAGÁ" },
   { "id_Depar": "54", "id": "54206", "name": "CONVENCIÓN" },
   { "id_Depar": "54", "id": "54223", "name": "CUCUTILLA" },
   { "id_Depar": "54", "id": "54239", "name": "DURANIA" },
   { "id_Depar": "54", "id": "54245", "name": "EL CARMEN" },
   { "id_Depar": "54", "id": "54250", "name": "EL TARRA" },
   { "id_Depar": "54", "id": "54261", "name": "EL ZULIA" },
   { "id_Depar": "54", "id": "54313", "name": "GRAMALOTE" },
   { "id_Depar": "54", "id": "54344", "name": "HACARÍ" },
   { "id_Depar": "54", "id": "54347", "name": "HERRÁN" },
   { "id_Depar": "54", "id": "54377", "name": "LABATECA" },
   { "id_Depar": "54", "id": "54385", "name": "LA ESPERANZA" },
   { "id_Depar": "54", "id": "54398", "name": "LA PLAYA" },
   { "id_Depar": "54", "id": "54405", "name": "LOS PATIOS" },
   { "id_Depar": "54", "id": "54418", "name": "LOURDES" },
   { "id_Depar": "54", "id": "54480", "name": "MUTISCUA" },
   { "id_Depar": "54", "id": "54498", "name": "OCAÑA" },
   { "id_Depar": "54", "id": "54518", "name": "PAMPLONA" },
   { "id_Depar": "54", "id": "54520", "name": "PAMPLONITA" },
   { "id_Depar": "54", "id": "54553", "name": "PUERTO SANTANDER" },
   { "id_Depar": "54", "id": "54599", "name": "RAGONVALIA" },
   { "id_Depar": "54", "id": "54660", "name": "SALAZAR" },
   { "id_Depar": "54", "id": "54670", "name": "SAN CALIXTO" },
   { "id_Depar": "54", "id": "54673", "name": "SAN CAYETANO" },
   { "id_Depar": "54", "id": "54680", "name": "SANTIAGO" },
   { "id_Depar": "54", "id": "54720", "name": "SARDINATA" },
   { "id_Depar": "54", "id": "54743", "name": "SILOS" },
   { "id_Depar": "54", "id": "54800", "name": "TEORAMA" },
   { "id_Depar": "54", "id": "54810", "name": "TIBÚ" },
   { "id_Depar": "54", "id": "54820", "name": "TOLEDO" },
   { "id_Depar": "54", "id": "54871", "name": "VILLA CARO" },
   { "id_Depar": "54", "id": "54874", "name": "VILLA DEL ROSARIO" },
   { "id_Depar": "63", "id": "63001", "name": "ARMENIA" },
   { "id_Depar": "63", "id": "63111", "name": "BUENAVISTA" },
   { "id_Depar": "63", "id": "63130", "name": "CALARCÁ" },
   { "id_Depar": "63", "id": "63190", "name": "CIRCASIA" },
   { "id_Depar": "63", "id": "63212", "name": "CÓRDOBA" },
   { "id_Depar": "63", "id": "63272", "name": "FILANDIA" },
   { "id_Depar": "63", "id": "63302", "name": "GÉNOVA" },
   { "id_Depar": "63", "id": "63401", "name": "LA TEBAIDA" },
   { "id_Depar": "63", "id": "63470", "name": "MONTENEGRO" },
   { "id_Depar": "63", "id": "63548", "name": "PIJAO" },
   { "id_Depar": "63", "id": "63594", "name": "QUIMBAYA" },
   { "id_Depar": "63", "id": "63690", "name": "SALENTO" },
   { "id_Depar": "66", "id": "66001", "name": "PEREIRA" },
   { "id_Depar": "66", "id": "66045", "name": "APÍA" },
   { "id_Depar": "66", "id": "66075", "name": "BALBOA" },
   { "id_Depar": "66", "id": "66088", "name": "BELÉN DE UMBRÍA" },
   { "id_Depar": "66", "id": "66170", "name": "DOSQUEBRADAS" },
   { "id_Depar": "66", "id": "66318", "name": "GUÁTICA" },
   { "id_Depar": "66", "id": "66383", "name": "LA CELIA" },
   { "id_Depar": "66", "id": "66400", "name": "LA VIRGINIA" },
   { "id_Depar": "66", "id": "66440", "name": "MARSELLA" },
   { "id_Depar": "66", "id": "66456", "name": "MISTRATÓ" },
   { "id_Depar": "66", "id": "66572", "name": "PUEBLO RICO" },
   { "id_Depar": "66", "id": "66594", "name": "QUINCHÍA" },
   { "id_Depar": "66", "id": "66682", "name": "SANTA ROSA DE CABAL" },
   { "id_Depar": "66", "id": "66687", "name": "SANTUARIO" },
   { "id_Depar": "68", "id": "68001", "name": "BUCARAMANGA" },
   { "id_Depar": "68", "id": "68013", "name": "AGUADA" },
   { "id_Depar": "68", "id": "68020", "name": "ALBANIA" },
   { "id_Depar": "68", "id": "68051", "name": "ARATOCA" },
   { "id_Depar": "68", "id": "68077", "name": "BARBOSA" },
   { "id_Depar": "68", "id": "68079", "name": "BARICHARA" },
   { "id_Depar": "68", "id": "68081", "name": "BARRANCABERMEJA" },
   { "id_Depar": "68", "id": "68092", "name": "BETULIA" },
   { "id_Depar": "68", "id": "68101", "name": "BOLÍVAR" },
   { "id_Depar": "68", "id": "68121", "name": "CABRERA" },
   { "id_Depar": "68", "id": "68132", "name": "CALIFORNIA" },
   { "id_Depar": "68", "id": "68147", "name": "CAPITANEJO" },
   { "id_Depar": "68", "id": "68152", "name": "CARCASÍ" },
   { "id_Depar": "68", "id": "68160", "name": "CEPITÁ" },
   { "id_Depar": "68", "id": "68162", "name": "CERRITO" },
   { "id_Depar": "68", "id": "68167", "name": "CHARALÁ" },
   { "id_Depar": "68", "id": "68169", "name": "CHARTA" },
   { "id_Depar": "68", "id": "68176", "name": "CHIMA" },
   { "id_Depar": "68", "id": "68179", "name": "CHIPATÁ" },
   { "id_Depar": "68", "id": "68190", "name": "CIMITARRA" },
   { "id_Depar": "68", "id": "68207", "name": "CONCEPCIÓN" },
   { "id_Depar": "68", "id": "68209", "name": "CONFINES" },
   { "id_Depar": "68", "id": "68211", "name": "CONTRATACIÓN" },
   { "id_Depar": "68", "id": "68217", "name": "COROMORO" },
   { "id_Depar": "68", "id": "68229", "name": "CURITÍ" },
   { "id_Depar": "68", "id": "68235", "name": "EL CARMEN DE CHUCURI" },
   { "id_Depar": "68", "id": "68245", "name": "EL GUACAMAYO" },
   { "id_Depar": "68", "id": "68250", "name": "EL PEÑÓN" },
   { "id_Depar": "68", "id": "68255", "name": "EL PLAYÓN" },
   { "id_Depar": "68", "id": "68264", "name": "ENCINO" },
   { "id_Depar": "68", "id": "68266", "name": "ENCISO" },
   { "id_Depar": "68", "id": "68271", "name": "FLORIÁN" },
   { "id_Depar": "68", "id": "68276", "name": "FLORIDABLANCA" },
   { "id_Depar": "68", "id": "68296", "name": "GALÁN" },
   { "id_Depar": "68", "id": "68298", "name": "GÁMBITA" },
   { "id_Depar": "68", "id": "68307", "name": "GIRÓN" },
   { "id_Depar": "68", "id": "68318", "name": "GUACA" },
   { "id_Depar": "68", "id": "68320", "name": "GUADALUPE" },
   { "id_Depar": "68", "id": "68322", "name": "GUAPOTÁ" },
   { "id_Depar": "68", "id": "68324", "name": "GUAVATÁ" },
   { "id_Depar": "68", "id": "68327", "name": "GÜEPSA" },
   { "id_Depar": "68", "id": "68344", "name": "HATO" },
   { "id_Depar": "68", "id": "68368", "name": "JESÚS MARÍA" },
   { "id_Depar": "68", "id": "68370", "name": "JORDÁN" },
   { "id_Depar": "68", "id": "68377", "name": "LA BELLEZA" },
   { "id_Depar": "68", "id": "68385", "name": "LANDÁZURI" },
   { "id_Depar": "68", "id": "68397", "name": "LA PAZ" },
   { "id_Depar": "68", "id": "68406", "name": "LEBRIJA" },
   { "id_Depar": "68", "id": "68418", "name": "LOS SANTOS" },
   { "id_Depar": "68", "id": "68425", "name": "MACARAVITA" },
   { "id_Depar": "68", "id": "68432", "name": "MÁLAGA" },
   { "id_Depar": "68", "id": "68444", "name": "MATANZA" },
   { "id_Depar": "68", "id": "68464", "name": "MOGOTES" },
   { "id_Depar": "68", "id": "68468", "name": "MOLAGAVITA" },
   { "id_Depar": "68", "id": "68498", "name": "OCAMONTE" },
   { "id_Depar": "68", "id": "68500", "name": "OIBA" },
   { "id_Depar": "68", "id": "68502", "name": "ONZAGA" },
   { "id_Depar": "68", "id": "68522", "name": "PALMAR" },
   { "id_Depar": "68", "id": "68524", "name": "PALMAS DEL SOCORRO" },
   { "id_Depar": "68", "id": "68533", "name": "PÁRAMO" },
   { "id_Depar": "68", "id": "68547", "name": "PIEDECUESTA" },
   { "id_Depar": "68", "id": "68549", "name": "PINCHOTE" },
   { "id_Depar": "68", "id": "68572", "name": "PUENTE NACIONAL" },
   { "id_Depar": "68", "id": "68573", "name": "PUERTO PARRA" },
   { "id_Depar": "68", "id": "68575", "name": "PUERTO WILCHES" },
   { "id_Depar": "68", "id": "68615", "name": "RIONEGRO" },
   { "id_Depar": "68", "id": "68655", "name": "SABANA DE TORRES" },
   { "id_Depar": "68", "id": "68669", "name": "SAN ANDRÉS" },
   { "id_Depar": "68", "id": "68673", "name": "SAN BENITO" },
   { "id_Depar": "68", "id": "68679", "name": "SAN GIL" },
   { "id_Depar": "68", "id": "68682", "name": "SAN JOAQUÍN" },
   { "id_Depar": "68", "id": "68684", "name": "SAN JOSÉ DE MIRANDA" },
   { "id_Depar": "68", "id": "68686", "name": "SAN MIGUEL" },
   { "id_Depar": "68", "id": "68689", "name": "SAN VICENTE DE CHUCURÍ" },
   { "id_Depar": "68", "id": "68705", "name": "SANTA BÁRBARA" },
   { "id_Depar": "68", "id": "68720", "name": "SANTA HELENA DEL OPÓN" },
   { "id_Depar": "68", "id": "68745", "name": "SIMACOTA" },
   { "id_Depar": "68", "id": "68755", "name": "SOCORRO" },
   { "id_Depar": "68", "id": "68770", "name": "SUAITA" },
   { "id_Depar": "68", "id": "68773", "name": "SUCRE" },
   { "id_Depar": "68", "id": "68780", "name": "SURATÁ" },
   { "id_Depar": "68", "id": "68820", "name": "TONA" },
   { "id_Depar": "68", "id": "68855", "name": "VALLE DE SAN JOSÉ" },
   { "id_Depar": "68", "id": "68861", "name": "VÉLEZ" },
   { "id_Depar": "68", "id": "68867", "name": "VETAS" },
   { "id_Depar": "68", "id": "68872", "name": "VILLANUEVA" },
   { "id_Depar": "68", "id": "68895", "name": "ZAPATOCA" },
   { "id_Depar": "70", "id": "70001", "name": "SINCELEJO" },
   { "id_Depar": "70", "id": "70110", "name": "BUENAVISTA" },
   { "id_Depar": "70", "id": "70124", "name": "CAIMITO" },
   { "id_Depar": "70", "id": "70204", "name": "COLOSÓ" },
   { "id_Depar": "70", "id": "70215", "name": "COROZAL" },
   { "id_Depar": "70", "id": "70221", "name": "COVEÑAS" },
   { "id_Depar": "70", "id": "70230", "name": "CHALÁN" },
   { "id_Depar": "70", "id": "70233", "name": "EL ROBLE" },
   { "id_Depar": "70", "id": "70235", "name": "GALERAS" },
   { "id_Depar": "70", "id": "70265", "name": "GUARANDA" },
   { "id_Depar": "70", "id": "70400", "name": "LA UNIÓN" },
   { "id_Depar": "70", "id": "70418", "name": "LOS PALMITOS" },
   { "id_Depar": "70", "id": "70429", "name": "MAJAGUAL" },
   { "id_Depar": "70", "id": "70473", "name": "MORROA" },
   { "id_Depar": "70", "id": "70508", "name": "OVEJAS" },
   { "id_Depar": "70", "id": "70523", "name": "PALMITO" },
   { "id_Depar": "70", "id": "70670", "name": "SAMPUÉS" },
   { "id_Depar": "70", "id": "70678", "name": "SAN BENITO ABAD" },
   { "id_Depar": "70", "id": "70702", "name": "SAN JUAN DE BETULIA" },
   { "id_Depar": "70", "id": "70708", "name": "SAN MARCOS" },
   { "id_Depar": "70", "id": "70713", "name": "SAN ONOFRE" },
   { "id_Depar": "70", "id": "70717", "name": "SAN PEDRO" },
   { "id_Depar": "70", "id": "70742", "name": "SAN LUIS DE SINCÉ" },
   { "id_Depar": "70", "id": "70771", "name": "SUCRE" },
   { "id_Depar": "70", "id": "70820", "name": "SANTIAGO DE TOLÚ" },
   { "id_Depar": "70", "id": "70823", "name": "SAN JOSÉ DE TOLUVIEJO" },
   { "id_Depar": "73", "id": "73001", "name": "IBAGUÉ" },
   { "id_Depar": "73", "id": "73024", "name": "ALPUJARRA" },
   { "id_Depar": "73", "id": "73026", "name": "ALVARADO" },
   { "id_Depar": "73", "id": "73030", "name": "AMBALEMA" },
   { "id_Depar": "73", "id": "73043", "name": "ANZOÁTEGUI" },
   { "id_Depar": "73", "id": "73055", "name": "ARMERO" },
   { "id_Depar": "73", "id": "73067", "name": "ATACO" },
   { "id_Depar": "73", "id": "73124", "name": "CAJAMARCA" },
   { "id_Depar": "73", "id": "73148", "name": "CARMEN DE APICALÁ" },
   { "id_Depar": "73", "id": "73152", "name": "CASABIANCA" },
   { "id_Depar": "73", "id": "73168", "name": "CHAPARRAL" },
   { "id_Depar": "73", "id": "73200", "name": "COELLO" },
   { "id_Depar": "73", "id": "73217", "name": "COYAIMA" },
   { "id_Depar": "73", "id": "73226", "name": "CUNDAY" },
   { "id_Depar": "73", "id": "73236", "name": "DOLORES" },
   { "id_Depar": "73", "id": "73268", "name": "ESPINAL" },
   { "id_Depar": "73", "id": "73270", "name": "FALAN" },
   { "id_Depar": "73", "id": "73275", "name": "FLANDES" },
   { "id_Depar": "73", "id": "73283", "name": "FRESNO" },
   { "id_Depar": "73", "id": "73319", "name": "GUAMO" },
   { "id_Depar": "73", "id": "73347", "name": "HERVEO" },
   { "id_Depar": "73", "id": "73349", "name": "HONDA" },
   { "id_Depar": "73", "id": "73352", "name": "ICONONZO" },
   { "id_Depar": "73", "id": "73408", "name": "LÉRIDA" },
   { "id_Depar": "73", "id": "73411", "name": "LÍBANO" },
   { "id_Depar": "73", "id": "73443", "name": "SAN SEBASTIÁN DE MARIQUITA" },
   { "id_Depar": "73", "id": "73449", "name": "MELGAR" },
   { "id_Depar": "73", "id": "73461", "name": "MURILLO" },
   { "id_Depar": "73", "id": "73483", "name": "NATAGAIMA" },
   { "id_Depar": "73", "id": "73504", "name": "ORTEGA" },
   { "id_Depar": "73", "id": "73520", "name": "PALOCABILDO" },
   { "id_Depar": "73", "id": "73547", "name": "PIEDRAS" },
   { "id_Depar": "73", "id": "73555", "name": "PLANADAS" },
   { "id_Depar": "73", "id": "73563", "name": "PRADO" },
   { "id_Depar": "73", "id": "73585", "name": "PURIFICACIÓN" },
   { "id_Depar": "73", "id": "73616", "name": "RIOBLANCO" },
   { "id_Depar": "73", "id": "73622", "name": "RONCESVALLES" },
   { "id_Depar": "73", "id": "73624", "name": "ROVIRA" },
   { "id_Depar": "73", "id": "73671", "name": "SALDAÑA" },
   { "id_Depar": "73", "id": "73675", "name": "SAN ANTONIO" },
   { "id_Depar": "73", "id": "73678", "name": "SAN LUIS" },
   { "id_Depar": "73", "id": "73686", "name": "SANTA ISABEL" },
   { "id_Depar": "73", "id": "73770", "name": "SUÁREZ" },
   { "id_Depar": "73", "id": "73854", "name": "VALLE DE SAN JUAN" },
   { "id_Depar": "73", "id": "73861", "name": "VENADILLO" },
   { "id_Depar": "73", "id": "73870", "name": "VILLAHERMOSA" },
   { "id_Depar": "73", "id": "73873", "name": "VILLARRICA" },
   { "id_Depar": "76", "id": "76001", "name": "CALI" },
   { "id_Depar": "76", "id": "76020", "name": "ALCALÁ" },
   { "id_Depar": "76", "id": "76036", "name": "ANDALUCÍA" },
   { "id_Depar": "76", "id": "76041", "name": "ANSERMANUEVO" },
   { "id_Depar": "76", "id": "76054", "name": "ARGELIA" },
   { "id_Depar": "76", "id": "76100", "name": "BOLÍVAR" },
   { "id_Depar": "76", "id": "76109", "name": "BUENAVENTURA" },
   { "id_Depar": "76", "id": "76111", "name": "GUADALAJARA DE BUGA" },
   { "id_Depar": "76", "id": "76113", "name": "BUGALAGRANDE" },
   { "id_Depar": "76", "id": "76122", "name": "CAICEDONIA" },
   { "id_Depar": "76", "id": "76126", "name": "CALIMA" },
   { "id_Depar": "76", "id": "76130", "name": "CANDELARIA" },
   { "id_Depar": "76", "id": "76147", "name": "CARTAGO" },
   { "id_Depar": "76", "id": "76233", "name": "DAGUA" },
   { "id_Depar": "76", "id": "76243", "name": "EL ÁGUILA" },
   { "id_Depar": "76", "id": "76246", "name": "EL CAIRO" },
   { "id_Depar": "76", "id": "76248", "name": "EL CERRITO" },
   { "id_Depar": "76", "id": "76250", "name": "EL DOVIO" },
   { "id_Depar": "76", "id": "76275", "name": "FLORIDA" },
   { "id_Depar": "76", "id": "76306", "name": "GINEBRA" },
   { "id_Depar": "76", "id": "76318", "name": "GUACARÍ" },
   { "id_Depar": "76", "id": "76364", "name": "JAMUNDÍ" },
   { "id_Depar": "76", "id": "76377", "name": "LA CUMBRE" },
   { "id_Depar": "76", "id": "76400", "name": "LA UNIÓN" },
   { "id_Depar": "76", "id": "76403", "name": "LA VICTORIA" },
   { "id_Depar": "76", "id": "76497", "name": "OBANDO" },
   { "id_Depar": "76", "id": "76520", "name": "PALMIRA" },
   { "id_Depar": "76", "id": "76563", "name": "PRADERA" },
   { "id_Depar": "76", "id": "76606", "name": "RESTREPO" },
   { "id_Depar": "76", "id": "76616", "name": "RIOFRÍO" },
   { "id_Depar": "76", "id": "76622", "name": "ROLDANILLO" },
   { "id_Depar": "76", "id": "76670", "name": "SAN PEDRO" },
   { "id_Depar": "76", "id": "76736", "name": "SEVILLA" },
   { "id_Depar": "76", "id": "76823", "name": "TORO" },
   { "id_Depar": "76", "id": "76828", "name": "TRUJILLO" },
   { "id_Depar": "76", "id": "76834", "name": "TULUÁ" },
   { "id_Depar": "76", "id": "76845", "name": "ULLOA" },
   { "id_Depar": "76", "id": "76863", "name": "VERSALLES" },
   { "id_Depar": "76", "id": "76869", "name": "VIJES" },
   { "id_Depar": "76", "id": "76890", "name": "YOTOCO" },
   { "id_Depar": "76", "id": "76892", "name": "YUMBO" },
   { "id_Depar": "76", "id": "76895", "name": "ZARZAL" },
   { "id_Depar": "81", "id": "81001", "name": "ARAUCA" },
   { "id_Depar": "81", "id": "81065", "name": "ARAUQUITA" },
   { "id_Depar": "81", "id": "81220", "name": "CRAVO NORTE" },
   { "id_Depar": "81", "id": "81300", "name": "FORTUL" },
   { "id_Depar": "81", "id": "81591", "name": "PUERTO RONDÓN" },
   { "id_Depar": "81", "id": "81736", "name": "SARAVENA" },
   { "id_Depar": "81", "id": "81794", "name": "TAME" },
   { "id_Depar": "85", "id": "85001", "name": "YOPAL" },
   { "id_Depar": "85", "id": "85010", "name": "AGUAZUL" },
   { "id_Depar": "85", "id": "85015", "name": "CHÁMEZA" },
   { "id_Depar": "85", "id": "85125", "name": "HATO COROZAL" },
   { "id_Depar": "85", "id": "85136", "name": "LA SALINA" },
   { "id_Depar": "85", "id": "85139", "name": "MANÍ" },
   { "id_Depar": "85", "id": "85162", "name": "MONTERREY" },
   { "id_Depar": "85", "id": "85225", "name": "NUNCHÍA" },
   { "id_Depar": "85", "id": "85230", "name": "OROCUÉ" },
   { "id_Depar": "85", "id": "85250", "name": "PAZ DE ARIPORO" },
   { "id_Depar": "85", "id": "85263", "name": "PORE" },
   { "id_Depar": "85", "id": "85279", "name": "RECETOR" },
   { "id_Depar": "85", "id": "85300", "name": "SABANALARGA" },
   { "id_Depar": "85", "id": "85315", "name": "SÁCAMA" },
   { "id_Depar": "85", "id": "85325", "name": "SAN LUIS DE PALENQUE" },
   { "id_Depar": "85", "id": "85400", "name": "TÁMARA" },
   { "id_Depar": "85", "id": "85410", "name": "TAURAMENA" },
   { "id_Depar": "85", "id": "85430", "name": "TRINIDAD" },
   { "id_Depar": "85", "id": "85440", "name": "VILLANUEVA" },
   { "id_Depar": "86", "id": "86001", "name": "MOCOA" },
   { "id_Depar": "86", "id": "86219", "name": "COLÓN" },
   { "id_Depar": "86", "id": "86320", "name": "ORITO" },
   { "id_Depar": "86", "id": "86568", "name": "PUERTO ASÍS" },
   { "id_Depar": "86", "id": "86569", "name": "PUERTO CAICEDO" },
   { "id_Depar": "86", "id": "86571", "name": "PUERTO GUZMÁN" },
   { "id_Depar": "86", "id": "86573", "name": "PUERTO LEGUÍZAMO" },
   { "id_Depar": "86", "id": "86749", "name": "SIBUNDOY" },
   { "id_Depar": "86", "id": "86755", "name": "SAN FRANCISCO" },
   { "id_Depar": "86", "id": "86757", "name": "SAN MIGUEL" },
   { "id_Depar": "86", "id": "86760", "name": "SANTIAGO" },
   { "id_Depar": "86", "id": "86865", "name": "VALLE DEL GUAMUEZ" },
   { "id_Depar": "86", "id": "86885", "name": "VILLAGARZÓN" },
   { "id_Depar": "88", "id": "88001", "name": "SAN ANDRÉS" },
   { "id_Depar": "88", "id": "88564", "name": "PROVIDENCIA" },
   { "id_Depar": "91", "id": "91001", "name": "LETICIA" },
   { "id_Depar": "91", "id": "91540", "name": "PUERTO NARIÑO" },
   { "id_Depar": "94", "id": "94001", "name": "INÍRIDA" },
   { "id_Depar": "94", "id": "94343", "name": "BARRANCOMINAS" },
   { "id_Depar": "95", "id": "95001", "name": "SAN JOSÉ DEL GUAVIARE" },
   { "id_Depar": "95", "id": "95015", "name": "CALAMAR" },
   { "id_Depar": "95", "id": "95025", "name": "EL RETORNO" },
   { "id_Depar": "95", "id": "95200", "name": "MIRAFLORES" },
   { "id_Depar": "97", "id": "97001", "name": "MITÚ" },
   { "id_Depar": "97", "id": "97161", "name": "CARURÚ" },
   { "id_Depar": "97", "id": "97666", "name": "TARAIRA" },
   { "id_Depar": "99", "id": "99001", "name": "PUERTO CARREÑO" },
   { "id_Depar": "99", "id": "99524", "name": "LA PRIMAVERA" },
   { "id_Depar": "99", "id": "99624", "name": "SANTA ROSALÍA" },
   { "id_Depar": "99", "id": "99773", "name": "CUMARIBO" }
  
]