import React, { useMemo, useState } from "react";
import { isInValidLimitCharacter, isValidFieldRequired, msgInValidLimitCharacter } from "../../util/Index";
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import { useFormikContext } from "formik";
import { CurricullumSectionProps } from "../../modules/auth/components/sections/curriculum-registration/CurricullumFormTypes";

export type SectionExpierenceType = {
    id?: number;
    position?: string;
    company?: string | undefined | null;
    start_date?: string | undefined | null;
    currentJob?: string | undefined | boolean;
    end_date?: string | undefined | null;
    activity?: string | undefined | null;
    fechaIngressoMoth?: string | undefined | null;
    fechaIngressoYear?: string | undefined | null | number;
    fechaRetiroMonth?: string | undefined | null;
    fechaRetiroYear?: string | undefined | null | number;

}

export const useExperience = ({ setFieldValue, values }: CurricullumSectionProps) => {
    const { t } = useTranslation();
    const { professionalHistory } = values;
    const [isSaving, setIsSaving] = useState(false);

    const [valueAdd, setValueAdd] = useState<SectionExpierenceType>({})
    const [borderColorEdit, setBorderColorEdit] = useState<any>('1px solid');
    const [editMode, setEditMode] = useState(false);
    const [openRowEdit, setOpenRowEdit] = useState<number>(0);
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(true);
    const [alertMsg, setAlertMsg] = useState('');
    const [rows, setRows] = useState<SectionExpierenceType[]>(professionalHistory || []);
    const setValues = (val: SectionExpierenceType) => {
        setValueAdd(val);
    }

    useMemo(() => {
        setIsSaving(false);

        if (professionalHistory?.length > 0) {
            setRows(professionalHistory)
        }

    }, [professionalHistory])

    const {
        position,
        company,
        activity,
        currentJob,
        fechaRetiroMonth,
        fechaRetiroYear,
        fechaIngressoMoth,
        fechaIngressoYear,
        start_date,
        end_date } = valueAdd;


    const handleclear = () => {
        setValueAdd({})
        setOpenRowEdit(0);
        setEditMode(false);
        setIsSaving(false);

    };

    const handleSaveEdit = () => {
        setIsSaving(true);

        if (editMode) {
            let item = rows.find((x) => x.id === openRowEdit);
            console.log('item edit', item);
            let iRemove = rows.findIndex((x) => x.id === openRowEdit);
            if (handleValidOnSubmit()) {

                let itemsToSave = rows.filter((_, i) => i !== iRemove);
                let d = new Date();
                let m = d.getMinutes();
                let s = d.getSeconds();
                let idRandom = openRowEdit || parseInt(`${m}${s}`);

                itemsToSave.push({
                    id: idRandom,
                    position: position as string,
                    company: company,
                    start_date: `${fechaIngressoMoth}/${fechaIngressoYear}`,
                    currentJob: currentJob,
                    end_date: !currentJob ? `${fechaRetiroMonth}/${fechaRetiroYear}` : null,
                    activity: activity,
                    fechaIngressoMoth: fechaIngressoMoth,
                    fechaIngressoYear: fechaIngressoYear,
                    fechaRetiroMonth: !currentJob ? fechaRetiroMonth : '',
                    fechaRetiroYear: !currentJob ? fechaRetiroYear : '',
                });

                setRows(itemsToSave);
                saveToForm(itemsToSave);
                handleclear();
            }
        }
    };

    const saveToForm = (rowsAdd: any[]) => {
        setFieldValue('professionalHistory', rowsAdd);
    }

    const handleAddExperienciaLaboral = () => {
        setIsSaving(true);
        if (handleValidOnSubmit()) {
            const rowsAdd = [
                ...rows,
                {
                    id: rows.length + 1,
                    position: position || '',
                    company: company,
                    start_date: `${fechaIngressoMoth}/${fechaIngressoYear}`,
                    currentJob: currentJob,
                    end_date: `${fechaRetiroMonth}/${fechaRetiroYear}`,
                    activity: activity,
                    fechaIngressoMoth,
                    fechaIngressoYear,
                    fechaRetiroMonth,
                    fechaRetiroYear,

                },
            ]
            setRows(rowsAdd);
            setOpen(true);
            setAlert(true);
            setAlertMsg(`Experiencia laboral Agregado con éxito!`);
            saveToForm(rowsAdd);
            setTimeout(() => {
                setOpen(false);
            }, 7000);

            handleclear();
        }
    };
    const handleRemoveExperienciaLaboral = (id: number) => {
        const list = [...rows];
        list.splice(_.findIndex(list, { id: id }), 1);
        setRows(list);
        saveToForm(list);
    };

    const checkValidDescriptionLogro = (): boolean => {
        return isInValidLimitCharacter(activity || '', 320, 150)
    }
    const msgInvalidDescription = (): string => {
        return msgInValidLimitCharacter(activity || '', 320, 150, t)
    }

    const handleValidOnSubmit = () => {
        let errors: { field: string; error: string }[] = [];
        if (!isValidFieldRequired(position)) errors.push({ field: 'position', error: 'Campo requerido' });
        if (!isValidFieldRequired(company))
            errors.push({ field: 'Nombre de la empresa', error: 'Campo requerido' });

        if (!isValidFieldRequired(fechaIngressoMoth) || !isValidFieldRequired(fechaIngressoYear))
            errors.push({ field: 'Fecha de ingreso', error: 'Campo requerido' });


        if (!currentJob)
            if (!isValidFieldRequired(fechaRetiroYear) || !isValidFieldRequired(fechaRetiroMonth))
                errors.push({ field: 'Fecha de retiro', error: 'Campo requerido' });

        if (!checkValidDescriptionLogro())
            errors.push({
                field: 'Funciones y logros',
                error: msgInvalidDescription(),
            });

        console.log(errors);
        if (errors.length > 0) {
            setAlert(false);
            setAlertMsg(
                `\ ${t('Diligencie los campos obligatorios')}\n ${errors.map((x) => {
                    return `\n ${t(x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase()))}: ${t(x.error)
                        }`;
                })}`
            );
            setOpen(true);
            return false;
        }

        return true;
    };

    const handleEdit = (item: any) => {
        setEditMode(true);
        setOpenRowEdit(item.id);
        setBorderColorEdit('2px solid blue')
        setTimeout(() => {
            setBorderColorEdit('1px solid')
        }, 4000);

        const doc = document.getElementById("positionExperience");
        doc?.focus({
            preventScroll: false
        });

        let yerStart;
        let monthStart;
        let yearEnd;
        let monthEnd;

        if (!item.startMonth && !item.startYear) {
            yerStart = item.start_date?.split("/")?.[1];
            monthStart = item.start_date?.split("/")?.[0];
        }

        if (!item.conclusionMonth && !item.conclusionYear) {
            yearEnd = item.end_date?.split("/")?.[1];
            monthEnd = item.end_date?.split("/")?.[0];
        }
        setValueAdd({
            id: item.id,
            position: item.position as string,
            company: item.company,
            start_date: item.start_date,
            currentJob: !item.fechaRetiroMonth && !monthEnd ? true : item.currentJob,
            end_date: item.end_date,
            activity: item.activity,
            fechaIngressoMoth: monthStart ? monthStart : item.fechaIngressoMoth,
            fechaIngressoYear: yerStart ? yerStart : item.fechaIngressoYear,
            fechaRetiroMonth: monthEnd ? monthEnd : item.fechaRetiroMonth,
            fechaRetiroYear: yearEnd ? yearEnd : item.fechaRetiroYear,
        })

    };
    const handleClose = () => {
        setOpen(false);
    };

    return {
        valueAdd,
        borderColorEdit,
        editMode,
        openRowEdit,
        alert,
        alertMsg,
        open,
        setValues,
        setFieldValue,
        handleClose,
        handleEdit,
        handleSaveEdit,
        handleRemoveExperienciaLaboral,
        handleAddExperienciaLaboral,
        handleclear,
        rows,
        isSaving
    }

}