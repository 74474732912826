import { FormEvent, useContext, useState, useEffect } from 'react';
import {
	TextField,
	SelectChangeEvent,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Button,
	Box,
	Typography,
	Divider,
	FormControlLabel,
	Checkbox,
	Alert,
	Snackbar,
	AlertTitle,
	FormHelperText,
	InputAdornment,
} from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { multiStepContext } from '../../../../../contexts/CurriculumContext';
import _ from 'lodash';
import { createCurriculo } from '../../../../../modules/apps/user-management/users-list/core/_requests';
import {
	CapacitacionCertificacionModel,
	ComplementoDirecaoModel,
	HerramientasModel,
	IdiomaModel,
	PuestosModel,
	NivelEducativoModel,
	ResumeModel,
	UserComplementoModel,
	ExperienciaLaboralModel,
} from '../../../../../models/ResumeModel';
import { initValue, isValidFieldRequired, getDateNowToString, setValueOrDefault } from '../../../../../util/Index';
import { useTranslation } from 'react-i18next';

export const Step5 = () => {
	const { t } = useTranslation();
	const { setStep, userData, setUserData } = useContext(multiStepContext);

	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const [idIdioma, setIdIdioma] = useState<string>('');
	const [fieldsNivelEducativo, setFieldsNivelEducativo] = useState<string>('');
	const [tieneEducacionFormal, setTieneEducacionFormal] = useState<string>('');
	const [nivelEducativo, setNivelEducativo] = useState<string>('');
	const [tituloObtenido, setTituloObtenido] = useState<string>('');
	const [institucion, setInstitucion] = useState<string>('');
	const [fechafinalizacion, setFechafinalizacion] = useState<string>('');
	const [tituloHomologado, setTituloHomologado] = useState<string>('');
	const [areaDesempeno, setAreaDesempeno] = useState<string>('');
	const [nucleoConocimiento, setNucleoConocimiento] = useState<string>('');
	const [paisDelCurso, setPaisDelCurso] = useState<string>('');
	const [estadoDelCurso, setEstadoDelCurso] = useState<string>('');
	const [idObservaciones, setIdObservaciones] = useState<string>('');
	const [txtNumeroTarjProf, setTxtNumeroTarjProf] = useState<string>('');
	const [fechaTarjProf, setFechaTarjProf] = useState<string>('');
	const [tarjetaProfesional, setTarjetaProfesional] = useState<string>('');

	const [interessadoPractica, setInteressadoPractica] = useState<boolean>(false);
	const [idNivelHerramientas, setIdNivelHerramientas] = useState<number | null>(null);
	const [display, setDisplay] = useState<string>('');
	const [loading, setLoading] = useState(false);
	const [step, setStepUser] = useState<string>('step5');

	const [editMode, setEditMode] = useState(false);
	const [openRowEdit, setOpenRowEdit] = useState<number>(0);
	const [borderColorEdit, setBorderColorEdit] = useState<any>('1px solid');

	const [nivelDDL, setNivelDDL] = useState([]);

	const ddltipo = [
		{ id: '1', name: 'Procesador de texto (Ejemplo: Word)' },
		{ id: '2', name: 'Hoja de cálculo (Ejemplo: Excel)' },
		{ id: '3', name: 'Multimedia (Ejemplo: PowerPoint)' },
		{ id: '4', name: 'Otros' },
	];
	const [ddlNivel, setDdlNivel] = useState([{ id: '', name: '' }]);

	const [ddlNucleo, setDdlNucleo] = useState([{ id: '', name: '' }]);

	const [ddlTitEquivalente, setDDLTitEquivalente] = useState([{ id: '', name: '' }]);

	const ddlPaisTitulo = [
		{ id: 'AF', name: 'Afganistán' },
		{ id: 'AL', name: 'Albania' },
		{ id: 'DE', name: 'Alemania' },
		{ id: 'AD', name: 'Andorra' },
		{ id: 'AO', name: 'Angola' },
		{ id: 'AI', name: 'Anguilla' },
		{ id: 'AQ', name: 'Antártida' },
		{ id: 'AG', name: 'Antigua y Barbuda' },
		{ id: 'AN', name: 'Antillas Holandesas' },
		{ id: 'SA', name: 'Arabia Saudí' },
		{ id: 'DZ', name: 'Argelia' },
		{ id: 'AR', name: 'Argentina' },
		{ id: 'AM', name: 'Armenia' },
		{ id: 'AW', name: 'Aruba' },
		{ id: 'MK', name: 'ARY Macedonia' },
		{ id: 'AU', name: 'Australia' },
		{ id: 'AT', name: 'Austria' },
		{ id: 'AZ', name: 'Azerbaiyán' },
		{ id: 'BS', name: 'Bahamas' },
		{ id: 'BH', name: 'Bahréin' },
		{ id: 'BD', name: 'Bangladesh' },
		{ id: 'BB', name: 'Barbados' },
		{ id: 'BE', name: 'Bélgica' },
		{ id: 'BZ', name: 'Belice' },
		{ id: 'BJ', name: 'Benin' },
		{ id: 'BM', name: 'Bermudas' },
		{ id: 'BT', name: 'Bhután' },
		{ id: 'BY', name: 'Bielorrusia' },
		{ id: 'BO', name: 'Bolivia' },
		{ id: 'BA', name: 'Bosnia y Herzegovina' },
		{ id: 'BW', name: 'Botsuana' },
		{ id: 'BR', name: 'Brasil' },
		{ id: 'BN', name: 'Brunéi' },
		{ id: 'BG', name: 'Bulgaria' },
		{ id: 'BF', name: 'Burkina Faso' },
		{ id: 'BI', name: 'Burundi' },
		{ id: 'CV', name: 'Cabo Verde' },
		{ id: 'KH', name: 'Camboya' },
		{ id: 'CM', name: 'Camerún' },
		{ id: 'CA', name: 'Canadá' },
		{ id: 'TD', name: 'Chad' },
		{ id: 'CL', name: 'Chile' },
		{ id: 'CN', name: 'China' },
		{ id: 'CY', name: 'Chipre' },
		{ id: 'VA', name: 'Ciudad del Vaticano' },
		{ id: 'CO', name: 'Colombia' },
		{ id: 'KM', name: 'Comoras' },
		{ id: 'CG', name: 'Congo' },
		{ id: 'KP', name: 'Corea del Norte' },
		{ id: 'KR', name: 'Corea del Sur' },
		{ id: 'CI', name: 'Costa de Marfil' },
		{ id: 'CR', name: 'Costa Rica' },
		{ id: 'HR', name: 'Croacia' },
		{ id: 'CU', name: 'Cuba' },
		{ id: 'DK', name: 'Dinamarca' },
		{ id: 'DM', name: 'Dominica' },
		{ id: 'EC', name: 'Ecuador' },
		{ id: 'EG', name: 'Egipto' },
		{ id: 'SV', name: 'El Salvador' },
		{ id: 'AE', name: 'Emiratos Árabes Unidos' },
		{ id: 'ER', name: 'Eritrea' },
		{ id: 'SK', name: 'Eslovaquia' },
		{ id: 'SI', name: 'Eslovenia' },
		{ id: 'ES', name: 'España' },
		{ id: 'US', name: 'Estados Unidos' },
		{ id: 'EE', name: 'Estonia' },
		{ id: 'ET', name: 'Etiopía' },
		{ id: 'PH', name: 'Filipinas' },
		{ id: 'FI', name: 'Finlandia' },
		{ id: 'FJ', name: 'Fiyi' },
		{ id: 'FR', name: 'Francia' },
		{ id: 'GA', name: 'Gabón' },
		{ id: 'GM', name: 'Gambia' },
		{ id: 'GE', name: 'Georgia' },
		{ id: 'GH', name: 'Ghana' },
		{ id: 'GI', name: 'Gibraltar' },
		{ id: 'GD', name: 'Granada' },
		{ id: 'GR', name: 'Grecia' },
		{ id: 'GL', name: 'Groenlandia' },
		{ id: 'GP', name: 'Guadalupe' },
		{ id: 'GU', name: 'Guam' },
		{ id: 'GT', name: 'Guatemala' },
		{ id: 'GF', name: 'Guayana Francesa' },
		{ id: 'GN', name: 'Guinea' },
		{ id: 'GQ', name: 'Guinea Ecuatorial' },
		{ id: 'GW', name: 'Guinea-Bissau' },
		{ id: 'GY', name: 'Guyana' },
		{ id: 'HT', name: 'Haití' },
		{ id: 'HN', name: 'Honduras' },
		{ id: 'HK', name: 'Hong Kong' },
		{ id: 'HU', name: 'Hungría' },
		{ id: 'IN', name: 'India' },
		{ id: 'ID', name: 'Indonesia' },
		{ id: 'IR', name: 'Irán' },
		{ id: 'IQ', name: 'Iraq' },
		{ id: 'IE', name: 'Irlanda' },
		{ id: 'NF', name: 'Isla Norfolk' },
		{ id: 'IS', name: 'Islandia' },
		{ id: 'KY', name: 'Islas Caimán' },
		{ id: 'CC', name: 'Islas Cocos' },
		{ id: 'CK', name: 'Islas Cook' },
		{ id: 'FO', name: 'Islas Feroe' },
		{ id: 'AX', name: 'Islas Gland' },
		{ id: 'FK', name: 'Islas Malvinas' },
		{ id: 'MP', name: 'Islas Marianas del Norte' },
		{ id: 'MH', name: 'Islas Marshall' },
		{ id: 'PN', name: 'Islas Pitcairn' },
		{ id: 'SB', name: 'Islas Salomón' },
		{ id: 'TC', name: 'Islas Turcas y Caicos' },
		{ id: 'VG', name: 'Islas Vírgenes Británicas' },
		{ id: 'VI', name: 'Islas Vírgenes de los Estados Unidos' },
		{ id: 'IL', name: 'Israel' },
		{ id: 'IT', name: 'Italia' },
		{ id: 'JM', name: 'Jamaica' },
		{ id: 'JP', name: 'Japón' },
		{ id: 'JO', name: 'Jordania' },
		{ id: 'KZ', name: 'Kazajstán' },
		{ id: 'KE', name: 'Kenia' },
		{ id: 'KG', name: 'Kirguistán' },
		{ id: 'KI', name: 'Kiribati' },
		{ id: 'KW', name: 'Kuwait' },
		{ id: 'LA', name: 'Laos' },
		{ id: 'LS', name: 'Lesotho' },
		{ id: 'LV', name: 'Letonia' },
		{ id: 'LB', name: 'Líbano' },
		{ id: 'LR', name: 'Liberia' },
		{ id: 'LY', name: 'Libia' },
		{ id: 'LI', name: 'Liechtenstein' },
		{ id: 'LT', name: 'Lituania' },
		{ id: 'LU', name: 'Luxemburgo' },
		{ id: 'MO', name: 'Macao' },
		{ id: 'MG', name: 'Madagascar' },
		{ id: 'MY', name: 'Malasia' },
		{ id: 'MW', name: 'Malawi' },
		{ id: 'MV', name: 'Maldivas' },
		{ id: 'ML', name: 'Malí' },
		{ id: 'MT', name: 'Malta' },
		{ id: 'MA', name: 'Marruecos' },
		{ id: 'MQ', name: 'Martinica' },
		{ id: 'MU', name: 'Mauricio' },
		{ id: 'MR', name: 'Mauritania' },
		{ id: 'YT', name: 'Mayotte' },
		{ id: 'MX', name: 'México' },
		{ id: 'FM', name: 'Micronesia' },
		{ id: 'MD', name: 'Moldavia' },
		{ id: 'MC', name: 'Mónaco' },
		{ id: 'MN', name: 'Mongolia' },
		{ id: 'MS', name: 'Montserrat' },
		{ id: 'MZ', name: 'Mozambique' },
		{ id: 'MM', name: 'Myanmar' },
		{ id: 'NA', name: 'Namibia' },
		{ id: 'NR', name: 'Nauru' },
		{ id: 'NP', name: 'Nepal' },
		{ id: 'NI', name: 'Nicaragua' },
		{ id: 'NE', name: 'Níger' },
		{ id: 'NG', name: 'Nigeria' },
		{ id: 'NU', name: 'Niue' },
		{ id: 'NO', name: 'Noruega' },
		{ id: 'NC', name: 'Nueva Caledonia' },
		{ id: 'NZ', name: 'Nueva Zelanda' },
		{ id: 'OM', name: 'Omán' },
		{ id: 'NL', name: 'Países Bajos' },
		{ id: 'PK', name: 'Pakistán' },
		{ id: 'PW', name: 'Palau' },
		{ id: 'PS', name: 'Palestina' },
		{ id: 'PA', name: 'Panamá' },
		{ id: 'PG', name: 'Papúa Nueva Guinea' },
		{ id: 'PY', name: 'Paraguay' },
		{ id: 'PE', name: 'Perú' },
		{ id: 'PF', name: 'Polinesia Francesa' },
		{ id: 'PL', name: 'Polonia' },
		{ id: 'PT', name: 'Portugal' },
		{ id: 'PR', name: 'Puerto Rico' },
		{ id: 'QA', name: 'Qatar' },
		{ id: 'GB', name: 'Reino Unido' },
		{ id: 'CF', name: 'República Centroafricana' },
		{ id: 'CZ', name: 'República Checa' },
		{ id: 'CD', name: 'República Democrática del Congo' },
		{ id: 'DO', name: 'República Dominicana' },
		{ id: 'RE', name: 'Reunión' },
		{ id: 'RW', name: 'Ruanda' },
		{ id: 'RO', name: 'Rumania' },
		{ id: 'RU', name: 'Rusia' },
		{ id: 'EH', name: 'Sahara Occidental' },
		{ id: 'WS', name: 'Samoa' },
		{ id: 'AS', name: 'Samoa Americana' },
		{ id: 'KN', name: 'San Cristóbal y Nevis' },
		{ id: 'SM', name: 'San Marino' },
		{ id: 'PM', name: 'San Pedro y Miquelón' },
		{ id: 'VC', name: 'San Vicente y las Granadinas' },
		{ id: 'SH', name: 'Santa Helena' },
		{ id: 'LC', name: 'Santa Lucía' },
		{ id: 'ST', name: 'Santo Tomé y Príncipe' },
		{ id: 'SN', name: 'Senegal' },
		{ id: 'CS', name: 'Serbia y Montenegro' },
		{ id: 'SC', name: 'Seychelles' },
		{ id: 'SL', name: 'Sierra Leona' },
		{ id: 'NS', name: 'Sin Definir' },
		{ id: 'SG', name: 'Singapur' },
		{ id: 'SY', name: 'Siria' },
		{ id: 'SO', name: 'Somalia' },
		{ id: 'LK', name: 'Sri Lanka' },
		{ id: 'SZ', name: 'Suazilandia' },
		{ id: 'ZA', name: 'Sudáfrica' },
		{ id: 'SD', name: 'Sudán' },
		{ id: 'SE', name: 'Suecia' },
		{ id: 'CH', name: 'Suiza' },
		{ id: 'SR', name: 'Surinam' },
		{ id: 'SJ', name: 'Svalbard y Jan Mayen' },
		{ id: 'TH', name: 'Tailandia' },
		{ id: 'TW', name: 'Taiwán' },
		{ id: 'TZ', name: 'Tanzania' },
		{ id: 'TJ', name: 'Tayikistán' },
		{ id: 'TL', name: 'Timor Oriental' },
		{ id: 'TG', name: 'Togo' },
		{ id: 'TK', name: 'Tokelau' },
		{ id: 'TO', name: 'Tonga' },
		{ id: 'TT', name: 'Trinidad y Tobago' },
		{ id: 'TN', name: 'Túnez' },
		{ id: 'TM', name: 'Turkmenistán' },
		{ id: 'TR', name: 'Turquía' },
		{ id: 'TV', name: 'Tuvalu' },
		{ id: 'UA', name: 'Ucrania' },
		{ id: 'UG', name: 'Uganda' },
		{ id: 'UY', name: 'Uruguay' },
		{ id: 'UZ', name: 'Uzbekistán' },
		{ id: 'VU', name: 'Vanuatu' },
		{ id: 'VE', name: 'Venezuela' },
		{ id: 'VN', name: 'Vietnam' },
		{ id: 'WF', name: 'Wallis y Futuna' },
		{ id: 'YE', name: 'Yemen' },
		{ id: 'DJ', name: 'Yibuti' },
		{ id: 'ZM', name: 'Zambia' },
		{ id: 'ZW', name: 'Zimbabue' },
	];
	const ddlEstado = [
		{ id: '1', name: 'En curso' },
		{ id: '2', name: 'Incompleto' },
		{ id: '3', name: 'Graduado' },
	];
	const [ddlArea, setDdlArea] = useState([{ id: '', name: '' }]);

	const [rowsNivelEducativo, setRowsNivelEducativo] = useState([
		{
			id: 0,
			nivelEducativo: '',
			tituloObtenido: '',
			tituloHomologado: '',
			areaDesempeno: '',
			nucleoConocimiento: '',
			paisDelCurso: '',
			estadoDelCurso: '',
			idObservaciones: '',
			txtNumeroTarjProf: '',
			fechaTarjProf: '',
			interessadoPractica: false,
			Institucion: '',
			fechafinalizacion: '',
		},
	]);

	useEffect(() => {
		if (
			userData['CurriculoNivelEducativos'] !== null &&
			userData['CurriculoNivelEducativos'] !== undefined &&
			(userData['CurriculoNivelEducativos'] as Array<any>).length > 0
		) {
			const listRowsNivelEducativo = rowsNivelEducativo.find((obj) => {
				return obj.id === 0;
			});

			if (listRowsNivelEducativo) {
				console.log('complementos', rowsNivelEducativo);
				const list = [...rowsNivelEducativo];
				list.splice(_.findIndex(list, { id: 0 }), 1);
				setRowsNivelEducativo(list);
			}

			///rowsComplementos.push(createObjectDirecaoAtuliza());
			setRowsNivelEducativo(userData['CurriculoNivelEducativos']);
			setDisplay('none');
			setTieneEducacionFormal('1');
			console.log('ddlTituloHomologado', userData['ddlTituloHomologado']);
			setNivelDDL(userData['ddlTituloHomologado']);
			console.log(userData);
		} else {
			const listRowsNivelEducativo = rowsNivelEducativo.find((obj) => {
				return obj.id === 0;
			});

			if (listRowsNivelEducativo) {
				console.log('complementos', rowsNivelEducativo);
				const list = [...rowsNivelEducativo];
				list.splice(_.findIndex(list, { id: 0 }), 1);
				setRowsNivelEducativo(list);
			}
		}
		if (
			userData['ddlNivelEducativo'] !== null &&
			userData['ddlNivelEducativo'] !== undefined &&
			(userData['ddlNivelEducativo'] as Array<any>).length > 0
		) {
			const list = [...ddlNivel];
			(list as Array<any>).forEach((d) => {
				ddlNivel.splice(_.findIndex(ddlNivel, { id: d['id'] }), 1);
			});
			(userData['ddlNivelEducativo'] as Array<any>).forEach((e) => {
				let x = {
					id: setValueOrDefault(e['sigla_nivel_educativo'], 'number'),
					name: setValueOrDefault(e['desc_nivel_educativo'], 'string'),
				};
				ddlNivel.push(x);
			});
		}
		console.log('nivel educativo', userData['CurriculoNivelEducativos']);
	}, []);

	const handleclear = () => {
		setNivelEducativo('');
		setTituloObtenido('');
		setTituloHomologado('');
		setAreaDesempeno('');
		setNucleoConocimiento('');
		setPaisDelCurso('');
		setEstadoDelCurso('');
		setIdObservaciones('');
		setTxtNumeroTarjProf('');
		setFechaTarjProf('');
		setInstitucion('');
		setFechafinalizacion('');
		setInteressadoPractica(false);
		setOpenRowEdit(0);
		setEditMode(false);
	};
	const handleValidOnSave = () => {
		let errors: { field: string; error: string }[] = [];
		if (tieneEducacionFormal === "" && userData['CurriculoNivelEducativos'].length === 0)
			errors.push({ field: 'Nivel Educativo', error: 'Seleccione Campo Tiene educación formal' });
		if (isValidFieldRequired(nivelEducativo))
			errors.push({ field: 'Nivel Educativo', error: 'Campo completado' });
		if (isValidFieldRequired(tituloObtenido))
			errors.push({ field: 'Titulo Obtenido', error: 'Campo completado' });
		if (isValidFieldRequired(tituloHomologado))
			errors.push({ field: 'Titulo Homologado', error: 'Campo completado' });
		if (isValidFieldRequired(nucleoConocimiento))
			errors.push({ field: 'Nucleo Conocimiento', error: 'Campo completado' });
		if (isValidFieldRequired(areaDesempeno))
			errors.push({ field: 'Área de desempeño', error: 'Campo completado' });
		if (isValidFieldRequired(paisDelCurso))
			errors.push({ field: 'País del curso', error: 'Campo completado' });
		if (isValidFieldRequired(estadoDelCurso))
			errors.push({ field: 'Estado del curso', error: 'Campo completado' });
		if (isValidFieldRequired(idObservaciones))
			errors.push({ field: 'IdObservaciones', error: 'Campo completado' });
		if (interessadoPractica)
			errors.push({ field: 'Interessado Practica', error: 'Campo completado' });
		if (isValidFieldRequired(institucion))
			errors.push({ field: 'Institución', error: 'Campo completado' });
		if (isValidFieldRequired(fechafinalizacion))
			errors.push({ field: 'Fecha Finalizacion', error: 'Campo completado' });
		console.log(errors);

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\ ${t('Diligencie los campos completado')}\n ${errors.map((x) => {
					return `\n ${t(x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase()))}: ${t(x.error)
						}`;
				})}`
			);
			setOpen(true);
			return false;
		}

		return true;

	};

	const handleAddNivelEducattivo = () => {
		if (handleValidOnSubmit()) {
			const listNivel = ddlNivel.find((obj) => {
				return obj.id === nivelEducativo;
			});
			console.log('ddl nivel', ddlTitEquivalente);
			console.log('titulo h', tituloHomologado);
			const listTitEquivalente = ddlTitEquivalente.find((obj) => {
				return obj.id === tituloHomologado;
			});
			const listArea = ddlArea.find((obj) => {
				return obj.id === areaDesempeno;
			});
			const listNucleo = ddlNucleo.find((obj) => {
				return obj.id === nucleoConocimiento;
			});
			const listPaisTitulo = ddlPaisTitulo.find((obj) => {
				return obj.id === paisDelCurso;
			});
			const listEstado = ddlEstado.find((obj) => {
				return obj.id === estadoDelCurso;
			});

			let idNivel = listNivel?.name as string;
			let idTitEquivalente = listTitEquivalente?.name as string;
			let idArea = listArea?.name as string;
			let idNucleo = listNucleo?.name as string;
			let idPaisTitulo =
				(listPaisTitulo?.name as string) === undefined ? '' : (listPaisTitulo?.name as string);
			let idEstado = listEstado?.name as string;
			let d = new Date();
			let m = d.getMinutes();
			let s = d.getSeconds();
			let idRandom = openRowEdit || parseInt(`${m}${s}`);
			setRowsNivelEducativo([
				...rowsNivelEducativo,
				{
					id: idRandom,
					nivelEducativo: idNivel,
					tituloObtenido: tituloObtenido,
					tituloHomologado: idTitEquivalente,
					areaDesempeno: idArea,
					nucleoConocimiento: idNucleo,
					paisDelCurso: idPaisTitulo,
					estadoDelCurso: idEstado,
					idObservaciones: idObservaciones,
					txtNumeroTarjProf: txtNumeroTarjProf,
					fechaTarjProf: fechaTarjProf,
					interessadoPractica: interessadoPractica,
					Institucion: institucion,
					fechafinalizacion: fechafinalizacion,
				},
			]);
			setOpen(true);
			setAlert(true);
			setAlertMsg(`Nivel educativo Agregado con éxito!`);
			setTimeout(() => {
				setOpen(false);
			}, 7000);
			handleclear();
		}
	};
	// Handle the case of delete confirmation where
	// user click yes delete a specific row of id:i
	const handleRemoveNivelEducativo = (id: number) => {
		const list = [...rowsNivelEducativo];
		list.splice(_.findIndex(list, { id: id }), 1);
		setRowsNivelEducativo(list);
		if (rowsNivelEducativo.length === 0) {
			setDisplay('');
			setTieneEducacionFormal('');
		}

		if (list.length === 0) {
			setDisplay('');
			setTieneEducacionFormal('');
		}
	};

	const handleNext = () => {
		setUserData({ ...userData, CurriculoNivelEducativos: rowsNivelEducativo });
		setStep(6);
	};
	const handleRetorno = () => {
		setUserData({ ...userData, CurriculoNivelEducativos: rowsNivelEducativo });
		setStep(4);
	};

	const buscaDDLTitEquivalente = (nivel: any) => {
		console.log('chegou nivel', nivel);
		if (nivel !== '') {
			if (nucleoConocimiento) {
				setNucleoConocimiento('');
			}
			const list = [...ddlTitEquivalente];
			(list as Array<any>).forEach((d) => {
				ddlTitEquivalente.splice(_.findIndex(ddlTitEquivalente, { id: d['id'] }), 1);
				///setDDLTitEquivalente(list);
			});
			let id = userData['ddlNivelEducativo'].find(
				(y: any) => y.sigla_nivel_educativo === nivel
			)?.id;
			(userData['ddlNivelNucleo'] as Array<any>).forEach((n) => {
				if (n['id_nivel_educativo'] === id) {
					(userData['ddlTituloHomologado'] as Array<any>).forEach((t) => {
						if (n['id'] === t['id_nivel_nucleo']) {
							let x = {
								id: setValueOrDefault(t['id'], 'number').toString(),
								name: setValueOrDefault(t['desc_titulo_homologado'], 'string'),
							};
							console.log('x', x);
							ddlTitEquivalente.push(x);
							// setDDLTitEquivalente([
							// 	...ddlTitEquivalente,
							// 	{
							// 		id: setValueOrDefault(t['id'], 'string'),
							// 		name: setValueOrDefault(t['desc_titulo_homologado'], 'string'),
							// 	},
							// ]);
						}
					});
				}
			});
		}
	};

	const showDatePickeRetiro = () => {
		// @ts-ignore
		document.querySelector('#fechafinalizacion').showPicker();
	};

	const showDatePickeFechaTarjProf = () => {
		// @ts-ignore
		document.querySelector('input[type=date]').showPicker();
	};


	const buscaDDLNucleoEquivalente = (nivel: any) => {
		console.log('nivel busca nucleo', nivel);
		if (!editMode) setNucleoConocimiento(nivel);
		if (nivel !== '') {
			const list3 = [...ddlTitEquivalente];
			(list3 as Array<any>).forEach((d) => {
				ddlTitEquivalente.splice(_.findIndex(ddlTitEquivalente, { id: d['id'] }), 1);
				///setDDLTitEquivalente(list);
			});
			let id = userData['ddlNivelEducativo'].find(
				(y: any) => y.sigla_nivel_educativo === nivelEducativo
			)?.id;
			(userData['ddlNivelNucleo'] as Array<any>).forEach((n) => {
				if (n['id_nucleo_conocimento'] === nivel && n['id_nivel_educativo'] === id) {
					(userData['ddlTituloHomologado'] as Array<any>).forEach((t) => {
						if (n['id'] === t['id_nivel_nucleo']) {
							let x = {
								id: setValueOrDefault(t['id'], 'number'),
								name: setValueOrDefault(t['desc_titulo_homologado'], 'string'),
							};
							console.log('x nucl equ', x);
							ddlTitEquivalente.push(x);
						}
					});
				}
			});
		}
	};

	const buscaDDLArea = (nivel: any) => {
		if (nivel !== '') {
			const list = [...ddlArea];
			(list as Array<any>).forEach((d) => {
				ddlArea.splice(_.findIndex(ddlArea, { id: d['id'] }), 1);
				///setDDLTitEquivalente(list);
			});

			(userData['ddlArea'] as Array<any>).forEach((n) => {
				let x = {
					id: setValueOrDefault(n['id'], 'number'),
					name: setValueOrDefault(n['desc_area_desempeno'], 'string'),
				};
				ddlArea.push(x);
			});
		}
	};

	const buscaDDLNucleo = (nivel: any) => {
		if (nivel !== '') {
			const list2 = [...ddlNucleo];
			(list2 as Array<any>).forEach((d) => {
				ddlNucleo.splice(_.findIndex(ddlNucleo, { id: d['id'] }), 1);
				///setDDLTitEquivalente(list);
			});
			let id = userData['ddlNivelEducativo'].find(
				(y: any) => y.sigla_nivel_educativo === nivel
			)?.id;
			(userData['ddlNivelNucleo'] as Array<any>).forEach((n) => {
				if (n['id_nivel_educativo'] === id) {
					(userData['ddlNucleoConocimiento'] as Array<any>).forEach((t) => {
						if (n['id_nucleo_conocimento'] === t['id']) {
							let x = {
								id: setValueOrDefault(t['id'], 'number'),
								name: setValueOrDefault(t['desc_nucleo_conocimiento'], 'string'),
							};
							ddlNucleo.push(x);
						}
					});
				}
			});
		}
	};

	const FieldsNivelEducativo = (nivel: any) => {
		console.log('FieldsNivelEducativo nucleocone', nucleoConocimiento);
		console.log('FieldsNivelEducativo nivel', nivel);

		switch (nivel) {
			case 'PR':
			case 'P':
			case 'BS':
			case 'MD':
				buscaDDLTitEquivalente(nivel);
				return (
					<>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<TextField
								fullWidth
								id={'tituloObtenido'}
								name={'tituloObtenido'}
								label={t('Título obtenido')}
								value={tituloObtenido}
								onChange={(e) => setTituloObtenido(e.target.value)}
								placeholder={t('Escriba el título')}
								size='medium'
							/>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={!isValidFieldRequired(tituloHomologado)}
							>
								<InputLabel required id={'tituloHomologado'}>
									{t('Título homologado')}
								</InputLabel>
								<Select
									required
									labelId={'tituloHomologado'}
									id={'tituloHomologado'}
									label={t('Título homologado')}
									value={initValue(tituloHomologado)}
									onChange={(e) => setTituloHomologado(e.target.value)}
								>
									<MenuItem value=''>{t('Seleccione...')}</MenuItem>
									{ddlTitEquivalente.map(({ id, name }) => (
										<MenuItem key={id} value={id}>
											{t(name)}
										</MenuItem>
									))}
								</Select>
								{!isValidFieldRequired(tituloHomologado) && (
									<FormHelperText>
										{!isValidFieldRequired(tituloHomologado) ? t('Campo requerido') : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid xs={4} mb={4} pr={2} item>
							<TextField
								sx={{ minWidth: '100%' }}
								required
								fullWidth
								id={'institucion'}
								name={'institucion'}
								label={t('Institución')}
								value={institucion}
								onChange={(e) => setInstitucion(e.target.value)}
								placeholder='Escreba el institución'
								size='medium'
								error={!isValidFieldRequired(institucion)}
								helperText={!isValidFieldRequired(institucion) ? t('Campo requerido') : ''}
							/>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={!isValidFieldRequired(estadoDelCurso)}
							>
								<InputLabel required id={'estadoDelCurso'}>
									{t('Estado del curso')}
								</InputLabel>
								<Select
									required
									labelId={'estadoDelCurso'}
									id={'estadoDelCurso'}
									label={t('Estado del curso')}
									value={estadoDelCurso}
									onChange={(e) => setEstadoDelCurso(e.target.value)}
								>
									<MenuItem value=''>{t('Seleccione...')}</MenuItem>
									{ddlEstado.map(({ id, name }) => (
										<MenuItem key={id} value={id}>
											{t(name)}
										</MenuItem>
									))}
								</Select>
								{!isValidFieldRequired(estadoDelCurso) && (
									<FormHelperText>
										{!isValidFieldRequired(estadoDelCurso) ? t('Campo requerido') : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						{estadoDelCurso === '3' && (
							<>
								<Grid xs={4} mb={4} pr={2} item>
									<TextField
										fullWidth
										required
										type="month"
										label={t('Fecha de Finalizacion')}
										id='fechafinalizacion'
										InputLabelProps={{ shrink: true }}
										name={fechafinalizacion}
										value={fechafinalizacion}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end' role='button'>
													<i
														className='bi bi bi-calendar2-plus-fill'
														style={{
															marginRight: '20px',
															fontSize: '15px',
														}}
														onClick={() => showDatePickeRetiro()}
													></i>
												</InputAdornment>
											),
											inputProps: { max: getDateNowToString(), min: '1901-01' },
										}}
										onChange={(e) => setFechafinalizacion(e.target.value)}
										size='medium'
									/>
								</Grid>
							</>
						)}
						<Grid xs={4} mb={5} pr={2} item></Grid>

						<Grid xs={12} mb={5} item>
							<TextField
								fullWidth
								id={'idObservaciones'}
								name={'idObservaciones'}
								label={t('Observaciones')}
								placeholder={t('Escriba observaciones')}
								value={idObservaciones}
								inputProps={{ type: 'text', maxLength: 100 }}
								onChange={(e) => setIdObservaciones(e.target.value)}
								size='medium'
							/>
						</Grid>
						<Grid xs={4} mb={5} item>
							<FormControlLabel
								value={interessadoPractica}
								control={
									<Checkbox
										color='default'
										checked={interessadoPractica}
										value={interessadoPractica}
										onChange={(e) => setInteressadoPractica(e.target.checked)}
									/>
								}
								label={t('Estoy interesado(a) en realizar una práctica empresarial')}
								labelPlacement='end'
							/>
						</Grid>
					</>
				);
			case 'TL':
				buscaDDLArea(nivel);
				return (
					<>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<TextField
								fullWidth
								id={'tituloObtenido'}
								name={'tituloObtenido'}
								label={t('Título obtenido')}
								value={tituloObtenido}
								onChange={(e) => setTituloObtenido(e.target.value)}
								placeholder={t('Escriba el título')}
								size='medium'
							/>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={!isValidFieldRequired(areaDesempeno)}
							>
								<InputLabel required id={'areaDesempeno'}>
									{t('Área de desempeño')}
								</InputLabel>
								<Select
									required
									labelId={'areaDesempeno'}
									id={'areaDesempeno'}
									label={t('Área de desempeño')}
									value={areaDesempeno}
									onChange={(e) => setAreaDesempeno(e.target.value)}
								>
									<MenuItem value=''>{t('Seleccione...')}</MenuItem>
									{ddlArea.map(({ id, name }) => (
										<MenuItem key={id} value={id}>
											{t(name)}
										</MenuItem>
									))}
								</Select>
								{!isValidFieldRequired(areaDesempeno) && (
									<FormHelperText>
										{!isValidFieldRequired(areaDesempeno) ? t('Campo requerido') : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={!isValidFieldRequired(paisDelCurso)}
							>
								<InputLabel required id={'paisDelCurso'}>
									{t('País del título')}
								</InputLabel>
								<Select
									required
									labelId={'paisDelCurso'}
									id={'paisDelCurso'}
									label={t('País del título')}
									value={paisDelCurso}
									onChange={(e) => setPaisDelCurso(e.target.value)}
								>
									<MenuItem value=''>{t('Seleccione...')}</MenuItem>
									{ddlPaisTitulo.map(({ id, name }) => (
										<MenuItem key={id} value={id}>
											{t(name)}
										</MenuItem>
									))}
								</Select>
								{!isValidFieldRequired(paisDelCurso) && (
									<FormHelperText>
										{!isValidFieldRequired(paisDelCurso) ? t('Campo requerido') : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={!isValidFieldRequired(estadoDelCurso)}
							>
								<InputLabel required id={'estadoDelCurso'}>
									{t('Estado del curso')}
								</InputLabel>
								<Select
									required
									labelId={'estadoDelCurso'}
									id={'estadoDelCurso'}
									label={t('Estado del curso')}
									value={estadoDelCurso}
									onChange={(e) => setEstadoDelCurso(e.target.value)}
								>
									<MenuItem value=''>{t('Seleccione...')}</MenuItem>
									{ddlEstado.map(({ id, name }) => (
										<MenuItem key={id} value={id}>
											{t(name)}
										</MenuItem>
									))}
								</Select>
								{!isValidFieldRequired(estadoDelCurso) && (
									<FormHelperText>
										{!isValidFieldRequired(estadoDelCurso) ? t('Campo requerido') : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>

						<Grid xs={12} md={4} mb={5} pr={2} item>
							<TextField
								required
								fullWidth
								id={'institucion'}
								name={'institucion'}
								label={t('Institución')}
								value={institucion}
								onChange={(e) => setInstitucion(e.target.value)}
								placeholder={t('Escriba la institución')}
								size='medium'
								error={!isValidFieldRequired(institucion)}
								helperText={!isValidFieldRequired(institucion) ? t('Campo requerido') : ''}
							/>
						</Grid>
						{estadoDelCurso === '3' && (
							<>
								<Grid xs={4} mb={5} pr={2} item>
									<TextField
										fullWidth
										required
										type="month"
										label={t('Fecha de Finalizacion')}
										id='fechafinalizacion'
										InputLabelProps={{ shrink: true }}
										name={fechafinalizacion}
										value={fechafinalizacion}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end' role='button'>
													<i
														className='bi bi bi-calendar2-plus-fill'
														style={{
															marginRight: '20px',
															fontSize: '15px',
														}}
														onClick={() => showDatePickeRetiro()}
													></i>
												</InputAdornment>
											),
											inputProps: { max: getDateNowToString(), min: '1901-01' },
										}}
										onChange={(e) => setFechafinalizacion(e.target.value)}
										size='medium'
									/>
								</Grid>
							</>
						)}
						{estadoDelCurso === '3' && (
							<>
								<Grid xs={4} mb={5} pr={2} item>
									<FormControl
										sx={{ minWidth: '100%' }}
										size='medium'

									>
										<InputLabel required id={'tarjetaProfesional'}>
											{t('Tiene tarjeta profesional')}
										</InputLabel>
										<Select
											required
											labelId={'tarjetaProfesional'}
											id={'tarjetaProfesional'}
											label={t('Tiene tarjeta profesional')}
											value={tarjetaProfesional}
											onChange={(e) => setTarjetaProfesional(e.target.value)}
										>
											<MenuItem value={''}>{t('Seleccione...')}</MenuItem>
											<MenuItem value={'0'}>{t('NO')}</MenuItem>
											<MenuItem value={'1'}>{t('SI')}</MenuItem>
										</Select>
										{!isValidFieldRequired(tarjetaProfesional) && (
											<FormHelperText>
												{!isValidFieldRequired(tarjetaProfesional) ? t('Campo requerido') : ''}
											</FormHelperText>
										)}
									</FormControl>

								</Grid>
							</>
						)}

						{tarjetaProfesional === '1' && (
							<>
								<Grid xs={12} md={4} mb={5} pr={2} item>
									<TextField
										required
										fullWidth
										id={'txtNumeroTarjProf'}
										name={'txtNumeroTarjProf'}
										label={t('Número tarjeta profesional')}
										value={txtNumeroTarjProf}
										onChange={(e) => setTxtNumeroTarjProf(e.target.value)}
										placeholder={t('Número tarjeta profesional')}
										size='medium'
										inputProps={{ type: 'text', maxLength: 30 }}
										error={!isValidFieldRequired(txtNumeroTarjProf)}
										helperText={!isValidFieldRequired(txtNumeroTarjProf) ? t('Campo requerido') : ''}
									/>
								</Grid>
								<Grid xs={4} mb={5} pr={2} item>
									<TextField
										fullWidth
										required
										type='date'
										label={t('Fecha expedición de tarjeta profesional')}
										id='fechaTarjProf'
										InputLabelProps={{ shrink: true }}
										name={fechaTarjProf}
										value={fechaTarjProf}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end' role='button'>
													<i
														className='bi bi bi-calendar2-plus-fill'
														style={{
															marginRight: '20px',
															fontSize: '15px',
														}}
														onClick={() => showDatePickeFechaTarjProf()}
													></i>
												</InputAdornment>
											),
											inputProps: { max: getDateNowToString(), min: '1901-01' },
										}}
										onChange={(e) => setFechaTarjProf(e.target.value)}
										size='medium'
									/>
								</Grid>
							</>
						)}
						<Grid xs={4} mb={5} pr={2} item></Grid>
						<Grid xs={12} mb={5} item>
							<TextField
								fullWidth
								id={'idObservaciones'}
								name={'idObservaciones'}
								label={t('Observaciones')}
								placeholder={t('Escriba observaciones')}
								value={idObservaciones}
								inputProps={{ type: 'text', maxLength: 100 }}
								onChange={(e) => setIdObservaciones(e.target.value)}
								size='medium'
							/>
						</Grid>
						<Grid xs={12} md={4} mb={5} item>
							<FormControlLabel
								value={interessadoPractica}
								control={
									<Checkbox
										color='default'
										checked={interessadoPractica}
										value={interessadoPractica}
										onChange={(e) => setInteressadoPractica(e.target.checked)}
									/>
								}
								label={t('Estoy interesado(a) en realizar una práctica empresarial')}
								labelPlacement='end'
							/>
						</Grid>
					</>
				);
			case 'TP':
			case 'T':
			case 'PG':
			case 'E':
			case 'M':
			case 'D':
				buscaDDLNucleo(nivel);
				if (editMode) buscaDDLNucleoEquivalente(nucleoConocimiento);
				return (
					<>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<TextField
								fullWidth
								id={'tituloObtenido'}
								name={'tituloObtenido'}
								label={t('Título obtenido')}
								value={tituloObtenido}
								onChange={(e) => setTituloObtenido(e.target.value)}
								placeholder={t('Escriba el título')}
								size='medium'
							/>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								className='col-12'
								error={!isValidFieldRequired(nucleoConocimiento)}
							>
								<InputLabel required id={'nucleoConocimiento'}>
									{t('Núcleo de conocimiento')}
								</InputLabel>
								<Select
									required
									labelId={'nucleoConocimiento'}
									id={'nucleoConocimiento'}
									label={t('Núcleo de conocimiento')}
									value={nucleoConocimiento}
									onChange={(e) => {
										buscaDDLNucleoEquivalente(e.target.value);
										setNucleoConocimiento(e.target.value);
									}}
								>
									<MenuItem value=''>{('Seleccione...')}</MenuItem>
									{ddlNucleo.map(({ id, name }) => (
										<MenuItem key={id} value={id}>
											{t(name)}
										</MenuItem>
									))}
								</Select>
								{!isValidFieldRequired(nucleoConocimiento) && (
									<FormHelperText>
										{!isValidFieldRequired(nucleoConocimiento) ? t('Campo requerido') : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						{FieldsNucleo(nucleoConocimiento)}
					</>
				);
			default:
				return <></>;
		}
	};

	const FieldsNucleo = (nivel: any) => {
		if (nivel) {
			return (
				<>
					<Grid xs={12} md={4} mb={5} pr={2} item>
						<FormControl
							sx={{ minWidth: '100%' }}
							size='medium'
							className='col-12'
							error={!isValidFieldRequired(tituloHomologado)}
						>
							<InputLabel required id={'tituloHomologado'}>
								{t('Título homologado')}
							</InputLabel>
							<Select
								required
								labelId={'tituloHomologado'}
								id={'tituloHomologado'}
								label={t('Título homologado')}
								value={tituloHomologado}
								onChange={(e) => setTituloHomologado(e.target.value)}
							>
								<MenuItem value=''>{t('Seleccione...')}</MenuItem>
								{ddlTitEquivalente.map(({ id, name }) => (
									<MenuItem key={id} value={id}>
										{t(name)}
									</MenuItem>
								))}
							</Select>
							{!isValidFieldRequired(tituloHomologado) && (
								<FormHelperText>
									{!isValidFieldRequired(tituloHomologado) ? t('Campo requerido') : ''}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>

					<Grid xs={12} md={4} mb={5} pr={2} item>
						<FormControl
							sx={{ minWidth: '100%' }}
							size='medium'
							error={!isValidFieldRequired(paisDelCurso)}
						>
							<InputLabel required id={'paisDelCurso'}>
								{t('País titulo')}
							</InputLabel>
							<Select
								required
								labelId={'paisDelCurso'}
								id={'paisDelCurso'}
								label={t('País titulo')}
								value={paisDelCurso}
								onChange={(e) => setPaisDelCurso(e.target.value)}
							>
								<MenuItem value=''>{t('Seleccione...')}</MenuItem>
								{ddlPaisTitulo.map(({ id, name }) => (
									<MenuItem key={id} value={id}>
										{t(name)}
									</MenuItem>
								))}
							</Select>
							{!isValidFieldRequired(paisDelCurso) && (
								<FormHelperText>
									{!isValidFieldRequired(paisDelCurso) ? t('Campo requerido') : ''}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid xs={12} md={4} mb={5} pr={2} item>
						<FormControl
							sx={{ minWidth: '100%' }}
							size='medium'
							error={!isValidFieldRequired(estadoDelCurso)}
						>
							<InputLabel required id={'estadoDelCurso'}>
								{t('Estado del curso')}
							</InputLabel>
							<Select
								required
								labelId={'estadoDelCurso'}
								id={'estadoDelCurso'}
								label={t('Estado del curso')}
								value={estadoDelCurso}
								onChange={(e) => setEstadoDelCurso(e.target.value)}
							>
								<MenuItem value=''>{t('Seleccione...')}</MenuItem>
								{ddlEstado.map(({ id, name }) => (
									<MenuItem key={id} value={id}>
										{t(name)}
									</MenuItem>
								))}
							</Select>
							{!isValidFieldRequired(estadoDelCurso) && (
								<FormHelperText>
									{!isValidFieldRequired(estadoDelCurso) ? t('Campo requerido') : ''}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>

					<Grid xs={12} md={4} mb={5} pr={2} item>
						<TextField
							required
							fullWidth
							id={'institucion'}
							name={'institucion'}
							label={t('Institución')}
							value={institucion}
							onChange={(e) => setInstitucion(e.target.value)}
							placeholder={t('Escriba la institución')}
							size='medium'
							error={!isValidFieldRequired(institucion)}
							helperText={!isValidFieldRequired(institucion) ? t('Campo requerido') : ''}
						/>
					</Grid>
					{estadoDelCurso === '3' && (
						<>
							<Grid xs={12} md={4} mb={5} pr={2} item>
								<TextField
									sx={{ minWidth: '100%' }}
									fullWidth
									required
									type="month"
									label={t('Fecha de Finalizacion')}
									id='fechafinalizacion'
									InputLabelProps={{ shrink: true }}
									name={fechafinalizacion}
									value={fechafinalizacion}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end' role='button'>
												<i
													className='bi bi bi-calendar2-plus-fill'
													style={{
														marginRight: '20px',
														fontSize: '15px',
													}}
													onClick={() => showDatePickeRetiro()}
												></i>
											</InputAdornment>
										),
										inputProps: { max: getDateNowToString(), min: '1901-01' },
									}}
									onChange={(e) => setFechafinalizacion(e.target.value)}
									size='medium'
								/>
							</Grid>
						</>
					)}
					{estadoDelCurso === '3' && (
						<>
							<Grid xs={4} mb={5} pr={2} item>
								<FormControl
									sx={{ minWidth: '100%' }}
									size='medium'

								>
									<InputLabel required id={'tarjetaProfesional'}>
										{t('Tiene tarjeta profesional')}
									</InputLabel>
									<Select
										required
										labelId={'tarjetaProfesional'}
										id={'tarjetaProfesional'}
										label={t('Tiene tarjeta profesional')}
										value={tarjetaProfesional}
										onChange={(e) => setTarjetaProfesional(e.target.value)}
									>
										<MenuItem value={''}>{t('Seleccione...')}</MenuItem>
										<MenuItem value={'0'}>{t('NO')}</MenuItem>
										<MenuItem value={'1'}>{t('SI')}</MenuItem>
									</Select>
									{!isValidFieldRequired(tarjetaProfesional) && (
										<FormHelperText>
											{!isValidFieldRequired(tarjetaProfesional) ? t('Campo requerido') : ''}
										</FormHelperText>
									)}
								</FormControl>

							</Grid>
						</>
					)}

					{tarjetaProfesional === '1' && (
						<>
							<Grid xs={12} md={4} mb={5} pr={2} item>
								<TextField
									required
									fullWidth
									id={'txtNumeroTarjProf'}
									name={'txtNumeroTarjProf'}
									label={t('Número tarjeta profesional')}
									value={txtNumeroTarjProf}
									onChange={(e) => setTxtNumeroTarjProf(e.target.value)}
									placeholder={t('Número tarjeta profesional')}
									size='medium'
									inputProps={{ type: 'text', maxLength: 30 }}
									error={!isValidFieldRequired(txtNumeroTarjProf)}
									helperText={!isValidFieldRequired(txtNumeroTarjProf) ? t('Campo requerido') : ''}
								/>
							</Grid>
							<Grid xs={4} mb={5} pr={2} item>
								<TextField
									fullWidth
									required
									type='date'
									label={t('Fecha expedición de tarjeta profesional')}
									id='fechaTarjProf'
									InputLabelProps={{ shrink: true }}
									name={fechaTarjProf}
									value={fechaTarjProf}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end' role='button'>
												<i
													className='bi bi bi-calendar2-plus-fill'
													style={{
														marginRight: '20px',
														fontSize: '15px',
													}}
													onClick={() => showDatePickeFechaTarjProf()}
												></i>
											</InputAdornment>
										),
										inputProps: { max: getDateNowToString(), min: '1901-01' },
									}}
									onChange={(e) => setFechaTarjProf(e.target.value)}
									size='medium'
								/>
							</Grid>
						</>
					)}
					<Grid xs={12} md={4} mb={5} pr={2} item></Grid>
					<Grid xs={12} mb={5} item>
						<TextField
							fullWidth
							id={'idObservaciones'}
							name={'idObservaciones'}
							label={t('Observaciones')}
							placeholder={t('Escriba observaciones')}
							value={idObservaciones}
							inputProps={{ type: 'text', maxLength: 100 }}
							onChange={(e) => setIdObservaciones(e.target.value)}
							size='medium'
						/>
					</Grid>
					<Grid xs={12} md={4} mb={5} item>
						<FormControlLabel
							value={interessadoPractica}
							control={
								<Checkbox
									color='default'
									checked={interessadoPractica}
									value={interessadoPractica}
									onChange={(e) => setInteressadoPractica(e.target.checked)}
								/>
							}
							label={t('Estoy interesado(a) en realizar una práctica empresarial')}
							labelPlacement='end'
						/>
					</Grid>
				</>
			);
		} else {
			return <></>;
		}
	};

	const handleValidOnSubmit = () => {
		let errors: { field: string; error: string }[] = [];
		if (tieneEducacionFormal === '1')
			if (!isValidFieldRequired(nivelEducativo))
				errors.push({ field: 'Nivel educativo', error: 'Seleccionar al menos un nível educativo' });
			else {
				switch (nivelEducativo) {
					case 'PR':
					case 'P':
					case 'BS':
					case 'MD':
						if (!isValidFieldRequired(tituloHomologado))
							errors.push({ field: 'Título homologado', error: 'Campo requerido' });
						if (!isValidFieldRequired(estadoDelCurso))
							errors.push({ field: 'Estado del curso', error: 'Campo requerido' });
						if (!isValidFieldRequired(institucion))
							errors.push({ field: 'Institución', error: 'Campo requerido' });
						break;
					case 'TL':
						if (!isValidFieldRequired(areaDesempeno))
							errors.push({ field: 'Área de desempeño', error: 'Campo requerido' });
						if (!isValidFieldRequired(paisDelCurso))
							errors.push({ field: 'País del curso', error: 'Campo requerido' });
						if (!isValidFieldRequired(estadoDelCurso))
							errors.push({ field: 'Estado del curso', error: 'Campo requerido' });
						if (!isValidFieldRequired(institucion))
							errors.push({ field: 'Institución', error: 'Campo requerido' });
						if (tarjetaProfesional === '1') {
							if (!isValidFieldRequired(txtNumeroTarjProf))
								errors.push({ field: 'Institución', error: 'Campo requerido' });
							if (!isValidFieldRequired(fechaTarjProf))
								errors.push({ field: 'Institución', error: 'Campo requerido' });
						}
						break;
					case 'TP':
					case 'T':
					case 'PG':
					case 'E':
					case 'M':
					case 'D':
						if (!isValidFieldRequired(nucleoConocimiento))
							errors.push({ field: 'nucleo_Conocimiento', error: 'Campo requerido' });
						else {
							if (!isValidFieldRequired(tituloHomologado))
								errors.push({ field: 'titulo_homologado', error: 'Campo requerido' });
							if (!isValidFieldRequired(paisDelCurso))
								errors.push({ field: 'pais_Del_Curso', error: 'Campo requerido' });
							if (!isValidFieldRequired(estadoDelCurso))
								errors.push({ field: 'estado_Del_Curso', error: 'Campo requerido' });
							if (!isValidFieldRequired(institucion))
								errors.push({ field: 'institucion', error: 'Campo requerido' });
						}
						if (tarjetaProfesional === '1') {
							if (!isValidFieldRequired(txtNumeroTarjProf))
								errors.push({ field: 'Institución', error: 'Campo requerido' });
							if (!isValidFieldRequired(fechaTarjProf))
								errors.push({ field: 'Institución', error: 'Campo requerido' });
						}
						break;
					default:
						break;
				}
			}

		console.log(errors);

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\ ${t('Diligencie los campos obligatorios')}\n ${errors.map((x) => {
					return `\n ${t(x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase()))}: ${t(x.error)
						}`;
				})}`
			);
			setOpen(true);
			return false;
		}

		return true;
	};

	const handleClose = () => {
		setOpen(false);
	};

	async function handleSubmit(event: FormEvent) {

		if (handleValidOnSave()) {
			console.log('userDataFinal', userData);
			setUserData({ ...userData, CurriculoNivelEducativos: rowsNivelEducativo });
			try {
				event.preventDefault();
				let object = createObjectToSubmit();
				console.log('objectFinal', object);
				setLoading(true);
				const retCurriculo = await createCurriculo(object);
				console.log('retCurriculo', retCurriculo);
				setOpen(true);
				setAlert(true);
				setAlertMsg(`¡Registro completado con éxito!`);

				setOpen(true);
				setStep(6);
				setLoading(false);
			} catch (error) {
				console.error(error);
				setOpen(true);
				setAlert(false);
				setAlertMsg('Ha ocurrido un error!');
			}
		}
	}

	const createObjectDirecao = (): ComplementoDirecaoModel[] => {
		let compDirecaoArr: ComplementoDirecaoModel[] = [];
		if (
			userData['complementoDirecao'] !== null &&
			userData['complementoDirecao'] !== undefined &&
			(userData['complementoDirecao'] as Array<any>).length > 0
		) {
			(userData['complementoDirecao'] as Array<any>).forEach((e) => {
				let x: ComplementoDirecaoModel = {
					complemento: setValueOrDefault(e['complemento'], 'string'),
					id: setValueOrDefault(e['id'], 'number'),
					tipo_complemento: setValueOrDefault(e['tipo_complemento'], 'string'),
				};
				compDirecaoArr.push(x);
			});
		}

		return compDirecaoArr;
	};

	const createObjectPuestos = (): PuestosModel[] => {
		let puestosArr: PuestosModel[] = [];

		if (
			userData['listPuestos'] !== null &&
			userData['listPuestos'] !== undefined &&
			(userData['listPuestos'] as Array<any>).length > 0
		) {
			(userData['listPuestos'] as Array<any>).forEach((e) => {
				let x: PuestosModel = {
					id: setValueOrDefault(e['id'], 'number'),
					puestos: setValueOrDefault(e['puestos'], 'string'),
				};
				puestosArr.push(x);
			});
		}

		return puestosArr;
	};

	const createObjectNivelEducativo = (): NivelEducativoModel[] => {
		let nivelEducativoArr: NivelEducativoModel[] = [];
		if (
			rowsNivelEducativo !== null &&
			rowsNivelEducativo !== undefined &&
			(rowsNivelEducativo as Array<any>).length > 0
		) {
			(rowsNivelEducativo as Array<any>).forEach((e) => {
				let x: NivelEducativoModel = {
					id: setValueOrDefault(e['id'], 'number'),
					areaDesempeno: setValueOrDefault(e['areaDesempeno'], 'string'),
					estadoDelCurso: setValueOrDefault(e['estadoDelCurso'], 'string'),
					idObservaciones: setValueOrDefault(e['idObservaciones'], 'string'),
					txtNumeroTarjProf: setValueOrDefault(e['txtNumeroTarjProf'], 'string'),
					fechaTarjProf: setValueOrDefault(e['fechaTarjProf'], 'string'),
					interessadoPractica: setValueOrDefault(e['interessadoPractica'], 'boolean'),
					nivelEducativo: setValueOrDefault(e['nivelEducativo'], 'string'),
					nucleoConocimiento: setValueOrDefault(e['nucleoConocimiento'], 'string'),
					paisDelCurso: setValueOrDefault(e['paisDelCurso'], 'string'),
					tituloHomologado: setValueOrDefault(e['tituloHomologado'], 'string'),
					tituloObtenido: setValueOrDefault(e['tituloObtenido'], 'string'),
					Institucion: setValueOrDefault(e['Institucion'], 'string'),
					fechafinalizacion: setValueOrDefault(e['fechafinalizacion'], 'string'),
				};
				nivelEducativoArr.push(x);
			});
		}

		return nivelEducativoArr;
	};

	const createObjectCapacitacionCertificacion = (): CapacitacionCertificacionModel[] => {
		let capCertificacionesArr: CapacitacionCertificacionModel[] = [];
		if (
			userData['capacitacionCertificacion'] !== null &&
			userData['capacitacionCertificacion'] !== undefined &&
			(userData['capacitacionCertificacion'] as Array<any>).length > 0
		) {
			(userData['capacitacionCertificacion'] as Array<any>).forEach((e) => {
				let x: CapacitacionCertificacionModel = {
					id: setValueOrDefault(e['id'], 'number'),
					duracionHoras: setValueOrDefault(e['duracionHoras'], 'string'),
					estadoCapacitacione: setValueOrDefault(e['estadoCapacitacione'], 'string'),
					fechaCertificacion: setValueOrDefault(e['fechaCertificacion'], 'string'),
					instituicion: setValueOrDefault(e['instituicion'], 'string'),
					nombrePrograma: setValueOrDefault(e['nombrePrograma'], 'string'),
					paisCapacitacione: setValueOrDefault(e['paisCapacitacione'], 'string'),
					tpCapacitacionCertificacion: setValueOrDefault(
						e['tpCapacitacionCertificacion'],
						'string'
					),
				};
				capCertificacionesArr.push(x);
			});
		}

		return capCertificacionesArr;
	};

	const createObjectIdioma = (): IdiomaModel[] => {
		let idiomaArr: IdiomaModel[] = [];
		if (
			userData['listIdioma'] !== null &&
			userData['listIdioma'] !== undefined &&
			(userData['listIdioma'] as Array<any>).length > 0
		) {
			(userData['listIdioma'] as Array<any>).forEach((e) => {
				let x: IdiomaModel = {
					id_idioma: setValueOrDefault(e['id'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					idioma: setValueOrDefault(e['idioma'], 'string'),
				};
				idiomaArr.push(x);
			});
		}
		return idiomaArr;
	};

	const createObjectHerramienta = (): HerramientasModel[] => {
		let herramientasArr: HerramientasModel[] = [];
		if (
			userData['listHerramientas'] !== null &&
			userData['listHerramientas'] !== undefined &&
			(userData['listHerramientas'] as Array<any>).length > 0
		) {
			(userData['listHerramientas'] as Array<any>).forEach((e) => {
				let x: HerramientasModel = {
					id: setValueOrDefault(e['id'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					id_tipo_conocimientos: setValueOrDefault(
						ddltipo.find((y) => y.name === e['tipo'])?.id,
						'string'
					),
					herramientas: setValueOrDefault(e['herramientas'], 'string'),
				};
				herramientasArr.push(x);
			});
		}

		return herramientasArr;
	};

	const createObjectExpLaboral = (): ExperienciaLaboralModel[] => {
		let expLaboralArr: ExperienciaLaboralModel[] = [];
		if (
			userData['experienciaLaboral'] !== null &&
			userData['experienciaLaboral'] !== undefined &&
			(userData['experienciaLaboral'] as Array<any>).length > 0
		) {
			(userData['experienciaLaboral'] as Array<any>).forEach((e) => {
				let x: ExperienciaLaboralModel = {
					id: setValueOrDefault(e['id'], 'number'),
					cargo: setValueOrDefault(e['cargo'], 'string'),
					cargoEquivalente: setValueOrDefault(e['cargoEquivalente'], 'string'),
					cuaPersonasTrabajan: setValueOrDefault(e['cuaPersonasTrabajan'], 'string'),
					fechaIngresso: setValueOrDefault(e['fechaIngresso'], 'string'),
					fechaRetiro: setValueOrDefault(e['fechaRetiro'], 'string'),
					funcionesLogros: setValueOrDefault(e['funcionesLogros'], 'string'),
					nombreEmpresa: setValueOrDefault(e['nombreEmpresa'], 'string'),
					productoServicio: setValueOrDefault(e['productoServicio'], 'string'),
					sector: setValueOrDefault(e['sector'], 'string'),
					telefonoEmpresa: setValueOrDefault(e['telefonoEmpresa'], 'string'),
					tpExperienciaLaboral: setValueOrDefault(e['tpExperienciaLaboral'], 'string'),
					trabajandoMismoTrabajo: setValueOrDefault(e['trabajandoMismoTrabajo'], 'boolean'),
					paisEmpresa: setValueOrDefault(e['paisEmpresa'], 'string'),
				};
				expLaboralArr.push(x);
			});
		}
		return expLaboralArr;
	};

	const createObjectUserComplemento = (): UserComplementoModel[] => {
		let userCompArr: UserComplementoModel[] = [];
		let userComp: UserComplementoModel = {
			id_users: setValueOrDefault(userData['id_users'], 'number'),
			primer_nome: setValueOrDefault(userData['primer_nombre'], 'string'),
			segundo_nome: setValueOrDefault(userData['segundo_nombre'], 'string'),
			primer_apelido: setValueOrDefault(userData['primer_apelido'], 'string'),
			segundo_apelido: setValueOrDefault(userData['segundo_apelido'], 'string'),
			tipo_doc: setValueOrDefault(userData['tipo_de_documento'], 'string'),
			numero_doc: setValueOrDefault(userData['numero_documento'], 'string'),
			fecha_nascimiento: setValueOrDefault(userData['fecha_nascimento'], 'string'),
			celular: setValueOrDefault(userData['telefono_contacto'], 'number').toString(),
		};
		userCompArr.push(userComp);
		return userCompArr;
	};

	const createObjectToSubmit = (): ResumeModel => {
		let resume: ResumeModel = {
			id_users: setValueOrDefault(userData['id_users'], 'number'),
			step: setValueOrDefault(step, 'string'),
			estado_civil: setValueOrDefault(userData['estado_civil'], 'number'),
			numero_doc_adicional: setValueOrDefault(userData['numero_doc_adicional'], 'string'),
			tipo_doc_adicional: setValueOrDefault(userData['tipo_doc_adicional'], 'string'),
			sexo: setValueOrDefault(userData['sexo'], 'string'),
			jefe_hogar: setValueOrDefault(userData['jefe_hogar'], 'string'),
			pais_nascimento: setValueOrDefault(userData['pais_nascimento'], 'string'),
			nacionalidad: setValueOrDefault(userData['nacionalidad'], 'string'),
			departamento_nacimiento: setValueOrDefault(userData['departamento_nacimiento'], 'string'),
			municipio_nacimiento: setValueOrDefault(userData['municipio_nacimiento'], 'string'),
			libreta_militar: setValueOrDefault(userData['libreta_militar'], 'string'),
			tipo_libreta: setValueOrDefault(userData['tipo_libreta'], 'string'),
			numero_libreta: setValueOrDefault(userData['numero_libreta'], 'string'),
			reconoce_focalizada: setValueOrDefault(userData['reconoce_focalizada'], 'string'),
			correo_Electronico: setValueOrDefault(userData['correo_Electronico'], 'string'),
			// telefono_contacto: setValueOrDefault(userData['telefono_contacto'], 'string'),
			otro_telefono: setValueOrDefault(userData['otro_telefono'], 'string'),
			observaciones: setValueOrDefault(userData['observaciones'], 'string'),
			pais_residencia: setValueOrDefault(userData['pais_residencia'], 'string'),
			bairro_residencia: setValueOrDefault(userData['bairro_residencia'], 'string'),
			pertence: setValueOrDefault(userData['pertence'], 'string'),
			prestador_preferencia: setValueOrDefault(userData['prestador_preferencia'], 'string'),
			punta_atencion: setValueOrDefault(userData['punta_atencion'], 'string'),
			local: setValueOrDefault(userData['local'], 'string'),
			id_coordenada: setValueOrDefault(userData['id_coordenada'], 'string'),
			id_coordenada_02: setValueOrDefault(userData['id_coordenada_02'], 'string'),
			id_coordenada_03: setValueOrDefault(userData['id_coordenada_03'], 'string'),
			id_coordenada_04: setValueOrDefault(userData['id_coordenada_04'], 'string'),
			id_coordenada_05: setValueOrDefault(userData['id_coordenada_05'], 'string'),
			id_coordenada_06: setValueOrDefault(userData['id_coordenada_06'], 'string'),
			id_coordenada_07: setValueOrDefault(userData['id_coordenada_07'], 'string'),
			id_coordenada_08: setValueOrDefault(userData['id_coordenada_08'], 'string'),
			id_coordenada_09: setValueOrDefault(userData['id_coordenada_09'], 'string'),
			codigo_postal: setValueOrDefault(userData['codigo_postal'], 'string'),
			direccion_residencia: setValueOrDefault(userData['direccion_residencia'], 'string'),
			possibilidade_viajar: setValueOrDefault(userData['possibilidade_viajar'], 'string'),
			situacion_laboral_actual: setValueOrDefault(userData['situacion_laboral_actual'], 'string'),
			aspiracion_salarial: setValueOrDefault(userData['aspiracion_salarial'], 'string'),
			Interes_ofertas_teletrabajo: setValueOrDefault(
				userData['Interes_ofertas_teletrabajo'],
				'string'
			),
			posibilidad_trasladarse: setValueOrDefault(userData['posibilidad_trasladarse'], 'string'),
			conduces_carro: setValueOrDefault(userData['conduces_carro'], 'string'),
			categoria_licencia_carro: setValueOrDefault(userData['categoria_licencia_carro'], 'string'),
			conduces_moto: setValueOrDefault(userData['conduces_moto'], 'string'),
			categoria_licencia_moto: setValueOrDefault(userData['categoria_licencia_moto'], 'string'),
			Prop_medio_transporte: setValueOrDefault(userData['Prop_medio_transporte'], 'string'),
			perfil_laboral: setValueOrDefault(userData['perfil_laboral'], 'string'),

			id_Afrocolombianos: setValueOrDefault(userData['id_Afrocolombianos'], 'boolean'),
			id_Negros: setValueOrDefault(userData['id_Negros'], 'boolean'),
			id_Palenqueros: setValueOrDefault(userData['id_Palenqueros'], 'boolean'),
			id_Raizales: setValueOrDefault(userData['id_Raizales'], 'boolean'),
			id_Indigenas: setValueOrDefault(userData['id_Indigenas'], 'boolean'),
			id_Rrom: setValueOrDefault(userData['id_Rrom'], 'boolean'),
			id_Estuvo: setValueOrDefault(userData['id_Estuvo'], 'boolean'),
			id_jovem_mayor: setValueOrDefault(userData['id_jovem_mayor'], 'boolean'),
			id_persona_habitabilidad: setValueOrDefault(userData['id_persona_habitabilidad'], 'boolean'),
			id_persona_joven: setValueOrDefault(userData['id_persona_joven'], 'boolean'),
			id_persona_mayor_50: setValueOrDefault(userData['id_persona_mayor_50'], 'boolean'),
			id_persona_migrante: setValueOrDefault(userData['id_persona_migrante'], 'boolean'),
			id_persona_trans: setValueOrDefault(userData['id_persona_trans'], 'boolean'),
			id_persona_reincorporación: setValueOrDefault(userData['id_persona_reincorporación'], 'boolean'),
			id_discapacidad_certificada: setValueOrDefault(userData['id_discapacidad_certificada'], 'boolean'),
			id_mujer: setValueOrDefault(userData['id_mujer'], 'boolean'),
			id_persona_victima_trata: setValueOrDefault(userData['id_persona_victima_trata'], 'boolean'),
			id_personas_probreza: setValueOrDefault(userData['id_personas_probreza'], 'boolean'),
			id_Personas: setValueOrDefault(userData['id_Personas'], 'boolean'),
			id_problacion_libertad_3_meses: setValueOrDefault(
				userData['id_problacion_libertad_3_meses'],
				'boolean'
			),
			id_problacion_rural: setValueOrDefault(userData['id_problacion_rural'], 'boolean'),
			id_reciclador: setValueOrDefault(userData['id_reciclador'], 'boolean'),
			id_Retornado: setValueOrDefault(userData['id_Retornado'], 'boolean'),
			id_vendedor_informal: setValueOrDefault(userData['id_vendedor_informal'], 'boolean'),
			id_Vend_informales: setValueOrDefault(userData['id_Vend_informales'], 'boolean'),
			id_victima_violencia_genero: setValueOrDefault(
				userData['id_victima_violencia_genero'],
				'boolean'
			),
			id_victima_conflicto_armado: setValueOrDefault(
				userData['id_victima_conflicto_armado'],
				'boolean'
			),
			id_Fisica: setValueOrDefault(userData['id_Fisica'], 'boolean'),
			id_Cognitiva: setValueOrDefault(userData['id_Cognitiva'], 'boolean'),
			id_visual: setValueOrDefault(userData['id_visual'], 'boolean'),
			id_Sordoceguera: setValueOrDefault(userData['id_Sordoceguera'], 'boolean'),
			id_Auditiva: setValueOrDefault(userData['id_Auditiva'], 'boolean'),
			id_Psicosocial: setValueOrDefault(userData['id_Psicosocial'], 'boolean'),
			id_Multiple: setValueOrDefault(userData['id_Multiple'], 'boolean'),
			id_Problema: setValueOrDefault(userData['id_Problema'], 'boolean'),
			id_Transtorno: setValueOrDefault(userData['id_Transtorno'], 'boolean'),
			id_Victima: setValueOrDefault(userData['id_Victima'], 'boolean'),
			id_Epilepsia: setValueOrDefault(userData['id_Epilepsia'], 'boolean'),
			id_Consumo: setValueOrDefault(userData['id_Consumo'], 'boolean'),
			id_persona_act_sexuales: setValueOrDefault(userData['id_persona_act_sexuales'], 'boolean'),

			userComplemento: createObjectUserComplemento(),
			complementoDirecao: createObjectDirecao(),
			listPuestos: createObjectPuestos(),
			nivelEducativo: createObjectNivelEducativo(),
			capacitacionCertificacion: createObjectCapacitacionCertificacion(),
			listIdioma: createObjectIdioma(),
			listHerramientas: createObjectHerramienta(),
			experienciaLaboral: createObjectExpLaboral(),
		};

		return resume;
	};

	const handleEdit = (item: any) => {
		setEditMode(true);
		setOpenRowEdit(item.id);
		setBorderColorEdit('2px solid blue');
		setTimeout(() => {
			setBorderColorEdit('1px solid');
		}, 4000);
		window.scrollTo(0, 170);
		// const item = userData['CurriculoNivelEducativos'].find((x: any) => x.id === id);
		console.log('item', item);
		console.log('area', ddlArea);
		setTieneEducacionFormal('1');
		setNivelEducativo(ddlNivel.find((x: any) => x.name === item.nivelEducativo)?.id || '');
		setInstitucion(item.Institucion || '');
		setFechafinalizacion(item.fechafinalizacion || '');
		if (item.fechaTarjProf !== '' || item.txtNumeroTarjProf !== '') {
			setTarjetaProfesional('1');
		}
		setFechaTarjProf(item.fechaTarjProf || '');
		setTxtNumeroTarjProf(item.txtNumeroTarjProf || '');
		setAreaDesempeno(
			userData['ddlArea'].find((x: any) => x.desc_area_desempeno === item.areaDesempeno)?.id || ''
		);
		setEstadoDelCurso(ddlEstado.find((x: any) => x.name === item.estadoDelCurso)?.id || '');
		setIdObservaciones(item.idObservaciones || '');
		setInteressadoPractica(item.interessadoPractica || '');
		setNucleoConocimiento(
			userData['ddlNucleoConocimiento'].find(
				(x: any) => x.desc_nucleo_conocimiento === item.nucleoConocimiento
			)?.id || ''
		);
		setPaisDelCurso(ddlPaisTitulo.find((x: any) => x.name === item.paisDelCurso)?.id || '');
		setTituloHomologado(
			userData['ddlTituloHomologado'].find(
				(x: any) => x.desc_titulo_homologado === item.tituloHomologado
			)?.id || ''
		);
		setTituloObtenido(item.tituloObtenido || '');
		console.log(
			't encontraod',
			userData['ddlTituloHomologado'].find(
				(x: any) => x.desc_titulo_homologado === item.tituloHomologado
			)?.id || ''
		);

		console.log(
			'todas ',
			userData['ddlTituloHomologado'].find(
				(x: any) => x.desc_titulo_homologado === item.tituloHomologado
			) || ''
		);
	};

	const handleSaveEdit = () => {
		if (editMode) {
			let item = rowsNivelEducativo.find((x) => x.id === openRowEdit);
			console.log('item edit', item);
			let iRemove = rowsNivelEducativo.findIndex((x) => x.id === openRowEdit);

			if (handleValidOnSubmit()) {
				//setRowsNivelEducativo(rowsNivelEducativo.filter((_, i) => i !== iRemove));
				//handleAddNivelEducattivo();

				const listNivel = ddlNivel.find((obj) => {
					return obj.id === nivelEducativo;
				});
				console.log('ddl tit', ddlTitEquivalente);
				console.log('ddlarea', ddlArea);
				console.log('ddlNucleo', ddlNucleo);
				console.log('titulo h', tituloHomologado);
				const listTitEquivalente = ddlTitEquivalente.find((obj) => {
					return obj.id === tituloHomologado;
				});
				const listArea = ddlArea.find((obj) => {
					return obj.id === areaDesempeno;
				});
				const listNucleo = ddlNucleo.find((obj) => {
					return obj.id === nucleoConocimiento;
				});
				const listPaisTitulo = ddlPaisTitulo.find((obj) => {
					return obj.id === paisDelCurso;
				});
				const listEstado = ddlEstado.find((obj) => {
					return obj.id === estadoDelCurso;
				});

				let idNivel = listNivel?.name as string;
				let idTitEquivalente = listTitEquivalente?.name as string;
				let idArea = listArea?.name as string;
				let idNucleo = listNucleo?.name as string;
				let idPaisTitulo =
					(listPaisTitulo?.name as string) === undefined ? '' : (listPaisTitulo?.name as string);
				let idEstado = listEstado?.name as string;

				let itemsToSave = rowsNivelEducativo.filter((_, i) => i !== iRemove);
				let d = new Date();
				let m = d.getMinutes();
				let s = d.getSeconds();
				let idRandom = openRowEdit || parseInt(`${m}${s}`);

				itemsToSave.push({
					id: idRandom,
					nivelEducativo: idNivel,
					tituloObtenido: tituloObtenido,
					tituloHomologado: idTitEquivalente,
					areaDesempeno: idArea,
					nucleoConocimiento: idNucleo,
					paisDelCurso: idPaisTitulo,
					estadoDelCurso: idEstado,
					idObservaciones: idObservaciones,
					txtNumeroTarjProf: tarjetaProfesional === '1' ? txtNumeroTarjProf : '',
					fechaTarjProf: tarjetaProfesional === '1' ? fechaTarjProf : '',
					interessadoPractica: interessadoPractica,
					Institucion: institucion,
					fechafinalizacion: fechafinalizacion,
				});

				console.log('itemsToSave', itemsToSave);
				setRowsNivelEducativo(itemsToSave);
				handleclear();
			}

			// const index = myArray.indexOf(2);

			// const x = myArray.splice(index, 1);
		}
	};
	const isValidSiguinte = (value: any) => {
		if (value === undefined || value === '' || value === '0') {
			setTieneEducacionFormal(value)
			handleclear();
		} else {
			setTieneEducacionFormal(value)
		}


	}
	const clearDependentFields = () => {
		setTituloHomologado('');
		setNucleoConocimiento('');
		setEstadoDelCurso('');
		setTarjetaProfesional('');
		// setPaisDelCurso('');
		// setEstadoDelCurso('');
		// setInstitucion('');
		setAreaDesempeno('');
	};

	return (
		<>
			<Typography color='#2A2A2A' variant='h6' mb={3} fontWeight='bold'>
				{t('Datos de perfil laboral')}
			</Typography>
			<Grid
				container
				direction='row'
				justifyContent='space-between'
				width={'100%'}
				sx={{
					backgroundColor: '#1C447F',
					pt: 2,
					pl: 2,
					pb: 2,
					pr: 2,
					borderTopLeftRadius: 6,
					borderTopRightRadius: 6,
				}}
				item
			>
				<Grid container direction='column' item>
					<Typography color='white' variant='h5'>
						{t('Agregar nivel educativo')}{editMode ? t(' - modo de edición') : ''}
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				direction='row'
				justifyContent='space-between'
				width={'100%'}
				mb={5}
				sx={{
					// border: 1,
					borderBottom: 3,
					borderColor: '#AFADAD',
					borderBottomLeftRadius: 6,
					borderBottomRightRadius: 6,
					pt: 5,
					pl: 3,
					pr: 3,
					border: `${borderColorEdit}`,
				}}
				item
			>
				<Grid xs={4} mb={5} pr={2} display={display} item>
					<FormControl sx={{ minWidth: '100%' }} size='medium'>
						<InputLabel id={'tieneEducacionFormal'}>{t('¿Tiene educación formal?')}</InputLabel>
						<Select
							labelId={'tieneEducacionFormal'}
							id={'tieneEducacionFormal'}
							label={t('¿Tiene educación formal?')}
							value={tieneEducacionFormal}
							onChange={(e) => isValidSiguinte(e.target.value)}
						>
							<MenuItem value={''}>{t('Seleccione...')}</MenuItem>
							<MenuItem value={'0'}>{t('NO')}</MenuItem>
							<MenuItem value={'1'}>{t('SI')}</MenuItem>
						</Select>
					</FormControl>
				</Grid>

				{tieneEducacionFormal === '1' && (
					<Grid container direction='row' justifyContent='space-between' width={'100%'} item>
						<Grid xs={12} md={4} mb={5} pr={2} item>
							<FormControl
								sx={{ minWidth: '100%' }}
								size='medium'
								error={!isValidFieldRequired(nivelEducativo)}
							>
								<InputLabel required id={nivelEducativo}>
									{t('Nivel educativo')}
								</InputLabel>
								<Select
									required
									labelId={nivelEducativo}
									id={nivelEducativo}
									label={t('Nivel educativo')}
									value={nivelEducativo}
									onChange={(e) => {
										setNivelEducativo(e.target.value);
										clearDependentFields();
									}}
								>
									<MenuItem value=''>{t('Seleccione...')}</MenuItem>
									{ddlNivel.map(({ id, name }) => (
										<MenuItem key={id} value={id}>
											{t(name)}
										</MenuItem>
									))}
								</Select>
								{!isValidFieldRequired(nivelEducativo) && (
									<FormHelperText>
										{!isValidFieldRequired(nivelEducativo) ? t('Campo requerido') : ''}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						{/* {FieldsNivelEducativo(nivelEducativo)} */}

						{/* <Grid xs={4} mb={5} pr={2}>
							<TextField
								fullWidth
								id={tituloObtenido}
								name={tituloObtenido}
								label='Título obtenido'
								value={tituloObtenido}
								onChange={(e) => setTituloObtenido(e.target.value)}
								placeholder='Escreba el título'
								size='medium'
							/>
						</Grid>
						<Grid xs={4} mb={5} pr={2}>
							<FormControl sx={{ minWidth: '100%' }} size='medium'>
								<InputLabel required id={tituloHomologado}>
									Título homologado
								</InputLabel>
								<Select
									sx={{
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor:
												tituloHomologado === '' || tituloHomologado === undefined ? '#DC3545' : '',
										},
									}}
									required
									labelId={tituloHomologado}
									id={tituloHomologado}
									label='Título homologado'
									value={tituloHomologado}
									onChange={(e) => setTituloHomologado(e.target.value)}
								>
									{DDLTitEquivalente.map(({ id, name }) => (
										<MenuItem value={id}>{name}</MenuItem>
									))}
								</Select>
								{(tituloHomologado === '' || tituloHomologado === undefined) && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>Campo requerido</span>
										</div>
									</div>
								)}
							</FormControl>
						</Grid>
						<Grid xs={4} mb={5} pr={2}>
							<FormControl sx={{ minWidth: '100%' }} size='medium'>
								<InputLabel required id={areaDesempeno}>
									Área de desempeño
								</InputLabel>
								<Select
									labelId={areaDesempeno}
									id={areaDesempeno}
									label='Área de desempeño'
									value={areaDesempeno}
									onChange={(e) => setAreaDesempeno(e.target.value)}
								>
									{ddlArea.map(({ id, name }) => (
										<MenuItem value={id}>{name}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>

						<Grid xs={4} mb={5} pr={2}>
							<FormControl sx={{ minWidth: '100%' }} size='medium'>
								<InputLabel required id={nucleoConocimiento}>
									Núcleo de conocimiento
								</InputLabel>
								<Select
									labelId={nucleoConocimiento}
									id={nucleoConocimiento}
									label='Núcleo de conocimiento'
									value={nucleoConocimiento}
									onChange={(e) => setNucleoConocimiento(e.target.value)}
								>
									{ddlNucleo.map(({ id, name }) => (
										<MenuItem value={id}>{name}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid xs={4} mb={5} pr={2}>
							<FormControl sx={{ minWidth: '100%' }} size='medium'>
								<InputLabel required id={paisDelCurso}>
									País titulo
								</InputLabel>
								<Select
									labelId={paisDelCurso}
									id={paisDelCurso}
									label='País titulo'
									value={paisDelCurso}
									onChange={(e) => setPaisDelCurso(e.target.value)}
								>
									{ddlPaisTitulo.map(({ id, name }) => (
										<MenuItem value={id}>{name}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid xs={4} mb={5} pr={2}>
							<FormControl sx={{ minWidth: '100%' }} size='medium'>
								<InputLabel required id={estadoDelCurso}>
									Estado del curso
								</InputLabel>
								<Select
									sx={{
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor:
												estadoDelCurso === '' || estadoDelCurso === undefined ? '#DC3545' : '',
										},
									}}
									required
									labelId={estadoDelCurso}
									id={estadoDelCurso}
									label='Estado del curso'
									value={estadoDelCurso}
									onChange={(e) => setEstadoDelCurso(e.target.value)}
								>
									<MenuItem value=''>Seleccione...</MenuItem>
									{ddlEstado.map(({ id, name }) => (
										<MenuItem value={id}>{name}</MenuItem>
									))}
								</Select>
								{(estadoDelCurso === '' || estadoDelCurso === undefined) && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>Campo requerido</span>
										</div>
									</div>
								)}
							</FormControl>
						</Grid>

						<Grid xs={4} mb={5} pr={2}>
							<TextField
								fullWidth
								id={institucion}
								name={institucion}
								label='Institución'
								value={institucion}
								onChange={(e) => setInstitucion(e.target.value)}
								placeholder='Escreba el institución'
								size='medium'
							/>
						</Grid>
						<Grid xs={12} mb={5}>
							<TextField
								fullWidth
								id={idObservaciones}
								name={idObservaciones}
								label='Observaciones'
								placeholder='Escribe observaciones'
								value={idObservaciones}
								onChange={(e) => setIdObservaciones(e.target.value)}
								size='medium'
							/>
						</Grid>
						<Grid xs={4} mb={5}>
							<FormControlLabel
								value={interessadoPractica}
								control={
									<Checkbox
										color='default'
										checked={interessadoPractica}
										value={interessadoPractica}
										onChange={(e) => setInteressadoPractica(e.target.checked)}
									/>
								}
								label='Estoy interesado(a) en realizar una práctica empresarial'
								labelPlacement='end'
							/>
						</Grid> */}
					</Grid>
				)}

				<Grid xs={4} mb={5} item></Grid>
				<Grid xs={12} mb={3} display='flex' justifyContent='space-between' alignItems='center' item>
					{editMode ? (
						<>
							<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
								<Button
									variant='contained'
									onClick={handleSaveEdit}
									sx={{ width: 90, background: '#0A4396', mr: 1 }}
								>
									{t('Guardar')}
								</Button>

								<Button
									variant='contained'
									sx={{ width: 90, background: '#6c757d', mr: 1 }}
									onClick={handleclear}
								>
									{t('Cancelar')}
								</Button>
							</Box>
						</>
					) : (
						<Button
							disabled={tieneEducacionFormal === '0' || tieneEducacionFormal === ''}
							variant='contained'
							onClick={handleAddNivelEducattivo}
							sx={{ width: 90, background: '#0A4396' }}
						>
							{t('Agregar')}
						</Button>
					)}
				</Grid>
			</Grid>
			{rowsNivelEducativo.length > 0 && (
				<Grid xs={12} item>
					<Typography color='#2B2B2B' variant='h6'>
						{t('Agregados')}
					</Typography>
					<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 5 }} />
				</Grid>
			)}
			<Grid xs={12} item>
				{rowsNivelEducativo.map((row, i) => (
					// <EditViewAggregatedComponent
					// 	key={i}
					// 	index={i}
					// 	item={row}
					// 	setRowsState={setRowsNivelEducativo}
					// 	rows={rowsNivelEducativo}
					// 	ddlNivel={userData['ddlNivelEducativo']}
					// 	ddlArea={ddlArea}
					// 	ddlNucleoConocimiento={userData['ddlNivelNucleo']}
					// 	ddlTituloHomologado={nivelDDL}
					// 	userData={userData}
					// />
					<Grid xs={12} key={i} item display={openRowEdit === row.id ? 'none' : ''}>
						<Grid
							container
							direction='row'
							justifyContent='space-between'
							width={'100%'}
							sx={{
								backgroundColor: '#1C447F',
								pt: 2,
								pl: 2,
								pb: 2,
								pr: 2,
								borderTopLeftRadius: 6,
								borderTopRightRadius: 6,
							}}
							item
						>
							<Typography color='white' variant='h6'>
								{'Nivel educativo: ' + row.nivelEducativo}
							</Typography>
							<Box display='flex' justifyContent='end' alignItems='center'>
								<div>
									<div className='d-flex justify-content-end flex-shrink-0'>
										<button
											className='btn btn-icon btn-active-color-secondary btn-sm me-3'
											style={{ background: '#C4D2E8' }}
											onClick={() => {
												handleEdit(row);
												// setOpenRowEdit(row.id === openRowEdit as number ? null : row.id);
											}}
										>
											<EditIcon />
										</button>
										<button
											className='btn btn-icon btn-active-color-secondary btn-sm'
											onClick={() => handleRemoveNivelEducativo(row.id)}
											style={{ background: '#DD1342' }}
										>
											<DeleteOutlinedIcon style={{ color: 'white' }} />
										</button>
									</div>
								</div>
							</Box>
						</Grid>
						<Grid
							container
							direction='row'
							justifyContent='space-between'
							width={'100%'}
							mb={5}
							sx={{
								border: 1,
								borderBottom: 3,
								borderColor: '#AFADAD',
								borderBottomLeftRadius: 6,
								borderBottomRightRadius: 6,
								pt: 5,
								pl: 3,
								pr: 3,
							}}
							item
						>
							<Grid xs={12} md={3} mb={5} item>
								<Typography>{t('Nivel educativo: ') + (t(row.nivelEducativo) || '')}</Typography>
								<Typography>{t('Estado del curso: ') + (row.estadoDelCurso || '')}</Typography>
								<Typography>{t('Pais del curso: ') + (row.paisDelCurso || '')}</Typography>
								<Typography>{t('Institución: ') + (row.Institucion || '')}</Typography>
								<Typography>{t('Fecha de finalizacion: ') + (row.fechafinalizacion || '')}</Typography>
								<Typography>{row.fechaTarjProf ? t('Fecha expedición de tarjeta profesional: ') + (row.fechaTarjProf || '') : ''}</Typography>
							</Grid>
							<Grid xs={1} mb={5} sx={{ borderLeft: 2, borderColor: '#AFADAD' }} item />
							<Grid xs={12} md={3} mb={5} item>
								<Typography>{t('Titulo obtenido: ') + (row.tituloObtenido || '')}</Typography>
								<Typography>{t('Título homologado: ') + (row.tituloHomologado || '')}</Typography>
								<Typography>
									{t('Núcleo de conocimiento: ') + (row.nucleoConocimiento || '')}
								</Typography>
								<Typography>{row.txtNumeroTarjProf ? t('Número tarjeta profesional: ') + (row.txtNumeroTarjProf || '') : ''}</Typography>
							</Grid>
							<Grid xs={1} mb={5} sx={{ borderLeft: 2, borderColor: '#AFADAD' }} item />
							<Grid xs={12} md={3} mb={5} item>
								<Typography>{t('Área de desempeño: ') + (row.areaDesempeno || '')}</Typography>
								<Typography>{t('Observaciones: ') + (row.idObservaciones || '')}</Typography>
								<Typography>
									{t('Está interesado(a) en realizar práctica empresarial: ') +
										(row.interessadoPractica ? t('Sí') : t('No'))}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				))}
			</Grid>
			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
			{/* <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
					onClick={() => handleRetorno()}
				>
					{t('Atrás')}
				</Button>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
					onClick={handleSubmit}
				>
					{t('Siguiente')}
				</Button>
			</Box> */}
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
					{t(alertMsg)}
				</Alert>
			</Snackbar>
		</>
	);
};
