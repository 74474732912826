import { useState } from "react"
export type SectionProfessionalProfileType = {
    first_name?: string,
    last_name?: string,
    potision?: string;
    birth_date?: number | null;
    professional_profile?: string;
    softSkills?: string[];
    hardSkills?: string[];
    salary_expectation?: string | null;
    home_office?: string | boolean | null;
    willing_to_travel?: string | boolean | null;
    marital_status?: string | number | null;
    licensed?: string | boolean | number | null;
    license_category?: string | boolean | null;
    own_vehicle?: string | boolean | null;
    military_certificate?: string | boolean | null;
    phone_number?: string | boolean | null;
    nationality?: string | boolean | null;
    country?: string | boolean | null;
}
export const useProfessionalProfile = ({ setFieldValue, values }: any) => {
    const [valueAdd, setValueAdd] = useState<SectionProfessionalProfileType>({})

    const setValues = (val: SectionProfessionalProfileType) => {
        setValueAdd(val);
    }

    return {
        setValues,
        valueAdd
    }

}