import { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import styleScss from './styles.module.scss';
import { CardModel } from '../../models/CardModel';
import { PageTitle } from '../../../_metronic/layout/core';
import { Link } from 'react-router-dom';
import { getCandidatoMatching } from '../../modules/auth/core/_requests';
import { getVagaCountCurriculoAll } from '../../modules/apps/user-management/users-list/core/_requests';
import { Alert, Box, CircularProgress, Snackbar, Typography } from '@mui/material';
import { useAuth } from '../../modules/auth';
import { useTranslation } from 'react-i18next';
import '../../i18n';

const EmployerAreaPage = () => {
	const { t } = useTranslation();

	const { currentUser, auth } = useAuth();
	const [listInterviewScheduled, setListInterviewScheduled]: any[] = useState([]);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [msgAlert, setMsgAlert] = useState('');
	const [idHash, setIdHash] = useState('');
	const [isError, setIsError] = useState(false);
	const [listBestCandidates, setListBestCandidates]: any[] = useState([]);
	const [listBestCandidates2, setListBestCandidates2]: any[] = useState([]);
	const [total, setTotal] = useState<number>(0);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
		name: '',
	});

	async function getCandidatos() {
		setLoading(true);
		try {
			const { data } = await getCandidatoMatching();
			console.log('candidatos', data);
			/* const formattedCandidates = candidatos?.data?.data.map((candidate: any) => ({
                id: candidate.id,
                email: candidate.email,
                active: candidate.active,
                status: candidate.status,
                role: candidate.Role.name,
                firstName: candidate.UserComplement.first_name,
                lastName: candidate.UserComplement.last_name,
            })); */
			setListBestCandidates2(data);
			//setIdHash(candidatos.data[0].empresaHash.idhashEmpresa);
			setLoading(false);
		} catch (error: any) {
			console.log('error', error);
			console.error(error.response.data.message);
			setMsgAlert(error.response.data.message);
			setLoading(false);
		}
	}

	const getVacancyAPI = async () => {
		try {
			setLoading(true);
			const vacancies = await getVagaCountCurriculoAll(
				`page=${filter.page}&limit=${filter.limit}${
					filter.name !== '' ? '&vacancy=' + filter.name : ''
				}`
			);
			vacancies.data.data.forEach((element: { status: any }) => {
				console.log(element.status);
			});
			setTotal(vacancies.data.total);

			setLoading(false);
		} catch (error: any) {
			console.error('error', error);
			setLoading(false);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		let arr: any[] = [];
		let arrB: any[] = [];
		for (let index = 0; index < 7; index++) {
			let x = {
				id: index,
				candidateName: `Nombre candidato ${index}`,
				roleDescription: `Asistente de ventas ${index}`,
			};

			let y = {
				candidateName: `Nombre del candidato ${index}`,
				roleDescription: `Asistente de ventas ${index}`,
				match: parseInt(index + '0'),
			};
			arr.push(x);
		}
		for (let index = 0; index < 10; index++) {
			let y = {
				candidateName: `Nombre del candidato ${index}`,
				roleDescription: `Asistente de ventas ${index}`,
				match: parseInt(index + '0'),
			};
			arrB.push(y);
		}

		setListInterviewScheduled(arr);
		setListBestCandidates(arrB);
		getCandidatos();
		getVacancyAPI();
	}, []);

	useEffect(() => {
		console.log(listBestCandidates2);
	}, [listBestCandidates2]);

	const listManage: CardModel[] = [
		{
			title: 'Gestionar vacantes',
			content: 'Actualiza la información de tus vacantes para recibir los mejores candidatos.',
			action: '/empleador/gestion-vacantes/',
			actionName: 'Ver más',
		},
		{
			title: 'Gestionar candidatos',
			content: 'Conoce qué candidatos se adaptan mejor a los trabajos que ofreces.',
			action: '/empleador/gestion-candidatos/0',
			actionName: 'Ver más',
		},
	];

	const cardCountVacancy: number = total;

	const listDashboard: CardModel[] = [
		{
			title: 'Dashboard de los candidato',
			content: 'Métricas y indicadores de los candidatos.',
			action:
				'https://lookerstudio.google.com/reporting/d2c69850-6964-400b-b3f8-0110b59b99eb/page/p_095goudz6c?params=%7B%22df215%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580' +
				idHash +
				'%22%7D',
		},
	];

	const normalise = (value: number) => ((value - 0) * 100) / (100 - 0);

	return (
		<>
			{/* <h2>Área del empleador</h2> */}
			<div className='row mt-15'>
				{/* left */}
				<div className='col-sm-4 mt-5'>
					<div className='row'>
						<div className='row col-sm-12'>
							{listManage.map((x: CardModel, i: number) => (
								<div className='col-sm-12'>
									<div
										className={`card mb-10 ${styleScss['card']}`}
										style={{
											borderRadius: '10px',
										}}
										key={i}
										id={i.toString()}
									>
										<div className={`${styleScss['top-icon']}`}>
											<i className={`bi bi-briefcase-fill ${styleScss['icon-top']}`}></i>
										</div>
										<div className='card-body mt-3'>
											<h2 className='card-title'>{t(x.title)}</h2>
											<p className='card-text'>{t(x.content)}</p>
											{
												<Link
													to={x.action!}
													className='btn btn-primary btn-lg btn-block'
													style={{ padding: '8px 40px' }}
												>
													{t(x.actionName || '')}
												</Link>
											}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				{/* middle */}
				<div className='col-sm-2 mt-5'>
					<div className='row'>
						<div className=' col-sm-12'>
							{listDashboard.map((x: CardModel, i: number) => (
								<div className='col-sm-12'>
									<div className={`card mb-5 ${styleScss['blue-card']}`} key={i} id={i.toString()}>
										{x.action ? (
											<a href={x.action} target='_blank' rel='noopener noreferrer'>
												<div className='card-body'>
													<i
														className={`bi bi-rocket-takeoff-fill ${styleScss['icon-custom-white']}`}
													></i>
													<h2 className='card-title text-white mt-2'>{t(x.title)}</h2>
													<p className='card-text text-white'>{t(x.content)}</p>
												</div>
											</a>
										) : (
											<div className='card-body'>
												<i
													className={`bi bi-rocket-takeoff-fill ${styleScss['icon-custom-white']}`}
												></i>
												<h2 className='card-title text-white mt-2'>{t(x.title)}</h2>
												<p className='card-text text-white'>{t(x.content)}</p>
											</div>
										)}
									</div>
								</div>
							))}
							<div className='col-sm-12'>
								<div
									className={`card mb-10 ${styleScss['dark-blue-card']}`}
									style={{ borderRadius: '10px', marginTop: '30px' }}
									key={100}
								>
									<div className='card-body'>
										<div className={`${styleScss['top-icon-vacancy']}`}>
											<span className='svg-icon svg-icon-2 svg-icon-lg-1'>
												<svg
													width='24'
													height='24'
													viewBox='0 0 24 24'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
													className='mh-50px'
												>
													<path
														d='M3 2H10C10.6 2 11 2.4 11 3V10C11 10.6 10.6 11 10 11H3C2.4 11 2 10.6 2 10V3C2 2.4 2.4 2 3 2Z'
														fill='white'
													></path>
													<path
														opacity='0.3'
														d='M14 2H21C21.6 2 22 2.4 22 3V10C22 10.6 21.6 11 21 11H14C13.4 11 13 10.6 13 10V3C13 2.4 13.4 2 14 2Z'
														fill='white'
													></path>
													<path
														opacity='0.3'
														d='M3 13H10C10.6 13 11 13.4 11 14V21C11 21.6 10.6 22 10 22H3C2.4 22 2 21.6 2 21V14C2 13.4 2.4 13 3 13Z'
														fill='white'
													></path>
													<path
														opacity='0.3'
														d='M14 13H21C21.6 13 22 13.4 22 14V21C22 21.6 21.6 22 21 22H14C13.4 22 13 21.6 13 21V14C13 13.4 13.4 13 14 13Z'
														fill='white'
													></path>
												</svg>
											</span>
										</div>
										<h2 className='card-title text-white mt-4'>
											{cardCountVacancy} {t('é o número de vagas')}
										</h2>
										<p className='card-text'> {t('que sua empresa se cadastrou na plataforma.')}</p>
										<a
											className='btn btn-primary btn-lg btn-block d-none'
											style={{ padding: '8px 40px' }}
										></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* Descomentar
				<div className='col-sm-4 mt-5'>
					<div className={`card mb-10 ${styleScss['card2']}`} style={{ borderRadius: '10px' }}>
						<div className={`${styleScss['top-icon']}`}>
							<i className={`bi bi-calendar-check ${styleScss['icon-top']}`}></i>
						</div>
						<div className='card-body mt-5'>
							<h2 className='card-title'>Entrevistas programadas</h2>
							<p className='card-text mb-5'>Consulta las próximas citas para tus vacantes.</p>
							{listInterviewScheduled.map((x: any, i: any) => (
								<div className='mt-4 card-box' key={i} id={x.id}>
									<div className={`${styleScss['box']}`}>
										<p
											className={`${styleScss['txt-box']} ${styleScss['txt-box-primary']} text-primary`}
										>
											{x.candidateName}
										</p>
										<p className={`${styleScss['txt-box']}`}>{x.roleDescription}</p>
									</div>
								</div>
							))}
							<a href='#' className='btn btn-primary mt-4' style={{ padding: '8px 40px' }}>
								Ver todas
							</a>
						</div>
					</div>
				</div> */}
				{/* right */}
				<div className='col-sm-4 mt-5'>
					<div
						className={`card mb-10 ${styleScss['card2']}`}
						style={{ borderRadius: '10px', width: '600px' }}
					>
						<div className={`${styleScss['top-icon']} ${styleScss['top-icon-scroll']}`}>
							<i className={`bi bi-person-fill-up ${styleScss['icon-top']}`}></i>
						</div>
						<div className='card-body mt-5'>
							<h2 className='card-title'>{t('Melhores candidatos')}</h2>
							<p className='card-text mb-5'>
								{t('Consulte os candidatos com melhores aptidões para as vagas que oferece')}
							</p>
							{loading ? (
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<CircularProgress />
								</Box>
							) : listBestCandidates2.length === 0 ? (
								<Typography variant='h5'>{t(msgAlert)}</Typography>
							) : (
								<>
									<table
										className='table table-hover table-responsive'
										style={{ fontSize: '12px' }}
									>
										<thead>
											<tr>
												<th scope='col'>
													<h4>{t('Candidatos')}</h4>
												</th>
												<th scope='col'>
													<h4>{t('Vacante')}</h4>
												</th>
												<th scope='col'>
													<h4>{t('Matching')}</h4>
												</th>
											</tr>
										</thead>
										<tbody>
											{listBestCandidates2.map((x: any, i: number) => (
												<tr key={i} id={i.toString()}>
													<td>{x.candidate_name}</td>
													<td>{x.job_title}</td>
													<td>
														<label>{x.matching}%</label>
														<LinearProgress
															variant='determinate'
															color='primary'
															value={normalise(x.matching)}
														/>
													</td>
												</tr>
											))}
										</tbody>
									</table>
									<a
										href='/empleador/matching-candidatos'
										className='btn btn-primary mt-4'
										style={{ padding: '8px 40px' }}
									>
										{t('Ver mais')}
									</a>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					variant='filled'
					severity={isError ? 'error' : 'success'}
					sx={{ width: '100%' }}
				>
					{t(msgAlert)}
				</Alert>
			</Snackbar>
		</>
	);
};

const EmployerAreaWrapper = () => {
	const { t } = useTranslation();
	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Área do Empregador')}</PageTitle>
			<EmployerAreaPage />
		</>
	);
};

export { EmployerAreaWrapper };
