import { LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PageTitle } from '../../../_metronic/layout/core';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAuth } from '../../modules/auth/core/Auth';
import {
	getCandidatoMatching,
	getCandidatoMatchingByVacancy,
} from '../../modules/auth/core/_requests';

import { useTranslation } from 'react-i18next';
import '../../i18n';
import { RoleEnum } from '../../models/enums/RoleEnum';

const EmployerMatchingCandidatePage = () => {
	const { t } = useTranslation();
	const { id } = useParams();
	const { currentUser, auth } = useAuth();
	const [candidates, setCandidates] = useState<any[]>();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');
	const [tituloVacancy, setTituloVacancy] = useState('');

	const companyId = currentUser?.user?.company_id;

	async function getCandidatos() {
		setLoading(true);
		const { data } =
			id !== undefined && parseInt(id) > 0
				? await getCandidatoMatchingByVacancy(`id_vacancy=${id}`)
				: await getCandidatoMatching();


		if (data?.length > 0) {
			console.log('candidatos', data);
			setCandidates(data);
			//setTituloVacancy(data);
		} else {
			console.log('entrou else');
			//setMessage(candidatos?.data?.message);
			setCandidates([]);
		}
		setLoading(false);
	}
	// const [filter, setFilter] = useState<any>({
	// 	page: 1,
	// 	limit: 10,
	// });
	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);
	const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	// const changePaginate = (pf: any) => {
	// 	const f = { page: pf.page, limit: pf.limit };
	// 	setFilter(f);
	// };

	useEffect(() => {
		getCandidatos();
	}, []);

	const normalise = (value: number) => ((value - 0) * 100) / (100 - 0);

	return (
		<>
			{/* <!-- Jumbotron --> */}
			<div className='p-4 shadow-4 rounded-3' style={{ backgroundColor: '#FFFF' }}>
				<div className='row'>
					<div className='col-sm-1'>
						<button
							onClick={() => {
								navigate(-1);
							}}
							className='btn btn-primary'
							style={{ background: '#C4D2E8', borderRadius: '4px', padding: '6px 15px' }}
						>
							<i
								className='bi bi-arrow-left'
								style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
							></i>
						</button>
					</div>
					<div className='col-sm-8' style={{ lineHeight: '0.5em' }}>
						<h2 style={{ display: 'flex', gap: '10px' }}>
							{t('Matching de los candidatos')} - {tituloVacancy}{' '}
						</h2>
						<span style={{}}>{t('Haz clic en el candidato para ver los detalles.')}</span>
					</div>
					<div className='col d-flex justify-content-end'>
						<Link to='/empleador/area' className='btn btn-primary' style={{ padding: '8px 20px' }}>
							{t('Visión general')}
						</Link>
					</div>
				</div>

				{loading ? (
					<LinearProgress style={{ marginTop: '10px' }} />
				) : (
					<div className='row'>
						{candidates !== undefined && candidates?.length > 0 ? (
							candidates?.map((x, i) => (
								<div className='col-sm-6 mt-5' key={i} id={x.id}>
									<div
										className='card mb-10'
										style={{ borderRadius: '10px', backgroundColor: '#F3F6F9' }}
									>
										<div className='card-body mt-5'>
											<div className={`card-text`}>
												<div className='row'>
													<div
														className='justify-content-around align-items-center'
														style={{ display: 'flex', flexWrap: 'wrap' }}
													>
														<div className='d-flex align-items-center'>
															<div className='m-3'>
																<i
																	className={`bi bi-person-fill`}
																	style={{ color: 'black', fontSize: '30px' }}
																></i>
															</div>
															<div
																className='m-3'
																style={{
																	fontStyle: 'normal',
																	fontWeight: '600',
																}}
															>
																{x.candidate_name}
															</div>

															<div className='m-3'>
																<label>{x.matching}%</label>
																<LinearProgress
																	variant='determinate'
																	color='primary'
																	value={normalise(x.matching)}
																/>
															</div>
														</div>

														<div>
															<div>
																<Menu
																	id='basic-menu'
																	anchorEl={anchorEl}
																	open={openMenu}
																	onClose={handleCloseMenu}
																	MenuListProps={{
																		'aria-labelledby': 'basic-button',
																	}}
																	style={{ boxShadow: 'none' }}
																>
																	<MenuItem onClick={handleCloseMenu}>
																		<Link
																			to={
																				auth?.user?.role === RoleEnum.EMPLOYER
																					? `/empleador/hoja-vida/${x.candidate_id}/0/0`
																					: `/consejero/hoja-vida/${x.candidate_id}/0/0`
																			}
																		>
																			{t('Ver curriculum')}
																		</Link>
																	</MenuItem>
																</Menu>
															</div>
															<div>
																<Link
																	to={
																		auth?.user?.role === RoleEnum.EMPLOYER
																			? `/empleador/hoja-vida/${x.candidate_id}/${companyId}/${x.vacancy_id}`
																			: `/consejero/hoja-vida/${x.candidate_id}/${companyId}/${x.vacancy_id}`
																	}
																	className='btn btn-primary'
																	style={{ padding: '8px 40px' }}
																>
																	{t('Ver')}
																</Link>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							))
						) : (
							<>
								<div
									className='alert alert-info'
									role='alert'
									style={{
										marginTop: '10%',
										textAlign: 'center',
										width: '50%',
										fontSize: '12pt',
										marginLeft: '20%',
									}}
								>
									{t(message)}
								</div>
							</>
						)}
					</div>
				)}
			</div>
			{/* <PaginationComponent totalPage={10} total={10} customClass={''} paginate={changePaginate} /> */}
			{/* <!-- Jumbotron --> */}
		</>
	);
};

const EmployerMatchingCandidateWrapper = () => {
	const { t } = useTranslation();

	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Mejores Candidatos')}</PageTitle>
			<EmployerMatchingCandidatePage />
		</>
	);
};

export { EmployerMatchingCandidateWrapper };
