import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import PaginationComponent from '../../components/Pagination/Index';
import styleScss from './styles.module.scss';
import { Link, useParams } from 'react-router-dom';
import { getSearchVacantes } from '../../modules/apps/user-management/users-list/core/_requests';
import UseDidMountEffect from '../../modules/auth/core/UseDidMountEffect';
import { Alert, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CandidateSearchVacancyPage = () => {
	const { t, i18n } = useTranslation();
	const { vacante } = useParams();
	const [data, setData]: any[] = useState([]);
	const [search, setSearch] = useState('');
	const [open, setOpen] = useState(false);
	const [msgAlert, setMsgAlert] = useState('');
	const [isError, setIsError] = useState(false);
	//Pagination
	const [total, setTotal] = useState<number>(0);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
	});

	const currentLanguage = i18n.language.split('-')[0];

	const handleClose = () => {
		setOpen(false);
	};

	const changeSearch = (data: any) => {
		console.log(data);
		setSearch(data);
	};

	const changePaginate = (pf: any) => {
		const f = { page: pf.page, limit: pf.limit };
		setFilter(f);
	};

	useEffect(() => {
		vacante ? setSearch(vacante) : setSearch('');
		setFilter({ ...filter });
	}, []);

	UseDidMountEffect(() => {
		handleSubmit();
	}, [filter]);

	const handleSubmit = async () => {
		console.log(search);
		try {
			const contractTypeMap: { [key: string]: string } = {
				pt: 'pt_type',
				es: 'es_type',
				en: 'en_type',
			};

			const contractTypeDescriptionMap: { [key: string]: string } = {
				pt: 'pt_description',
				es: 'es_description',
				en: 'en_description',
			};

			const mapOldFormatToNewFormat = (oldFormat: any[]) => {
				console.log(
					888888,
					oldFormat[0].ContractType && oldFormat[0].ContractType[contractTypeMap[currentLanguage]]
						? oldFormat[0].ContractType[contractTypeMap[currentLanguage]]
						: null
				);

				return oldFormat.map((item) => ({
					id: item.id,
					company_id: item.company_id,
					position: item.position,
					position_count: item.position_count,
					nombre_vacante: item.job_title,
					job_responsible: item.job_responsible,
					requester: item.requester,
					contact_phone: item.contact_phone,
					contact_email: item.contact_email,
					responsible_advisor: item.responsible_advisor,
					Describa_vacante: item.job_description,
					education_level:
						item.ContractType && item.ContractType[contractTypeMap[currentLanguage]]
							? item.ContractType[contractTypeMap[currentLanguage]]
							: null,
					graduated: item.graduated,
					education_title: item.education_title,
					experience_required: item.experience_required,
					training_required: item.training_required,
					telework_possible: item.telework_possible,
					rango_experiencia: item.experience_range,
					training_description: item.training_description,
					application_deadline: item.application_deadline,
					estimated_start_date: item.estimated_start_date,
					contract_type: item.contract_type,
					monthly_salary: item.monthly_salary,
					work_schedule: item.work_schedule,
					confidential_job: item.confidential_job,
					outsourced_job: item.outsourced_job,
					job_type: item.job_type,
					street: item.street,
					complement: item.complement,
					city: item.city,
					state: item.state,
					district: item.district,
					country: item.country,
					latitude: item.latitude,
					longitude: item.longitude,
					status: item.status,
					createdAt: item.created_at,
					updatedAt: item.updated_at,
					remoto: item.telework_possible,
					Empresa: {
						razon_social: item.Company.company_name,
					},
				}));
			};

			const vacantes = await getSearchVacantes(
				`page=${filter.page}&limit=${filter.limit}&vacancy=${search}`
			);

			const newFormatVacantes = mapOldFormatToNewFormat(vacantes.data.data);
			console.log('data', newFormatVacantes);

			setTotal(vacantes.data.total);
			setTotalPage(vacantes.data.totalPages);
			setData(newFormatVacantes);
		} catch (error: any) {
			console.log('error', error);
			setOpen(true);
			setIsError(true);
			setMsgAlert(`${error.response.data.error}`);
		}
	};

	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15 ' style={{ backgroundColor: '#FFFF' }}>
				<div className='form-row align-items-center'>
					<div className='row'>
						<div className='col-sm-3 my-1'>
							<input
								value={search}
								onChange={(e) => changeSearch(e.target.value)}
								className='form-control'
								type='text'
								placeholder={t('Busca la vacante')}
							/>
						</div>
						<div className='col-sm-2 my-1'>
							<button className={`form-control btn ${styleScss['btn-red']}`} onClick={handleSubmit}>
								<i className='bi bi-search'></i>
								{t('Buscar')}
							</button>
						</div>
					</div>
				</div>
				{data.length !== 0 && (
					<div className='p-4 shadow-4 rounded-3 mt-5' style={{ backgroundColor: '#FAFAFA' }}>
						<div className='row'>
							<p style={{ color: '#0A4396', paddingLeft: '60px', fontSize: '12pt' }}>
								{total} {t('Vacantes de empleo encontradas')}
							</p>
						</div>
						<hr />

						<div className='row'>
							{data.map((x: any, i: number) => (
								<div className='col-sm-4' key={i}>
									<div className='card shadow p-3 mb-5 bg-white rounded' style={{ height: '95%' }}>
										<div className='card-body'>
											<h5 className='card-title text-primary'>{x.nombre_vacante}</h5>
											<div>
												<h6
													style={{ display: 'inline' }}
													className='card-subtitle mb-2 mt-4 text-muted'
												>
													<b>{x.Empresa.razon_social}</b>
												</h6>
												<span style={{ display: 'inline', marginLeft: '20px' }}>
													<b>{t('Fecha de anuncio:')}</b>{' '}
													{x.createdAt.toLocaleString().substr(0, 10)}
												</span>
											</div>

											<div
												className='card-text mt-4'
												style={{
													height: '10rem',
													overflow: 'hidden',
													whiteSpace: 'normal',
													scrollbarWidth: 'revert',
												}}
											>
												<span className='mt-4'>{x.Describa_vacante}</span>
											</div>
											<div className='card-text mt-4'>
												<span className='mt-4'>
													<i className='bi bi-book' style={{ fontSize: '16px' }}></i>
													&nbsp;&nbsp;
													<b>{t('Nivel de estudios:')} </b>
													{x.education_level}
												</span>
												<br />
												<span className='mt-4'>
													<i className='bi bi-stopwatch' style={{ fontSize: '16px' }}></i>
													&nbsp;&nbsp;
													<b>{t('Experiencia: ')} </b>
													{x.rango_experiencia}
												</span>
												<br />
												<span className='mt-4'>
													<i className='bi bi-house-door' style={{ fontSize: '16px' }}></i>
													&nbsp;&nbsp;
													<b>{x.telework_possible ? t('Remoto') : t('Presencial')}</b>
												</span>
												<br />
											</div>
											<Link
												to={`/candidato/vacante/${x.id}`}
												className={`btn btn-primary btn-lg btn-block`}
												style={{
													padding: '8px 20px',
													float: 'right',
												}}
											>
												{t('Ver vacante')}
											</Link>
										</div>
									</div>
								</div>
							))}
						</div>
						<PaginationComponent
							totalPage={totalPage}
							total={total}
							customClass={''}
							paginate={changePaginate}
						/>
					</div>
				)}
			</div>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					variant='filled'
					severity={isError ? 'error' : 'success'}
					sx={{ width: '100%' }}
				>
					{t(msgAlert)}
				</Alert>
			</Snackbar>
		</>
	);
};

const CandidateSearchVacantes = () => {
	const { t } = useTranslation();
	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Lista de vacantes')}</PageTitle>
			<CandidateSearchVacancyPage />
		</>
	);
};

export { CandidateSearchVacantes };
