import { useEffect, useState } from 'react';
import PaginationComponent from '../../components/Pagination/Index';
import UseDidMountEffect from '../../modules/auth/core/UseDidMountEffect';
import { useNavigate, Link } from 'react-router-dom';
import styleScss from './styles.module.scss';
import {
	deleteVacancyById,
	getVacancys,
	updateVacancyStatus,
	getVagaCountCurriculoAll,
} from '../../modules/apps/user-management/users-list/core/_requests';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import {
	Box,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	LinearProgress,
	Stack,
	Typography,
} from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MuiToggleButton from '@mui/material/ToggleButton';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { convertDateISOToDateString } from '../../util/Index';
import { Alert, Snackbar, AlertTitle } from '@mui/material';
import { ManageAccounts } from '@mui/icons-material';
import { useAuth } from '../../modules/auth';
import { useTranslation } from 'react-i18next';
import VacancyCardComponent from '../../components/VacancyCardComponent';
import MenuActionManagementVacancy from '../../components/MenuActionManagementVacancy';
import { RoleEnum } from '../../models/enums/RoleEnum';

const EmployerManagementVacancyPage = () => {
	const { t } = useTranslation();
	const { auth } = useAuth();
	const [data, setData]: any[] = useState([
		{
			id: 0,
			createdAt: '',
			nombre_vacante: '',
			numero_puestos: '',
			numero_candidatos: 0,
			status: false,
			Describa_vacante: '',
		},
	]);
	const [loading, setLoading] = useState(false);

	const [open, setOpen] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [qtdVacante, setQtdVacante] = useState('0');
	const [idVaga, setIdVaga] = useState(0);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const [alignment, setAlignment] = useState<string>('list');

	const handleChangeView = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
		setAlignment(newAlignment);
	};
	const navigate = useNavigate();
	const ToggleButton = styled(MuiToggleButton)({
		'&.Mui-selected, &.Mui-selected:hover': {
			color: 'white',
			backgroundColor: '#4683DE',
		},
	});

	//Pagination
	const [total, setTotal] = useState<number>(0);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
		name: '',
	});

	useEffect(() => {
		setFilter({ ...filter });
	}, []);

	UseDidMountEffect(() => {
		getVacancyAPI();
	}, [filter]);

	const changePaginate = (pf: any) => {
		const f = {
			...filter, // Copia todas as propriedades existentes do objeto filter
			page: pf.page, // Atualiza a propriedade page com o novo valor
			limit: pf.limit, // Atualiza a propriedade limit com o novo valor
		};
		setFilter(f);
	};

	const getVacancyAPI = async () => {
		try {
			setLoading(true);
			const response = await getVagaCountCurriculoAll(
				`page=${filter.page}&limit=${filter.limit}${
					filter.name !== '' ? '&vacancy=' + filter.name : ''
				}`
			);
			const vacancies = response.data.data.map((vacancy: any) => ({
				id: vacancy.id,
				createdAt: vacancy.created_at,
				nombre_vacante: vacancy.job_title,
				numero_puestos: vacancy.position_count,
				numero_candidatos: vacancy.candidateCount,
				status: vacancy.status === 'true',
				Describa_vacante: vacancy.job_description,
				Empresa: {
					razon_social: vacancy.Company.company_name,
				},
			}));

			setTotal(response.data.total);
			setTotalPage(response.data.totalPages);
			setData(vacancies);
			setLoading(false);
		} catch (error: any) {
			console.error('error', error);
			setLoading(false);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const modalDelete = (vacante: any) => {
		console.log(vacante);
		setQtdVacante(vacante.numero_candidatos);
		setIdVaga(vacante.id);
		setOpenModal(true);
	};

	const deleteVacancy = async () => {
		try {
			await deleteVacancyById({ vacancy_id: idVaga });
			setOpen(true);
			setAlert(true);
			setAlertMsg(`Vacante eliminada con éxito!`);
			setOpenModal(false);
			setTimeout(() => {
				setFilter({ ...filter });
				setOpen(false);
			}, 2000);
		} catch (error: any) {
			console.error(error.response.data.message);
			setOpen(true);
			setAlert(false);
			setAlertMsg(error.response.data.message);
			setOpenModal(false);
			setTimeout(() => {
				setOpen(false);
			}, 5000);
		}
	};

	const MenuAction = (props: any) => {
		const [anchorEl, setAnchorEl] = useState(null);
		const handleClick = (e: any) => {
			setAnchorEl(e.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};
		const { data } = props;

		const updateUserStatus = async () => {
			try {
				await updateVacancyStatus({ id: data.id, status: !data.status });
				setOpen(true);
				setAlert(true);
				setFilter({ ...filter });
				data.status
					? setAlertMsg(`Vacante inactivada con éxito!`)
					: setAlertMsg(`Vacante activada con éxito!`);
			} catch (error: any) {
				console.error(error.response.data.message);
				setOpen(true);
				setAlert(false);
				setAlertMsg(error.response.data.message);
			} finally {
				setTimeout(() => {
					setOpen(false);
				}, 2000);
			}
		};

		return (
			<>
				<Button onClick={handleClick}>
					<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
				</Button>
				<Menu
					id='card-actions-menu'
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={handleClose}>
						<Link
							to={
								auth?.user?.role === RoleEnum.EMPLOYER
									? `/empleador/gestion-de-vacante/${data.id}`
									: `/consejero/gestion-de-vacante/${data.id}`
							}
						>
							<BorderColorIcon />
							&nbsp; {t('Editar')}
						</Link>
					</MenuItem>
					<MenuItem onClick={handleClose}>
						{/* <a className='pointer' onClick={() => deleteVacancy(data.id)}> */}
						<a className='pointer' onClick={() => modalDelete(data)}>
							<DeleteIcon />
							&nbsp; {t('Borrar')}
						</a>
					</MenuItem>
					<MenuItem onClick={handleClose}>
						<Link
							to={
								auth?.user?.role === RoleEnum.EMPLOYER
									? `/empleador/matching-candidatos/${data.id}`
									: `/consejero/matching-candidatos/${data.id}`
							}
						>
							<EqualizerIcon />
							&nbsp; {t('Matching')}
						</Link>
					</MenuItem>
					<MenuItem onClick={handleClose}>
						<a className='pointer' onClick={updateUserStatus}>
							<ManageAccounts />
							&nbsp; {t('Activar/Inactivar')}
						</a>
					</MenuItem>
				</Menu>
			</>
		);
	};

	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<div className='row'>
					<div className='col-sm-1'>
						<button
							onClick={() => navigate(-1)}
							className='btn btn-primary'
							style={{ background: '#C4D2E8', borderRadius: '4px', padding: '6px 15px' }}
						>
							<i
								className='bi bi-arrow-left'
								style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
							></i>
						</button>
					</div>
					<div className='col-sm-6'>
						<div className={`input-group ${styleScss.titleMobile}`}>
							<h3>{t('Gestiona tus vacantes')}</h3>
						</div>
						<span className={styleScss.subTitlePageMobile}>
							{t('Elige una vacante para gestionarla')}
						</span>
					</div>
				</div>

				<div className='row mt-4 mb-1'>
					<div className='col-sm-12 col-md-4'>
						<div className='input-group input-group-sm mb-3'>
							<input
								type='text'
								className={`form-control ${styleScss.inputMobile}`}
								aria-label='nombreVacante'
								aria-describedby='searchVacancy'
								placeholder={t('Escriba el nombre')}
								onChange={(e) => setFilter({ ...filter, page: 1, name: e.target.value })}
							/>
							<div className='input-group-prepend'>
								<span className='input-group-text' id='searchVacancy'>
									<i
										className={`bi bi-search cursor-pointer text-primary ${styleScss.iconSearchMobile}`}
									></i>
								</span>
							</div>
						</div>
					</div>
					<div className='col'>
						<div className='input-group-sm'>
							<Link
								to={
									auth?.user?.role === RoleEnum.EMPLOYER
										? '/empleador/gestion-de-vacante'
										: '/consejero/gestion-de-vacante'
								}
								className={`btn btn-primary btn-sm btn-block ${styleScss.btnMobile}`}
							>
								{t('Nueva vacante')}
							</Link>
						</div>
					</div>
					<div
						className={`col-auto ${styleScss.gridTemplateTableForMobile}`}
						style={{ float: 'right' }}
					>
						<ToggleButtonGroup
							value={alignment}
							exclusive
							onChange={handleChangeView}
							aria-label='view'
							size='small'
						>
							<ToggleButton value='list' aria-label='list'>
								<ViewListIcon />
							</ToggleButton>
							<ToggleButton value='module' aria-label='module'>
								<ViewModuleIcon />
							</ToggleButton>
						</ToggleButtonGroup>
					</div>
				</div>

				{loading ? (
					<LinearProgress style={{ marginTop: '10px' }} />
				) : alignment == 'list' ? (
					<div className={`${styleScss['table2']} ${styleScss.HideTableMobile}`}>
						<table className={`table table-responsive table-striped`}>
							<thead className={`${styleScss['th-custom']}`}>
								<tr>
									{/* {auth?.user?.role !== 3 ? (
										<></>
									) : (
										<th scope='col'>
											<h4>{t('Empresa')}</h4>
										</th>
									)} */}
									<th scope='col'>
										<h4>{t('Fecha')}</h4>
									</th>
									<th scope='col'>
										<h4>{t('Nombre vacante')}</h4>
									</th>
									<th scope='col'>
										<h4>{t('Numero de puestos')}</h4>
									</th>
									<th scope='col'>
										<h4>{t('Número de aplicantes')}</h4>
									</th>
									<th scope='col'>
										<h4>{t('Activo')}</h4>
									</th>
									<th scope='col'>
										<h4>{t('Acciones')}</h4>
									</th>
								</tr>
							</thead>
							<tbody className={`${styleScss['td-custom']}`}>
								{data.map((x: any, i: number) => (
									<tr key={i} id={i.toString()}>
										{/* {auth?.user?.role !== 3 ? (
											<></>
										) : (
											<td title={x.Empresa?.razon_social}>{x.Empresa?.razon_social}</td>
										)} */}
										<td title={convertDateISOToDateString(x['createdAt'])}>
											{convertDateISOToDateString(x['createdAt'])}
										</td>
										<td title={t(x['nombre_vacante'])}>{t(x['nombre_vacante'])}</td>
										<td title={t(x['numero_puestos'])}> {t(x['numero_puestos'])} </td>
										<td title={t(x['candidatos'])}>
											<Link
												to={
													auth?.user?.role === RoleEnum.EMPLOYER
														? `/empleador/candidatos-postula/${x['id']}`
														: `/consejero/candidatos-postula/${x['id']}`
												}
											>
												{x?.numero_candidatos}
											</Link>
										</td>
										<td title={x['status'] ? t('Sí') : t('No')}>
											{x['status'] ? t('Sí') : t('No')}
										</td>
										<td>
											<MenuActionManagementVacancy
												data={{
													id: x.id,
													status: x.status,
													numero_candidatos: x?.numero_candidatos,
												}}
												setOpen={setOpen}
												setAlert={setAlert}
												setAlertMsg={setAlertMsg}
												auth={auth}
												setFilter={setFilter}
												filter={filter}
												updateVacancyStatus={updateVacancyStatus}
												modalDelete={modalDelete}
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				) : (
					<Grid container item xs={12}>
						{data.map((x: any, i: number) => (
							<Grid
								key={x.id}
								item
								xs={12}
								md={6}
								mb={2}
								pr={2}
								className={styleScss.HideTableMobile}
							>
								<Card
									sx={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-between',
										backgroundColor: '#f3f6f9',
										boxShadow: 0,
									}}
								>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											backgroundColor: 'transparent',
										}}
									>
										<CardContent sx={{ flex: '1 0 auto' }}>
											<Typography variant='subtitle1' component='div'>
												{convertDateISOToDateString(x['createdAt'])}
											</Typography>
											<Typography component='div' variant='h6' fontWeight='bold'>
												{x['nombre_vacante']}
											</Typography>
											<Typography component='div' variant='subtitle2'>
												{t('Describa')}: {String(x['Describa_vacante']).slice(0, 180)}...
											</Typography>
											<Typography component='div' variant='subtitle2' fontWeight='bold'>
												{x['numero_puestos']} {t('puestos')}.
											</Typography>
											<Typography component='div' variant='subtitle2' fontWeight='bold'>
												<Link to={`/empleador/candidatos-postula/${x['id']}`}>
													{x['candidatos']} {t('candidatos para esa vacante.')}
												</Link>
											</Typography>
											<Typography component='div' variant='subtitle2' fontWeight='bold'>
												{t('Activo')}: {x['status'] ? t('Sí') : t('No')}
											</Typography>
										</CardContent>
									</Box>
									<Stack direction='row' sx={{ alignItems: 'center' }} p={2} spacing={2}>
										<Button
											variant='contained'
											sx={{ p: 0, m: 0, minHeight: 40, minWidth: 40, backgroundColor: '#c4d2e8' }}
										>
											<Link
												to={
													auth?.user?.role === RoleEnum.EMPLOYER
														? `/empleador/matching-candidatos/${x.id}`
														: `/consejero/matching-candidatos/${x.id}`
												}
											>
												<CheckCircleOutlineIcon color='action' />{' '}
											</Link>
										</Button>
										<Button
											variant='contained'
											sx={{ p: 0, m: 0, minHeight: 40, minWidth: 40, backgroundColor: '#c4d2e8' }}
										>
											<Link
												to={
													auth?.user?.role === RoleEnum.EMPLOYER
														? `/empleador/gestion-de-vacante/${x.id}`
														: `/consejero/gestion-de-vacante/${x.id}`
												}
											>
												<EditIcon color='action' />{' '}
											</Link>
										</Button>
										<Button
											variant='contained'
											color='error'
											sx={{ p: 0, m: 0, minHeight: 40, minWidth: 40 }}
											onClick={() => modalDelete(x)}
										>
											<DeleteIcon />{' '}
										</Button>
									</Stack>
								</Card>
							</Grid>
						))}
					</Grid>
				)}
				<div>
					{data.map((x: any, i: any) => (
						<VacancyCardComponent
							key={i}
							setOpen={setOpen}
							setAlert={setAlert}
							setAlertMsg={setAlertMsg}
							data={x}
							auth={auth}
							setFilter={setFilter}
							filter={filter}
							updateVacancyStatus={updateVacancyStatus}
							modalDelete={modalDelete}
						/>
					))}
				</div>
				<PaginationComponent
					totalPage={totalPage}
					total={total}
					customClass={''}
					paginate={changePaginate}
				/>
			</div>
			<Dialog open={openModal} onClose={handleCloseModal}>
				<DialogTitle>{t('¿Quieres eliminar la vacante?')}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{t('La vacante tiene')} {qtdVacante} {t('candidatos activos.')}
					</DialogContentText>
					<DialogContentText>
						{t('Si elige continuar, las relaciones candidatas también se eliminarán.')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseModal} autoFocus>
						{t('Cancelar')}
					</Button>
					<Button onClick={deleteVacancy}>{t('Eliminar')}</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
					{t(alertMsg)}
				</Alert>
			</Snackbar>
		</>
	);
};

export { EmployerManagementVacancyPage };
