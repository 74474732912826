import { useTranslation } from 'react-i18next';
import { RoleEnum } from '../../models/enums/RoleEnum';
import MenuAction from '../MenuAction';
import styleScss from './styles.module.scss';
import { Link } from 'react-router-dom';
import { convertDateISOToDateString } from '../../util/Index';
import MenuActionManagementVacancy from '../MenuActionManagementVacancy';

export default function VacancyCardComponent({
	data,
	setAlert,
	setAlertMsg,
	setOpen,
	auth,
	setFilter,
	filter,
	updateVacancyStatus,
	modalDelete,
}: any) {
	const { t } = useTranslation();

	return (
		<div className={styleScss.cardMobile}>
			<div className={styleScss['card-body']}>
				{auth?.user?.role === RoleEnum.EMPLOYER ?? (
					<h5 className={styleScss['card-title']}>
						<strong>{t('Empresa')}:</strong> {data.Empresa?.razon_social}
					</h5>
				)}
				<p className={styleScss['card-text']}>
					<strong>{t('Fecha')}:</strong> {convertDateISOToDateString(data['createdAt'])}
				</p>
				<p className={styleScss['card-text']}>
					<strong>{t('Nombre vacante')}:</strong> {data['nombre_vacante']}
				</p>
				<p className={styleScss['card-text']}>
					<strong>{t('Numero de puestos')}:</strong> {data['numero_puestos']}
				</p>
				<p className={styleScss['card-text']}>
					<strong>{t('Número de aplicantes')}:</strong>{' '}
					{
						<Link
							to={
								auth?.user?.role === RoleEnum.EMPLOYER
									? `/empleador/candidatos-postula/${data['id']}`
									: `/consejero/candidatos-postula/${data['id']}`
							}
						>
							{data?.CurriculoVagaEmpresas?.length ? data?.CurriculoVagaEmpresas?.length : '0'}
						</Link>
					}
				</p>
				<p className={styleScss['card-text']}>
					<strong>{t('Activo')}:</strong> {data.active ? t('Sí') : t('No')}
				</p>
			</div>
			<div className={styleScss.options}>
				<MenuActionManagementVacancy
					data={{ id: data.id, active: data.active }}
					setOpen={setOpen}
					setAlert={setAlert}
					setAlertMsg={setAlertMsg}
					auth={auth}
					setFilter={setFilter}
					filter={filter}
					updateVacancyStatus={updateVacancyStatus}
					modalDelete={modalDelete}
				/>
			</div>
		</div>
	);
}
