import {
    Autocomplete,
    Box,
    Checkbox,
    Grid,
    InputAdornment,
    TextField,
    Typography,
    Popover
} from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTranslation } from "react-i18next";
import { getErrorFormik, getValueFormik } from "../../../../../util/Index";
import { TextFieldWrapper } from "../../../../../../_metronic/partials/content/texttinput/TextFieldWrapper";
import { useMemo, useState } from "react";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function SectionExtraCourse({ errors, handleChange, values, setValues }: any): JSX.Element {
    const { t } = useTranslation();
    const currentExtraCourses = values?.extraCourses;

    const [valExtraCourses, setValExtraCourses] = useState(currentExtraCourses || []);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    useMemo(() => {
        setValExtraCourses(currentExtraCourses);
    }, [currentExtraCourses]);

    const handleChangeExtraCourse = (newValue: any) => {
        const val: any = [
            ...newValue
        ]
        setValExtraCourses(val);
        setValues({ ...values, extraCourses: val });
    };
    return (
        <>
            <Box sx={{ mb: 3 }} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center' }}>
                <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                    {t('SPECIFIC_KNOWHOW_EXTRA_COURSE')}
                </Typography>
                <div onClick={(e: any) => handleClick(e)} style={{ marginLeft: 5 }}>
                    <i
                        className='bi bi-question-circle-fill'
                        style={{ color: '#2266cc' }}
                    ></i>
                </div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Typography sx={{ p: 2 }}>
                        {t('SPECIFIC_KNOWHOW_EXTRA_COURSE_1')}<br />{t('SPECIFIC_KNOWHOW_EXTRA_COURSE_2')}
                    </Typography>
                </Popover>
            </Box>
            <Grid container direction='row' justifyContent='space-between' width={'100%'} mb={5}>
                <Grid xs={12} md={12} pr={2} sx={{ display: 'block' }} item={true}>
                    <Autocomplete
                        multiple
                        fullWidth
                        id="checkboxes-tags-hardskilss"
                        freeSolo
                        options={[]}
                        autoSelect
                        autoHighlight
                        filterSelectedOptions
                        isOptionEqualToValue={(option, value) => value === option}
                        value={valExtraCourses || []}
                        onChange={(event, newValue) => {
                            handleChangeExtraCourse(newValue);
                        }}
                        // onInputChange={(event: any, value: any) => {
                        //     const cValue = value;
                        //     const val = value.split('')
                        //     console.log("option", cValue)

                        //     console.log(val);
                        //     if (val[val?.length - 1]?.includes(';')) {
                        //         console.log("valExtraCourses", valExtraCourses)

                        //         const v = [
                        //             ...valExtraCourses,
                        //             ...cValue.replace(";", "")
                        //         ]
                        //         console.log("vvvvv", v)
                        //         handleChangeExtraCourse(v);
                        //     }
                        // }}
                        getOptionLabel={(option: any) => option}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option}
                            </li>
                        )}
                        // style={{ width: 500 }}
                        renderInput={(params) => (
                            <TextField {...params} label={t('Conocimientos')} placeholder="Ex: Python, Java, Node, React, C#" />
                        )}
                    />
                </Grid>
            </Grid>

        </>
    )
}