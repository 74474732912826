import {
	Box,
	Grid,
	Button,
	TextField,
	Typography,
	Snackbar,
	Alert,
	InputAdornment,
	CircularProgress,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
	getUserCompelmentoId,
	updateUserComplementoPut,
} from '../../modules/apps/user-management/users-list/core/_requests';
import { getAuth, useAuth } from '../../modules/auth';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { formatMaskPhoneNumber12Digits, regExpPassword } from '../../util/Index';
import { Link, useNavigate } from 'react-router-dom';
import { PageTitle } from '../../../_metronic/layout/core';
import { useTranslation } from 'react-i18next'
import '../../i18n';

const Profile = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { currentUser } = useAuth();

	const [isChangePassword, setChangePassword] = useState(false);
	const [showPass, setShowPass] = useState(false);
	const [showNewPass, setShowNewPass] = useState(false);
	const [showConfirmNewPass, setShowConfirmNewPass] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [isError, setIsError] = useState(false);
	const [msgNotification, setMsgNotification] = useState('');

	useEffect(() => {
		(async function fetchData() {
			const auth = getAuth();
			const data = await getUserCompelmentoId(auth!.user!.id).then((res) => res.data);

			const { user } = currentUser || {};

			const valueFormatted: IUpdateUserCompelmento = {
				id_users: data.user_id ? data.user_id : '',
				primer_nome: data.first_name ? data.first_name : '',
				primer_apelido: data.last_name ? data.last_name : '',
				correo: user?.email ?? '',
				password: '',
				new_password: '',
				confirm_new_password: '',
				celular: data.phone_number ? data.phone_number : '',
			};
			formik.setValues(valueFormatted);
		})();
	}, []);

	const passwordRequirement = useMemo(() => {
		if (isChangePassword) {
			return {
				password: Yup.string().required('Campo requerid'),
				new_password: Yup.string()
					.required('Campo requerido')
					.min(8, 'Contraseña debe tener como mínimo 8 dígitos')
					.matches(
						regExpPassword(),
						`Contraseña debe tener al menos: 1 carácter especial, 1 letra mayúscula, 1 número`
					),
				confirm_new_password: Yup.string()
					.required('Campo requerido')
					.oneOf(
						[Yup.ref('new_password'), null],
						'Contraseña debe ser la misma que la contraseña ingresada'
					),
			};
		}
		return {
			password: Yup.string(),
			new_password: Yup.string(),
			confirm_new_password: Yup.string(),
		};
	}, [isChangePassword]);

	const profileDetailsSchema = useMemo(
		() =>
			Yup.object().shape({
				primer_nome: Yup.string().matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos').required('Primer nombre es requerido'),
				segundo_nome: Yup.string().matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
				primer_apelido: Yup.string().matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos').required('Primer apellido es requerido'),
				segundo_apelido: Yup.string().matches(/^[A-Za-záéíóúÁÉÍÓÚ ]+$/, 'Solo caracteres alfabéticos'),
				//genero: Yup.string().required('Género es requerido'),
				//correo: Yup.string().required('Correo electrónico es requerido'),
				celular: Yup.string()
					//.matches(/\+(\d{2})-(\d{3})-(\d{7})/, 'Teléfono de contacto es inválido')
					.required('Teléfono de contacto es requerido'),
				password: passwordRequirement.password,
				new_password: passwordRequirement.new_password,
				confirm_new_password: passwordRequirement.confirm_new_password,
			}),
		[passwordRequirement.confirm_new_password, passwordRequirement.new_password]
	);

	const formik = useFormik<IUpdateUserCompelmento>({
		initialValues: submitValues,
		validationSchema: profileDetailsSchema,
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: async (values) => {
			try {
				console.log(values);

				const dataToSend = {
					first_name: values.primer_nome,
					last_name: values.primer_apelido,
					phone_number: values.celular,
					//gender: values.gender,
					password: values.password,
					new_password: values.new_password,
					confirm_new_password: values.confirm_new_password,
				  };

				const update = await updateUserComplementoPut(dataToSend);
				console.log(update);
				setIsError(false);
				setMsgNotification(`¡Perfil de usuario actualizado con éxito!`);
			} catch (error: any) {
				console.log(error);
				const errors = error.response.data.error ?? 'Intenta Nuevamente';
				setMsgNotification(`${errors}`);
				setIsError(true);
			} finally {
				setOpenDialog((open) => !open);
			}
		},
	});

	const handleClose = () => {
		setOpenDialog(false);
		if (!isError) navigate('/', { replace: true });
	};

	useEffect(() => {
		const alterPass =
			formik.values.password.length > 0 ||
			formik.values.new_password.length > 0 ||
			formik.values.confirm_new_password.length > 0;
		setChangePassword(alterPass);
	}, [
		formik.values.confirm_new_password.length,
		formik.values.new_password.length,
		formik.values.password.length,
	]);

	return (
		<>
			<Box sx={{ backgroundColor: '#ffff', p: 3 }}>
				<PageTitle breadcrumbs={[]}>{t('Perfil del usuario')}</PageTitle>
				<Box sx={{ p: 0, mb: 3, display: 'flex', alignItems: 'center' }} flexDirection={'row'}>
					<button
						onClick={() => navigate(-1)}
						className='btn btn-primary'
						style={{ background: '#C4D2E8', borderRadius: '4px', padding: '6px 15px' }}
					>
						<i
							className='bi bi-arrow-left'
							style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
						></i>
					</button>
					<Typography variant='h5' sx={{ fontWeight: 'bold', pl: 5 }}>
						{t('Perfil del usuario')}
					</Typography>
				</Box>
				<Grid item xs={12}>
					<form action='' onSubmit={formik.handleSubmit} noValidate>
						<Grid container item xs={12}>
							<Grid item xs={12} mb={5} pr={2} md={6}>
								<TextField
									fullWidth
									required
									label={t('Primer nombre')}
									{...formik.getFieldProps('primer_nome')}
									error={formik.touched.primer_nome && Boolean(formik.errors.primer_nome)}
									helperText={formik.touched.primer_nome ? t(formik.errors.primer_nome || '') : ''}
									size='medium'
								/>
							</Grid>
							{/* <Grid item xs={12} mb={5} pr={2} md={6}>
								<TextField
									fullWidth
									label={t('Segundo nombre')}
									{...formik.getFieldProps('segundo_nome')}
									size='medium'
								/>
							</Grid> */}
							<Grid item xs={12} mb={5} pr={2} md={6}>
								<TextField
									fullWidth
									required
									label={t('Primer apellido')}
									{...formik.getFieldProps('primer_apelido')}
									error={formik.touched.primer_apelido && Boolean(formik.errors.primer_apelido)}
									helperText={formik.touched.primer_apelido ? t(formik.errors.primer_apelido || '') : ''}
									size='medium'
								/>
							</Grid>
							{/* <Grid item xs={12} mb={5} pr={2} md={6}>
								<TextField
									fullWidth
									label={t('Segundo apellido')}
									{...formik.getFieldProps('segundo_apelido')}
									size='medium'
								/>
							</Grid> */}
							<Grid item xs={12} mb={5} pr={2} md={6}>
								<TextField
									fullWidth
									type='email'
									label={t('Correo electrónico')}
									{...formik.getFieldProps('correo')}
								/>
							</Grid>
							{/*
							<Grid item xs={12} mb={5} pr={2} md={6}>
								<FormControl sx={{ minWidth: '100%' }} size='medium'>
									<InputLabel id='genero' required>
										Género
									</InputLabel>
									<Select
										labelId='genero'
										{...formik.getFieldProps('genero')}
										label='Género'
										required
										error={formik.touched.genero && Boolean(formik.errors.genero)}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										<MenuItem value='M'>Masculino</MenuItem>
										<MenuItem value='F'>Femenino</MenuItem>
										<MenuItem value='B'>No binario</MenuItem>
									</Select>
									{formik.touched.genero && formik.errors.genero && (
										<FormHelperText error={true}>{formik.errors.genero}</FormHelperText>
									)}
								</FormControl>
							</Grid>
									*/}
							<Grid item xs={12} mb={5} pr={2} md={6}>
								<TextField
									fullWidth
									required
									label={t('Telefóno de contacto')}
									inputProps={{ maxLength: 15 }}
									{...formik.getFieldProps('celular')}
									size='medium'
									error={formik.touched.celular && Boolean(formik.errors.celular)}
									helperText={formik.touched.celular ? t(formik.errors.celular || '') : ''}
									onChange={(e) => {
										let val = e.target.value
											?.replaceAll(/[^\d]/g, '')
											?.replaceAll('-', '')
											?.replaceAll('+', '');
										let reg = /[0-9]|\./;
										if (reg.test(val) || val === '') {
											e.target.value = formatMaskPhoneNumber12Digits(val);
											formik.handleChange(e);
										}
									}}
								/>
							</Grid>
						</Grid>
						<Grid container item direction='row' xs={12}>
							<Grid item xs={12} mb={3} md={12}>
								<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
									{t('Cambiar la contraseña')}
								</Typography>
							</Grid>
							<Grid item xs={12} mb={5} pr={2} md={6}>
								<TextField
									fullWidth
									label={t('Contraseña')}
									{...formik.getFieldProps('password')}
									error={Boolean(formik.errors.password) && isChangePassword}
									helperText={isChangePassword ? t(formik.errors.password || '') : ''}
									size='medium'
									required
									type={showPass ? 'string' : 'password'}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												{showPass ? (
													<i
														className='bi bi-eye pe-auto'
														style={{
															fontSize: '20px',
															float: 'right',
															position: 'relative',
															zIndex: '2',
														}}
														onClick={() => setShowPass(!showPass)}
													></i>
												) : (
													<i
														className='bi bi-eye-slash pe-auto'
														style={{
															fontSize: '20px',
															float: 'right',
															position: 'relative',
															zIndex: '2',
														}}
														onClick={() => setShowPass(!showPass)}
													></i>
												)}
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12} mb={5} pr={2} md={6}>
								<TextField
									fullWidth
									label={t('Nueva contraseña')}
									{...formik.getFieldProps('new_password')}
									error={Boolean(formik.errors.new_password) && isChangePassword}
									helperText={isChangePassword ? t(formik.errors.new_password || '') : ''}
									size='medium'
									required={isChangePassword}
									type={showNewPass ? 'string' : 'password'}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												{showNewPass ? (
													<i
														className='bi bi-eye pe-auto'
														style={{
															fontSize: '20px',
															float: 'right',
															position: 'relative',
															zIndex: '2',
														}}
														onClick={() => setShowNewPass(!showNewPass)}
													></i>
												) : (
													<i
														className='bi bi-eye-slash pe-auto'
														style={{
															fontSize: '20px',
															float: 'right',
															position: 'relative',
															zIndex: '2',
														}}
														onClick={() => setShowNewPass(!showNewPass)}
													></i>
												)}
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12} mb={5} pr={2} md={6}>
								<TextField
									fullWidth
									label={t('Confirme la nueva contraseña')}
									{...formik.getFieldProps('confirm_new_password')}
									error={Boolean(formik.errors.confirm_new_password) && isChangePassword}
									helperText={isChangePassword ? t(formik.errors.confirm_new_password || ''): ''}
									size='medium'
									required={isChangePassword}
									type={showConfirmNewPass ? 'string' : 'password'}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												{showConfirmNewPass ? (
													<i
														className='bi bi-eye pe-auto'
														style={{
															fontSize: '20px',
															float: 'right',
															position: 'relative',
															zIndex: '2',
														}}
														onClick={() => setShowConfirmNewPass(!showConfirmNewPass)}
													></i>
												) : (
													<i
														className='bi bi-eye-slash pe-auto'
														style={{
															fontSize: '20px',
															float: 'right',
															position: 'relative',
															zIndex: '2',
														}}
														onClick={() => setShowConfirmNewPass(!showConfirmNewPass)}
													></i>
												)}
											</InputAdornment>
										),
									}}
								/>
							</Grid>
						</Grid>

						<Grid container item direction='row' justifyContent='end' width={'100%'}>
							<Button variant='contained' type='submit' className='btn btn-primary'>
								{!formik.isSubmitting && (t('Actualizar'))}
								{formik.isSubmitting && <CircularProgress size={20} color='inherit' />}
							</Button>
						</Grid>
					</form>

					<Snackbar
						open={openDialog}
						autoHideDuration={1500}
						onClose={handleClose}
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					>
						<Alert
							onClose={handleClose}
							variant='filled'
							severity={isError ? 'error' : 'success'}
							sx={{ whiteSpace: 'pre-line' }}
						>
							<Link to='/' style={{ pointerEvents: isError ? 'none' : 'auto', color: 'white' }}>
								{t(msgNotification || '')}
							</Link>
						</Alert>
					</Snackbar>
				</Grid>
			</Box>
		</>
	);
};
const submitValues: IUpdateUserCompelmento = {
	id_users: 0,
	primer_nome: '',
	primer_apelido: '',
	celular: '',
	correo: '',
	password: '',
	new_password: '',
	confirm_new_password: '',
};
export interface IUpdateUserCompelmento {
	id_users: number;
	primer_nome: string;
	primer_apelido: string;
	celular: string;
	correo: string;
	password: string;
	new_password: string;
	confirm_new_password: string;
}
export default Profile;
