/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import Avatar from 'react-avatar';
import { useAuth } from '../../../../app/modules/auth';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const HeaderUserMenu: FC = () => {
  const { t } = useTranslation()
	const [languageSelectec] = useState(localStorage.getItem('language') || navigator.language);
	const { currentUser, logout } = useAuth();
	console.log('current', currentUser);

  function handleChangeLanguage(event: any) {
		localStorage.setItem('language', event.value);
		window.location.reload();
	}

  const languageOptions = [
    { value: 'pt-BR', label: 'Brasil', flag: '/media/icons/language/brasil.png' },
    { value: 'en-US', label: 'Estados Unidos', flag: '/media/icons/language/estados-unidos.png' },
    { value: 'es', label: 'Espanha', flag: '/media/icons/language/espanha.png' },
  ];

	return (
		<div
			className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
			data-kt-menu='true'
		>
			<div className='menu-item px-3'>
				<div className='menu-content d-flex align-items-center px-3'>
					<div className='symbol symbol-50px me-5'>
						{/* <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} /> */}
						{/* TODO: HM - ALTERAR O NOME DO USUÁRIO AQUI */}
						<Avatar
							size='80px'
							alt={currentUser?.user?.email}
							round={true}
							name={currentUser?.user?.email}
						/>
					</div>
					<div className='d-flex flex-column'>
						<div className='fw-bolder d-flex align-items-center fs-5'>
							{/*  {currentUser?.user?.email} */}
							{/* {currentUser?.first_name} {currentUser?.first_name} */}
							{/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
						</div>
						<a href='#' className='fw-bold text-muted text-hover-primary fs-8'>
							{currentUser?.user?.email}
						</a>
					</div>
				</div>
			</div>

			<div className='separator my-2'></div>

			<div className='menu-item px-5'>
				<Link to="/profile" className='menu-link px-5'>{t('Perfil')}</Link>

        <Select
          options={languageOptions}
          styles={
            {
              control: (base: any) => ({
                ...base,
                border: 0,
                width: 100,
                boxShadow: 'none',
                '&:hover': { border: 0 },
              }),
            }
          }
          onChange={handleChangeLanguage}
          value={languageOptions.find((obj) => obj.value === languageSelectec)}
          formatOptionLabel={({ label, flag }) => (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <img src={flag} alt={label} style={{ width: 20, height: 20 }} />
            </div>
          )}
        />

				{/* comentado <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link> */}
			</div>
			{/* comentado <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div>
      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      
      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div>
      */}



			<div className='menu-item px-5'>
				<a onClick={logout} className='menu-link px-5' style={{ color: '#AC0000' }}>
					<i className='bi bi-box-arrow-left' style={{ fontSize: '20px', color: '#AC0000' }}></i>
					&nbsp;{t('Sair')}
				</a>
			</div>
		</div>
	);
};

export { HeaderUserMenu };
