import { SetStateAction, useCallback, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import { Calendar, DateSelectArg, EventApi, EventClickArg } from '@fullcalendar/core';
import { INITIAL_AVAILABLE_COUNSELOR, INITIAL_EVENTS } from './Data';
import './styles.css';
import Avatar from 'react-avatar';
import { PageTitle } from '../../../_metronic/layout/core';
import Modal from '@mui/material/Modal';
import { Alert, Box, Snackbar, TextField, Typography } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
	deleteMeetApi,
	getMeetsByCandidate,
	getRandomDate,
	getVerifyFreeDate,
	listCouselor,
	storeMeetApi,
} from '../../modules/apps/user-management/users-list/core/_requests';
import { useAuth } from '../../../app/modules/auth';
import React from 'react';
import { Tooltip } from 'bootstrap';
import moment from 'moment';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const CustomCalendarComponent = () => {
	const { t } = useTranslation()
	const { currentUser } = useAuth();
	const [openModal, setOpenModal] = useState(false);
	const handleCloseModal = () => setOpenModal(false);
	const [openModalConfirm, setOpenModalConfirm] = useState(false);
	const [eventSelected, setEevntSelected]: any = useState();
	const handleCloseModalConfirm = () => setOpenModalConfirm(false);
	const onTimeChange = (time: SetStateAction<string>) => setTime(time);
	const [messageModal, setMessageModal] = useState('');
	const [data, setData] = useState(new Date());
	const [time, setTime] = useState('10:00');
	const [dateMeet, setDateMeet] = useState(new Date());
	const startsWithTwo = time[0] === '2';
	const [error, setError] = useState(false);
	const [consuelor, setConsuelor] = useState(0);
	const [consuelorList, setConsuelorList]: any[] = useState([]);
	const [randomDateValue, setRandomDateValue]: any[] = useState([]);
	const [meets, setMeets]: any[] = useState([]);
	const [open, setOpen] = useState(false);
	const [msgAlert, setMsgAlert] = useState('');
	const [isError, setIsError] = useState(false);
	const handleClose = () => {
		setOpen(false);
	};

	const selectConstraint = {
		start: moment().subtract(1, 'days'),
	};

	const handleInput = (event: any | null) => {
		if (event) {
			let date = new Date(event.$d);
			data.setHours(date.getHours());
			data.setMinutes(date.getMinutes());
			setDateMeet(data);
			verifyDate(data);
		}
	};

	useEffect(() => {
		loadMeets();
		getConsuelorList();
	}, []);

	async function verifyDate(date: any) {
		await getVerifyFreeDate(`date=${date}`).then((response) => {
			if (response.data.id_organizador) {
				setError(false);
				setRandomDateValue([]);
				setConsuelor(response.data.id_organizador);
			} else {
				setConsuelor(0);
				setError(true);
				randomDate();
			}
		});
	}

	async function randomDate() {
		await getRandomDate().then((response: { data: any }) => {
			if (response.data) {
				let dates: string[] = [];
				response.data.forEach((date: string | number | Date) => {
					var d = new Date(date);
					dates.push(d.toLocaleString().replace(',', '').substring(0, 16));
				});
				console.log(dates.toString());
				console.log(dates);
				setRandomDateValue(dates);
			}
		});
	}

	async function storeMeet() {
		setError(true);
		let idUser = currentUser?.user?.id;
		let obj = {
			id_candidate: idUser,
			id_couselor: consuelor,
			data: dateMeet,
		};

		await storeMeetApi(obj).then((response: { data: any }) => {
			handleCloseModal();
			loadMeets();
			setMsgAlert('Programación realizada con éxito');
			setIsError(false);
			setOpen(true);
			setError(false)
		});
	}

	async function loadMeets() {
		let idUser = currentUser?.user?.id;
		await getMeetsByCandidate(`id_candidate=${idUser}`).then((response) => {
			if (response.data) {
				let events: any[] = [];
				response.data.forEach((element: any) => {
					let event = {
						id: element.id,
						publicId: element.id,
						title: element.descripcion,
						description:
							element.descripcion +
							' ' +
							new Date(element.inicio).toLocaleString().replace(',', ''),
						link_event: element.link_event,
						start: new Date(element.inicio).toISOString(), //.replace(/T.*$/, "") + "T12:00:00"
					};
					events.push(event);
				});
				setMeets(events);
			}
		});
	}

	async function getConsuelorList() {
		await listCouselor().then((response) => {
			if (response.data) {
				setConsuelorList(response.data);
			}
		});
	}

	const [currentEvents, setCurrentEvents] = useState<EventApi[]>([]);

	const handleEvents = useCallback((events: EventApi[]) => setCurrentEvents(events), []);

	async function confirmDelete() {
		if (eventSelected.extendedProps.publicId) {
			await deleteMeetApi(`id=${eventSelected.extendedProps.publicId}`).then((response) => {
				setOpenModalConfirm(false);
				loadMeets();
				setMsgAlert('Programación eliminada con éxito');
				setIsError(false);
				setOpen(true);
				if (response.data) {
					handleCloseModalConfirm();
					eventSelected.remove();
				}
			});
		}
	}

	const handleEventClick = useCallback(async (clickInfo: EventClickArg) => {
		setEevntSelected(clickInfo.event);
		setOpenModalConfirm(true);
	}, []);

	const handleOpenModal = useCallback(async (clickInfo: DateSelectArg) => {
		setConsuelor(0);
		setData(new Date(clickInfo.start));

		const dataI = new Date(clickInfo.start);
		dataI.setDate(dataI.getDate() + 1);

		if (dataI.getDay() == 0 || dataI.getDay() == 1) {
			setMsgAlert('Programación no permitida durante el fin de semana');
			setIsError(true);
			setOpen(true);
			return;
		}
		console.log(new Date().toLocaleString());

		if (dataI.getTime() < new Date().getTime()) {
			setMsgAlert('La fecha debe ser posterior a la fecha actual');
			setIsError(true);
			setOpen(true);
		} else {
			let idUser = currentUser?.user?.id;
			await getMeetsByCandidate(`id_candidate=${idUser}`).then((response) => {
				if (response.data.length == 0) {
					setOpenModal(true);
				} else {
					setMsgAlert('El candidato ya tiene una reunión programada');
					setIsError(true);
					setOpen(true);
				}
			});
		}
	}, []);

	const handleDidMount = useCallback(async (clickInfo: any) => {
		var tooltip = new Tooltip(clickInfo.el, {
			title: clickInfo.event.extendedProps.description,
			placement: 'top',
			trigger: 'hover',
			container: 'body',
		});
	}, []);

	const language = localStorage.getItem('language') || navigator.language;

	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Citas programadas')}</PageTitle>
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<div className='row'>
					<div className='col-sm-8 mb-5'>
						<FullCalendar
							plugins={[dayGridPlugin, interactionPlugin]}
							initialView='dayGridMonth'
							selectable={true}
							editable={true}
							events={meets}
							locales={allLocales}
							locale={language}
							eventsSet={handleEvents}
							eventDidMount={handleDidMount}
							select={handleOpenModal}
							eventClick={handleEventClick}	
						/>
					</div>
					<div className='col-sm-4'>
						<div className='card mb-10' style={{ borderRadius: '10px' }}>
							<div className='card-body mt-5'>
								<h2 className='card-title'>{t('Consejeros disponibles')}</h2>
								<p className='card-text'>{t('Buscar y seleccionar un consejero.')}</p>
								{/* <div className="row">
                                    <div className="col-sm-6 mt-4">
                                        <label htmlFor="day">¿En qué día?</label>
                                        <div className="input-group mb-3">
                                            <input id='day' type={'date'} className="form-control" placeholder="¿En qué día?" aria-label="¿En qué día?" aria-describedby="basic-addon2" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-4">
                                        <label htmlFor="time">¿A qué hora?</label>
                                        <div className="input-group mb-3">
                                            <input id="time" type={'time'} className="form-control" placeholder="¿A qué hora?" aria-label="¿A qué hora?" aria-describedby="basic-addon2" />
                                        </div>
                                    </div>
                                </div> */}

								{consuelorList.map((x: any, i: number) => (
									<div className='row mt-6' style={{ flexWrap: 'nowrap' }} key={i} id={x.id}>
										<div className='col-sm-1' style={{ width: 'auto' }}>
											<div className={`box-aside`}>
												<Avatar
													size='60px'
													alt={x.primer_nome}
													round={'11px'}
													name={x.primer_nome}
												/>
											</div>
										</div>
										<div className='col-sm-9 mt-2' style={{ width: 'auto' }}>
											<span className='text-primary'>{x.primer_nome}</span>
											<br />
											<span>
												{new Date(x.inicio_atendimento).toString().substring(16, 21)} -{' '}
												{new Date(x.termino_atendimento).toString().substring(16, 21)}
											</span>
										</div>
									</div>
								))}
							</div>
							{/* <div className="card-footer">
                                <button className='btn btn-primary btn-lg btn-block' style={{ padding: '8px 40px' }}>Agendar cita</button>
                            </div> */}
						</div>
					</div>
				</div>
			</div>

			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					variant='filled'
					severity={isError ? 'error' : 'success'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{t(msgAlert)}
				</Alert>
			</Snackbar>

			{/* MODAL */}
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					sx={{
						position: 'absolute' as 'absolute',
						top: '40%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '80vw',
						bgcolor: 'background.paper',
						border: 'none',
						borderRadius: '10px',
						boxShadow: 24,
						p: 4,
					}}
				>
					<Typography id='modal-modal-title' variant='h4' component='h4'>
						{t('Nueva reunión en')} {new Date(data).toJSON().slice(5, 10).split('-').reverse().join('/')}
					</Typography>
					<Typography id='modal-modal-description' sx={{ mt: 2, fontSize: '12pt' }}>
						{t('Selecciona una hora')}
					</Typography>
					<br />
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<TimePicker
							label={t('Seleccione')}
							defaultValue={dayjs('2023-01-01T10:00')}
							ampm={true}
							onChange={(newValue) => handleInput(newValue)}
						/>
					</LocalizationProvider>
					<br />
					<Typography color={'gray'} variant='caption'>
						{t('Haz clic en el icono del reloj para cambiar la hora.')}
					</Typography>
					{error ? (
						<Typography
							id='modal-modal-description'
							sx={{ mt: 2, fontSize: '12pt', color: '#ff0000' }}
						>
							{t('No hay consejero disponible')}
						</Typography>
					) : null}
					{randomDateValue ? (
						<>
							<Typography id='modal-modal-description' sx={{ mt: 2, fontSize: '12pt' }}>
								{t('Fechas sugeridas:')}
							</Typography>
							{randomDateValue.map((r: string, index: number) => (
								<Typography key={index} variant='body1'>
									- {r}
								</Typography>
							))}
						</>
					) : null}
					<div className='col d-flex justify-content-end'>
						{!error && consuelor > 0 ? (
							<button
								className='btn btn-primary'
								onClick={() => {
									storeMeet();
								}}
								style={{ padding: '8px 20px', background: '#153E7B' }}
							>
								{t('Confirmar')}
							</button>
						) : null}
						&nbsp;
						<button
							className='btn btn-secondary'
							onClick={() => {
								setMessageModal('');
								handleCloseModal();
							}}
							style={{ padding: '8px 20px' }}
						>
							{t('Cancelar')}
						</button>
					</div>
				</Box>
			</Modal>

			<Modal
				open={openModalConfirm}
				onClose={handleCloseModalConfirm}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box
					sx={{
						position: 'absolute' as 'absolute',
						top: '40%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '80%',
						bgcolor: 'background.paper',
						border: '2px solid #000',
						borderRadius: '10px',
						boxShadow: 24,
						p: 4,
					}}
				>
					<Typography id='modal-modal-title' variant='h6' component='h6'>
						{eventSelected?.extendedProps?.description ?? ''}
					</Typography>
					<Typography id='modal-modal-description' sx={{ mt: 2, fontSize: '12pt' }}>
						<a href={eventSelected?.extendedProps?.link_event ?? ''} target='_blank'>
							{t('Ver en el calendario')}
						</a>
					</Typography>

					<br />
					<div className='col d-flex justify-content-end'>
						<button
							className='btn btn-secondary'
							onClick={() => {
								handleCloseModalConfirm();
							}}
							style={{ padding: '8px 20px' }}
						>
							{t('Cancelar')}
						</button>
						&nbsp;
						<button
							className='btn btn-danger'
							onClick={() => {
								confirmDelete();
							}}
							style={{ padding: '8px 20px', background: '#d41717' }}
						>
							{t('borrar la cita?')}
						</button>
					</div>
				</Box>
			</Modal>
		</>
	);
};

export default CustomCalendarComponent;

function initialDate(initialDate: any): [any, any] {
	throw new Error('Function not implemented.');
}
