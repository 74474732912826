import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import styleScss from './styles.module.scss';
import { useTranslation } from 'react-i18next'
import '../../i18n';

const CandidateManagementPage = () => {
	const { t } = useTranslation()
	const [data, setData]: any[] = useState([]);

	useEffect(() => {
		let arr: any[] = [];
		for (let index = 0; index < 10; index++) {
			let x = {
				document: index,
				number: index,
				name: `${t('Nombre')} ${index}`,
				nickName: `apellido ${index}`,
				email: `teste@${index}.com`,
				local: `local ${index}`,
				register: `${index}`,
				state: `Bogota`,
				ficha: `${index}`,
			};
			arr.push(x);
		}
		setData(arr);
	}, []);

	// const cols: Column[] = [
	//     { heading: 'Documento', value: 'document' },
	//     { heading: 'Numero', value: 'number' },
	//     { heading: 'Nombre', value: 'name' },
	//     { heading: 'Apellido', value: 'nickName' },
	//     { heading: 'Correo Electrónico', value: 'email' },
	//     { heading: 'Prestador', value: 'local' },
	//     { heading: 'Registro', value: 'register' },
	//     { heading: 'Estado', value: 'state' },
	//     { heading: 'Ficha', value: 'ficha' },
	// ];

	return (
		<>
			<div className='p-4 shadow-4 rounded-3' style={{ backgroundColor: '#FFFF' }}>
				<div className='row mb-4'>
					<div className='col-sm-4'>
						<div className='input-group mb-3'>
							<select name='documentType' className='form-control' id='documentType'>
								<option value='-1' defaultChecked>
									{t('Seleccione un documento')}
								</option>
								<option value='1'>1</option>
								<option value='1'>1</option>
							</select>
							<div className='input-group-append'>
								<span className='input-group-text' id='searchCandidato'>
									<i className='bi bi-chevron-compact-down' style={{ fontSize: '20px' }}></i>
								</span>
							</div>
						</div>
					</div>
					<div className='col-sm-4'>
						<div className='input-group mb-3'>
							<input
								type='text'
								className='form-control'
								placeholder={t('Escreba el nombre')}
								aria-label="Recipient's username"
								aria-describedby='basic-addon2'
							/>
							<div className='input-group-append'>
								<span className='input-group-text' id='searchCandidato'>
									<i className='bi bi-search' style={{ fontSize: '20px' }}></i>
								</span>
							</div>
						</div>
					</div>
				</div>
				<table className={`table table-responsive table-striped`}>
					<thead className={`${styleScss['th-custom']}`}>
						<tr>
							<th scope='col'>
								<h4>{t('Documento')}</h4>
							</th>
							<th scope='col'>
								<h4>{t('Numero')}</h4>
							</th>
							<th scope='col'>
								<h4>{t('Nombre')}</h4>
							</th>
							<th scope='col'>
								<h4>{t('Apellido')}</h4>
							</th>
							<th scope='col'>
								<h4>{t('Correo electrónico')}</h4>
							</th>
							<th scope='col'>
								<h4>{t('Prestador')}</h4>
							</th>
							<th scope='col'>
								<h4>{t('Registro')}</h4>
							</th>
							<th scope='col'>
								<h4>{t('Estado')}</h4>
							</th>
							<th scope='col'>
								<h4>{t('Ficha')}</h4>
							</th>
							<th scope='col'>
								<h4>{t('Acciones')}</h4>
							</th>
						</tr>
					</thead>
					<tbody className={`${styleScss['td-custom']}`}>
						{data.map((x: any, i: number) => (
							<tr key={i} id={i.toString()}>
								<th scope='row'>{x.document}</th>
								<td>{x.number}</td>
								<td>{x.name}</td>
								<td>{x.nickName}</td>
								<td>{x.email}</td>
								<td>{x.local}</td>
								<td>{x.register}</td>
								<td>{x.state}</td>
								<td>{x.ficha}</td>
								<td>
									<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</>
	);
};

const AdminCandidateManagementWrapper = () => {
	const { t } = useTranslation()
	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Gestión candidatos')}</PageTitle>
			<CandidateManagementPage />
		</>
	);
};

export { AdminCandidateManagementWrapper };
