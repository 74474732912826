import { FormEvent, useContext, useState, useEffect } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';

import { useFormik } from 'formik'
import {
	TextField,
	SelectChangeEvent,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Checkbox,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Box,
	Button,
	Divider,
	Typography,
	Alert,
	Snackbar,
	AlertTitle,
	FormHelperText,
	InputAdornment,
	CircularProgress,
} from '@mui/material';
import { useAuth } from '../../..';
import { useParams, useNavigate } from 'react-router-dom';
import { getCurriculoById, } from '../../../../apps/user-management/users-list/core/_requests';
import { createUpdateCurriculum, getCurriculo } from '../../../../auth/core/_requests'
import {
	convertDateToISOString,
	formatMaskPhoneNumber12Digits,
	getCheckedValueFormik,
	getValueFormik,
	isValidEmail,
	isValidFieldRequired,
	isValidPhoneNumber,
	regExpPhoneNumber12Digits,
} from '../../../../../util/Index';

import { softSkills } from '../../../../../mocks/options';

import { multiStepContext } from '../../../../../contexts/CurriculumContext';

import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';
import { SOFTSKILS } from '../../../../../constants/constants';
import SectionExpierence from './SectionExpierence';
import SectionProfessionalProfile from './SectionProfessionalProfile';
import SectionEducation from './SectionEducation';
import SectionAdditionalCourse from './SectionAdditionalCourse';
import SectionExtraCourse from './SectionExtraCourse';
import SectionCertification from './SectionCertification';
import SectionWorkshop from './SectionWorkshop';
import SectionVoluntary from './SectionVoluntary';
import SectionLanguages from './SectionLanguages';
import SectionImportPDF from '../import-pdf/SectionImportPDF';

const initialData = {
	first_name: "",
	last_name: "",
	birth_date: "",
	position: "",
	professional_profile: "",
	salary_expectation: null as number | null,
	home_office: true,
	willing_to_travel: true,
	licensed: true,
	license_category: "",
	own_vehicle: true,
	marital_status: 2,
	military_certificate: true,
	phone_number: "",
	country: "",
	nationality: "",
	softskils: [],
	hardSkills: [],
	professionalHistory: [],
	traditionalEducation: false,
	educations: [],
	extraCourses: [],
	additionalCourses: [],
	languages: [],
	certifications: [],
	workshops: [],
	volunteering: [],
}

export default function CurriculumForm(props: any) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const [alert, setAllert] = useState(false);
	const [open, setOpen] = useState(false);

	const [initalValues, setInitialValues] = useState(initialData);

	const formik = useFormik({

		initialValues: initalValues,
		onSubmit: async (values) => {


			values['salary_expectation'] = values['salary_expectation']
				? parseFloat(String(values['salary_expectation']).replace(/\./g, '').replace(',', '.'))
				: null;

			setLoading(true)
			try {
				const response = await createUpdateCurriculum(values);
				setAllert(true);
				setLoading(false)
				setOpen(true)

			} catch (e) {
				setAllert(false);
				setLoading(false)
				setOpen(true)

			}
		},
	});
	const { values, errors, touched, handleChange, setStatus, handleBlur, handleSubmit, status, isSubmitting, setFieldValue, setValues, setErrors } = formik

	const onSubmit = () => {
		handleSubmit()
	}
	const [borderColorEdit, setBorderColorEdit] = useState<any>('1px solid');
	const [editMode, setEditMode] = useState(false);

	const [errorMessage, setErrorMessage] = useState(null);
	const {
		userData,
		setUserData,
		courseTypes,
		levelLanguages,
		workshopTypeParticipants,
		listMaritalStatus
	} = useContext(multiStepContext);

	const validationSchema = Yup.object().shape({
		cargo_pretendido: Yup.string()
			.required('Campo requerido')
			.max(100, 'O campo deve ter no máximo 100 caracteres'),
		perfil_profissional: Yup.string()
			.required('Campo requerido')
			.max(100, 'O campo deve ter no máximo 100 caracteres'),
	});

	const callRequestCurriculum = async () => {
		setLoading(true);
		try {
			const response = await getCurriculo();
			if (!!response) {
				setInitialValues(response);
				setValues(response)
			}
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}

	}

	useEffect(() => {
		try {
			callRequestCurriculum();
		} catch {

		}
		validationSchema
			.validate({
				cargo_pretendido: userData['cargo_pretendido'],
				perfil_profissional: userData['perfil_profissional'],
			})
			.then(() => {
				// Se a validação for bem-sucedida, faça algo aqui
			})
			.catch((error) => {
				setErrorMessage(error.message);
			});
	}, [userData]);


	useEffect(() => {
		formik.setValues({
			first_name: "Allan",
			last_name: "Silva",
			birth_date: "08/11/1991",
			position: "Desenvolvedor FullStack Sr.",
			professional_profile: "Desenvolvedor Web JavaScript com seis anos de experiência. Atualmente focado em Node.js, React e React Native, com o objetivo de atuar na criação de sistemas completos (frontend, backend e mobile).",
			salary_expectation: null,
			home_office: true,
			willing_to_travel: true,
			licensed: true,
			license_category: "B",
			own_vehicle: true,
			marital_status: 2,
			military_certificate: true,
			phone_number: "",
			country: "",
			nationality: "",
			softskils: userData.softskils,
			hardSkills: userData.hardSkills,
			professionalHistory: userData.experiencie,
			traditionalEducation: userData.traditionalEducation,
			educations: userData.education,
			additionalCourses: userData.additionalCourses,
			languages: userData.languages,
			extraCourses: userData.extraCourses,
			certifications: userData.licenceAndCertifications,
			workshops: userData.seminarieAndWorkshop,
			volunteering: userData.jobVoluntarie
		});
	}, [userData])

	const loadValuesFromPDF = (values: any) => {
		formik.setValues(initialData);
		setLoading(true);
		try {
			setTimeout(() => {
				formik.setValues(values);
				setLoading(false);
			}, 5000)
		} catch (e) {
			console.error(e);
		} finally {
			setLoading(false);
		}


	}

	return (
		<>
			{loading ? (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress />
				</Box>
			) : (
				<form onSubmit={(e: any) => {
					e.preventDefault();
					if (e.type !== "keydown" || (e.target && e.target?.type === "submit")) {
						// handleSubmit(e);
					}
				}}>
					<SectionImportPDF onLoadPDF={loadValuesFromPDF} onClear={callRequestCurriculum} />
					{/* PERFIl PROFISSIONAL */}
					<SectionProfessionalProfile  {...formik} listMaritalStatus={listMaritalStatus} />
					{/* FIM DO PERFIL PROFISSIONAL */}

					{/* EXPERIÊNCIA PROFISSIONAL */}
					<SectionExpierence {...formik} />
					{/* FIM DO EXPERIÊNCIA PROFISSIONAL */}

					{/* EDUCAÇÃO */}
					<SectionEducation {...formik} courseTypes={courseTypes} />
					{/* FIM DO EDUCAÇÃO */}

					<SectionAdditionalCourse {...formik} />

					<SectionLanguages {...formik} levelLanguages={levelLanguages} />

					<SectionExtraCourse {...formik} />

					<SectionCertification {...formik} />

					<SectionWorkshop {...formik} typeParticipants={workshopTypeParticipants} />

					<SectionVoluntary {...formik} />
					<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>

						{/* <Link to='/auth'> */}
						<Button
							variant='contained'
							sx={{ mr: 1, width: 90, height: 40 }}
							onClick={onSubmit}
							type="button"
						>
							{t('Finalizar')}
						</Button>
						{/* </Link> */}
					</Box>
					<Snackbar
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={open}
						onClose={() => setOpen(false)}
					>
						<Alert
							onClose={() => setOpen(false)}
							variant={'filled'}
							severity={alert ? 'success' : 'error'}
							sx={{ whiteSpace: 'pre-line' }}
						>
							{alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
							{'sucesso'}
						</Alert>
					</Snackbar>
				</form >
			)
			}
		</>
	);
}
