import React, { ReactNode, ErrorInfo } from 'react';

interface ErrorBoundaryProps {
	children: ReactNode;
}

interface ErrorBoundaryState {
	hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(_: Error): { hasError: boolean } {
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		console.error('Erro capturado pela ErrorBoundary:', error, errorInfo);
	}

	render(): React.ReactNode {
		if (this.state.hasError) {
			return <p>Desculpe, algo deu errado. Por favor, recarregue a página.</p>;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
