import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { PageTitle } from '../../../_metronic/layout/core';
import { Link } from 'react-router-dom';
import {
	TextField,
	SelectChangeEvent,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Box,
	Button,
	Divider,
	Typography,
} from '@mui/material';
import moment from 'moment';
import styleScss from './styles.module.scss';

import { Alert, Snackbar, AlertTitle } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import SavingsIcon from '@mui/icons-material/Savings';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ArticleIcon from '@mui/icons-material/Article';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import CottageIcon from '@mui/icons-material/Cottage';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import {
	getVacancyId,
	registerForVacancy,
} from '../../modules/apps/user-management/users-list/core/_requests';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import {
	capitalizeFirstLetter,
	initValue,
	initValueString,
	setValueOrDefault,
} from '../../util/Index';
import { useAuth } from '../../modules/auth';
import { useTranslation } from 'react-i18next';

const VacancyPage = () => {
	const { t, i18n } = useTranslation();
	const { id } = useParams();
	const { currentUser } = useAuth();

	const navigate = useNavigate();

	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [postulateLabel, setPostulateLabel] = useState('Aplicar');
	const [postulateColor, setPostulateColor] = useState('#153E7B');
	const [descNivelEducativo, setDescNivelEducativo] = useState('');
	const [openInformationVacancy, setOpenInformationVacancy] = useState(true);
	const [openDescription, setOpenDescription] = useState(true);

	const [vacancy, setVacancy] = useState<any>({});

	const handlePostulateLabel = (data: any) => {
		if (data['UserCurriculos']?.length > 0) {
			setPostulateLabel('Aplicado');
			setPostulateColor('green');
		} else {
			setPostulateLabel('Aplicar');
		}
	};

	useEffect(() => {
		getVacanteId();
	}, []);

	const getVacanteId = async () => {
		try {
			if (!id) {
				throw new Error('La identificación no está definida');
			}
			const response = await getVacancyId(parseInt(id));

			const currentLanguage = i18n.language.split('-')[0];

			const courseTypeMap: { [key: string]: string } = {
				pt: 'pt_course_type',
				es: 'es_course_type',
				en: 'en_course_type',
			};

			const contractTypeMap: { [key: string]: string } = {
				pt: 'pt_type',
				es: 'es_type',
				en: 'en_type',
			};

			const contractTypeDescriptionMap: { [key: string]: string } = {
				pt: 'pt_description',
				es: 'es_description',
				en: 'en_description',
			};

			const contractTypeKey = contractTypeMap[currentLanguage];
			const contractTypeDescriptionKey = contractTypeDescriptionMap[currentLanguage];

			const contractType =
				response.data.ContractType && response.data.ContractType[contractTypeKey]
					? response.data.ContractType[contractTypeKey]
					: null;
			const contractTypeDescription =
				response.data.ContractType && response.data.ContractType[contractTypeDescriptionKey]
					? response.data.ContractType[contractTypeDescriptionKey]
					: null;

			const formattedResponse = {
				id: response.data.id,
				id_empresa: response.data.company_id,
				numero_puestos: response.data.position_count.toString(),
				nombre_vacante: response.data.job_title,
				responsable_vacante: response.data.job_responsible,
				persona_solicita_cargo: response.data.requester,
				telefono_contacto: response.data.contact_phone,
				Correo_eletrenico_contacto: response.data.contact_email,
				asesor_responsable: response.data.responsible_advisor,
				Describa_vacante: response.data.job_description,
				nivel_estudio: response.data.education_level,
				graduado: response.data.graduated ? '1' : '',
				formacion_titulo: response.data.education_title,
				requiere_experiencia_relacionada: response.data.experience_required ? '1' : '',
				requiere_capacitacion_especifica: response.data.training_required ? '1' : '',
				vacante_susceptible_teletrabajo: response.data.telework_possible ? '1' : '',
				rango_experiencia: response.data.experience_range,
				descricion_capacitacion_especifica: response.data.training_description,
				fecha_limite_aplicacion: response.data.application_deadline,
				fecha_estimada_incorporacion: response.data.estimated_start_date,
				tipo_contrato: contractType,
				tipo_contrato_desc: contractTypeDescription,
				salario_mensual: new Intl.NumberFormat('pt-BR', {
					style: 'currency',
					currency: 'BRL',
				}).format(parseFloat(response.data.monthly_salary)),
				jornada_laboral: response.data.work_schedule,
				vacante_confidencial: response.data.confidential_job ? '1' : '',
				vacante_gestionada_prestador: response.data.outsourced_job ? '1' : '',
				tipo_vacante: response.data.job_type,
				punto_atencion: response.data.street,
				prestador_alterno: response.data.complement,
				districto: response.data.city,
				createdAt: response.data.created_at,
				status: response.data.status === 'true',
				Empresa: {
					id: response.data.Company.id,
					idhashEmpresa: response.data.Company.id,
					tipo_documento: response.data.Company.document_type,
					numero_documento: response.data.Company.document_number,
					razon_social: response.data.Company.company_name,
					naturaleza: '2',
					tipo: response.data.Company.company_type,
					//Tamano_empleados: response.data.Company.number_employees.toString(),
					local: response.data.Company.city,
					tipo_complemento: response.data.Company.complement,
					direccion_residencia: response.data.Company.street,
					pais: response.data.Company.country,
					departamento: response.data.Company.state,
					municipio: response.data.Company.district,
					latitud: response.data.Company.latitude,
					longitud: response.data.Company.longitude,
					localidad: response.data.Company.district,
					active: response.data.Company.active,
					status: response.data.Company.status,
					telefono: response.data.Company.phone_number,
					nombre_sede: null,
				},
				/* Cargos: [
					{
						id: response.data.Position.id,
						desc_cargo: response.data?.Position?.pt_name,
						vaga_cargo: {
							id_empresa_vaga: response.data?.id,
							id_cargo: response.data?.Position?.id,
						},
					},
				], */
				UserCurriculos: response.data.UserCvVacancies,
				NivelEducativo: {
					id: response.data.education_level,
				},
			};

			const formattedDate = moment(response?.data?.Position?.created_at).format(
				'DD/MM/YYYY HH:mm:ss'
			);

			setVacancy({
				...formattedResponse,
				created_at: formattedDate,
			});

			const courseTypeKey = courseTypeMap[currentLanguage] || 'pt_course_type';
			console.log('courseTypeKey', courseTypeKey);
			const courseType = response.data.CourseType ? response.data.CourseType[courseTypeKey] : null;

			setDescNivelEducativo(courseType);
			await handlePostulateLabel({
				...formattedResponse,
				created_at: formattedDate,
			});
		} catch (error: any) {
			console.log('error', error);
			setOpen(true);
			setAlert(false);
			//setAlertMsg(`${error.response.data.error}`);
		}
	};

	const ddlTipoContrato = [
		{ id: 'AP', name: 'Aprendizaje' },
		{ id: 'OB', name: 'Obra' },
		{ id: 'OC', name: 'Otro' },
		{ id: 'PR', name: 'Prest. de Servicios' },
		{ id: 'TE', name: 'Temporal' },
		{ id: 'TF', name: 'Término Fijo' },
		{ id: 'TI', name: 'Término Indefinido' },
	];

	const ddlTipoSalario = [
		{ id: '1', name: 'Menos de 1 SMMLV' },
		{ id: '2', name: '1 SMMLV ' },
		{ id: '3', name: '1 a 2 SMMLV' },
		{ id: '4', name: '2 a 4 SMMLV' },
		{ id: '5', name: '4 a 6 SMMLV' },
		{ id: '6', name: '6 a 9 SMMLV' },
		{ id: '7', name: '9 a 12 SMMLV' },
		{ id: '8', name: '12 a 15 SMMLV' },
		{ id: '9', name: '15 a 19 SMMLV' },
		{ id: '10', name: '20 SMMLV en adelante' },
		{ id: '11', name: 'A convenir' },
	];
	const ddlJornada = [
		{ id: '1', name: 'Diurna' },
		{ id: '2', name: 'Nocturna' },
		{ id: '3', name: 'Mixta' },
	];
	const ddlTipoVacante = [
		{ id: '1', name: 'Local' },
		{ id: '2', name: 'Home Office' },
	];

	const ddlNivel = [
		{ id: 'PR', name: 'Preescolar' },
		{ id: 'P', name: 'Básica primaria(1-5)' },
		{ id: 'BS', name: 'Básica secundaria(6-9)' },
		{ id: 'MD', name: 'Media(10-13)' },
		{ id: 'TL', name: 'Técnica laboral' },
		{ id: 'TP', name: 'Técnica profesional' },
		{ id: 'T', name: 'Tecnológica' },
		{ id: 'PG', name: 'Universitaria' },
		{ id: 'E', name: 'Especialización' },
		{ id: 'M', name: 'Maestría' },
		{ id: 'D', name: 'Doctorado' },
	];

	const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#f5f5f9',
			color: 'rgba(0, 0, 0, 0.87)',
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(12),
			border: '1px solid #dadde9',
		},
	}));

	const iconJornada = (mode: string) => {
		if (mode === '1')
			return (
				<>
					<WbSunnyIcon />
					&nbsp;{t('Jornada')}:&nbsp;{t('Diurna')}
				</>
			);
		if (mode === '2')
			return (
				<>
					<BedtimeIcon />
					&nbsp;{t('Jornada')}:&nbsp;{t('Nocturna')}
				</>
			);
		if (mode === '3')
			return (
				<>
					<NightsStayIcon />
					&nbsp;{t('Jornada laboral:')}&nbsp;{t('Mixta')}
				</>
			);

		return <></>;
	};

	const registerVacancy = async (): Promise<void> => {
		if (currentUser === undefined) {
			setAlert(false);
			setAlertMsg('Debes estar logueado en el sistema para aplicar');
			setOpen(true);
			setTimeout(() => {
				setOpen(false);
				navigate('/auth');
			}, 7000);
		} else {
			try {
				const register = {
					id_vacancy: parseInt(setValueOrDefault(id, 'number')),
				};
				const response = await registerForVacancy(register);
				console.log('response', response);
				setAlert(true);
				setAlertMsg(response.message);
				setOpen(true);
				setPostulateLabel('Postulado');
				setPostulateColor('green');
				navigate(-1);
			} catch (error: any) {
				console.error('error', error);
				setAlert(false);
				setAlertMsg(`${error.response.data.error}`);
				setOpen(true);
			}
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-16' style={{ backgroundColor: '#FFFF' }}>
				<div className='p-4 shadow-4 rounded-3' style={{ backgroundColor: '#FFFF' }}>
					<div className='row'>
						<div className='col-sm-1'>
							<button
								onClick={() => navigate(-1)}
								className='btn btn-primary'
								style={{ background: '#C4D2E8', borderRadius: '4px', padding: '6px 15px' }}
							>
								<i
									className='bi bi-arrow-left'
									style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
								></i>
							</button>
						</div>
						<h3 className={styleScss.mobileTitle}>{t('Vacante')}</h3>
						<div className='col-sm-5 mt-2' style={{ lineHeight: '1em', marginLeft: '-10px' }}>
							<span>
								<WatchLaterIcon sx={{ color: '#153E7B' }} />
								&nbsp;&nbsp;{t('Publicada em:')} {vacancy['created_at']} {t('- Encerra em:')}{' '}
								{vacancy['fecha_limite_aplicacion']}
							</span>
						</div>
						<div className='col d-flex justify-content-end'>
							<span
								className='badge rounded-pill'
								style={{
									boxSizing: 'border-box',
									color: initValue(vacancy['status']) ? 'black' : 'red',
									backgroundColor: '#DEEAFB',
									marginRight: '15px',
									marginBottom: '10px',
									padding: '5px 25px 5px 25px',
									textAlign: 'center',
									height: '46px',
									alignContent: 'center',
									fontSize: '11pt',
								}}
							>
								{initValue(vacancy['status']) ? t('Aberta') : t('Cerrado')}
							</span>
						</div>
					</div>
					<div className='row mt-10'>
						<div className='col-sm-4' style={{ lineHeight: '0.5em' }}>
							<h3 style={{ display: 'flex', gap: '10px' }}>
								{initValue(vacancy['nombre_vacante'])}
							</h3>
							<span style={{}}>
								empresa {initValue(vacancy['Empresa']?.['razon_social']).toUpperCase()}
							</span>
						</div>
						<div className='col d-flex justify-content-end' style={{ marginRight: '15px' }}>
							<button
								className='btn btn-primary'
								style={{ padding: '8px 20px', background: postulateColor }}
								onClick={registerVacancy}
							>
								{postulateLabel}
							</button>
						</div>
					</div>
					{/* Informaciónes de la vacante */}
					<div className='row mt-10'>
						<Card
							sx={{
								border: 'none',
								boxShadow: 'none',
								width: '100%',
							}}
						>
							<CardHeader
								title={t('Interesse ocupacional')}
								titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
								action={
									<IconButton
										onClick={() => setOpenInformationVacancy(!openInformationVacancy)}
										aria-label='expand'
										size='small'
									>
										{openInformationVacancy ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
									</IconButton>
								}
							/>
							<div>
								<Collapse in={openInformationVacancy} timeout='auto' unmountOnExit>
									<CardContent>
										<hr style={{ marginTop: '-10px' }} />
										<div className='mt-10'>
											{vacancy.salario_mensual && !isNaN(vacancy.salario_mensual) && (
												<span
													className='badge rounded-pill'
													style={{
														boxSizing: 'border-box',
														color: '#153E7B',
														border: '1px solid #153E7B',
														backgroundColor: 'transparent',
														marginRight: '15px',
														marginBottom: '10px',
														padding: '15px 30px 15px 30px',
														textAlign: 'center',
														height: '46px',
														alignContent: 'center',
														fontSize: '11pt',
													}}
												>
													<SavingsIcon />
													&nbsp;{t('Salario')}:&nbsp;
													{vacancy.salario_mensual}
												</span>
											)}
											<HtmlTooltip
												style={{ textAlign: 'center' }}
												title={
													<>
														<Typography color='inherit'>{vacancy['tipo_contrato_desc']}</Typography>
													</>
												}
											>
												<span
													className='badge rounded-pill'
													style={{
														boxSizing: 'border-box',
														color: '#153E7B',
														border: '1px solid #153E7B',
														backgroundColor: 'transparent',
														marginRight: '15px',
														marginBottom: '10px',
														padding: '15px 30px 15px 30px',
														textAlign: 'center',
														height: '46px',
														alignContent: 'center',
														fontSize: '11pt',
													}}
												>
													<ArticleIcon />
													&nbsp;{t('Contrato')}:&nbsp;
													{vacancy.tipo_contrato || ''}
												</span>
											</HtmlTooltip>

											<span
												className='badge rounded-pill'
												style={{
													boxSizing: 'border-box',
													color: '#153E7B',
													border: '1px solid #153E7B',
													backgroundColor: 'transparent',
													marginRight: '15px',
													marginBottom: '10px',
													padding: '15px 30px 15px 30px',
													textAlign: 'center',
													height: '46px',
													alignContent: 'center',
													fontSize: '11pt',
												}}
											>
												<MenuBookIcon />
												&nbsp;{t('Nivel de estudo:')} {descNivelEducativo}
											</span>
											<span
												className='badge rounded-pill'
												style={{
													boxSizing: 'border-box',
													color: '#153E7B',
													border: '1px solid #153E7B',
													backgroundColor: 'transparent',
													marginRight: '15px',
													marginBottom: '10px',
													padding: '15px 30px 15px 30px',
													textAlign: 'center',
													height: '46px',
													alignContent: 'center',
													fontSize: '11pt',
												}}
											>
												<HistoryToggleOffIcon />
												&nbsp;{t('Experiencia:')}{' '}
												{vacancy.rango_experiencia !== '' ? vacancy.rango_experiencia : 0}{' '}
												{t('meses')}
											</span>
											<span
												className='badge rounded-pill'
												style={{
													boxSizing: 'border-box',
													color: '#153E7B',
													border: '1px solid #153E7B',
													backgroundColor: 'transparent',
													marginRight: '15px',
													marginBottom: '10px',
													padding: '15px 30px 15px 30px',
													textAlign: 'center',
													height: '46px',
													alignContent: 'center',
													fontSize: '11pt',
												}}
											>
												{vacancy['vacante_susceptible_teletrabajo'] === '1' ? (
													<CottageIcon />
												) : (
													<ApartmentIcon />
												)}
												&nbsp;
												{vacancy['vacante_susceptible_teletrabajo'] === '1'
													? t('Remoto')
													: t('Presencial')}
											</span>

											<span
												className='badge rounded-pill'
												style={{
													boxSizing: 'border-box',
													color: '#153E7B',
													border: '1px solid #153E7B',
													backgroundColor: 'transparent',
													marginRight: '15px',
													marginBottom: '10px',
													padding: '15px 30px 15px 30px',
													textAlign: 'center',
													height: '46px',
													alignContent: 'center',
													fontSize: '11pt',
												}}
											>
												{iconJornada(initValue(vacancy['jornada_laboral']))}
											</span>
										</div>
									</CardContent>
								</Collapse>
							</div>
						</Card>
					</div>
					{/* Descrição */}
					<div className='row'>
						<Card
							sx={{
								border: 'none',
								boxShadow: 'none',
								width: '100%',
							}}
						>
							<CardHeader
								title={t('Descrição')}
								titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
								action={
									<IconButton
										onClick={() => setOpenDescription(!openDescription)}
										aria-label='expand'
										size='small'
									>
										{openDescription ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
									</IconButton>
								}
							/>
							<div>
								<Collapse in={openDescription} timeout='auto' unmountOnExit>
									<CardContent>
										<hr style={{ marginTop: '-10px' }} />
										<div className='row mt-10'>
											<span>{initValue(vacancy['Describa_vacante'])}</span>
										</div>
									</CardContent>
								</Collapse>
							</div>
						</Card>
					</div>
				</div>
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={open}
					onClose={handleClose}
				>
					<Alert
						onClose={handleClose}
						variant={'filled'}
						severity={alert ? 'success' : 'error'}
						sx={{ whiteSpace: 'pre-line' }}
					>
						{alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
						{alertMsg}
					</Alert>
				</Snackbar>
			</div>
		</>
	);
};

const VacancyComponent = () => {
	const { t } = useTranslation();
	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Vacante')}</PageTitle>
			<VacancyPage />
		</>
	);
};

export default VacancyComponent;
