import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import PaginationComponent from '../../components/Pagination/Index';
import styleScss from './styles.module.scss';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, Link } from 'react-router-dom';
import { getAgendaVagaCurriculoAll } from '../../modules/apps/user-management/users-list/core/_requests';
import { useAuth } from '../../modules/auth';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import EmployerReqFeedbackCardComponent from '../../components/EmployerReqVacancyCardComponent';

const EmployerRequestedFeedbackCandidatoPage = () => {
	const { t } = useTranslation();
	const { currentUser } = useAuth();
	const [data, setData]: any[] = useState([]);
	const [userData, setUserData]: any[] = useState([]);
	const navigate = useNavigate();
	const [total, setTotal] = useState<number>(0);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
	});

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);
	const [id, setId] = useState<string>('');
	const [idEmpresa, setIdEmpresa] = useState<string>('');
	const [idVacancy, setIdVacancy] = useState<string>('');
	const id_users = currentUser?.user?.id;
	const id_empresa = currentUser?.user?.empresa_id;
	const handleClickMenu = (
		event: React.MouseEvent<HTMLButtonElement>,
		id: any,
		idEmpresa: any,
		idVacancy: any
	) => {
		setAnchorEl(event.currentTarget);
		setId(id);
		setIdEmpresa(idEmpresa);
		setIdVacancy(idVacancy);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		setUserData({ ...userData, id_users: currentUser?.user?.id });
		let arr: any[] = [];
		for (let index = 0; index < 10; index++) {
			let x = {
				id: 1,
				vacancy: `El nombre de la vacante ${index}`,
				employer: `El nombre de lo empleador ${index}`,
				description: `CUIDADOR DE ADULTO MAYOR PREPARACIóN DE ALIMENTACIóN ESPECIAL ${index}`,
				status: 'Fechada',
			};
			arr.push(x);
		}
		handleSubmit();
	}, []);

	const changePaginate = (pf: any) => {
		const f = { page: pf.page, limit: pf.limit };
		setFilter(f);
	};

	const handleSubmit = async () => {
		try {
			const users = await getAgendaVagaCurriculoAll(
				`page=${filter.page}&limit=${filter.limit}}&id_empresa=${id_empresa}&nome=${userData.nombre_candidato}`
			);
			setTotal(users.data.total);
			setTotalPage(users.data.totalPages);
			setData(users.data.data);
		} catch (e: any) {
			console.error(e);
			var erro = e;
			alert(e + ' ' + e.response.data.error);
		}
	};

	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<div className='row'>
					<div className='col-sm-1'>
						<button
							onClick={() => navigate(-1)}
							className='btn btn-primary'
							style={{ background: '#C4D2E8', borderRadius: '4px', padding: '6px 15px' }}
						>
							<i
								className='bi bi-arrow-left'
								style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
							></i>
						</button>
					</div>
					<div className={styleScss.titleMobile} style={{marginTop: 8, marginBottom: 8}}>
						<h3>{t('Candidatos seleccionados')}</h3>
					</div>
					<div className='col-sm-4'>
						<div className='input-group mb-3'>
							<input
								type='text'
								className={`form-control ${styleScss.inputMobile}`}
								value={userData['nombre_candidato']}
								onChange={(e) => setUserData({ ...userData, nombre_candidato: e.target.value })}
								placeholder={t('Escriba el nombre del candidato')}
								aria-label='Escriba el nombre del candidato'
								aria-describedby='basic-addon2'
							/>
							<div className='input-group-append'>
								<span
									className='input-group-text'
									style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
									onClick={handleSubmit}
									id='searchCandidato'
								>
									<i className={`bi bi-search ${styleScss.iconSearchMobile}`} style={{ fontSize: '20px' }}></i>
								</span>
							</div>
						</div>
					</div>
				</div>
				{data.lenght !== 0 && (
					<table className={`table table-responsive table-striped ${styleScss.HideTableMobile}`}>
						<thead className={`${styleScss['th-custom']}`}>
							<tr>
								<th scope='col'>
									<h4>{t('Candidato')}</h4>
								</th>

								<th scope='col'>
									<h4>{t('Email')}</h4>
								</th>
								<th scope='col'>
									<h4>{t('Vacante')}</h4>
								</th>
								<th scope='col'>
									<h4>{t('Estado')}</h4>
								</th>
								<th scope='col'>
									<h4>{t('Acciones')}</h4>
								</th>
							</tr>
						</thead>
						<tbody className={`${styleScss['td-custom']}`}>
							{data.map((x: any, i: number) => (
								<tr key={i} id={i.toString()}>
									<th className='pt-3 pb-3' scope='row'>
										{x.primer_nome}
									</th>
									<td>{x.email}</td>
									<td>{x.nombre_vacante}</td>
									{x.id_aprobado !== null && x.id_aprobado !== 0 && <td>{t('Contratado')}</td>}
									{x.id_desaprobado !== null && x.id_desaprobado !== 0 && (<td>{t('Descartado')}</td>)}
									{x.id_abandonar !== null && x.id_abandonar !== 0 && <td>{t('No compareció')}</td>}
									{!x.id_aprobado && !x.id_desaprobado && !x.id_abandonar && <td> - </td>}
									<td>
										<Button
											id='basic-button'
											aria-controls={openMenu ? 'basic-menu' : undefined}
											aria-haspopup='true'
											aria-expanded={openMenu ? 'true' : undefined}
											onClick={(e) =>
												handleClickMenu(
													e,
													x.id_entrevista_curriculo,
													x.id_empresa,
													x.id_entrevista_vaga
												)
											}
										>
											<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
										</Button>

										<Menu
											id='basic-menu'
											anchorEl={anchorEl}
											open={openMenu}
											onClose={handleCloseMenu}
											MenuListProps={{
												'aria-labelledby': 'basic-button',
											}}
										>
											<MenuItem onClick={handleCloseMenu}>
												<Link to={`/empleador/entrevista/${id}/${idEmpresa}/${idVacancy}`}>
													{t('Feedback candidato')}
												</Link>
											</MenuItem>
										</Menu>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
				{data.map((x: any, i: number) => (
					<EmployerReqFeedbackCardComponent
						key={i}
						data={x}
						openMenu={openMenu}
						anchorEl={anchorEl}
						handleCloseMenu={handleCloseMenu}
						handleClickMenu={handleClickMenu}
					/>
				))}
				<PaginationComponent
					totalPage={totalPage}
					total={total}
					customClass={''}
					paginate={changePaginate}
				/>
			</div>
		</>
	);
};

const EmployerRequestedFeedbackCandidatoWrapper = () => {
	const { t } = useTranslation();
	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Candidatos seleccionados')}</PageTitle>
			<EmployerRequestedFeedbackCandidatoPage />
		</>
	);
};

export { EmployerRequestedFeedbackCandidatoWrapper };
