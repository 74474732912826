import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import {
	deleteUserById,
	updateStatusUserToActive,
	updateStatusUserToDesactive,
} from '../../modules/apps/user-management/users-list/core/_requests';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { useTranslation } from 'react-i18next';

import styleScss from './styles.module.scss';

export default function MenuAction({data, setAlert, setAlertMsg, setOpen}: any) {
	const { t } = useTranslation();
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
		name: '',
		role: 0,
	});

	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = (e: any) => {
		setAnchorEl(e.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	//0 = ACTIVE
	//1 = DESACTIVE
	const updateStatusUser = async (id: number, type: number) => {
		try {
			type === 0
				? await updateStatusUserToActive(`id=${id}`)
				: await updateStatusUserToDesactive(`id=${id}`);
			setOpen(true);
			setAlert(true);
			setAlertMsg(`¡Usuario actualizado con éxito!`);
			setTimeout(() => {
				setFilter({ ...filter });
				setOpen(false);
			}, 2000);
		} catch (error) {
			console.error(error);
			setOpen(true);
			setAlert(false);
			setAlertMsg('Ha ocurrido un error!');
			setTimeout(() => {
				setOpen(false);
			}, 2000);
		}
	};

	const deleteUser = async (id: number) => {
		try {
			await deleteUserById(`id=${id}`);
			setOpen(true);
			setAlert(true);
			setAlertMsg(`¡Usuario eliminado con éxito!`);
			setTimeout(() => {
				setFilter({ ...filter });
				setOpen(false);
			}, 2000);
		} catch (error) {
			console.error(error);
			setOpen(true);
			setAlert(false);
			setAlertMsg('Ha ocurrido un error!');
			setTimeout(() => {
				setOpen(false);
			}, 2000);
		}
	};

	return (
		<>
			<Button onClick={handleClick}>
				<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
			</Button>
			<Menu
				id='card-actions-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={handleClose} className={styleScss.menu} >
					<Link to={`/admin/editar-usuario/${data.id}`}>
						<BorderColorIcon />
						&nbsp; {t('Editar')}
					</Link>
				</MenuItem>
				<MenuItem onClick={handleClose} className={styleScss.menu}>
					{data.active ? (
						<a className='pointer' onClick={() => updateStatusUser(data.id, 1)}>
							<ToggleOffIcon />
							&nbsp; {t('Inactivar')}
						</a>
					) : (
						<a className='pointer' onClick={() => updateStatusUser(data.id, 0)}>
							<ToggleOnIcon />
							&nbsp; {t('Activar')}
						</a>
					)}
				</MenuItem>
				<MenuItem onClick={handleClose} className={styleScss.menu}>
					<a className='pointer' onClick={() => deleteUser(data.id)}>
						<DeleteIcon />
						&nbsp; {t('Borrar')}
					</a>
				</MenuItem>
			</Menu>
		</>
	);
}
