import { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import './Styles.css';

import { multiStepContext } from '../../../contexts/CurriculumContext';

import { Step1 } from './steps/curriculum-registration/Step1';
import { Step2 } from './steps/curriculum-registration/Step2';
import { Step3 } from './steps/curriculum-registration/Step3';
import { Step4 } from './steps/curriculum-registration/Step4';
import { Step5 } from './steps/curriculum-registration/Step5';
import { Step6 } from './steps/curriculum-registration/Step6';
import { Step7 } from './steps/curriculum-registration/Step7';
import { Step8 } from './steps/curriculum-registration/Step8';
import { Typography, Alert, Snackbar, AlertTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
	CapacitacionCertificacionModel,
	ComplementoDirecaoModel,
	ExperienciaLaboralModel,
	HerramientasModel,
	IdiomaModel,
	NivelEducativoModel,
	PuestosModel,
	ResumeModel,
	UserComplementoModel,
} from '../../../models/ResumeModel';
import { createCurriculo } from '../../apps/user-management/users-list/core/_requests';
import { t } from 'i18next';
import NewForm from './steps/curriculum-registration/newForm';
import CurriculumForm from './sections/curriculum-registration/CurriculumForm';

const steps = ['', '', '', '', '', '', '', ''];

export const CurriculumRegistration = () => {
	const { currentStep, userData } = useContext(multiStepContext);
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const navigate = useNavigate();

	const ddltipo = [
		{ id: '1', name: 'Procesador de texto (Ejemplo: Word)' },
		{ id: '2', name: 'Hoja de cálculo (Ejemplo: Excel)' },
		{ id: '3', name: 'Multimedia (Ejemplo: PowerPoint)' },
		{ id: '4', name: 'Otros' },
	];
	const showTitle = (step: number) => {
		switch (step) {
			case 1:
				return 'Información básica';
			case 2:
				return 'Información básica';
			case 3:
				return 'Información básica';
			case 4:
				return 'Información básica';
			case 5:
				return 'Nivel educativo';
			case 6:
				return 'Experiencia laboral';
			case 7:
				return 'Educación informal';
			case 8:
				return 'Idiomas y habilidades';
		}
	};

	const showStep = (step: number) => {
		switch (step) {
			case 1:
				return <Step1 />;
			case 2:
				return <Step2 />;
			case 3:
				return <Step3 />;
			case 4:
				return <Step4 />;
			case 5:
				return <Step5 />;
			case 6:
				return <Step6 />;
			case 7:
				return <Step7 />;
			case 8:
				return <Step8 />;
		}
	};

	const setValueOrDefault = (value: any, typeClassMember: string) => {
		switch (typeClassMember) {
			case 'string':
				return value === '' || value === undefined || value === null ? '' : value;
			case 'boolean':
				return value === '' || value === undefined || value === null ? false : value;
			case 'number':
				return value === '' || value === undefined || value === null ? 0 : value;
			default:
				return '';
		}
	};

	const createObjectDirecao = (): ComplementoDirecaoModel[] => {
		let compDirecaoArr: ComplementoDirecaoModel[] = [];
		if (
			userData['complementoDirecao'] !== null &&
			userData['complementoDirecao'] !== undefined &&
			(userData['complementoDirecao'] as Array<any>).length > 0
		) {
			(userData['complementoDirecao'] as Array<any>).forEach((e) => {
				let x: ComplementoDirecaoModel = {
					complemento: setValueOrDefault(e['complemento'], 'string'),
					id: setValueOrDefault(e['id'], 'number'),
					tipo_complemento: setValueOrDefault(e['tipo_complemento'], 'string'),
				};
				compDirecaoArr.push(x);
			});
		}

		return compDirecaoArr;
	};

	const createObjectPuestos = (): PuestosModel[] => {
		let puestosArr: PuestosModel[] = [];

		if (
			userData['listPuestos'] !== null &&
			userData['listPuestos'] !== undefined &&
			(userData['listPuestos'] as Array<any>).length > 0
		) {
			(userData['listPuestos'] as Array<any>).forEach((e) => {
				let x: PuestosModel = {
					id: setValueOrDefault(e['id'], 'number'),
					puestos: setValueOrDefault(e['puestos'], 'string'),
				};
				puestosArr.push(x);
			});
		}

		return puestosArr;
	};

	const createObjectNivelEducativo = (): NivelEducativoModel[] => {
		let nivelEducativoArr: NivelEducativoModel[] = [];

		if (
			userData['nivelEducativo'] !== null &&
			userData['nivelEducativo'] !== undefined &&
			(userData['nivelEducativo'] as Array<any>).length > 0
		) {
			(userData['nivelEducativo'] as Array<any>).forEach((e) => {
				let x: NivelEducativoModel = {
					id: setValueOrDefault(e['id'], 'number'),
					areaDesempeno: setValueOrDefault(e['areaDesempeno'], 'string'),
					estadoDelCurso: setValueOrDefault(e['estadoDelCurso'], 'string'),
					idObservaciones: setValueOrDefault(e['idObservaciones'], 'string'),
					txtNumeroTarjProf: setValueOrDefault(e['txtNumeroTarjProf'], 'string'),
					fechaTarjProf: setValueOrDefault(e['fechaTarjProf'], 'string'),
					interessadoPractica: setValueOrDefault(e['interessadoPractica'], 'boolean'),
					nivelEducativo: setValueOrDefault(e['nivelEducativo'], 'string'),
					nucleoConocimiento: setValueOrDefault(e['nucleoConocimiento'], 'string'),
					paisDelCurso: setValueOrDefault(e['paisDelCurso'], 'string'),
					tituloHomologado: setValueOrDefault(e['tituloHomologado'], 'string'),
					tituloObtenido: setValueOrDefault(e['tituloObtenido'], 'string'),
				};
				nivelEducativoArr.push(x);
			});
		}

		return nivelEducativoArr;
	};

	const createObjectCapacitacionCertificacion = (): CapacitacionCertificacionModel[] => {
		let capCertificacionesArr: CapacitacionCertificacionModel[] = [];
		if (
			userData['capacitacionCertificacion'] !== null &&
			userData['capacitacionCertificacion'] !== undefined &&
			(userData['capacitacionCertificacion'] as Array<any>).length > 0
		) {
			(userData['capacitacionCertificacion'] as Array<any>).forEach((e) => {
				let x: CapacitacionCertificacionModel = {
					id: setValueOrDefault(e['id'], 'number'),
					duracionHoras: setValueOrDefault(e['duracionHoras'], 'string'),
					estadoCapacitacione: setValueOrDefault(e['estadoCapacitacione'], 'string'),
					fechaCertificacion: setValueOrDefault(e['fechaCertificacion'], 'string'),
					instituicion: setValueOrDefault(e['instituicion'], 'string'),
					nombrePrograma: setValueOrDefault(e['nombrePrograma'], 'string'),
					paisCapacitacione: setValueOrDefault(e['paisCapacitacione'], 'string'),
					tpCapacitacionCertificacion: setValueOrDefault(
						e['tpCapacitacionCertificacion'],
						'string'
					),
				};
				capCertificacionesArr.push(x);
			});
		}

		return capCertificacionesArr;
	};

	const createObjectIdioma = (): IdiomaModel[] => {
		let idiomaArr: IdiomaModel[] = [];
		if (
			userData['listIdioma'] !== null &&
			userData['listIdioma'] !== undefined &&
			(userData['listIdioma'] as Array<any>).length > 0
		) {
			(userData['listIdioma'] as Array<any>).forEach((e) => {
				let x: IdiomaModel = {
					id_idioma: setValueOrDefault(e['id'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					idioma: setValueOrDefault(e['idioma'], 'string'),
				};
				idiomaArr.push(x);
			});
		}
		return idiomaArr;
	};

	const createObjectHerramienta = (): HerramientasModel[] => {
		let herramientasArr: HerramientasModel[] = [];
		if (
			userData['listHerramientas'] !== null &&
			userData['listHerramientas'] !== undefined &&
			(userData['listHerramientas'] as Array<any>).length > 0
		) {
			(userData['listHerramientas'] as Array<any>).forEach((e) => {
				let x: HerramientasModel = {
					id: setValueOrDefault(e['id'], 'number'),
					nivel: setValueOrDefault(e['nivel'], 'number'),
					id_tipo_conocimientos: setValueOrDefault(
						ddltipo.find((y) => y.name === e['tipo'])?.id,
						'string'
					),
					herramientas: setValueOrDefault(e['herramientas'], 'string'),
				};
				herramientasArr.push(x);
			});
		}

		return herramientasArr;
	};

	const createObjectExpLaboral = (): ExperienciaLaboralModel[] => {
		let expLaboralArr: ExperienciaLaboralModel[] = [];
		if (
			userData['experienciaLaboral'] !== null &&
			userData['experienciaLaboral'] !== undefined &&
			(userData['experienciaLaboral'] as Array<any>).length > 0
		) {
			(userData['experienciaLaboral'] as Array<any>).forEach((e) => {
				let x: ExperienciaLaboralModel = {
					id: setValueOrDefault(e['id'], 'number'),
					cargo: setValueOrDefault(e['cargo'], 'string'),
					cargoEquivalente: setValueOrDefault(e['cargoEquivalente'], 'string'),
					cuaPersonasTrabajan: setValueOrDefault(e['cuaPersonasTrabajan'], 'string'),
					fechaIngresso: setValueOrDefault(e['fechaIngresso'], 'string'),
					fechaRetiro: setValueOrDefault(e['fechaRetiro'], 'string'),
					funcionesLogros: setValueOrDefault(e['funcionesLogros'], 'string'),
					nombreEmpresa: setValueOrDefault(e['nombreEmpresa'], 'string'),
					productoServicio: setValueOrDefault(e['productoServicio'], 'string'),
					sector: setValueOrDefault(e['sector'], 'string'),
					telefonoEmpresa: setValueOrDefault(e['telefonoEmpresa'], 'string'),
					tpExperienciaLaboral: setValueOrDefault(e['tpExperienciaLaboral'], 'string'),
					trabajandoMismoTrabajo: setValueOrDefault(e['trabajandoMismoTrabajo'], 'boolean'),
					paisEmpresa: setValueOrDefault(e['paisEmpresa'], 'string'),
				};
				expLaboralArr.push(x);
			});
		}
		return expLaboralArr;
	};

	// const createObjectUserComplemento = (): UserComplementoModel[] => {
	// 	let userCompArr: UserComplementoModel[] = [];
	// 	let userComp: UserComplementoModel = {
	// 		id_users: setValueOrDefault(userData['id_users'], 'number'),
	// 		primer_nome: setValueOrDefault(userData['primer_nombre'], 'string'),
	// 		segundo_nome: setValueOrDefault(userData['segundo_nombre'], 'string'),
	// 		primer_apelido: setValueOrDefault(userData['primer_apelido'], 'string'),
	// 		segundo_apelido: setValueOrDefault(userData['segundo_apelido'], 'string'),
	// 		tipo_doc: setValueOrDefault(userData['tipo_de_documento'], 'string'),
	// 		numero_doc: setValueOrDefault(userData['numero_documento'], 'string'),
	// 		fecha_nascimiento: setValueOrDefault(userData['fecha_nascimento'], 'string'),
	// 		celular: setValueOrDefault(userData['telefono_contacto'], 'number').toString(),
	// 	};
	// 	userCompArr.push(userComp);
	// 	return userCompArr;
	// };

	// const createObjectToSubmit = (): ResumeModel => {
	// 	let resume: ResumeModel = {
	// 		id_users: setValueOrDefault(userData['id_users'], 'number'),
	// 		estado_civil: setValueOrDefault(userData['estado_civil'], 'number'),
	// 		numero_doc_adicional: setValueOrDefault(userData['numero_doc_adicional'], 'string'),
	// 		tipo_doc_adicional: setValueOrDefault(userData['tipo_doc_adicional'], 'string'),
	// 		sexo: setValueOrDefault(userData['sexo'], 'string'),
	// 		jefe_hogar: setValueOrDefault(userData['jefe_hogar'], 'string'),
	// 		pais_nascimento: setValueOrDefault(userData['pais_nascimento'], 'string'),
	// 		nacionalidad: setValueOrDefault(userData['nacionalidad'], 'string'),
	// 		departamento_nacimiento: setValueOrDefault(userData['departamento_nacimiento'], 'string'),
	// 		municipio_nacimiento: setValueOrDefault(userData['municipio_nacimiento'], 'string'),
	// 		libreta_militar: setValueOrDefault(userData['libreta_militar'], 'string'),
	// 		tipo_libreta: setValueOrDefault(userData['tipo_libreta'], 'string'),
	// 		numero_libreta: setValueOrDefault(userData['numero_libreta'], 'string'),
	// 		reconoce_focalizada: setValueOrDefault(userData['reconoce_focalizada'], 'string'),
	// 		correo_Electronico: setValueOrDefault(userData['correo_Electronico'], 'string'),
	// 		// telefono_contacto: setValueOrDefault(userData['telefono_contacto'], 'string'),
	// 		otro_telefono: setValueOrDefault(userData['otro_telefono'], 'string'),
	// 		observaciones: setValueOrDefault(userData['observaciones'], 'string'),
	// 		pais_residencia: setValueOrDefault(userData['pais_residencia'], 'string'),
	// 		bairro_residencia: setValueOrDefault(userData['bairro_residencia'], 'string'),
	// 		pertence: setValueOrDefault(userData['pertence'], 'string'),
	// 		prestador_preferencia: setValueOrDefault(userData['prestador_preferencia'], 'string'),
	// 		punta_atencion: setValueOrDefault(userData['punta_atencion'], 'string'),
	// 		local: setValueOrDefault(userData['local'], 'string'),
	// 		id_coordenada: setValueOrDefault(userData['id_coordenada'], 'string'),
	// 		id_coordenada_02: setValueOrDefault(userData['id_coordenada_02'], 'string'),
	// 		id_coordenada_03: setValueOrDefault(userData['id_coordenada_03'], 'string'),
	// 		id_coordenada_04: setValueOrDefault(userData['id_coordenada_04'], 'string'),
	// 		id_coordenada_05: setValueOrDefault(userData['id_coordenada_05'], 'string'),
	// 		id_coordenada_06: setValueOrDefault(userData['id_coordenada_06'], 'string'),
	// 		id_coordenada_07: setValueOrDefault(userData['id_coordenada_07'], 'string'),
	// 		id_coordenada_08: setValueOrDefault(userData['id_coordenada_08'], 'string'),
	// 		id_coordenada_09: setValueOrDefault(userData['id_coordenada_09'], 'string'),
	// 		codigo_postal: setValueOrDefault(userData['codigo_postal'], 'string'),
	// 		direccion_residencia: setValueOrDefault(userData['direccion_residencia'], 'string'),
	// 		possibilidade_viajar: setValueOrDefault(userData['possibilidade_viajar'], 'string'),
	// 		situacion_laboral_actual: setValueOrDefault(userData['situacion_laboral_actual'], 'string'),
	// 		aspiracion_salarial: setValueOrDefault(userData['aspiracion_salarial'], 'string'),
	// 		Interes_ofertas_teletrabajo: setValueOrDefault(
	// 			userData['Interes_ofertas_teletrabajo'],
	// 			'string'
	// 		),
	// 		posibilidad_trasladarse: setValueOrDefault(userData['posibilidad_trasladarse'], 'string'),
	// 		conduces_carro: setValueOrDefault(userData['conduces_carro'], 'string'),
	// 		categoria_licencia_carro: setValueOrDefault(userData['categoria_licencia_carro'], 'string'),
	// 		conduces_moto: setValueOrDefault(userData['conduces_moto'], 'string'),
	// 		categoria_licencia_moto: setValueOrDefault(userData['categoria_licencia_moto'], 'string'),
	// 		Prop_medio_transporte: setValueOrDefault(userData['Prop_medio_transporte'], 'string'),
	// 		perfil_laboral: setValueOrDefault(userData['perfil_laboral'], 'string'),

	// 		id_Afrocolombianos: setValueOrDefault(userData['id_Afrocolombianos'], 'boolean'),
	// 		id_Negros: setValueOrDefault(userData['id_Negros'], 'boolean'),
	// 		id_Palenqueros: setValueOrDefault(userData['id_Palenqueros'], 'boolean'),
	// 		id_Raizales: setValueOrDefault(userData['id_Raizales'], 'boolean'),
	// 		id_Indigenas: setValueOrDefault(userData['id_Indigenas'], 'boolean'),
	// 		id_Rrom: setValueOrDefault(userData['id_Rrom'], 'boolean'),
	// 		id_Estuvo: setValueOrDefault(userData['id_Estuvo'], 'boolean'),
	// 		id_jovem_mayor: setValueOrDefault(userData['id_jovem_mayor'], 'boolean'),
	// 		id_persona_habitabilidad: setValueOrDefault(userData['id_persona_habitabilidad'], 'boolean'),
	// 		id_persona_joven: setValueOrDefault(userData['id_persona_joven'], 'boolean'),
	// 		id_persona_mayor_50: setValueOrDefault(userData['id_persona_mayor_50'], 'boolean'),
	// 		id_persona_migrante: setValueOrDefault(userData['id_persona_migrante'], 'boolean'),
	// 		id_persona_trans: setValueOrDefault(userData['id_persona_trans'], 'boolean'),
	// 		id_persona_reincorporación: setValueOrDefault(userData['id_persona_reincorporación'], 'boolean'),
	// 		id_discapacidad_certificada: setValueOrDefault(userData['id_discapacidad_certificada'], 'boolean'),
	// 		id_mujer: setValueOrDefault(userData['id_mujer'], 'boolean'),
	// 		id_persona_victima_trata: setValueOrDefault(userData['id_persona_victima_trata'], 'boolean'),
	// 		id_personas_probreza: setValueOrDefault(userData['id_personas_probreza'], 'boolean'),
	// 		id_Personas: setValueOrDefault(userData['id_Personas'], 'boolean'),
	// 		id_problacion_libertad_3_meses: setValueOrDefault(
	// 			userData['id_problacion_libertad_3_meses'],
	// 			'boolean'
	// 		),
	// 		id_problacion_rural: setValueOrDefault(userData['id_problacion_rural'], 'boolean'),
	// 		id_reciclador: setValueOrDefault(userData['id_reciclador'], 'boolean'),
	// 		id_Retornado: setValueOrDefault(userData['id_Retornado'], 'boolean'),
	// 		id_vendedor_informal: setValueOrDefault(userData['id_vendedor_informal'], 'boolean'),
	// 		id_Vend_informales: setValueOrDefault(userData['id_Vend_informales'], 'boolean'),
	// 		id_victima_violencia_genero: setValueOrDefault(
	// 			userData['id_victima_violencia_genero'],
	// 			'boolean'
	// 		),
	// 		id_victima_conflicto_armado: setValueOrDefault(
	// 			userData['id_victima_conflicto_armado'],
	// 			'boolean'
	// 		),
	// 		id_Fisica: setValueOrDefault(userData['id_Fisica'], 'boolean'),
	// 		id_Cognitiva: setValueOrDefault(userData['id_Cognitiva'], 'boolean'),
	// 		id_visual: setValueOrDefault(userData['id_visual'], 'boolean'),
	// 		id_Sordoceguera: setValueOrDefault(userData['id_Sordoceguera'], 'boolean'),
	// 		id_Auditiva: setValueOrDefault(userData['id_Auditiva'], 'boolean'),
	// 		id_Psicosocial: setValueOrDefault(userData['id_Psicosocial'], 'boolean'),
	// 		id_Multiple: setValueOrDefault(userData['id_Multiple'], 'boolean'),
	// 		id_Problema: setValueOrDefault(userData['id_Problema'], 'boolean'),
	// 		id_Transtorno: setValueOrDefault(userData['id_Transtorno'], 'boolean'),
	// 		id_Victima: setValueOrDefault(userData['id_Victima'], 'boolean'),
	// 		id_Epilepsia: setValueOrDefault(userData['id_Epilepsia'], 'boolean'),
	// 		id_Consumo: setValueOrDefault(userData['id_Consumo'], 'boolean'),
	// 		id_persona_act_sexuales: setValueOrDefault(userData['id_persona_act_sexuales'], 'boolean'),

	// 		userComplemento: createObjectUserComplemento(),
	// 		complementoDirecao: createObjectDirecao(),
	// 		listPuestos: createObjectPuestos(),
	// 		nivelEducativo: createObjectNivelEducativo(),
	// 		capacitacionCertificacion: createObjectCapacitacionCertificacion(),
	// 		listIdioma: createObjectIdioma(),
	// 		listHerramientas: createObjectHerramienta(),
	// 		experienciaLaboral: createObjectExpLaboral(),
	// 	};
	// 	console.log('resume', resume);
	// 	return resume;
	// };

	// const handleSave = () => {
	// 	console.log('finalData', userData);
	// };

	const handleSave = async () => {
		console.log('userDataFinal', userData);
		try {
			// let object = createObjectToSubmit();
			// console.log('objectFinal', object);
			// const retCurriculo = await createCurriculo(object);
			// console.log('retCurriculo', retCurriculo);
			setOpen(true);
			setAlert(true);
			setAlertMsg(`¡Registro completado con éxito!`);

			setOpen(true);
			navigate('/auth');
		} catch (error) {
			console.error(error);
			setOpen(true);
			setAlert(false);
			setAlertMsg('Ha ocurrido un error!');
		}
	};

	const handleClose = () => {
		setOpen(false);
	};
	return (
		<>
			<Box sx={{ width: '100%', background: 'white', padding: 4, borderRadius: 2, marginTop: '50px' }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
					<Box>
						<div className='row'>
							<div className='col-sm-3'>
								<button
									onClick={() => {
										navigate('/auth');
									}}
									className='btn btn-primary'
									style={{ background: '#C4D2E8', borderRadius: '4px', padding: '6px 15px' }}
								>
									<i
										className='bi bi-arrow-left'
										style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
									></i>
								</button>
							</div>
							<div className='col-sm-9'>
								<Typography
									style={{ marginLeft: 15, marginTop: '10px', gap: '10px', lineHeight: '0.9em' }}
									variant='h5'
									sx={{ fontWeight: 'bold' }}
								>
									{t(`CURRICULUM`)}
								</Typography>
								{/* <Typography sx={{ color: '#888888' }}>
								Completa al menos el 25% del formulario para ahorrar
							</Typography> */}
							</div>
						</div>
					</Box>
					{/*
					<Button
						variant='contained'
						sx={{ background: '#1D816A', height: '5%' }}
						onClick={handleSave}
					>
						Guardar
					</Button>
						*/}
				</Box>

				<Box mt={5}><CurriculumForm /></Box>

			</Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
					{t(alertMsg)}
				</Alert>
			</Snackbar>
		</>
	);
};
