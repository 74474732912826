

import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};


export default function DialogMoreInfoLanguage({ open, setOpen }: any) {
    const { t } = useTranslation();
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 400 }}>
                    <b>A1:</b> {t('A1_TEXT')}
                    <br /><br />

                    <b>A2:</b> {t('A2_TEXT')}
                    <br /><br />

                    <b>B1:</b>  {t('B1_TEXT')}
                    <br /><br />

                    <b>B2:</b> {t('B2_TEXT')}
                    <br /><br />

                    <b>C1:</b> {t('C1_TEXT')}
                    <br /><br />

                    <b>C2:</b> {t('C2_TEXT')}
                    <br /><br />
                    <DialogActions >
                        <Button onClick={handleClose}>{t('CLOSE')}</Button>
                    </DialogActions>
                </Box>
            </Modal>
        </div>
    );
}