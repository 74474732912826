import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styleScss from './styles.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { getVacanteMatching } from '../../modules/auth/core/_requests';
import {
	Alert,
	Box,
	LinearProgress,
	Tooltip,
	TooltipProps,
	Typography,
	tooltipClasses,
	styled,
} from '@mui/material';
import { OverlayTrigger, Tooltip as BootTip } from 'react-bootstrap';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';

const CandidateMatchingVacancyPage = () => {
	const { t } = useTranslation()
	const navigate = useNavigate();
	const [data, setData]: any[] = useState([]);
	//Pagination
	// const [total, setTotal] = useState<number>(0);
	// const [totalPage, setTotalPage] = useState<number>(0);
	// const [filter, setFilter] = useState<any>({
	// 	page: 1,
	// 	limit: 10,
	// });
	const [loading, setLoading] = useState(false);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [idVaga, setIdVaga] = useState<string>('');
	const openMenu = Boolean(anchorEl);
	const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
		setAnchorEl(event.currentTarget);
		setIdVaga(id);
	};
	const handleCloseMenu = () => {
		setIdVaga('');
		setAnchorEl(null);
	};

	const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#f5f5f9',
			color: 'rgba(0, 0, 0, 0.87)',
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(12),
			border: '1px solid #dadde9',
		},
	}));

	async function getVacantes() {
		try {
			setLoading(true);
			const vacantes = await getVacanteMatching();
			console.log('vacantes', vacantes);
			setData(vacantes.data);
			// setTotal(vacantes.data.total);
			// setTotalPage(vacantes.data.totalPages);
		} catch (error: any) {
			console.error('error', error);
		} finally {
			setLoading(false);
		}
	}

	// UseDidMountEffect(() => {
	// 	getVacantes();
	// }, [filter]);

	useEffect(() => {
		getVacantes();
		// setFilter({ ...filter });
	}, []);

	const normalise = (value: number) => ((value - 0) * 100) / (100 - 0);

	// const changePaginate = (pf: any) => {
	// 	const f = { page: pf.page, limit: pf.limit };
	// 	setFilter(f);
	// };

	return (
		<>
			{/* <div className='p-4 shadow-4 rounded-3' style={{ backgroundColor: '#FFFF' }}>
				<div className='row mb-4'>
					<div className='col-sm-1'>
						<button
							onClick={() => navigate(-1)}
							className='btn btn-primary'
							style={{ background: '#C4D2E8', borderRadius: '4px', padding: '6px 15px' }}
						>
							<i
								className='bi bi-arrow-left'
								style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
							></i>
						</button>
					</div>
					<div className='col-sm-4'>
					</div>
				</div>
				{loading ? (
					<LinearProgress style={{ marginTop: '10px' }} />
				) : (
					<table className={`table table-responsive table-striped`}>
						<thead className={`${styleScss['th-custom']}`}>
							<tr>
								<th scope='col'>
									<h4>Vacante</h4>
								</th>
								<th scope='col'>
									<h4>Empleador</h4>
								</th>
								<th scope='col'>
									<h4>Descripción</h4>
								</th>
								<th scope='col'>
									<h4>Matching</h4>
								</th>
								<th scope='col'>
									<h4>Estado</h4>
								</th>
								<th scope='col'>
									<h4>Acciones</h4>
								</th>
							</tr>
						</thead>
						<tbody className={`${styleScss['td-custom']}`}>
							{data.map((x: any, i: number) => (
								<tr key={i} id={i.toString()}>
									<th scope='row'>{x.nombre_vacante}</th>
									<td>{x.razon_social}</td>
									<td
										style={{
											maxWidth: '550px',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
									>
										{x.Describa_vacante}
									</td>
									<td>{x.matching}%</td>
									<td>{x.status ? 'Aberta' : 'Fechada'}</td>
									<td>
										<Button
											id='basic-button'
											aria-controls={openMenu ? 'basic-menu' : undefined}
											aria-haspopup='true'
											aria-expanded={openMenu ? 'true' : undefined}
											onClick={(e) => handleClickMenu(e, x.idVaga)}
										>
											<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
										</Button>

										<Menu
											id='basic-menu'
											anchorEl={anchorEl}
											open={openMenu}
											onClose={handleCloseMenu}
											MenuListProps={{
												'aria-labelledby': 'basic-button',
											}}
										>
											<MenuItem onClick={handleCloseMenu}>
												<Link to={`/candidato/vacante/${idVaga}`}>Ver vacante </Link>
											</MenuItem>
											{/* <MenuItem onClick={handleCloseMenu}>My account</MenuItem>
										<MenuItem onClick={handleCloseMenu}>Logout</MenuItem> */}
			{/* </Menu>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				)} */}
			{/* </div> */}

			<div className='mt-15 p-4 shadow-4 rounded-3' style={{ backgroundColor: '#FFFF' }}>
				<div className='row mb-4'>
					<div className='col-sm-1'>
						<button
							onClick={() => navigate(-1)}
							className='btn btn-primary'
							style={{ background: '#C4D2E8', borderRadius: '4px', padding: '6px 15px' }}
						>
							<i
								className='bi bi-arrow-left'
								style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
							></i>
						</button>
					</div>
					<div className='col-sm-4'>
						{/*
						<div className='input-group mb-3'>
							<input
								type='text'
								className='form-control'
								placeholder='Nombre de la vacante'
								aria-label='Nombre de la vacante'
								aria-describedby='basic-addon2'
							/>
							<div className='input-group-append'>
								<span className='input-group-text' id='searchVacancy'>
									<i className='bi bi-search' style={{ fontSize: '20px' }}></i>
								</span>
							</div>
						</div>
	*/}
					</div>
				</div>

				{loading ? (
					<LinearProgress style={{ marginTop: '10px' }} />
				) : (
					<>
						<div className='row row-cols-1 row-cols-md-3 g-4' style={{ textAlign: 'center' }}>
							{data !== undefined &&
								data?.length > 0 &&
								data.map((x: any, i: number) => (
									<div className='col' key={i} id={x.idCandidato}>
										<div
											className='card h-100'
											style={{ borderRadius: '10px', backgroundColor: '#F3F6F9' }}
										>
											<div className='card-body'>
												<h5 className='card-title'>{x.job_title}</h5>
												<h6 className={`card-text`}>{x.company_name}</h6>
												{/* <OverlayTrigger
													placement='left'
													delay={{ show: 250, hide: 400 }}
													overlay={(props) => (
														<BootTip id='tooltip' {...props}>
															<div dangerouslySetInnerHTML={{ __html: x.job_title }} />
															<span>{x.job_title}</span>
														</BootTip>
													)}
												>
													<p className={`card-text text-primary`}>
														<i
															className='bi bi-question-circle-fill'
															style={{ color: '#2266cc' }}
														></i>
														{'  '}
														{x.job_title}{' '}
													</p>
												</OverlayTrigger> */}
												{/* <HtmlTooltip
													title={
														<>
															<Typography color='inherit'>Descripción completa</Typography>
															{x.Describa_vacante}
														</>
													}
												>
													<Button>
														<p>{x.Describa_vacante}</p>
													</Button>
												</HtmlTooltip> */}
												<p className={`card-text mt-2`}>
													<OverlayTrigger
														placement='left'
														delay={{ show: 250, hide: 400 }}
														overlay={(props) => (
															<BootTip id='tooltip' {...props}>
																{`${t('La matching con esta vacante es:')} ${Math.round(x.matching)}%`}
															</BootTip>
														)}
													>
														<Box sx={{ position: 'relative', display: 'inline-flex' }}>
															<CircularProgress variant='determinate' value={x.matching} />
															<Box
																sx={{
																	top: 0,
																	left: 0,
																	bottom: 0,
																	right: 0,
																	position: 'absolute',
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'center',
																}}
															>
																<Typography
																	variant='caption'
																	component='div'
																	color='text.secondary'
																>{`${Math.round(x.matching)}%`}</Typography>
															</Box>
														</Box>
													</OverlayTrigger>
													{/* {' '}
													<CircularProgress variant='determinate' value={x.matching} /> {x.matching}
													% */}
												</p>
												{/* <p className={`card-text`}>{x.status ? 'Aberta' : 'Fechada'}</p> */}

												{/* <div className={`card-text`}>
														<div className='row'>
															<table className={`table table-responsive`}>
																<thead>
																	<tr>
																		<th scope='col'></th>
																		<th scope='col'></th>
																		<th scope='col'></th>
																		<th scope='col'></th>
																		<th scope='col'></th>
																	</tr>
																</thead>
																<tbody>
																	<tr key={i} id={i.toString()}>
																		<th scope='row'>{x.nombre_vacante}</th>
																		<td>{x.razon_social}</td>
																		<td
																			style={{
																				maxWidth: '550px',
																				overflow: 'hidden',
																				textOverflow: 'ellipsis',
																				whiteSpace: 'nowrap',
																			}}
																		>
																			{x.Describa_vacante}
																		</td>
																		<td>{x.matching}%</td>
																		<td>{x.status ? 'Aberta' : 'Fechada'}</td>
																		<td>
																			<Button
																				id='basic-button'
																				aria-controls={openMenu ? 'basic-menu' : undefined}
																				aria-haspopup='true'
																				aria-expanded={openMenu ? 'true' : undefined}
																				onClick={(e) => handleClickMenu(e, x.idVaga)}
																			>
																				<i
																					className='bi bi-three-dots'
																					style={{ fontSize: '20px' }}
																				></i>
																			</Button>

																			<Menu
																				id='basic-menu'
																				anchorEl={anchorEl}
																				open={openMenu}
																				onClose={handleCloseMenu}
																				MenuListProps={{
																					'aria-labelledby': 'basic-button',
																				}}
																			>
																				<MenuItem onClick={handleCloseMenu}>
																					<Link to={`/candidato/vacante/${idVaga}`}>
																						Ver vacante{' '}
																					</Link>
																				</MenuItem>
																			</Menu>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div> */}
											</div>
											<div className='card-footer' style={{ marginTop: '-20px' }}>
												<div className='container'>
													<span
														className={`badge ${
															styleScss[`text-${x.status ? 'aberta' : 'fechada'}`]
														}`}
														style={{ float: 'left' }}
													>
														{x.status ? <LockOpenIcon /> : <LockIcon />}{' '}
														{x.status ? t('Aberta') : t('Fechada')}
													</span>
													<Link to={`/candidato/vacante/${x.vacancy_id}`}>
														<Button
															id='basic-button'
															// aria-controls={openMenu ? 'basic-menu' : undefined}
															// aria-haspopup='true'
															// aria-expanded={openMenu ? 'true' : undefined}
															// onClick={(e) => handleClickMenu(e, x.idVaga)}
															sx={{
																float: 'right',
																backgroundColor: '#2266CC',
																color: '#FFFF',
																':hover': {
																	bgcolor: '#6c757d',
																	color: 'white',
																},
															}}
														>
															{t('Ver vaga')}
															{/* <i
															className='bi bi-three-dots'
															style={{ fontSize: '20px', color: '#FFFF' }}
														></i> */}
														</Button>
													</Link>

													{/* <Menu
														id='basic-menu'
														anchorEl={anchorEl}
														open={openMenu}
														onClose={handleCloseMenu}
														MenuListProps={{
															'aria-labelledby': 'basic-button',
														}}
													>
														<MenuItem onClick={handleCloseMenu}>
															<Link to={`/candidato/vacante/${idVaga}`}>Ver vacante </Link>
														</MenuItem>
													</Menu> */}
												</div>
											</div>
										</div>
									</div>
								))}
						</div>
					</>
				)}
			</div>
		</>
	);
};

const CandidateMatchingVacancyWrapper = () => {
const { t } = useTranslation()
	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Matching de vagas')}</PageTitle>
			<CandidateMatchingVacancyPage />
		</>
	);
};

export { CandidateMatchingVacancyWrapper };
