import { PageTitle } from '../../../_metronic/layout/core'
import { EmployerManagementVacancyPage } from './EmployerManagementVacancyPage'
import { useTranslation } from 'react-i18next'
import '../../i18n';

const EmployerManagementVacancyWrapper = () => {
	const { t } = useTranslation()
	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Gestión Vacantes')}</PageTitle>
			<EmployerManagementVacancyPage />
		</>
	)
}

export { EmployerManagementVacancyWrapper }
