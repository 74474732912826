import { Link, useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { useState } from 'react';
import styleCss from './styles.module.scss';
import { resetPassword } from '../../modules/auth/core/_requests';
import { Alert, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PublicForgotPasswordPage = () => {
	const { t } = useTranslation()
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [data, setData] = useState({
		email: '',
		numero_doc: '',
	});
	const [selectedDocument, setSelectedDocument] = useState(1);
	const [loadingButton, setLoadingButton] = useState(false);
	const [successMail, setSuccessMail] = useState(false);

	const navigate = useNavigate();

	const documentTypes = [
		{ key: 1, value: 'CORREO ELECTRÓNICO' },
		// { key: 2, value: 'DOCUMENTO' },
	];

	function handleInputChange(value: any) {
		const field = selectedDocument === 1 ? 'email' : 'numero_doc';
		setData({ ...data, [field]: value });
		console.log(data);
	}

	function handleSelectedDocument(value: number) {
		setSelectedDocument(value);
		const inputElement = document.getElementById('idUser') as HTMLInputElement;
		inputElement.value = '';
		setData({
			email: '',
			numero_doc: '',
		});
	}

	const sendMail = async () => {
		setLoadingButton(true);
		try {
			const resposta = await resetPassword(data);
			setAlert(true);
			setAlertMsg(resposta.data.msg);
			setOpen(true);
			changeView();
			setTimeout(() => {
				navigate('/auth');
			}, 4000);
		} catch (erro: any) {
			setAlert(false);
			setAlertMsg(erro.response.data.error);
			setOpen(true);
		}
		setLoadingButton(false);
	};

	const changeView = () => {
		setTimeout(() => {
			setSuccessMail(true);
		}, 2000);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<div className='d-flex flex-column pt-1 h-40px w-100' style={{ background: '#3366C9' }}>
				{/* begin::Logo 
				<Link to='/' className='mb-1'>
					<img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} />
				</Link>*/}
				{/* end::Logo */}
			</div>
			{/* end::Header */}

			<div
				className={styleCss['custom-body']}
				style={{
					display: 'grid',
					justifyContent: 'center',
					alignContent: 'center',
					marginTop: '8%',
				}}
			>
				{!successMail ? (
					<>
						<i
							className='bi bi-unlock-fill'
							style={{
								fontSize: '80px',
								alignContent: 'center',
								textAlign: 'center',
								color: '#3366C8',
							}}
						></i>
						<h1 className='mb-10 mt-3'>{t('Recuperar contraseña')}</h1>
						<div className='mb-3'>
							<label className='form-label fs-6 fw-bolder text-dark'>{t('Tipo de documento')}</label>
							<div>
								<select
									className='form-select'
									data-kt-select2='true'
									data-placeholder='Seleccione un tipo'
									data-allow-clear='true'
									onChange={(e) => { 
										handleSelectedDocument(e.target.value === 'CORREO ELECTRÓNICO' ? 1 : 2);
										// setData({
										// 	...data,
										// 	[selectedDocument === 1 ? 'email' : 'numero_doc']: '',
										// });
									}}
									defaultValue={''}
								>
									{documentTypes.map((types, i) => (
										<option value={types.value} key={i}>
											{t(types.value)}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className='mb-3'>
							<label className='form-label'>
								{selectedDocument === 1 ? t('Correo electrónico') : t('Documento')}
							</label>
							<input
								type={selectedDocument === 1 ? 'email' : 'text'}
								className={`form-control form-control-lg ${
									(selectedDocument === 1 && data.email === '') ||
									(selectedDocument === 2 && data.numero_doc === '')
										? 'is-invalid'
										: ''
								}`}
								id='idUser'
								placeholder={selectedDocument === 1 ? t('nombre@example.com') : t('Documento')}
								onChange={(e) => handleInputChange(e.target.value)}
							/>
							{(selectedDocument === 1 && data.email === '') ||
								(selectedDocument === 2 && data.numero_doc === '' && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{t('Campo obligatorio')}</span>
										</div>
									</div>
								))}
						</div>

						<div className='row mb-4'></div>
						{!loadingButton ? (
							<button
								type='button'
								className='btn btn-primary btn-block mb-4'
								onClick={sendMail}
								disabled={
									(selectedDocument === 2 && data.numero_doc === '') ||
									(selectedDocument === 1 && data.email === '')
								}
							>
								{t('Restablecer la contraseña')}
							</button>
						) : (
							<button className='btn btn-primary' type='button' disabled>
								<span
									className='spinner-border spinner-border-sm'
									role='status'
									aria-hidden='true'
								></span>
								&nbsp; {t('Cargando...')}
							</button>
						)}
					</>
				) : (
					<>
						<div
							className='mb-3'
							style={{
								alignContent: 'center',
								textAlign: 'center',
								marginTop: '15%',
								color: '#3366C8',
								fontWeight: 'bold',
							}}
						>
							<div className='card' style={{ width: '60em' }}>
								<i
									className='bi bi-envelope-check card-img-top mt-5'
									style={{ fontSize: '100px', color: '#60C98C' }}
								></i>
								<div className='card-body'>
									<p className='card-text' style={{ fontSize: '16pt' }}>
										{t('¡Email enviado con éxito!')} <br />
										{t('Por favor realice el trámite enviado al correo electrónico:')} {data.numero_doc}
									</p>
								</div>
							</div>
							{/* <h1>email enviado com sucesso</h1>
							<i className='bi bi-envelope-paper-fill' style={{ fontSize: '100px' }}></i> */}
						</div>
					</>
				)}
			</div>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{t(alertMsg)}
				</Alert>
			</Snackbar>
		</>
	);
};

const PublicForgotPasswordWrapper = () => {
	return (
		<>
			<PublicForgotPasswordPage />
		</>
	);
};

export { PublicForgotPasswordWrapper };
