/* eslint-disable react/jsx-no-target-blank */
// import { useIntl } from 'react-intl'
import { AsideMenuItemWithSubMain } from './AsideMenuItemWithSubMain';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { AsideMenuItem } from './AsideMenuItem';
import * as authHelper from '../../../../app/modules/auth/core/AuthHelpers';
import { RoleEnum } from '../../../../app/models/enums/RoleEnum';
import { useTranslation } from 'react-i18next';
import styleCss from './style.module.scss'

export function MenuMobile({onMenuItemClick}: any) {
	const { t } = useTranslation()
	const { user } = authHelper.getAuth() || {};
	console.log('user', user);
	const role = user?.role;

	const AsideMenuAdmin = () => {
		return (

				<AsideMenuItemWithSub to='/admin' title={t('Menú Administrador')} hasBullet={false}>
					<AsideMenuItem
						to='/admin/area'
						title={t('Área Administrador')}
						bsTitle={t('Área Administrador')}
						hasBullet={false}
						outside={false}
						className={styleCss.fontSizeMobile}
						click={onMenuItemClick}
					/>
					<AsideMenuItem
						to='/admin/gestion-empresas'
						title={t('Gestión Empresas')}
						bsTitle={t('Gestión Empresas')}
						hasBullet={false}
						outside={false}
						className={styleCss.fontSizeMobile}
						click={onMenuItemClick}
					/>
					<AsideMenuItem
						to='/admin/gestion-registros'
						title={t('Gestión Registros')}
						bsTitle={t('Gestión Registros')}
						hasBullet={false}
						outside={false}
						className={styleCss.fontSizeMobile}
						click={onMenuItemClick}
					/>
					<AsideMenuItem
						to='/admin/nuevo-usuario'
						title={t('Nuevo Usuario')}
						bsTitle={t('Nuevo Usuario')}
						hasBullet={true}
						outside={false}
						className={styleCss.fontSizeMobile}
						click={onMenuItemClick}
					/>
				</AsideMenuItemWithSub>
		);
	};

	const AsideMenuCandidate = () => {
		return (

				<AsideMenuItemWithSub to='/candidato' title={t('Menu Candidato')} hasBullet={false}>
					<AsideMenuItem
						to='/candidato/area'
						title={t('Minha Área')}
						bsTitle={t('Minha Área')}
						hasBullet={false}
						outside={false}
						className={styleCss.fontSizeMobile}
						click={onMenuItemClick}
					/>
					<AsideMenuItem
						to='/candidato/postuladas-vacantes'
						title={t('Minhas Vagas')}
						bsTitle={t('Minhas Vagas')}
						hasBullet={false}
						outside={false}
						className={styleCss.fontSizeMobile}
						click={onMenuItemClick}
					/>
					<AsideMenuItem
						to={`/candidato/crear-hoja-vida/${user?.id}`}
						title={t('Criar Currículo')}
						bsTitle={t('Criar Currículo')}
						hasBullet={false}
						outside={false}
						className={styleCss.fontSizeMobile}
						click={onMenuItemClick}
					/>
					{/* <AsideMenuItem
						to='/candidato/citas-programadas'
						title={t('Agenda de Compormisso')}
						bsTitle={t('Agenda de Compormisso')}
						hasBullet={false}
						outside={false}
						className={styleCss.fontSizeMobile}
						click={onMenuItemClick}
					/> */}
				</AsideMenuItemWithSub>
		);
	};

	const AsideMenuCounselor = () => {
		return (

				<AsideMenuItemWithSub to='/consejero' title={t('Menú Consejero')} hasBullet={false}>
					<AsideMenuItem
						to='/consejero/area'
						title={t('Área Consejero')}
						bsTitle={t('Área Consejero')}
						hasBullet={false}
						outside={false}
						className={styleCss.fontSizeMobile}
						click={onMenuItemClick}
					/>
				</AsideMenuItemWithSub>

		);
	};

	const AsideMenuEmployer = () => {
		return (
				<AsideMenuItemWithSub to='/empleador' title={t('Menú Empleador')} hasBullet={false}>
					<AsideMenuItem
						to='/empleador/area'
						title={t('Área Empleador')}
						bsTitle={t('Área Empleador')}
						hasBullet={false}
						outside={false}
						className={styleCss.fontSizeMobile}
						click={onMenuItemClick}
					/>
					<AsideMenuItem
						to='/empleador/gestion-vacantes'
						title={t('Gestión Vacantes')}
						bsTitle={t('Gestión Vacantes')}
						hasBullet={false}
						outside={false}
						className={styleCss.fontSizeMobile}
						click={onMenuItemClick}
					/>
				</AsideMenuItemWithSub>
		);
	};
	const AsideMenuProvisional = () => {
		return (

				<AsideMenuItemWithSub to='/empleador' title={t('Menú Empleador')} hasBullet={false}>
					<AsideMenuItem
						to='/empleador/area'
						title={t('Área Empleador')}
						bsTitle={t('Área Empleador')}
						hasBullet={false}
						outside={false}
						className={styleCss.fontSizeMobile}
						click={onMenuItemClick}
					/>
				</AsideMenuItemWithSub>
		);
	};

	switch (role) {
		case RoleEnum.ADMIN:
			return <AsideMenuAdmin />;
		case RoleEnum.CANDIDATE:
			return <AsideMenuCandidate />;
		/* case RoleEnum.COUNSELOR:
			return <AsideMenuCounselor />; */
		case RoleEnum.EMPLOYER:
			return <AsideMenuEmployer />;
		case RoleEnum.PROVISIONAL:
			return <AsideMenuProvisional />;
		default:
			return <></>;
	}

	// return (
	//   <>
	//     {/* comentado <AsideMenuItem
	//       to='/dashboard'
	//       title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
	//       fontIcon='bi-bar-chart-line'
	//       bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
	//       className='py-3'
	//     />
	//     <AsideMenuItem
	//       to='/builder'
	//       title='Layout Builder'
	//       bsTitle='Layout Builder'
	//       fontIcon='bi-gear'
	//       className='py-3'
	//     />
	//     <AsideMenuItemWithSubMain
	//       to='/crafted/pages'
	//       title='Crafted'
	//       fontIcon='bi-file-text'
	//       bsTitle='Crafted'
	//     >
	//       <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={false}>
	//         <AsideMenuItem
	//           to='/crafted/pages/profile/overview'
	//           title='Overview'
	//           bsTitle='Overview'
	//           hasBullet={false}
	//         />
	//         <AsideMenuItem
	//           to='/crafted/pages/profile/projects'
	//           title='Projects'
	//           bsTitle='Projects'
	//           hasBullet={false}
	//         />
	//         <AsideMenuItem
	//           to='/crafted/pages/profile/campaigns'
	//           title='Campaigns'
	//           bsTitle='Campaigns'
	//           hasBullet={false}
	//         />
	//         <AsideMenuItem
	//           to='/crafted/pages/profile/documents'
	//           title='Documents'
	//           bsTitle='Documents'
	//           hasBullet={false}
	//         />
	//         <AsideMenuItem
	//           to='/crafted/pages/profile/connections'
	//           title='Connections'
	//           hasBullet={false}
	//           bsTitle='Connections'
	//         />
	//       </AsideMenuItemWithSub>

	//       <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={false}>
	//         <AsideMenuItem
	//           to='/crafted/pages/wizards/horizontal'
	//           title='Horizontal'
	//           hasBullet={false}
	//           bsTitle='Horizontal'
	//         />
	//         <AsideMenuItem
	//           to='/crafted/pages/wizards/vertical'
	//           title='Vertical'
	//           bsTitle='Vertical'
	//           hasBullet={false}
	//         />
	//       </AsideMenuItemWithSub>

	//       <AsideMenuItemWithSub to='/crafted/accounts' title='Accounts' hasBullet={false}>
	//         <AsideMenuItem
	//           to='/crafted/account/overview'
	//           title='Overview'
	//           hasBullet={false}
	//           bsTitle='Overview'
	//         />
	//         <AsideMenuItem
	//           to='/crafted/account/settings'
	//           title='Settings'
	//           hasBullet={false}
	//           bsTitle='Settings'
	//         />
	//       </AsideMenuItemWithSub>

	//       <AsideMenuItemWithSub to='/crafted/widgets' title='Widgets' hasBullet={true}>
	//         <AsideMenuItem
	//           to='/crafted/widgets/lists'
	//           title='Lists'
	//           bsTitle='Lists'
	//           hasBullet={true}
	//         />
	//         <AsideMenuItem
	//           to='/crafted/widgets/statistics'
	//           title='Statistics'
	//           bsTitle='Statistics'
	//           hasBullet={true}
	//         />
	//         <AsideMenuItem
	//           to='/crafted/widgets/charts'
	//           title='Charts'
	//           bsTitle='Charts'
	//           hasBullet={true}
	//         />
	//         <AsideMenuItem
	//           to='/crafted/widgets/mixed'
	//           title='Mixed'
	//           bsTitle='Mixed'
	//           hasBullet={true}
	//         />
	//         <AsideMenuItem
	//           to='/crafted/widgets/tables'
	//           title='Tables'
	//           bsTitle='Tables'
	//           hasBullet={true}
	//         />
	//         <AsideMenuItem
	//           to='/crafted/widgets/feeds'
	//           title='Feeds'
	//           bsTitle='Feeds'
	//           hasBullet={true}
	//         />
	//       </AsideMenuItemWithSub>

	//       <AsideMenuItemWithSub to='/apps/chat' title='Chat' hasBullet={true}>
	//         <AsideMenuItem
	//           to='/apps/chat/private-chat'
	//           title='Private Chat'
	//           bsTitle='Private Chat'
	//           hasBullet={true}
	//         />
	//         <AsideMenuItem
	//           to='/apps/chat/group-chat'
	//           title='Group Chart'
	//           bsTitle='Group Chart'
	//           hasBullet={true}
	//         />
	//         <AsideMenuItem
	//           to='/apps/chat/drawer-chat'
	//           title='Drawer Chart'
	//           bsTitle='Drawer Chart'
	//           hasBullet={true}
	//         />
	//       </AsideMenuItemWithSub>
	//     </AsideMenuItemWithSubMain>

	//     <AsideMenuItemWithSubMain to='/error' title='Errors' fontIcon='bi-sticky' bsTitle='Errors'>
	//       <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
	//       <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
	//     </AsideMenuItemWithSubMain>

	//     <AsideMenuItem
	//       to='/apps/user-management/users'
	//       title='User management'
	//       fontIcon='bi-people'
	//       bsTitle='User management'
	//       className='py-3'
	//     />
	//     <AsideMenuItem
	//       outside={false}
	//       to='/cadastro'
	//       title='User management'
	//       fontIcon='bi-card-text'
	//       bsTitle='Curriculum Register'
	//       className='py-3'
	//     />*/}

	//     {/* ADMIN */}
	//     <AsideMenuItemWithSubMain
	//       to='/admin'
	//       title='Administrador'
	//       fontIcon='bi-file-text'
	//       bsTitle=''
	//     >
	//       <AsideMenuItemWithSub to='/admin' title='Acciones Admin' hasBullet={false}>
	//         <AsideMenuItem
	//           to='/admin/area'
	//           title='Admin Area'
	//           bsTitle='Admin Area'
	//           hasBullet={true}
	//           outside={false}
	//         />
	//         <AsideMenuItem
	//           to='/admin/gestion-candidatos'
	//           title='Gestion Candidatos'
	//           bsTitle='Gestion Candidatos'
	//           hasBullet={true}
	//           outside={false}
	//         />
	//       </AsideMenuItemWithSub>

	//     </AsideMenuItemWithSubMain>

	//     {/* CANDIDATO */}
	//     <AsideMenuItemWithSubMain
	//       to='/candidato'
	//       title='Candidato'
	//       fontIcon='bi-file-text'
	//       bsTitle=''
	//     >
	//       <AsideMenuItemWithSub to='/candidato' title='Acciones Candidato' hasBullet={false}>
	//         <AsideMenuItem
	//           to='/candidato/area'
	//           title='Candidato Area'
	//           bsTitle='Candidato Area'
	//           hasBullet={true}
	//           outside={false}
	//         />
	//         <AsideMenuItem
	//           to='/candidato/matching-vacantes'
	//           title='Matching Vacantes'
	//           bsTitle='Matching Vacantes'
	//           hasBullet={true}
	//           outside={false}
	//         />
	//         <AsideMenuItem
	//           to='/candidato/postuladas-vacantes'
	//           title='Postuladas Vacantes'
	//           bsTitle='Postuladas Vacantes'
	//           hasBullet={true}
	//           outside={false}
	//         />
	//         <AsideMenuItem
	//           to='/candidato/crear-hoja-vida'
	//           title='Criar Currículo'
	//           bsTitle='Criar Currículo'
	//           hasBullet={true}
	//           outside={false}
	//         />
	//         <AsideMenuItem
	//           to='/candidato/citas-programadas'
	//           title='Citas Programadas'
	//           bsTitle='Citas Programadas'
	//           hasBullet={true}
	//           outside={false}
	//         />
	//       </AsideMenuItemWithSub>

	//     </AsideMenuItemWithSubMain>

	//     {/* COUNSELOR */}
	//     <AsideMenuItemWithSubMain
	//       to='/consejero'
	//       title='Consejero'
	//       fontIcon='bi-file-text'
	//       bsTitle=''
	//     >
	//       <AsideMenuItemWithSub to='/consejero' title='Acciones Consejero' hasBullet={false}>
	//         <AsideMenuItem
	//           to='/consejero/area'
	//           title='Consejero Area'
	//           bsTitle='Consejero Area'
	//           hasBullet={true}
	//           outside={false}
	//         />
	//       </AsideMenuItemWithSub>

	//     </AsideMenuItemWithSubMain>

	//     {/* EMPLEADOR */}
	//     <AsideMenuItemWithSubMain
	//       to='/empleador'
	//       title='Empleador'
	//       fontIcon='bi-file-text'
	//       bsTitle=''
	//     >
	//       <AsideMenuItemWithSub to='/empleador' title='Acciones Empleador' hasBullet={false}>
	//         <AsideMenuItem
	//           to='/empleador/area'
	//           title='Empleador Area'
	//           bsTitle='Empleador Area'
	//           hasBullet={true}
	//           outside={false}
	//         />
	//         <AsideMenuItem
	//           to='/empleador/gestion-vacantes'
	//           title='Gestion Vacantes'
	//           bsTitle='Gestion Vacantes'
	//           hasBullet={true}
	//           outside={false}
	//         />
	//       </AsideMenuItemWithSub>

	//     </AsideMenuItemWithSubMain>
	//   </>
	// )
}
