import { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import styleScss from './styles.module.scss';
import { CardModel } from '../../models/CardModel';
import { PageTitle } from '../../../_metronic/layout/core';
import { Link } from 'react-router-dom';
import { Alert, Box, CircularProgress, Snackbar, Typography } from '@mui/material';
import * as authHelper from '../../modules/auth/core/AuthHelpers';
import { useTranslation } from 'react-i18next'
import '../../i18n';

const ProvisionalAreaPage = () => {
	
	const { t } = useTranslation();
	const [listInterviewScheduled, setListInterviewScheduled]: any[] = useState([]);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [msgAlert, setMsgAlert] = useState('');
	const [isError, setIsError] = useState(false);
	const { user } = authHelper.getAuth() || {};
	console.log('user', user?.role);
	console.log('user', user?.empresa_id);
	const [total, setTotal] = useState<number>(0)
	const [totalPage, setTotalPage] = useState<number>(0)
	const [filter, setFilter] = useState<any>({
	  page: 1,
	  limit: 10,
	  name: '',
	})

	

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		let arr: any[] = [];
		let arrB: any[] = [];

		for (let index = 0; index < 7; index++) {
			let x = {
				id: index,
				candidateName: `Nombre candidato ${index}`,
				roleDescription: `Asistente de ventas ${index}`,
			};

			let y = {
				candidateName: `Nombre del candidato ${index}`,
				roleDescription: `Asistente de ventas ${index}`,
				match: parseInt(index + '0'),
			};
			arr.push(x);
		}
		for (let index = 0; index < 10; index++) {
			let y = {
				candidateName: `Nombre del candidato ${index}`,
				roleDescription: `Asistente de ventas ${index}`,
				match: parseInt(index + '0'),
			};
			arrB.push(y);
		}

		setListInterviewScheduled(arr);
	
	}, []);

	useEffect(() => {
	}, []);

	const listManage: CardModel[] = [
		{
			title:'Gestionar employer',
			content:'Actualiza la información de tus employer.',
			action: '/empleador/registro-de-empresa/'+ user?.empresa_id,
			actionName:'Ver más',
		},
		
	];

	const cardCountVacancy: number = total;

	const listDashboard: CardModel[] = [
		{
			title:'Dashboard de los candidato',
			content:'Métricas y indicadores de los candidatos.',
			action:
				'https://lookerstudio.google.com/u/0/reporting/8f7dc586-9e97-4d12-afe3-4753790971b9/page/p_4ymnosdz6c',
		},
		{
			title:'Dashboard de los empleadores',
			content:'Métricas e indicadores de los empleadores.',
			action:
				'https://lookerstudio.google.com/u/1/reporting/d2c69850-6964-400b-b3f8-0110b59b99eb/page/p_095goudz6c',
		},
		{
			title:'Métricas e indicadores ',
			content:'Estadísticas de la plataforma.',
			action: 'https://lookerstudio.google.com/u/1/reporting/b5b00fa7-69da-46b7-810e-74aa1ef7f8ed/page/p_ktyx3bjm7c',
		},
	];

	const normalise = (value: number) => ((value - 0) * 100) / (100 - 0);

	return (
		<>
			{/* <h2>Área del empleador</h2> */}
			<div className='row mt-15'>
				{/* left */}
				<div className='col-sm-4 mt-5'>
					{listManage.map((x: CardModel, i: number) => (
						<div
							className={`card mb-10 ${styleScss['card']}`}
							style={{ borderRadius: '10px' }}
							key={i}
							id={i.toString()}
						>
							<div className={`${styleScss['top-icon']}`}>
								<i className={`bi bi-briefcase-fill ${styleScss['icon-top']}`}></i>
							</div>
							<div className='card-body mt-5'>
								<h2 className='card-title'>{t(x.title)}</h2>
								<p className='card-text'>{t(x.content)}</p>
								<Link
									to={x.action!}
									className='btn btn-primary btn-lg btn-block'
									style={{ padding: '8px 40px' }}
								>
									{t(x.actionName || '')}
								</Link>
							</div>
						</div>
					))}
				
				</div>
				{/* middle */}
				{/* Descomentar 
				
				<div className='col-sm-4 mt-5'>
					<div className={`card mb-10 ${styleScss['card2']}`} style={{ borderRadius: '10px' }}>
						<div className={`${styleScss['top-icon']}`}>
							<i className={`bi bi-calendar-check ${styleScss['icon-top']}`}></i>
						</div>
						<div className='card-body mt-5'>
							<h2 className='card-title'>Entrevistas programadas</h2>
							<p className='card-text mb-5'>Consulta las próximas citas para tus vacantes.</p>
							{listInterviewScheduled.map((x: any, i: any) => (
								<div className='mt-4 card-box' key={i} id={x.id}>
									<div className={`${styleScss['box']}`}>
										<p
											className={`${styleScss['txt-box']} ${styleScss['txt-box-primary']} text-primary`}
										>
											{x.candidateName}
										</p>
										<p className={`${styleScss['txt-box']}`}>{x.roleDescription}</p>
									</div>
								</div>
							))}
							<a href='#' className='btn btn-primary mt-4' style={{ padding: '8px 40px' }}>
								Ver todas
							</a>
						</div>
					</div>
				</div>

				*/}

				{/* right */}

			</div>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					variant='filled'
					severity={isError ? 'error' : 'success'}
					sx={{ width: '100%' }}
				>
					{t(msgAlert)}
				</Alert>
			</Snackbar>
		</>
	);
};

const ProvisionalAreaWrapper = () => {
	const { t } = useTranslation();

	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Área Empleador')}</PageTitle>
			<ProvisionalAreaPage />
		</>
	);
};

export { ProvisionalAreaWrapper };
