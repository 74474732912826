import { useTranslation } from 'react-i18next';
import styleScss from './styles.module.scss';
import { Button, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';

export default function EmployerReqFeedbackCardComponent({
	data,
	openMenu,
	anchorEl,
	handleCloseMenu,
	handleClickMenu,
}: any) {
	const { t } = useTranslation();

	return (
		<div className={styleScss.cardMobile}>
			<div className={styleScss['card-body']}>
				<h5 className={styleScss['card-title']}>
					<strong>{t('Candidato')}: </strong>
					{data?.primer_nome}
				</h5>
				<p className={styleScss['card-text']}>
					<strong>{t('Documento')}:</strong> {data?.numero_doc}
				</p>
				<p className={styleScss['card-text']}>
					<strong>{t('Email')}:</strong> {data?.email}
				</p>
				<p className={styleScss['card-text']}>
					<strong>{t('Teléfono')}:</strong> {data.celular}
				</p>
				<p className={styleScss['card-text']}>
					<strong>{t('Vacante')}:</strong> {data.nombre_vacante}
				</p>
			</div>
			<div className={styleScss.options}>
				<Button
					id='basic-button'
					aria-controls={openMenu ? 'basic-menu' : undefined}
					aria-haspopup='true'
					aria-expanded={openMenu ? 'true' : undefined}
					onClick={(e) =>
						handleClickMenu(e, data.id, data.id_empresa, data.id_vagas)
					}
				>
					<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
				</Button>

				<Menu
					id='basic-menu'
					anchorEl={anchorEl}
					open={openMenu}
					onClose={handleCloseMenu}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
				>
					<MenuItem onClick={handleCloseMenu}>
						<Link to={`/empleador/entrevista/${data.id}/${data.idEmpresa}/${data.idVacancy}`}>
							{t('Feedback candidato')}
						</Link>
					</MenuItem>
				</Menu>
			</div>
		</div>
	);
}
