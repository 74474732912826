import { useContext, useEffect, useState } from 'react';
import {
	Box,
	Button,
	TextField,
	InputAdornment,
	IconButton,
	Grid,
	Snackbar,
	Alert,
	AlertTitle,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { multiStepContext } from '../../../../../hooks/StepContext';
import { Form, Formik } from 'formik';
import { object, string, ref } from 'yup';
import {
	formatMaskPhoneNumber12Digits,
	regExpPhoneNumber12Digits,
	regExpPassword,
} from '../../../../../util/Index';
import { getVerifyEmailExists } from '../../../core/_requests';
import { useTranslation } from 'react-i18next';

export const Step2 = () => {
	const {t} = useTranslation();
	const { setStep, firstUser, setFirstUser } = useContext(multiStepContext);

	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = () => setShowPassword(!showPassword);

	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
	const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const [initialValues, setInitialValues] = useState({
		complemento: {
			celular: '',
		},
		user: {
			email: '',
			confirma_email: '',
			password: '',
			confirma_password: '',
		},
	});

	useEffect(() => {
		console.log('effect');
		console.log('effect first', firstUser);
		setInitialValues(firstUser);
	}, []);

	// console.log(firstUser);

	const handleNext = async (values: any, setErrors: any) => {
		console.log(values);

		const exists = await verifyEmailExists(values.user.email, setErrors);

		if (!exists) {
			setFirstUser({
				...firstUser,
				complemento: {
					...firstUser.complemento,
					celular: values.complemento.celular,
					correo: values.user.email,
				},
				user: {
					...firstUser.user,
					email: values.user.email,
					confirma_email: values.user.confirma_email,
					password: values.user.password,
					confirma_password: values.user.confirma_password,
				},
			});
			setStep(3);
		}
	};

	const verifyEmailExists = async (email: string, setErrors: any) => {
		try {
			const response = await getVerifyEmailExists(`email=${email}`);
			return response.data.exists;
		} catch (error: any) {
			console.log(`error`, error.response);
			setAlert(false);
			setAlertMsg(error.response.data.error);
			setOpen(true);
			setErrors({
				user: {
					email: error.response.data.error,
					confirma_email: error.response.data.error,
				},
			});
			return error.response.data.exists;
		}
	};

	const handleBefore = (values: any) => {
		console.log(values);
		setFirstUser({
			...firstUser,
			complemento: {
				...firstUser.complemento,
				celular: values.complemento.celular,
				correo: values.user.email,
			},
			user: {
				...firstUser.user,
				email: values.user.email,
				confirma_email: values.user.confirma_email,
				password: values.user.password,
				confirma_password: values.user.confirma_password,
			},
		});
		setStep(1);
	};
	// debe tener como mínimo 8 dígitos con al menos 1 carácter especial y una letra mayúscula
	return (
		<Box
			sx={{
				'& .MuiTextField-root': { m: 2, width: '95%' },
			}}
		>
			<Formik
				validationSchema={object({
					complemento: object({
						celular: string()
							.transform((value) =>
								value.replace(/[^\d]/g, '').replaceAll('-', '').replaceAll('+', '')
							)
							.matches(
								regExpPhoneNumber12Digits(),
								'Debe ser un celular válido (DDI + DDD + numero celular)'
							)
							.required('Obrigatorio'),
					}),
					user: object({
						email: string()
							.email('Debe ser un Email  válido')
							.required('Obrigatorio'),
						confirma_email: string()
							.email('Debe ser un Email  válido')
							.oneOf(
								[ref('email'), null],
								'El Email  de confirmación debe ser el mismo que el ingresado'
							)
							.required('Obrigatorio'),
						password: string()
							.required('Senha Obrigatorio')
							.min(8, 'Senha deve conter no mínimo 8 dígitos')
							.matches(
								regExpPassword(),
								`Senha debe tener al menos: 1 carácter especial, 1 letra mayúscula, 1 número`
							),
						confirma_password: string()
							.oneOf(
								[ref('password'), null],
								'Confirmar Senha debe ser la misma que la Senha ingresada'
							)
							.required('Obrigatorio'),
					}),
				})}
				initialValues={initialValues}
				validateOnBlur
				validateOnMount
				enableReinitialize
				initialTouched={{
					complemento: {
						celular: true,
					},
					user: {
						email: true,
						confirma_email: true,
						password: true,
						confirma_password: true,
					},
				}}
				onSubmit={() => {}}
			>
				{({
					errors,
					touched,
					values,
					setFieldValue,
					handleChange,
					handleBlur,
					isValid,
					setErrors,
				}) => (
					<Form autoComplete='off'>
						<Grid container direction='row' justifyContent='space-between' width={'100%'}>
							<Grid xs={12} md={6}>
								<TextField
									autoComplete='off'
									fullWidth
									required
									name='complemento.celular'
									label={t('Celular')}
									size='medium'
									onChange={(e) => {
										// let x = e.currentTarget.value.replace(/\D/g, '').match(/(\d{2})(\d{3})(\d{7})/);
										// if (x !== null) e.currentTarget.value = '+' + x[1] + '-' + x[2] + '-' + x[3];
										e.currentTarget.value = formatMaskPhoneNumber12Digits(e.currentTarget.value);
										setFieldValue('complemento.celular', e.currentTarget.value);
									}}
									onBlur={handleBlur}
									value={values.complemento.celular}
									error={touched.complemento?.celular && Boolean(errors.complemento?.celular)}
									helperText={touched.complemento?.celular ? t(errors.complemento?.celular || '') : ''}
									inputProps={{
										autoComplete: 'new-password', //For Chrome not to try to autocomplete the information
									}}
								/>
							</Grid>
							<Grid xs={12} md={6}>
								<TextField
									fullWidth
									required
									name='user.email'
									label={t('Email ')}
									size='medium'
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.user.email}
									error={touched.user?.email && Boolean(errors.user?.email)}
									helperText={touched.user?.email ? t(errors.user?.email || '') : ''}
									inputProps={{
										autoComplete: 'new-password', //For Chrome not to try to autocomplete the information
									}}
								/>
							</Grid>
							<Grid xs={12} md={6}>
								<TextField
									fullWidth
									required
									name='user.confirma_email'
									label={t('Confirmar Email ')}
									size='medium'
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.user.confirma_email}
									error={touched.user?.confirma_email && Boolean(errors.user?.confirma_email)}
									helperText={touched.user?.confirma_email ? t(errors.user?.confirma_email || '') : ''}
									inputProps={{
										autoComplete: 'new-password', //For Chrome not to try to autocomplete the information
									}}
								/>
							</Grid>
							<Grid xs={12} md={6}>
								<TextField
									autoComplete='off'
									fullWidth
									required
									type={showPassword ? 'text' : 'password'}
									name='user.password'
									label={t('Senha ')}
									size='medium'
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.user.password}
									error={touched.user?.password && Boolean(errors.user?.password)}
									helperText={touched.user?.password ? t(errors.user?.password || '') : ''}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle password visibility'
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid xs={12} md={6}>
								<TextField
									autoComplete='off'
									fullWidth
									required
									type={showConfirmPassword ? 'text' : 'password'}
									name='user.confirma_password'
									label={t('Confirmar Senha ')}
									size='medium'
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.user.confirma_password}
									error={touched.user?.confirma_password && Boolean(errors.user?.confirma_password)}
									helperText={touched.user?.confirma_password ? t(errors.user?.confirma_password || '') : ''}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle confirm password visibility'
													onClick={handleClickShowConfirmPassword}
													onMouseDown={handleMouseDownConfirmPassword}
												>
													{showConfirmPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
						</Grid>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'end',
								pt: 0,
							}}
						>
							<Button
								variant='contained'
								sx={{ mr: 1, width: 90, height: 40 }}
								onClick={() => handleBefore(values)}
							>
								{t('Voltar')}
							</Button>
							{/* <Box sx={{ flex: '1 1 auto' }} /> */}
							<Button
								variant='contained'
								sx={{ mr: 1, width: 90, height: 40 }}
								onClick={() => handleNext(values, setErrors)}
								disabled={!isValid}
							>
								{t('Próximo')}
							</Button>
						</Box>
					</Form>
				)}
			</Formik>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
					{t(alertMsg)}
				</Alert>
			</Snackbar>
		</Box>
	);
};
