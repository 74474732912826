import React, { useCallback, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { IMaskInput } from 'react-imask';
import { useDebouncedCallback } from 'use-debounce';
import InputMask from 'react-input-mask';


interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    mask?: string
    definitions?: any
}

export const TextMaskCustom = React.forwardRef<any, any>(
    function TextMaskCustom(props, ref) {
        const { onChange, mask, definitions, ...other } = props;
        console.log("mask", mask)

        return (
            <IMaskInput
                {...other}
                mask={mask || "00/00/0000"}
                definitions={definitions || {
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

export const MaskInputWrapper = (props: any) => {
    const [innerValue, setInnerValue] = useState('');

    useEffect(() => {
        if (props.value) {
            setInnerValue(props.value as string);
        } else {
            setInnerValue('');
        }
    }, [props.value]);

    const debouncedHandleOnChange = useDebouncedCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (props.onChange) {
                props.onChange(event);
            }
        },
        200
    );

    const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            console.log("event", event)
            if (typeof event.persist === "function") {
                event.persist();
            }
            if (event.currentTarget) {
                const newValue = event.currentTarget?.value;
                setInnerValue(newValue);
                debouncedHandleOnChange(event);
            }


        } catch (error) {
            console.error(error)
        }

    }, []);

    return (
        <InputMask mask={props.mask} value={innerValue} onChange={handleOnChange}>

            <TextField
                {...props}
                value={innerValue}
                onChange={handleOnChange}
            />
        </InputMask>

        // InputProps={{
        //     inputComponent: (props_curr) =>
        //         <TextMaskCustom
        //             {...props_curr as any}
        //             {...props as any}
        //             mask={props.mask || "00/00/0000"} /> as any
        // }}
        // />
    );
};



