import { useEffect, useState } from 'react';
import styleCss from './styles.module.scss';
import PaginationComponent from '../../components/Pagination/Index';
import { Link } from 'react-router-dom';
import { getVagaCurriculoById } from '../../modules/apps/user-management/users-list/core/_requests';
import UseDidMountEffect from '../../modules/auth/core/UseDidMountEffect';
import { useTranslation } from 'react-i18next';
const PublicVacanciesPage = () => {
	const { t } = useTranslation()
	const [data, setData]: any[] = useState([]);
	const [userData, setUserData]: any[] = useState([]);

	//Pagination
	const [total, setTotal] = useState<number>(0);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
	});

	const changePaginate = (pf: any) => {
		const f = { page: pf.page, limit: pf.limit };
		setFilter(f);
	};

	const ddlNivel = [
		{ id: 'PR', name: 'Preescolar' },
		{ id: 'P', name: 'Básica primaria(1-5)' },
		{ id: 'BS', name: 'Básica secundaria(6-9)' },
		{ id: 'MD', name: 'Media(10-13)' },
		{ id: 'TL', name: 'Técnica laboral' },
		{ id: 'TP', name: 'Técnica profesional' },
		{ id: 'T', name: 'Tecnológica' },
		{ id: 'PG', name: 'Universitaria' },
		{ id: 'E', name: 'Especialización' },
		{ id: 'M', name: 'Maestría' },
		{ id: 'D', name: 'Doctorado' },
	];

	const ddlTipoContrato = [
		{ id: 'AP', name: 'Aprendizaje' },
		{ id: 'OB', name: 'Obra' },
		{ id: 'OC', name: 'Otro' },
		{ id: 'PR', name: 'Prest. de Servicios' },
		{ id: 'TE', name: 'Temporal' },
		{ id: 'TF', name: 'Término Fijo' },
		{ id: 'TI', name: 'Término Indefinido' },
	];

	useEffect(() => {
		setFilter({ ...filter });
	}, []);

	UseDidMountEffect(() => {
		handleSubmit();
	}, [filter]);

	const handleSubmit = async () => {
		try {
			var nivelestudio = userData.nivel_estudio;
			var tipocontrato = userData.tipo_contrato;
			console.log(nivelestudio);
			if (userData.nivel_estudio === 'Escolaridad') {
				nivelestudio = '';
			}
			if (userData.tipo_contrato === 'Tipo de contrato') {
				tipocontrato = '';
			}
			const users = await getVagaCurriculoById(
				`page=${filter.page}&limit=${filter.limit}&nombre_vacante=${userData.nombre_vacante}&nivel_estudio=${nivelestudio}&tipo_contrato=${tipocontrato}`
			);

			console.log(users.data.data);
			setTotal(users.data.total);
			setTotalPage(users.data.totalPages);
			setData(users.data.data);

			//console.log(userData);
			//const retVacancy = await createVacancy(userData);
			//console.log(retVacancy);
			//alert('¡Retorno da consulta   ' + users.data.total  );
			//navigate('/auth');
			//setLoading(false);
		} catch (e: any) {
			console.error(e);
			var erro = e;
			alert(e + ' ' + e.response.data.error);
		}
	};

	return (
		<>
			<div className={`${styleCss['custom-body']}`}>
				<div className='p-4 shadow-4 rounded-3 mt-5' style={{ backgroundColor: '#D0DDF0' }}>
					<div className='form-row align-items-center'>
						<div className='row'>
							<div className='col-sm-2 my-1'>
								<input
									value={userData['nombre_vacante']}
									onChange={(e) => setUserData({ ...userData, nombre_vacante: e.target.value })}
									className='form-control'
									type='text'
									placeholder={t('Busca la vacante')}
								/>
							</div>

							{/* <label htmlFor=''>Fecha de anuncio</label> 
							<div className='col-sm-2 my-1'>
								<input
									style={{ textAlign: 'center', backgroundColor: '#0A4396', color: '#FFFF' }}
									className={`form-control`}
									type='text'
									placeholder='Fecha de anuncio'
									onFocus={(e) => (e.target.type = 'date')}
								/>
							</div>
							
							<div className={`col-sm-1 my-1`}>
								<select
									className='form-control form-select'
									style={{ textAlign: 'center', backgroundColor: '#0A4396', color: '#FFFF' }}
									aria-label='Modality'
								>
									<option defaultValue={-1}>Modalidad</option>
									{modality.map((x, i) => (
										<option key={i} value={x.id}>
											{x.description}
										</option>
									))}
								</select>
							</div>
							*/}
							<div className='col-sm-2 my-1'>
								<select
									className='form-control form-select'
									style={{ textAlign: 'center', backgroundColor: '#0A4396', color: '#FFFF' }}
									aria-label='schooling'
									value={userData['nivel_estudio']}
									onChange={(e) => setUserData({ ...userData, nivel_estudio: e.target.value })}
								>
									<option defaultValue={-1}>{t('Escolaridad')}</option>
									{ddlNivel.map((x, i) => (
										<option key={i} value={x.id}>
											{t(x.name)}
										</option>
									))}
								</select>
							</div>
							<div className='col-sm-2 my-1'>
								<select
									className='form-control form-select'
									style={{ textAlign: 'center', backgroundColor: '#0A4396', color: '#FFFF' }}
									aria-label='typeContract'
									value={userData['tipo_contrato']}
									onChange={(e) => setUserData({ ...userData, tipo_contrato: e.target.value })}
								>
									<option defaultValue={-1}>Tipo de contrato</option>
									{ddlTipoContrato.map((x, i) => (
										<option key={i} value={x.id}>
											{t(x.name)}
										</option>
									))}
								</select>
							</div>
							{/* 
							<div className='col-sm-2 my-1'>
								<select
									className='form-control form-select'
									style={{ textAlign: 'center', backgroundColor: '#0A4396', color: '#FFFF' }}
									aria-label='company'
								>
									<option defaultValue={-1}>Empresa</option>
									{company.map((x, i) => (
										<option key={i} value={x.id}>
											{x.description}
										</option>
									))}
								</select>
							</div>
						*/}
							<div className='col-sm-1 my-1'>
								<button
									className={`form-control btn ${styleCss['btn-red']}`}
									onClick={handleSubmit}
								>
									<i className='bi bi-search'></i>{t('Buscar')}
								</button>
							</div>
						</div>
					</div>
				</div>
				{data.length === 0 && (
					<div className='p-4 shadow-4 rounded-3 mt-5' style={{ backgroundColor: '#FAFAFA' }}>
						<div className='row'>
							<p style={{ color: '#0A4396', paddingLeft: '60px', fontSize: '12pt' }}>
								{data.length} {t('Vacantes de empleo encontradas')}
							</p>
						</div>
						<hr />
					</div>
				)}
				{data.length !== 0 && (
					<div className='p-4 shadow-4 rounded-3 mt-5' style={{ backgroundColor: '#FAFAFA' }}>
						<div className='row'>
							<p style={{ color: '#0A4396', paddingLeft: '60px', fontSize: '12pt' }}>
								{total} {t('Vacantes de empleo encontradas')}
							</p>
						</div>
						<hr />

						<div className='row'>
							{data.map((x: any, i: number) => (
								<div className='col-sm-4' key={i}>
									<div className='card shadow p-3 mb-5 bg-white rounded' style={{ height: '95%' }}>
										<div className='card-body'>
											<h5 className='card-title text-primary'>{x.nombre_vacante}</h5>
											<div>
												<h6
													style={{ display: 'inline' }}
													className='card-subtitle mb-2 mt-4 text-muted'
												>
													<b>{x.Empresa?.razon_social}</b>
												</h6>
												<span style={{ display: 'inline', marginLeft: '40px' }}>
													<b>{('Fecha de anuncio:')}</b> {x.createdAt.toLocaleString().substr(0, 10)}
												</span>
											</div>

											<div className='card-text mt-4'>
												<span className='mt-4 aa'>
													<p>{x.Describa_vacante}</p>
												</span>
											</div>
											<div className='card-text mt-4'>
												<span className='mt-4'>
													<i className='bi bi-book' style={{ fontSize: '16px' }}></i>
													&nbsp;&nbsp;
													<b>{t('Nivel de estudios:')} </b>
													{ddlNivel.find((y) => y.id === x.nivel_estudio)?.name}
												</span>
												<br />
												<span className='mt-4'>
													<i className='bi bi-stopwatch' style={{ fontSize: '16px' }}></i>
													&nbsp;&nbsp;
													<b>{t('Experiencia:')} </b>
													{x.rango_experiencia}
												</span>
												<br />
												<span className='mt-4'>
													<i className='bi bi-house-door' style={{ fontSize: '16px' }}></i>
													&nbsp;&nbsp;
													<b>{x.trabajo_suplementario}</b>
												</span>
												<br />
											</div>
											<Link
												to={`/vacante/${x.id}`}
												className={`btn btn-lg btn-block mt-5 ${styleCss['btn-red']}`}
												style={{
													padding: '8px 20px',
													float: 'right',
												}}
											>
												{t('Aplicar')}
											</Link>
										</div>
									</div>
								</div>
							))}
						</div>
						<PaginationComponent
							totalPage={totalPage}
							total={total}
							customClass={''}
							paginate={changePaginate}
						/>
					</div>
				)}
			</div>
		</>
	);
};

const PublicVacanciesWrapper = () => {
	return (
		<>
			<PublicVacanciesPage />
		</>
	);
};

export { PublicVacanciesWrapper };
