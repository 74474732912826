import {
	Alert,
	AlertTitle,
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	InputAdornment,
	Menu,
	MenuItem,
	Snackbar,
	TextField,
	Typography,
} from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { multiStepContext } from '../../../../../contexts/VacancyContext';
import { getPositions } from '../../../../apps/user-management/users-list/core/_requests';
import { initValue } from '../../../../../util/Index';
import { useParams } from 'react-router-dom';

interface FormValues {
	position: string;
	zip_code: string;
	street: string;
	complement: string;
	city: string;
	state: string;
	district: string;
	country: string;
	latitude?: number;
	longitude?: number;
}

export const Step2 = () => {
	const { id } = useParams();
	const { t } = useTranslation();
	const { setStep, userData, setUserData } = useContext(multiStepContext);
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');

	const [positions, setPositions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [zipCodeDataLoaded, setZipCodeDataLoaded] = useState(userData['zip_code'] ? true : false);
	const [inputValue, setInputValue] = useState(initValue(userData['position']));
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	const formSchema = useMemo(() => {
		return Yup.object().shape({
			zip_code: Yup.string().when([], {
                is: () => !(id !== undefined && parseInt(id) > 0),
                then: Yup.string().required('CEP é obrigatório'),
                otherwise: Yup.string(),
            }),
			street: Yup.string(),
			complement: Yup.string(),
			city: Yup.string().required('Cidade é obrigatória'),
			state: Yup.string().required('Estado é obrigatório'),
			district: Yup.string(),
		});
	}, []);

	const formik = useFormik<FormValues>({
		initialValues: {
			position: initValue(userData['position']),
			zip_code: initValue(userData['zip_code']),
			street: initValue(userData['street']),
			complement: initValue(userData['complement']),
			city: initValue(userData['city']),
			state: initValue(userData['state']),
			district: initValue(userData['district']),
			country: 'Brasil',
		},
		validationSchema: formSchema,
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			try {
				setSubmitting(true);
			} catch (error) {
				setSubmitting(false);
				setStatus('Información incorrecta');
			}
		},
	});

	useEffect(() => {
		apiGetPositions();
	}, []);

	useEffect(() => {
		if (formik.values.zip_code.length === 8) {
			fetchAddressByZipCode(formik.values.zip_code);
		}
	}, [formik.values.zip_code]);

	const fetchAddressByZipCode = async (zipCode: string) => {
		try {
			const apiKey = process.env.REACT_APP_API_MAPS;
			const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${apiKey}`;

			const { data } = await axios.get(url);
			const { address_components, geometry } = data.results[0] || {};

			if (!data.erro) {
				if (!zipCodeDataLoaded) setZipCodeDataLoaded(true);

				const addressSchema = {
					street: address_components[1]?.long_name || '',
					city: address_components[3]?.long_name || '',
					state: address_components[4]?.long_name || '',
					district: address_components[2]?.long_name || '',
					country: 'Brasil',
					longitude: geometry?.location.lng || 0,
					latitude: geometry?.location.lat || 0,
				};

				formik.setValues({
					...formik.values,
					...addressSchema,
				});
				setUserData({
					...userData,
					...addressSchema,
				});
			} else {
				console.error('CEP não encontrado');
			}
		} catch (error) {
			console.error('Erro ao consultar o CEP', error);
		}
	};

	const apiGetPositions = async (query?: string) => {
		if (!query || query?.length < 3) {
			setPositions([]);
			return;
		}

		setIsLoading(true);

		try {
			let queryString = '';

			if (query && query.trim() !== '') {
				queryString = `name=${query.trim()}`;
			}

			const positions = await getPositions(queryString);

			setPositions(positions.data);
		} catch (e: any) {
			console.error(e);
		} finally {
			setIsLoading(false);
		}
	};

	const handleInputChange = (event: any) => {
		const { value } = event.target;
		setInputValue(value);
		setUserData({ ...userData, position: value });

		if (debounceTimeoutRef.current) {
			clearTimeout(debounceTimeoutRef.current);
		}

		debounceTimeoutRef.current = setTimeout(() => {
			apiGetPositions(value);
		}, 750);
	};

	const handleOptionClick = (option: any) => {
		setInputValue(option);
		setPositions([]);
		setAnchorEl(null);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleNext = async () => {
		formik.setTouched({
			city: true,
			complement: true,
			country: true,
			district: true,
			latitude: true,
			longitude: true,
			state: true,
			street: true,
			position: true,
		});

		await formik.validateForm();

		if (!formik?.isValid) {
			setOpen(true);
			setAlert(false);
			setAlertMsg('Preencha os campos obrigatórios');
			return;
		}

		if (!inputValue || inputValue === '' || inputValue.length < 3) {
			setOpen(true);
			setAlert(false);
			setAlertMsg('Selecione um cargo');
			return;
		}

		setUserData({
			...userData,
			city: formik.values.city,
			complement: formik.values.complement,
			country: formik.values.country,
			district: formik.values.district,
			latitude: formik.values.latitude,
			longitude: formik.values.longitude,
			state: formik.values.state,
			street: formik.values.street,
			position: inputValue,
		});
		setStep(3);
	};
	const handleRetorno = () => {
		setUserData({
			...userData,
			city: formik.values.city || userData.city,
			complement: formik.values.complement || userData.complement,
			country: formik.values.country || userData.country,
			district: formik.values.district || userData.district,
			latitude: formik.values.latitude || userData.latitude,
			longitude: formik.values.longitude || userData.longitude,
			state: formik.values.state || userData.state,
			street: formik.values.street || userData.street,
			position: inputValue,
		});
		setStep(1);
	};

	return (
		<>
			<Box sx={{ mb: 3 }}>
				<Typography variant='h6' sx={{ color: '#2A2A2A', fontWeight: 'bold' }}>
					{t('Selecione o cargo da vaga')}
				</Typography>
			</Box>

			<Grid container direction='column' width={'100%'} item={true}>
				<Grid xs={12} md={4} mb={5} pr={2} item={true}>
					{/* <Autocomplete
						freeSolo
						onChange={(e, newValue) => handleChange(e, newValue)}
						clearText=''
						options={positions?.map((option) => option)}
						renderInput={(params) => <TextField required {...params} label='Buscar cargos' />}
						disabled={isLoading}
					/> */}
					<TextField
						ref={inputRef}
						value={inputValue}
						onChange={handleInputChange}
						label='Pesquisar cargo'
						required
						fullWidth
						onFocus={(event) => setAnchorEl(event.currentTarget)}
						placeholder='Digite para iniciar a pesquisa'
						error={formik.touched.position && Boolean(formik.errors.position)}
						InputProps={{
							endAdornment: isLoading ? (
								<InputAdornment position='end'>
									<CircularProgress size={20} />
								</InputAdornment>
							) : null,
						}}
					/>
					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl) && positions.length > 0}
						onClose={() => {
							setAnchorEl(null);
							setPositions([]);
						}}
						PaperProps={{
							style: {
								maxHeight: 200,
								width: inputRef.current ? inputRef.current.clientWidth : 'auto',
							},
						}}
						disableAutoFocus
					>
						{positions.map((option, index) => (
							<MenuItem key={index} onClick={() => handleOptionClick(option)}>
								{option}
							</MenuItem>
						))}
					</Menu>
				</Grid>
				<Box sx={{ mb: 3 }}>
					<Typography variant='h6' sx={{ color: '#2A2A2A', fontWeight: 'bold' }}>
						{t('Endereço da vaga')}
					</Typography>
				</Box>
				<Grid xs={12} md={4} mb={5} pr={2} item={true}>
					<TextField
						fullWidth
						required
						{...formik.getFieldProps('zip_code')}
						error={formik.touched.zip_code && Boolean(formik.errors.zip_code)}
						helperText={formik.touched.zip_code ? t(formik.errors.zip_code || '') : ''}
						label={t('CEP')}
						placeholder={t('Enter CEP')}
						size='medium'
						onChange={(e) => {
							setUserData({ ...userData, zip_code: e.target.value });
							formik.handleChange(e);
						}}
						value={initValue(userData['zip_code'])}
					/>
				</Grid>
				{(zipCodeDataLoaded || !!userData?.street) && (
					<>
						<Grid xs={12} md={4} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								{...formik.getFieldProps('street')}
								error={formik.touched.street && Boolean(formik.errors.street)}
								helperText={formik.touched.street ? t(formik.errors.street || '') : ''}
								label={t('Street')}
								placeholder={t('Enter street')}
								size='medium'
								onChange={(e) => {
									setUserData({ ...userData, street: e.target.value });
									formik.handleChange(e);
								}}
								value={initValue(userData['street'])}
							/>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								{...formik.getFieldProps('complement')}
								error={formik.touched.complement && Boolean(formik.errors.complement)}
								helperText={formik.touched.complement ? t(formik.errors.complement || '') : ''}
								label={t('Complement')}
								placeholder={t('Enter complement')}
								size='medium'
								onChange={(e) => {
									setUserData({ ...userData, complement: e.target.value });
									formik.handleChange(e);
								}}
								value={initValue(userData['complement'])}
							/>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								required
								{...formik.getFieldProps('city')}
								error={formik.touched.city && Boolean(formik.errors.city)}
								helperText={formik.touched.city ? t(formik.errors.city || '') : ''}
								label={t('City')}
								placeholder={t('Enter city')}
								size='medium'
								onChange={(e) => {
									setUserData({ ...userData, city: e.target.value });
									formik.handleChange(e);
								}}
								value={initValue(userData['city'])}
							/>
						</Grid>
						<Grid xs={12} md={4} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								required
								{...formik.getFieldProps('state')}
								error={formik.touched.state && Boolean(formik.errors.state)}
								helperText={formik.touched.state ? t(formik.errors.state || '') : ''}
								label={t('State')}
								placeholder={t('Enter state')}
								size='medium'
								onChange={(e) => {
									setUserData({ ...userData, state: e.target.value });
									formik.handleChange(e);
								}}
								value={initValue(userData['state'])}
							/>
						</Grid>
						<Grid xs={12} md={3} mb={5} pr={2} item={true}>
							<TextField
								fullWidth
								{...formik.getFieldProps('district')}
								error={formik.touched.district && Boolean(formik.errors.district)}
								helperText={formik.touched.district ? t(formik.errors.district || '') : ''}
								label={t('District')}
								placeholder={t('Enter district')}
								size='medium'
								onChange={(e) => {
									setUserData({ ...userData, district: e.target.value });
									formik.handleChange(e);
								}}
								value={initValue(userData['district'])}
							/>
						</Grid>
					</>
				)}
			</Grid>
			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
					onClick={() => handleRetorno()}
				>
					{t('Atrás')}
				</Button>
				{/*<Box sx={{ flex: '1 1 auto' }} />*/}
				<Button
					variant='contained'
					sx={{ background: '#0A4396', width: 90 }}
					onClick={() => handleNext()}
					disabled={isLoading}
				>
					{t('Siguiente')}
				</Button>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
					{t(alertMsg)}
				</Alert>
			</Snackbar>
		</>
	);
};
