import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import { CurricullumSectionProps } from "../../modules/auth/components/sections/curriculum-registration/CurricullumFormTypes";

export type SectionWorkshopType = {
    id?: number;
    event_name?: string;
    organizer?: string;
    date?: string | undefined | boolean;
    dateMonth?: string | undefined | boolean;
    dateYear?: string | number | undefined | boolean;
    type_of_participation?: string | undefined | null;
}

export const useWorkshop = ({ setFieldValue, values }: CurricullumSectionProps) => {
    const { t } = useTranslation();
    const { workshops } = values;
    const [valueAdd, setValueAdd] = useState<SectionWorkshopType>({})
    const [borderColorEdit, setBorderColorEdit] = useState<any>('1px solid');
    const [editMode, setEditMode] = useState(false);

    const [openRowEdit, setOpenRowEdit] = useState<number>(0); const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(true);
    const [alertMsg, setAlertMsg] = useState('');
    const [rows, setRows] = useState<SectionWorkshopType[]>(workshops || []);

    const setValues = (val: SectionWorkshopType) => {
        setValueAdd(val);
    }


    useMemo(() => {
        if (workshops?.length > 0) {
            setRows(workshops)
        }

    }, [workshops]);

    const {
        event_name,
        organizer,
        date,
        dateMonth,
        dateYear,
        type_of_participation,
    } = valueAdd;


    const handleclear = () => {
        setValueAdd({})
        setOpenRowEdit(0);
        setEditMode(false);
    };


    const saveToForm = (rowsAdd: any[]) => {
        setFieldValue('workshops', rowsAdd);
    }

    const handleSaveEdit = () => {
        if (editMode) {
            let item = rows.find((x) => x.id === openRowEdit);
            console.log('item edit', item);
            let iRemove = rows.findIndex((x) => x.id === openRowEdit);
            if (handleValidOnSubmit()) {

                let itemsToSave = rows.filter((_, i) => i !== iRemove);
                let d = new Date();
                let m = d.getMinutes();
                let s = d.getSeconds();
                let idRandom = openRowEdit || parseInt(`${m}${s}`);

                itemsToSave.push({
                    id: idRandom,
                    organizer: organizer as string,
                    event_name: event_name,
                    date: `${dateMonth}/${dateYear}`,
                    dateMonth: dateMonth,
                    dateYear: dateYear,
                    type_of_participation,
                });

                setRows(itemsToSave);
                saveToForm(itemsToSave);
                handleclear();
            }
        }
    };

    const handleAddExperienciaLaboral = () => {
        if (handleValidOnSubmit()) {
            const rowsAdd = [
                ...rows,
                {
                    id: rows.length + 1,
                    organizer: organizer as string,
                    event_name: event_name,
                    date: `${dateMonth}/${dateYear}`,
                    dateMonth: dateMonth,
                    dateYear: dateYear,
                    type_of_participation,

                },
            ]
            setRows(rowsAdd);
            setOpen(true);
            setAlert(true);
            setAlertMsg(`Experiencia laboral Agregado con éxito!`);
            saveToForm(rowsAdd);
            setTimeout(() => {
                setOpen(false);
            }, 7000);
            handleclear();
        }
    };
    const handleRemoveExperienciaLaboral = (id: number) => {
        const list = [...rows];
        list.splice(_.findIndex(list, { id: id }), 1);
        setRows(list);
        saveToForm(list);

    };


    const handleValidOnSubmit = () => {
        let errors: { field: string; error: string }[] = [];



        console.log(errors);
        if (errors.length > 0) {
            setAlert(false);
            setAlertMsg(
                `\ ${t('Diligencie los campos obligatorios')}\n ${errors.map((x) => {
                    return `\n ${t(x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase()))}: ${t(x.error)
                        }`;
                })}`
            );
            setOpen(true);
            return false;
        }
        return true;
    };

    const handleEdit = (item: any) => {
        setEditMode(true);
        setOpenRowEdit(item.id);
        setBorderColorEdit('2px solid blue')
        setTimeout(() => {
            setBorderColorEdit('1px solid')
        }, 4000);
        // window.scrollTo(0, 170);
        const doc = document.getElementById("nameWorkShop");
        doc?.focus({
            preventScroll: false
        });

        let yearExpire;
        let monthExpire;

        if (!item.dateEmissionMonth && !item.dateEmissionYear) {
            yearExpire = item.date.split("/")?.[1];
            monthExpire = item.date.split("/")?.[0];
        }

        console.log('item', item);
        setValueAdd({
            id: item.id,
            organizer: item.organizer as string,
            event_name: item.event_name,
            date: `${dateMonth}/${dateYear}`,
            dateMonth: monthExpire ? monthExpire : item.dateMonth,
            dateYear: yearExpire ? yearExpire : item.dateYear,
            type_of_participation: item.type_of_participation,
        })

    };
    const handleClose = () => {
        setOpen(false);
    };

    return {
        valueAdd,
        borderColorEdit,
        editMode,
        openRowEdit,
        alert,
        alertMsg,
        open,
        setValues,
        setFieldValue,
        handleClose,
        handleEdit,
        handleSaveEdit,
        handleRemoveExperienciaLaboral,
        handleAddExperienciaLaboral,
        handleclear,
        rows,
    }

}