export const COUNTRY = [
  { id: 'AF', name: 'Afganistán' },
  { id: 'AL', name: 'Albania' },
  { id: 'DE', name: 'Alemania' },
  { id: 'AD', name: 'Andorra' },
  { id: 'AO', name: 'Angola' },
  { id: 'AI', name: 'Anguila' },
  { id: 'AQ', name: 'Antártida' },
  { id: 'AG', name: 'Antigua y Barbuda' },
  { id: 'AN', name: 'Antillas Holandesas' },
  { id: 'SA', name: 'Arabia Saudita' },
  { id: 'DZ', name: 'Argelia' },
  { id: 'AR', name: 'Argentina' },
  { id: 'AM', name: 'Armenia' },
  { id: 'AW', name: 'Aruba' },
  { id: 'MK', name: 'ARY Macedonia' },
  { id: 'AU', name: 'Australia' },
  { id: 'AT', name: 'Austria' },
  { id: 'AZ', name: 'Azerbaiyán' },
  { id: 'BS', name: 'Bahamas' },
  { id: 'BH', name: 'Baréin' },
  { id: 'BD', name: 'Bangladesh' },
  { id: 'BB', name: 'Barbados' },
  { id: 'BE', name: 'Bélgica' },
  { id: 'BZ', name: 'Belice' },
  { id: 'BJ', name: 'Benín' },
  { id: 'BM', name: 'Bermudas' },
  { id: 'BT', name: 'Bután' },
  { id: 'BY', name: 'Bielorrusia' },
  { id: 'BO', name: 'Bolivia' },
  { id: 'BA', name: 'Bosnia y Herzegovina' },
  { id: 'BW', name: 'Botsuana' },
  { id: 'BR', name: 'Brasil' },
  { id: 'BN', name: 'Brunéi' },
  { id: 'BG', name: 'Bulgaria' },
  { id: 'BF', name: 'Burkina Faso' },
  { id: 'BI', name: 'Burundi' },
  { id: 'CV', name: 'Cabo Verde' },
  { id: 'KH', name: 'Camboya' },
  { id: 'CM', name: 'Camerún' },
  { id: 'CA', name: 'Canadá' },
  { id: 'QA', name: 'Catar' },
  { id: 'TD', name: 'Chad' },
  { id: 'CL', name: 'Chile' },
  { id: 'CN', name: 'China' },
  { id: 'CY', name: 'Chipre' },
  { id: 'VA', name: 'Ciudad del Vaticano' },
  { id: 'CO', name: 'Colombia' },
  { id: 'KM', name: 'Comoras' },
  { id: 'CG', name: 'Congo' },
  { id: 'KP', name: 'Corea del Norte' },
  { id: 'KR', name: 'Corea del Sur' },
  { id: 'CI', name: 'Costa de Marfil' },
  { id: 'CR', name: 'Costa Rica' },
  { id: 'HR', name: 'Croacia' },
  { id: 'CU', name: 'Cuba' },
  { id: 'DK', name: 'Dinamarca' },
  { id: 'DM', name: 'Dominica' },
  { id: 'EC', name: 'Ecuador' },
  { id: 'EG', name: 'Egipto' },
  { id: 'SV', name: 'El Salvador' },
  { id: 'AE', name: 'Emiratos Árabes Unidos' },
  { id: 'ER', name: 'Eritrea' },
  { id: 'SK', name: 'Eslovaquia' },
  { id: 'SI', name: 'Eslovenia' },
  { id: 'ES', name: 'España' },
  { id: 'US', name: 'Estados Unidos' },
  { id: 'EE', name: 'Estonia' },
  { id: 'ET', name: 'Etiopía' },
  { id: 'PH', name: 'Filipinas' },
  { id: 'FI', name: 'Finlandia' },
  { id: 'FJ', name: 'Fiyi' },
  { id: 'FR', name: 'Francia' },
  { id: 'GA', name: 'Gabón' },
  { id: 'GM', name: 'Gambia' },
  { id: 'GE', name: 'Georgia' },
  { id: 'GH', name: 'Ghana' },
  { id: 'GI', name: 'Gibraltar' },
  { id: 'GD', name: 'Granada' },
  { id: 'GR', name: 'Grecia' },
  { id: 'GL', name: 'Groenlandia' },
  { id: 'GP', name: 'Guadalupe' },
  { id: 'GU', name: 'Guam' },
  { id: 'GT', name: 'Guatemala' },
  { id: 'GF', name: 'Guayana Francesa' },
  { id: 'GN', name: 'Guinea' },
  { id: 'GQ', name: 'Guinea Ecuatorial' },
  { id: 'GW', name: 'Guinea-Bissau' },
  { id: 'GY', name: 'Guyana' },
  { id: 'HT', name: 'Haití' },
  { id: 'HN', name: 'Honduras' },
  { id: 'HK', name: 'Hong Kong' },
  { id: 'HU', name: 'Hungría' },
  { id: 'IN', name: 'India' },
  { id: 'ID', name: 'Indonesia' },
  { id: 'IR', name: 'Irán' },
  { id: 'IQ', name: 'Iraq' },
  { id: 'IE', name: 'Irlanda' },
  { id: 'NF', name: 'Isla Norfolk' },
  { id: 'IS', name: 'Islandia' },
  { id: 'KY', name: 'Islas Caimán' },
  { id: 'CC', name: 'Islas Cocos' },
  { id: 'CK', name: 'Islas Cook' },
  { id: 'FO', name: 'Islas Feroe' },
  { id: 'AX', name: 'Islas Gland' },
  { id: 'FK', name: 'Islas Malvinas' },
  { id: 'MP', name: 'Islas Marianas del Norte' },
  { id: 'MH', name: 'Islas Marshall' },
  { id: 'PN', name: 'Islas Pitcairn' },
  { id: 'SB', name: 'Islas Salomón' },
  { id: 'TC', name: 'Islas Turcas y Caicos' },
  { id: 'VG', name: 'Islas Vírgenes Británicas' },
  { id: 'VI', name: 'Islas Vírgenes de los Estados Unidos' },
  { id: 'IL', name: 'Israel' },
  { id: 'IT', name: 'Italia' },
  { id: 'JM', name: 'Jamaica' },
  { id: 'JP', name: 'Japón' },
  { id: 'JO', name: 'Jordania' },
  { id: 'KZ', name: 'Kazajistán' },
  { id: 'KE', name: 'Kenia' },
  { id: 'KG', name: 'Kirguistán' },
  { id: 'KI', name: 'Kiribati' },
  { id: 'KW', name: 'Kuwait' },
  { id: 'LA', name: 'Laos' },
  { id: 'LS', name: 'Lesoto' },
  { id: 'LV', name: 'Letonia' },
  { id: 'LB', name: 'Líbano' },
  { id: 'LR', name: 'Liberia' },
  { id: 'LY', name: 'Libia' },
  { id: 'LI', name: 'Liechtenstein' },
  { id: 'LT', name: 'Lituania' },
  { id: 'LU', name: 'Luxemburgo' },
  { id: 'MO', name: 'Macao' },
  { id: 'MK', name: 'Macedonia del Norte' },
  { id: 'MG', name: 'Madagascar' },
  { id: 'MY', name: 'Malasia' },
  { id: 'MW', name: 'Malawi' },
  { id: 'MV', name: 'Maldivas' },
  { id: 'ML', name: 'Malí' },
  { id: 'MT', name: 'Malta' },
  { id: 'MA', name: 'Marruecos' },
  { id: 'MQ', name: 'Martinica' },
  { id: 'MU', name: 'Mauricio' },
  { id: 'MR', name: 'Mauritania' },
  { id: 'YT', name: 'Mayotte' },
  { id: 'MX', name: 'México' },
  { id: 'FM', name: 'Micronesia' },
  { id: 'MD', name: 'Moldavia' },
  { id: 'MC', name: 'Mónaco' },
  { id: 'MN', name: 'Mongolia' },
  { id: 'MS', name: 'Montserrat' },
  { id: 'MZ', name: 'Mozambique' },
  { id: 'MM', name: 'Myanmar' },
  { id: 'NA', name: 'Namibia' },
  { id: 'NR', name: 'Nauru' },
  { id: 'NP', name: 'Nepal' },
  { id: 'NI', name: 'Nicaragua' },
  { id: 'NE', name: 'Níger' },
  { id: 'NG', name: 'Nigeria' },
  { id: 'NU', name: 'Niue' },
  { id: 'NO', name: 'Noruega' },
  { id: 'NC', name: 'Nueva Caledonia' },
  { id: 'NZ', name: 'Nueva Zelanda' },
  { id: 'OM', name: 'Omán' },
  { id: 'NL', name: 'Países Bajos' },
  { id: 'PK', name: 'Pakistán' },
  { id: 'PW', name: 'Palau' },
  { id: 'PS', name: 'Palestina' },
  { id: 'PA', name: 'Panamá' },
  { id: 'PG', name: 'Papúa Nueva Guinea' },
  { id: 'PY', name: 'Paraguay' },
  { id: 'PE', name: 'Perú' },
  { id: 'PF', name: 'Polinesia Francesa' },
  { id: 'PL', name: 'Polonia' },
  { id: 'PT', name: 'Portugal' },
  { id: 'PR', name: 'Puerto Rico' },
  { id: 'GB', name: 'Reino Unido' },
  { id: 'CF', name: 'República Centroafricana' },
  { id: 'CZ', name: 'República Checa' },
  { id: 'CD', name: 'República Democrática del Congo' },
  { id: 'DO', name: 'República Dominicana' },
  { id: 'RE', name: 'Reunión' },
  { id: 'RW', name: 'Ruanda' },
  { id: 'RO', name: 'Rumania' },
  { id: 'RU', name: 'Rusia' },
  { id: 'EH', name: 'Sahara Occidental' },
  { id: 'WS', name: 'Samoa' },
  { id: 'AS', name: 'Samoa Americana' },
  { id: 'KN', name: 'San Cristóbal y Nevis' },
  { id: 'SM', name: 'San Marino' },
  { id: 'PM', name: 'San Pedro y Miquelón' },
  { id: 'VC', name: 'San Vicente y las Granadinas' },
  { id: 'SH', name: 'Santa Helena' },
  { id: 'LC', name: 'Santa Lucía' },
  { id: 'ST', name: 'Santo Tomé y Príncipe' },
  { id: 'SN', name: 'Senegal' },
  { id: 'CS', name: 'Serbia y Montenegro' },
  { id: 'SC', name: 'Seychelles' },
  { id: 'SL', name: 'Sierra Leona' },
  { id: 'NS', name: 'Sin Definir' },
  { id: 'SG', name: 'Singapur' },
  { id: 'SY', name: 'Siria' },
  { id: 'SO', name: 'Somalia' },
  { id: 'LK', name: 'Sri Lanka' },
  { id: 'SZ', name: 'Suazilandia' },
  { id: 'ZA', name: 'Sudáfrica' },
  { id: 'SD', name: 'Sudán' },
  { id: 'SE', name: 'Suecia' },
  { id: 'CH', name: 'Suiza' },
  { id: 'SR', name: 'Surinam' },
  { id: 'SJ', name: 'Svalbard y Jan Mayen' },
  { id: 'TH', name: 'Tailandia' },
  { id: 'TW', name: 'Taiwán' },
  { id: 'TZ', name: 'Tanzania' },
  { id: 'TJ', name: 'Tayikistán' },
  { id: 'TL', name: 'Timor Oriental' },
  { id: 'TG', name: 'Togo' },
  { id: 'TK', name: 'Tokelau' },
  { id: 'TO', name: 'Tonga' },
  { id: 'TT', name: 'Trinidad y Tobago' },
  { id: 'TN', name: 'Túnez' },
  { id: 'TM', name: 'Turkmenistán' },
  { id: 'TR', name: 'Turquía' },
  { id: 'TV', name: 'Tuvalu' },
  { id: 'UA', name: 'Ucrania' },
  { id: 'UG', name: 'Uganda' },
  { id: 'UY', name: 'Uruguay' },
  { id: 'UZ', name: 'Uzbekistán' },
  { id: 'VU', name: 'Vanuatu' },
  { id: 'VE', name: 'Venezuela' },
  { id: 'VN', name: 'Vietnam' },
  { id: 'WF', name: 'Wallis y Futuna' },
  { id: 'YE', name: 'Yemen' },
  { id: 'DJ', name: 'Yibuti' },
  { id: 'ZM', name: 'Zambia' },
  { id: 'ZW', name: 'Zimbabue' },
];
export const ddlDepartamentoResidencia = [
  { id: '91', name: 'AMAZONAS' },
  { id: '5', name: 'ANTIOQUIA' },
  { id: '81', name: 'ARAUCA' },
  { id: '8', name: 'ATLÁNTICO' },
  { id: '11', name: 'BOGOTÁ, D.C.' },
  { id: '13', name: 'BOLÍVAR' },
  { id: '15', name: 'BOYACÁ' },
  { id: '17', name: 'CALDAS' },
  { id: '18', name: 'CAQUETÁ' },
  { id: '85', name: 'CASANARE' },
  { id: '19', name: 'CAUCA' },
  { id: '20', name: 'CESAR' },
  { id: '27', name: 'CHOCÓ' },
  { id: '23', name: 'CÓRDOBA' },
  { id: '25', name: 'CUNDINAMARCA' },
  { id: '94', name: 'GUAINÍA' },
  { id: '95', name: 'GUAVIARE' },
  { id: '41', name: 'HUILA' },
  { id: '44', name: 'LA GUAJIRA' },
  { id: '47', name: 'MAGDALENA' },
  { id: '50', name: 'META' },
  { id: '52', name: 'NARIÑO' },
  { id: '54', name: 'NORTE DE SANTANDER' },
  { id: '86', name: 'PUTUMAYO' },
  { id: '63', name: 'QUINDIO' },
  { id: '66', name: 'RISARALDA' },
  { id: '88', name: 'SAN ANDRÉS Y PROVIDENCIA' },
  { id: '68', name: 'SANTANDER' },
  { id: '70', name: 'SUCRE' },
  { id: '73', name: 'TOLIMA' },
  { id: '76', name: 'VALLE DEL CAUCA' },
  { id: '97', name: 'VAUPÉS' },
  { id: '99', name: 'VICHADA' },
];


export const SOFTSKILS_PT = [
  'Comunicação',
  'Gestão de tempo',
  'Solução de problemas',
  'Resolução de conflitos',
  'Flexibilidade',
  'Adaptabilidade',
  'Trabalho em equipe',
  'Capacidade de trabalhar sob pressão',
  'Autoconfiança',
  'Proatividade',
  'Suportar críticas',
  'Atitude positiva',
  'Liderança',
  'Capacidade de persuasão',
  'Negociação',
  'Pensamento crítico',
  'Pensamento criativo',
  'Paciência',
];

export const SOFTSKILS_ES = [
  'Comunicación',
  'Gestión del tiempo',
  'Resolución de problemas',
  'Resolución de conflictos',
  'Flexibilidad',
  'Adaptabilidad',
  'Trabajo en equipo',
  'Capacidad para trabajar bajo presión',
  'Autoconfianza',
  'Proactividad',
  'Aceptar críticas',
  'Actitud positiva',
  'Liderazgo',
  'Capacidad de persuasión',
  'Negociación',
  'Pensamiento crítico',
  'Pensamiento creativo',
  'Paciencia'
];
export const SOFTSKILS_EN = [
  'Communication',
  'Time management',
  'Problem-solving',
  'Conflict resolution',
  'Flexibility',
  'Adaptability',
  'Teamwork',
  'Ability to work under pressure',
  'Self-confidence',
  'Proactivity',
  'Accepting criticism',
  'Positive attitude',
  'Leadership',
  'Persuasion skills',
  'Negotiation',
  'Critical thinking',
  'Creative thinking',
  'Patience'
];

export const SOFTSKILS = {
  'pt': SOFTSKILS_PT,
  'en': SOFTSKILS_EN,
  'es': SOFTSKILS_ES
}

export const HARDSKILS_PT = [
  'Comunicação',
  'Gestão de tempo',
  'Solução de problemas',
  'Resolução de conflitos',
  'Flexibilidade',
  'Adaptabilidade',
  'Trabalho em equipe',
  'Capacidade de trabalhar sob pressão',
  'Autoconfiança',
  'Proatividade',
  'Suportar críticas',
  'Atitude positiva',
  'Liderança',
  'Capacidade de persuasão',
  'Negociação',
  'Pensamento crítico',
  'Pensamento criativo',
  'Paciência',
];
export const HARDSKILS_ES = [
  'Comunicación',
  'Gestión del tiempo',
  'Resolución de problemas',
  'Resolución de conflictos',
  'Flexibilidad',
  'Adaptabilidad',
  'Trabajo en equipo',
  'Capacidad para trabajar bajo presión',
  'Autoconfianza',
  'Proactividad',
  'Aceptar críticas',
  'Actitud positiva',
  'Liderazgo',
  'Capacidad de persuasión',
  'Negociación',
  'Pensamiento crítico',
  'Pensamiento creativo',
  'Paciencia'
];
export const HARDSKILS_EN = [
  'Communication',
  'Time management',
  'Problem-solving',
  'Conflict resolution',
  'Flexibility',
  'Adaptability',
  'Teamwork',
  'Ability to work under pressure',
  'Self-confidence',
  'Proactivity',
  'Accepting criticism',
  'Positive attitude',
  'Leadership',
  'Persuasion skills',
  'Negotiation',
  'Critical thinking',
  'Creative thinking',
  'Patience'
];

export const HARDSKILS = {
  'pt': HARDSKILS_PT,
  'en': HARDSKILS_EN,
  'es': HARDSKILS_ES
}
export const MESES = [
  { name: 'Janeiro', val: '01', i18n: 'JAN' },
  { name: 'Fevereiro', val: '02', i18n: 'FEB' },
  { name: 'Março', val: '03', i18n: 'MAR' },
  { name: 'Abril', val: '04', i18n: 'APR' },
  { name: 'Maio', val: '05', i18n: 'MAY' },
  { name: 'Junho', val: '06', i18n: 'JUN' },
  { name: 'Julho', val: '07', i18n: 'JUL' },
  { name: 'Agosto', val: '08', i18n: 'AUG' },
  { name: 'Setembro', val: '09', i18n: 'SEP' },
  { name: 'Outubro', val: '10', i18n: 'OCT' },
  { name: 'Novembro', val: '11', i18n: 'NOV' },
  { name: 'Dezembro', val: '12', i18n: 'DEZ' }
];

export const TYPES_COURSE = [
  { label: 'Ensino Básico', tagI18n: 'ENSINO_BASICO' },
  { label: 'Ensino Fundamental', tagI18n: 'ENSINO_FUNDAMENTAL' },
  { label: 'Ensino Médio', tagI18n: 'ENSINO_MEDIO' },
  { label: 'Ensino Técnico', tagI18n: 'ENSINO_TECNICO' },
  { label: 'Ensino Profissionalizante', tagI18n: 'ENSINO_PROFISSIONALIZANTE' },
  { label: 'Graduação', tagI18n: 'GRADUACAO' },
  { label: 'Pós-graduação latu senso', tagI18n: 'POS_GRADUACAO_LATU_SENSO' },
  { label: 'Pós-graduação stricto senso', tagI18n: 'POS_GRADUACAO_STRICTO_SENSO' },
  { label: 'Especialização', tagI18n: 'ESPECIALIZACAO' },
  { label: 'Mestrado', tagI18n: 'MESTRADO' },
  { label: 'Doutorado', tagI18n: 'DOUTORADO' },
  { label: 'Pós-doutorado', tagI18n: 'POS_DOUTORADO' },
  { label: 'MBA ou equivalente', caitagI18nxaAlta: 'MBA_OU_EQUIVALENTE' }
];

export const STATUS_COURSE = [
  { label: 'Concluído', val: 0, tagI18n: 'CONCLUDED' },
  { label: 'Em andamento', val: 1, tagI18n: 'IN_PROGRESS' },
  { label: 'Não concluído', val: 2, tagI18n: 'NOT_COMPLETED' }
];
export const TYPES_EVENTO = [
  { label: 'Palestrante', tagI18n: 'PALESTRANTE' },
  { label: 'Panelista', tagI18n: 'PANELISTA' },
  { label: 'Ouvinte', tagI18n: 'OUVINTE' },
  { label: 'Moderador', tagI18n: 'MODERADOR' },
  { label: 'Orador', tagI18n: 'ORADOR' },
  { label: 'Organizador', tagI18n: 'ORGANIZADOR' }
];

export const EXPECTED_SALARY = [
  { id: '1', name: 'Menos de 1 SMMLV' },
  { id: '2', name: '1 SMMLV ' },
  { id: '3', name: '1 a 2 SMMLV' },
  { id: '4', name: '2 a 4 SMMLV' },
  { id: '5', name: '4 a 6 SMMLV' },
  { id: '6', name: '6 a 9 SMMLV' },
  { id: '7', name: '9 a 12 SMMLV' },
  { id: '8', name: '12 a 15 SMMLV' },
  { id: '9', name: '15 a 19 SMMLV' },
  { id: '10', name: '20 SMMLV en adelante' },
  { id: '11', name: 'A convenir' },
];

export const IDIOMAS = {
  pt: [
    'Africâner',
    'Aimará',
    'Albanês',
    'Alemão',
    'Amárico',
    'Árabe',
    'Armênio',
    'Assamês',
    'Azerbaijano',
    'Bambara',
    'Basco',
    'Bengali',
    'Bielorrusso',
    'Birmanês',
    'Boiapuri',
    'Bósnio',
    'Búlgaro',
    'Canarês',
    'Catalão',
    'Cazaque',
    'Cebuano',
    'Chicheua',
    'Chinês (simplificado)',
    'Chinês (tradicional)',
    'Chona',
    'Cingalês',
    'Concani',
    'Coreano',
    'Corso',
    'Crioulo haitiano',
    'Croata',
    'Curdo (kurmanji)',
    'Curdo (sorâni)',
    'Dinamarquês',
    'Diveí',
    'Dogri',
    'Eslovaco',
    'Esloveno',
    'Espanhol',
    'Esperanto',
    'Estoniano',
    'Filipino',
    'Finlandês',
    'Francês',
    'Frísio',
    'Gaélico escocês',
    'Galego',
    'Galês',
    'Georgiano',
    'Grego',
    'Guarani',
    'Guzerate',
    'Hauçá',
    'Havaiano',
    'Hebraico',
    'Hindi',
    'Hmong',
    'Holandês',
    'Húngaro',
    'Igbo',
    'Iídiche',
    'Ilocano',
    'Indonésio',
    'Inglês',
    'Iorubá',
    'Irlandês',
    'Islandês',
    'Italiano',
    'Japonês',
    'Javanês',
    'Jeje',
    'Khmer',
    'Krio',
    'Laosiano',
    'Latim',
    'Letão',
    'Lingala',
    'Lituano',
    'Luganda',
    'Luxemburguês',
    'Macedônio',
    'Maithili',
    'Malaiala',
    'Malaio',
    'Malgaxe',
    'Maltês',
    'Maori',
    'Marata',
    'Meiteilon (manipuri)',
    'Mizo',
    'Mongol',
    'Nepalês',
    'Norueguês',
    'Oriá',
    'Oromo',
    'Pachto',
    'Persa',
    'Polonês',
    'Português',
    'Punjabi',
    'Quíchua',
    'Quiniaruanda',
    'Quirguiz',
    'Romeno',
    'Russo',
    'Samoano',
    'Sânscrito',
    'Sepedi',
    'Sérvio',
    'Sessoto',
    'Sindi',
    'Somali',
    'Suaíli',
    'Sueco',
    'Sundanês',
    'Tadjique',
    'Tailandês',
    'Tâmil',
    'Tártaro',
    'Tcheco',
    'Telugo',
    'Tigrínia',
    'Tsonga',
    'Turco',
    'Turcomano',
    'Twi',
    'Ucraniano',
    'Uigur',
    'Urdu',
    'Uzbeque',
    'Vietnamita',
    'Xhosa',
    'Zulu'
  ],
  es: [
    'Afrikáans',
    'Aimara',
    'Albanés',
    'Alemán',
    'Amárico',
    'Árabe',
    'Armenio',
    'Asamés',
    'Azerí',
    'Bambara',
    'Vasco',
    'Bengalí',
    'Bielorruso',
    'Birmano',
    'Bhojpuri',
    'Bosnio',
    'Búlgaro',
    'Canarés',
    'Catalán',
    'Kazajo',
    'Cebuano',
    'Chichewa',
    'Chino (Simplificado)',
    'Chino (Tradicional)',
    'Chona',
    'Cingalés',
    'Konkani',
    'Coreano',
    'Corso',
    'Criollo haitiano',
    'Croata',
    'Kurdo (Kurmanji)',
    'Kurdo (Sorani)',
    'Danés',
    'Divehi',
    'Dogri',
    'Eslovaco',
    'Esloveno',
    'Español',
    'Esperanto',
    'Estonio',
    'Filipino',
    'Finlandés',
    'Francés',
    'Frisón',
    'Gaélico escocés',
    'Gallego',
    'Galés',
    'Georgiano',
    'Griego',
    'Guaraní',
    'Gujarati',
    'Hausa',
    'Hawaiano',
    'Hebreo',
    'Hindi',
    'Hmong',
    'Holandés',
    'Húngaro',
    'Igbo',
    'Yidis',
    'Ilocano',
    'Indonesio',
    'Inglés',
    'Yoruba',
    'Irlandés',
    'Islandés',
    'Italiano',
    'Japonés',
    'Javanés',
    'Jeje',
    'Khmer',
    'Krio',
    'Lao',
    'Latín',
    'Letón',
    'Lingala',
    'Lituano',
    'Luganda',
    'Luxemburgués',
    'Macedonio',
    'Maithili',
    'Malayalam',
    'Malayo',
    'Malgache',
    'Maltés',
    'Maorí',
    'Marathi',
    'Meiteilon (Manipuri)',
    'Mizo',
    'Mongol',
    'Nepalí',
    'Noruego',
    'Oriya',
    'Oromo',
    'Pastún',
    'Persa',
    'Polaco',
    'Portugués',
    'Punjabi',
    'Quechua',
    'Kinyarwanda',
    'Kirguís',
    'Rumano',
    'Ruso',
    'Samoano',
    'Sánscrito',
    'Sepedi',
    'Serbio',
    'Sotho del norte',
    'Sesotho',
    'Sindhi',
    'Somalí',
    'Suajili',
    'Sueco',
    'Sundanés',
    'Tayiko',
    'Tailandés',
    'Tamil',
    'Tártaro',
    'Checo',
    'Telugu',
    'Tigriña',
    'Tsonga',
    'Turco',
    'Turcomano',
    'Twi',
    'Ucraniano',
    'Uigur',
    'Urdu',
    'Uzbeko',
    'Vietnamita',
    'Xhosa',
    'Zulú'
  ],
  en: [
    'Afrikaans',
    'Aymara',
    'Albanian',
    'German',
    'Amharic',
    'Arabic',
    'Armenian',
    'Assamese',
    'Azerbaijani',
    'Bambara',
    'Basque',
    'Bengali',
    'Belarusian',
    'Burmese',
    'Bhojpuri',
    'Bosnian',
    'Bulgarian',
    'Kannada',
    'Catalan',
    'Kazakh',
    'Cebuano',
    'Chichewa',
    'Chinese (Simplified)',
    'Chinese (Traditional)',
    'Shona',
    'Sinhala',
    'Konkani',
    'Korean',
    'Corsican',
    'Haitian Creole',
    'Croatian',
    'Kurdish (Kurmanji)',
    'Kurdish (Sorani)',
    'Danish',
    'Divehi',
    'Dogri',
    'Slovak',
    'Slovenian',
    'Spanish',
    'Esperanto',
    'Estonian',
    'Filipino',
    'Finnish',
    'French',
    'Frisian',
    'Scottish Gaelic',
    'Galician',
    'Welsh',
    'Georgian',
    'Greek',
    'Guarani',
    'Gujarati',
    'Hausa',
    'Hawaiian',
    'Hebrew',
    'Hindi',
    'Hmong',
    'Dutch',
    'Hungarian',
    'Igbo',
    'Yiddish',
    'Ilocano',
    'Indonesian',
    'English',
    'Yoruba',
    'Irish',
    'Icelandic',
    'Italian',
    'Japanese',
    'Javanese',
    'Yoruba',
    'Khmer',
    'Krio',
    'Lao',
    'Latin',
    'Latvian',
    'Lingala',
    'Lithuanian',
    'Luganda',
    'Luxembourgish',
    'Macedonian',
    'Maithili',
    'Malayalam',
    'Malay',
    'Malagasy',
    'Maltese',
    'Maori',
    'Marathi',
    'Manipuri',
    'Mizo',
    'Mongolian',
    'Nepali',
    'Norwegian',
    'Oriya',
    'Oromo',
    'Pashto',
    'Persian',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Quechua',
    'Kinyarwanda',
    'Kyrgyz',
    'Romanian',
    'Russian',
    'Samoan',
    'Sanskrit',
    'Northern Sotho',
    'Serbian',
    'Southern Sotho',
    'Sindhi',
    'Somali',
    'Swahili',
    'Swedish',
    'Sundanese',
    'Tajik',
    'Thai',
    'Tamil',
    'Tatar',
    'Czech',
    'Telugu',
    'Tigrinya',
    'Tsonga',
    'Turkish',
    'Turkmen',
    'Twi',
    'Ukrainian',
    'Uighur',
    'Urdu',
    'Uzbek',
    'Vietnamese',
    'Xhosa',
    'Zulu'
  ]
}