import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Alert, AlertTitle, CircularProgress, Container, Snackbar } from '@mui/material';
import { uploadCurriculum } from '../../../core/_requests';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const SectionImportPDF = ({ onLoadPDF, onClear }: any) => {
    const { t } = useTranslation();
    const [uploadFile, setUploadFile] = useState<any>(null)
    const [alert, setAlert] = useState(true);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const refFormulario: any = useRef(null);
    const refInput: any = useRef(null);
    const onSelectFile = async (e: any) => {
        setLoading(true);
        try {
            const file: any = e.target.files[0]
            let data = new FormData();
            data.append('file', file);
            console.log('data', data.get('file'));
            setUploadFile(file)
            const response = await uploadCurriculum(data)
            if (!!response) {
                onLoadPDF(response)
                setAlert(true);
            } else {
                setAlert(false);
            }
            setOpen(true)
            setLoading(false);

        } catch (e) {
            setAlert(false);
            setOpen(true)
            setLoading(false);
        }


    }
    const handleOnClear = (e: React.ChangeEvent<HTMLInputElement> | any): void => {
        setUploadFile(null);
        onClear();

    }

    console.log("uploadFile", uploadFile)

    return (
        <>
            <Button
                component="label"
                role={undefined}
                style={{ display: 'flex', marginBottom: 20, borderRadius: 20, flexDirection: 'column', backgroundColor: 'rgb(226 232 240)', padding: '32px 32px', alignContent: 'center', alignItems: 'center' }}>
                <CloudUploadIcon fontSize='large' sx={{ fontSize: 100 }} />
                <h4>{t('IMPORT_PDF')}</h4>
                <VisuallyHiddenInput ref={refInput} accept=".pdf" type="file" onChange={onSelectFile} />
                <VisuallyHiddenInput id="submit-form" ref={refInput} type="submit" />
                {loading && <CircularProgress />}
            </Button>
            {uploadFile &&
                (<div style={{ display: 'flex', width: 'auto', flexDirection: 'row', alignContent: 'center', alignItems: 'center', padding: '15px 0px' }}>
                    <div onClick={handleOnClear} style={{ fontSize: 16, color: '#3a3a3a' }}>{t('CURRICULLUM_IMPORTED')}: {uploadFile?.name}</div>
                    <a href="#" onClick={handleOnClear}><DeleteIcon fontSize='large' sx={{ fontSize: 25, color: '#3a3a3a' }} /></a>
                </div>)
            }

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={() => setOpen(false)}
            >
                <Alert
                    onClose={() => setOpen(false)}
                    variant={'filled'}
                    severity={alert ? 'success' : 'error'}
                    sx={{ whiteSpace: 'pre-line' }}
                >
                    {alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
                    {alert ? t('MSG_UPLOAD_SUCCESS') : t('MSG_UPLOAD_ERROR')}
                </Alert>
            </Snackbar>
        </>
    );
}

export default SectionImportPDF