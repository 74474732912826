import { useEffect, useState } from 'react';
import { useAuth } from '../../modules/auth/core/Auth';
import styleCss from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import '../../i18n';

const preload = [{ valid: false }];

const Chatbot = () => {
	const { t } = useTranslation();
	const { currentUser, auth } = useAuth();
	const [reloadKey, setReloadKey] = useState(0);
	const [chatEnv, setChatEnv] = useState('');
	const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;

	let dfMessenger: any = null;
	let msgReceived: [];

	useEffect(() => {
		setChatEnv(process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : '');
		console.log(process.env.REACT_APP_ENV);

		const link = document.createElement('link');
		link.rel = 'stylesheet';
		link.href =
			'https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/themes/df-messenger-default.css';
		document.head.appendChild(link);

		// const script = document.createElement('script');
		// script.src =
		// 	'https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js';
		// script.defer = true;
		// document.body.appendChild(script);

		document.addEventListener('df-messenger-loaded', () => {
			console.log('Dialogflow Carregado!!!');

			dfMessenger = document.querySelector('df-messenger');

			dfMessenger.clearStorage();

			const queryParameters = {
				parameters: {
					userId: `${auth?.user?.id}` || '',
					env: `${process.env.REACT_APP_ENV}` || '',
					apiToken: `${auth?.api_token}` || '',
				},
			};

			(dfMessenger as any).setQueryParameters(queryParameters);

			(dfMessenger as any).sendRequest('event', 'WELCOME');
		});

		document.addEventListener('df-request-sent', (event: any) => {
			console.log('Request', event.detail.data.requestBody);
		});

		document.addEventListener('df-response-received', async (event: any) => {
			console.log('event2', event.detail.messages);
			msgReceived = event.detail.messages;
		});

		document.addEventListener('df-button-clicked', async (event: any) => {
			console.log('event', event);

			const text = event.detail.text;

			console.log('msg: ', msgReceived);

			const filteredArray = msgReceived.filter((item: any) => {
				if (!item.richElements) {
					return null;
				} else {
					return item.richElements.some((element: any) => {
						return element.text === text;
					});
				}
			});

			const notNullArray = filteredArray.filter((valor) => valor !== null);

			const names = notNullArray.map((item: any) => {
				console.log(item);

				return item.richElements[0].event.name;
			});

			(dfMessenger as any).sendRequest('event', names[0]);
		});

		return () => {
			document.head.removeChild(link);
			// document.body.removeChild(script);
		};
	}, []);

	//window.location.reload();

	useEffect(() => {
		setReloadKey(reloadKey + 1);
		if (reloadCount < 1) {
			sessionStorage.setItem('reloadCount', String(reloadCount + 1));
			// window.location.reload();
			window.location.reload();
		} else {
			sessionStorage.removeItem('reloadCount');
		}
	}, [auth, auth?.user?.id, auth?.api_token]);

	return (
		<div key={reloadKey}>
			<df-messenger
				className={`${styleCss['dft-messenger']}`}
				project-id='xertica-gen-ai-br'
				agent-id='9a0edc7a-97ac-4aa7-aa7f-994468e33c48'
				chat-title="Nai Bot 🤖"
				chat-icon='https://storage.googleapis.com/docs-chatico/ChaticoBogota.png'
				language-code='pt-br'
				// intent='WELCOME'
				wait-open='true'
				max-query-length='-1'
			></df-messenger>
		</div>
	);
};

export default Chatbot;
