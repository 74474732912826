import { useContext, useMemo, useState } from 'react'
import {
	TextField,
	SelectChangeEvent,
	Grid,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Box,
	Button,
	Divider,
	Typography,
	FormHelperText,
	Snackbar,
	Alert,
	AlertTitle,

} from '@mui/material'
import { multiStepContext } from '../../../../../contexts/VacancyContext'
import { initValue } from '../../../../../util/Index'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { isValidFieldRequired } from '../../../../../util/Index';
import { useTranslation } from 'react-i18next'
export const Step4 = () => {
	const { t } = useTranslation();
	const { setStep, userData, setUserData } = useContext(multiStepContext)
	const [reqExperience, setReqExperience] = useState(false)
	const [wordCount, setWordCount] = useState(0)
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [capacitacion, setCapacitacion] = useState<string>('');
	const ddlNivel = [
		{ id: 1, name: 'Educação Infantil', englishName: 'Early Childhood Education', spanishName: 'Educación Infantil' },
		{ id: 2, name: 'Ensino Fundamental', englishName: 'Elementary School', spanishName: 'Educación Primaria' },
		{ id: 3, name: 'Ensino Médio', englishName: 'High School', spanishName: 'Educación Secundaria' },
		{ id: 4, name: 'Técnico', englishName: 'Technical', spanishName: 'Técnico' },
		{ id: 5, name: 'Tecnólogo', englishName: 'Technologist', spanishName: 'Tecnólogo' },
		{ id: 6, name: 'Graduação', englishName: 'Undergraduate', spanishName: 'Grado de Pregrado' },
		{ id: 7, name: 'Bacharelado', englishName: 'Bachelors', spanishName: 'Bachillerato' },
		{ id: 8, name: 'Licenciatura', englishName: 'Teaching', spanishName: 'Licenciatura' },
		{ id: 9, name: 'Pós-Graduação', englishName: 'Postgraduate', spanishName: 'Posgrado' },
		{ id: 10, name: 'Mestrado', englishName: 'Masters', spanishName: 'Maestría' },
		{ id: 11, name: 'Doutorado', englishName: 'Doctorate', spanishName: 'Doctorado' },
	];
	const ddlJefeHogar = [
		{ id: false, name: 'NO' },
		{ id: true, name: 'SI' },
	]
	const handleSelectPais = (e: SelectChangeEvent) => {
		if (e.target.value !== '') {
			setUserData({ ...userData, requiere_capacitacion_especifica: e.target.value });
			var teste = e.target.value;
			setCapacitacion(teste)
			console.log(userData['requiere_capacitacion_especifica'])
		} else {
			setCapacitacion('');
		}
	};
	class validationForm {
		nivel_estudio: string = initValue(userData['nivel_estudio']);
		graduado: string = initValue(userData['graduado']);
		formacion_titulo: string = initValue(userData['formacion_titulo']);
		descricion_capacitacion_especifica: string = initValue(
			userData['descricion_capacitacion_especifica']
		);
	}

	const formSchema = useMemo(() => {
		if (capacitacion === '1') {
			return Yup.object().shape({
				nivel_estudio: Yup.string().required('Campo requerido'),
				graduado: Yup.string().required('Campo requerido'),
				formacion_titulo: Yup.string().required('Campo requerido'),
				descricion_capacitacion_especifica: Yup.string().required('Campo requerido'),
			});
		} else {
			return Yup.object().shape({
				nivel_estudio: Yup.string().required('Campo requerido'),
				graduado: Yup.string().required('Campo requerido'),
				formacion_titulo: Yup.string().required('Campo requerido'),
				descricion_capacitacion_especifica: Yup.string(),
			});
		}
	}, []);


	const formik = useFormik({
		initialValues: new validationForm(),
		validationSchema: formSchema,
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			console.log(capacitacion)

			try {
				setSubmitting(true)
				setStatus('')
				console.log('ok')

				// setUserData({ ...userData, requiere_experiencia_relacionada: reqExperience })
			} catch (error) {
				setSubmitting(false)
				setStatus('Información incorrecta')
			}
		},
	})
	const handleNext = async () => {

		let errors: { field: string; error: string }[] = [];
		if (!isValidFieldRequired(userData['nivel_estudio']))
			errors.push({ field: 'nivel_estudio', error: 'Campo requerido' });


		if (!isValidFieldRequired(userData['graduado']))
			errors.push({ field: 'graduado', error: 'Campo requerido' });

		if (!isValidFieldRequired(userData['formacion_titulo']))
			errors.push({ field: 'formacion_titulo', error: 'Campo requerido' });
		if (!isValidFieldRequired(userData['requiere_capacitacion_especifica']))
			errors.push({ field: 'requiere_capacitacion_especifica', error: 'Campo requerido' });
			
		if (userData['requiere_capacitacion_especifica'] === '1') {
			if (!isValidFieldRequired(userData['descricion_capacitacion_especifica']))
				errors.push({ field: 'descricion_capacitacion_especifica', error: 'Campo requerido' });
		}

		console.log(errors);

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\n${t('Rellene los campos obligatorios')}\n ${errors.map((x) => {
					return `\n ${t(x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase()))}: ${t(x.error)
						}`;
				})}`
			);
			setOpen(true);
			setTimeout(() => {
				setOpen(false);
			}, 5000);
			return;
		} else {
			setStep(5);
		}

	};

	const handleClose = () => {
		setOpen(false);
	};

	console.log(6656, userData)

	return (
		<>
			<Box sx={{ mb: 3 }}>
				<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
					{t('Requisitos')}
				</Typography>
			</Box>
			<Grid container direction='row' justifyContent='space-between' width={'100%'} item={true}>
				<Grid xs={12} md={3} mb={5} pr={2} item={true}>
					<FormControl sx={{ minWidth: '100%' }} size='medium'>
						<InputLabel required id='nivel_estudio'>
							{t('Nivel de estudio')}
						</InputLabel>
						<Select
							labelId='nivel_estudio'
							{...formik.getFieldProps('nivel_estudio')}
							error={formik.touched.nivel_estudio && Boolean(formik.errors.nivel_estudio)}
							required
							name='nivel_estudio'
							id='nivel_estudio'
							label={t('Nivel de estudio')}
							value={initValue(userData['nivel_estudio'])}
							onChange={(e) => {
								setUserData({ ...userData, nivel_estudio: e.target.value })
								formik.handleChange(e)
							}}
						>
							<MenuItem value=''>{t('Seleccione...')}</MenuItem>
							{ddlNivel.map(({ id, name }, i) => (
								<MenuItem value={id.toString()} key={i}>
									{t(name)}
								</MenuItem>
							))}
						</Select>
						<FormHelperText sx={{ color: '#d32f2f' }}>
							{formik.touched.nivel_estudio ? t(formik.errors.nivel_estudio || '') : ''}
						</FormHelperText>
					</FormControl>
				</Grid>
				<Grid xs={12} md={3} mb={5} pr={2} item={true}>
					<FormControl sx={{ minWidth: '100%' }} size='medium'>
						<InputLabel id='graduado'>{t('Graduado')}</InputLabel>
						<Select
							labelId='graduado'
							{...formik.getFieldProps('graduado')}
							error={formik.touched.graduado && Boolean(formik.errors.graduado)}
							required
							name='graduado'
							id='graduado'
							label={t('Graduado')}
							value={initValue(userData['graduado'])}
							onChange={(e) => {
								setUserData({ ...userData, graduado: e.target.value })
								formik.handleChange(e)
							}}
						>
							<MenuItem value=''>{t('Seleccione...')}</MenuItem>
							{ddlJefeHogar.map(({ id, name }, i) => (
								<MenuItem value={id.toString()} key={i}>
									{t(name)}
								</MenuItem>
							))}
						</Select>
						<FormHelperText sx={{ color: '#d32f2f' }}>
							{formik.touched.graduado ? formik.errors.graduado : ''}
						</FormHelperText>
					</FormControl>
				</Grid>
				<Grid xs={12} md={6} mb={5} item={true}>
					<TextField
						fullWidth
						{...formik.getFieldProps('formacion_titulo')}
						error={formik.touched.formacion_titulo && Boolean(formik.errors.formacion_titulo)}
						helperText={formik.touched.formacion_titulo ? t(formik.errors.formacion_titulo || '') : ''}
						required
						label={t('Formación o título requerido')}
						value={initValue(userData['formacion_titulo'])}
						inputProps={{ type: 'text', maxLength: 100 }}
						onChange={(e) => {
							setUserData({ ...userData, formacion_titulo: e.target.value })
							formik.handleChange(e)
						}}
						size='medium'
					/>
				</Grid>
				<Grid xs={4} md={3} mb={5} pr={2} item={true}>
					<FormControl sx={{ minWidth: '100%' }} size='medium'>
						<InputLabel id='requiere_experiencia_relacionada'>
							{t('¿Requiere experiencia relacionada?')}
						</InputLabel>
						<Select
							labelId='requiere_experiencia_relacionada'
							id='requiere_experiencia_relacionada'
							label={t('¿Requiere experiencia relacionada?')}
							value={initValue(userData['requiere_experiencia_relacionada'])}
							onChange={(e) =>
								setUserData({ ...userData, requiere_experiencia_relacionada: e.target.value })
							}
						>
							<MenuItem value=''>{t('Seleccione...')}</MenuItem>
							{ddlJefeHogar.map(({ id, name }, i) => (
								<MenuItem value={id.toString()} key={i}>
									{t(name)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid xs={4} md={3} mb={5} pr={2} item={true}>
					<FormControl sx={{ minWidth: '100%' }} size='medium'>
						<InputLabel id='requiere_capacitacion_especifica'>
							{t('Requiere capacitación específica?')}
						</InputLabel>
						<Select
							labelId='requiere_capacitacion_especifica'
							id='requiere_capacitacion_especifica'
							label={t('Requiere capacitación específica?')}
							value={initValue(userData['requiere_capacitacion_especifica'])}
							onChange={(e) => handleSelectPais(e)}

							onBlur={formik.handleBlur}
						>
							<MenuItem value=''>{t('Seleccione...')}</MenuItem>
							{ddlJefeHogar.map(({ id, name }, i) => (
								<MenuItem value={id.toString()} key={i}>
									{t(name)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid xs={4} md={3} mb={5} pr={2} item={true}>
					<FormControl sx={{ minWidth: '100%' }} size='medium'>
						<InputLabel id='vacante_susceptible_teletrabajo'>
							{t('¿Vacante susceptible a teletrabajo?')}
						</InputLabel>
						<Select
							labelId='vacante_susceptible_teletrabajo'
							id='vacante_susceptible_teletrabajo'
							label={t('¿Vacante susceptible a teletrabajo?')}
							value={initValue(userData['vacante_susceptible_teletrabajo'])}
							onChange={(e) =>
								setUserData({ ...userData, vacante_susceptible_teletrabajo: e.target.value })
							}
						>
							<MenuItem value=''>{t('Seleccione...')}</MenuItem>
							{ddlJefeHogar.map(({ id, name }, i) => (
								<MenuItem value={id.toString()} key={i}>
									{t(name)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid xs={12} md={3} mb={5} item={true}>
					<TextField
						fullWidth
						id='rango_experiencia'
						name='rango_experiencia'
						label={t('Rango de experiencia (en meses)')}
						value={initValue(userData['rango_experiencia'])}
						onChange={(e) => setUserData({ ...userData, rango_experiencia: e.target.value })}
						size='medium'
						disabled={reqExperience}
						inputProps={{ type: 'number', maxLength: 100, min: 0 }}
					/>
				</Grid>
				<Grid xs={12} mb={5} pr={2} item={true}>
					<TextField
						fullWidth
						{...formik.getFieldProps('descricion_capacitacion_especifica')}
						error={
							formik.touched.descricion_capacitacion_especifica &&
							Boolean(formik.errors.descricion_capacitacion_especifica)
						}
						helperText={
							formik.touched.descricion_capacitacion_especifica
								? t(formik.errors.descricion_capacitacion_especifica || '')
								: ''
						}
						required
						id='descricion_capacitacion_especifica'
						name='descricion_capacitacion_especifica'
						label={t('Descripción de la capacitación específica')}
						value={initValue(userData['descricion_capacitacion_especifica'])}
						onChange={(e) => {
							setUserData({ ...userData, descricion_capacitacion_especifica: e.target.value })
							formik.handleChange(e)
							setWordCount(() => e.target.value.length)
						}}
						size='medium'
						multiline
						rows={5}
					/>
					<p>{1000 - wordCount} {t('caracteres restantes')} </p>
				</Grid>
				<Grid xs={8} mb={5} item={true} />
			</Grid>
			<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
				<Button
					variant='contained'
					sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
					onClick={() => setStep(3)}
				>
					{t('Atrás')}
				</Button>
				{/*<Box sx={{ flex: '1 1 auto' }} />*/}
				<Button
					variant='contained'
					sx={{ background: '#0A4396', width: 90 }}
					onClick={handleNext}
				>
					{t('Siguiente')}
				</Button>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>{t('Éxito')}</AlertTitle> : <AlertTitle>{t('Error')}</AlertTitle>}
					{t(alertMsg)}
				</Alert>
			</Snackbar>
		</>
	)
}
