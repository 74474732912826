import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import { CurricullumSectionProps } from "../../modules/auth/components/sections/curriculum-registration/CurricullumFormTypes";

export type SectionLanguageType = {
    id?: number;
    name?: string;
    level_code?: string | undefined | null;
}

export const useLanguage = ({ setFieldValue, values }: CurricullumSectionProps) => {
    const { t } = useTranslation();
    const { languages } = values;

    const [valueAdd, setValueAdd] = useState<SectionLanguageType>({})
    const [borderColorEdit, setBorderColorEdit] = useState<any>('1px solid');
    const [editMode, setEditMode] = useState(false);

    const [openRowEdit, setOpenRowEdit] = useState<number>(0); const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(true);
    const [alertMsg, setAlertMsg] = useState('');
    const [rows, setRows] = useState<SectionLanguageType[]>(languages || []);
    const setValues = (val: SectionLanguageType) => {
        setValueAdd(val);
    }

    const {
        name,
        level_code
    } = valueAdd;

    useMemo(() => {
        if (languages?.length > 0) {
            setRows(languages)
        }

    }, [languages])
    const handleclear = () => {
        setValueAdd({})
        setOpenRowEdit(0);
        setEditMode(false);
    };
    const saveToForm = (rowsAdd: any[]) => {
        setFieldValue('languages', rowsAdd);
    }

    const handleSaveEdit = () => {
        if (editMode) {
            let item = rows.find((x) => x.id === openRowEdit);
            let iRemove = rows.findIndex((x) => x.id === openRowEdit);
            if (handleValidOnSubmit()) {

                let itemsToSave = rows.filter((_, i) => i !== iRemove);
                let d = new Date();
                let m = d.getMinutes();
                let s = d.getSeconds();
                let idRandom = openRowEdit || parseInt(`${m}${s}`);

                itemsToSave.push({
                    id: idRandom,
                    name: name as string,
                    level_code: level_code
                });

                setRows(itemsToSave);
                saveToForm(itemsToSave);
                handleclear();
            }
        }
    };

    const handleAddExperienciaLaboral = () => {
        if (handleValidOnSubmit()) {
            const rowsAdd = [
                ...rows,
                {
                    id: rows.length + 1,
                    name: name as string,
                    level_code: level_code

                },
            ]
            setRows(rowsAdd);
            setOpen(true);
            setAlert(true);
            setAlertMsg(`Experiencia laboral Agregado con éxito!`);
            saveToForm(rowsAdd);
            setTimeout(() => {
                setOpen(false);
            }, 7000);
            handleclear();
        }
    };
    const handleRemoveExperienciaLaboral = (id: number) => {
        const list = [...rows];
        list.splice(_.findIndex(list, { id: id }), 1);
        setRows(list);
        saveToForm(list);
    };


    const handleValidOnSubmit = () => {
        let errors: { field: string; error: string }[] = [];

        console.log(errors);
        if (errors.length > 0) {
            setAlert(false);
            setAlertMsg(
                `\ ${t('Diligencie los campos obligatorios')}\n ${errors.map((x) => {
                    return `\n ${t(x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase()))}: ${t(x.error)
                        }`;
                })}`
            );
            setOpen(true);
            return false;
        }
        return true;
    };

    const handleEdit = (item: any) => {
        setEditMode(true);
        setOpenRowEdit(item.id);
        setBorderColorEdit('2px solid blue')
        setTimeout(() => {
            setBorderColorEdit('1px solid')
        }, 4000);
        setValueAdd({
            id: item.id,
            name: item.name as string,
            level_code: item.level_code
        })

    };
    const handleClose = () => {
        setOpen(false);
    };

    return {
        valueAdd,
        borderColorEdit,
        editMode,
        openRowEdit,
        alert,
        alertMsg,
        open,
        setValues,
        handleClose,
        handleEdit,
        handleSaveEdit,
        handleRemoveExperienciaLaboral,
        handleAddExperienciaLaboral,
        handleclear,
        rows,
    }

}