import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	TextField,
	FormControl,
	FormLabel,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Alert,
	Snackbar,
	AlertTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { Rating } from '@mui/material';
import { PageTitle } from '../../../_metronic/layout/core';
import {
	getAgendaCurriculoById,
	feedbackCandidate,
} from '../../modules/apps/user-management/users-list/core/_requests';
import { useAuth } from '../../modules/auth';
import { initValue, isValidFieldRequired, setValueOrDefault } from '../../util/Index';
import { useTranslation } from 'react-i18next';

const ResumeCandidatoPage = () => {
	const { t } = useTranslation();
	const parms = useParams();
	const { currentUser } = useAuth();
	const navigate = useNavigate();
	const [checkList, setCheckList] = useState('');
	const [isSelected, setIsSelected] = useState(false);
	const [resume, setResume] = useState<any>({});
	interface StatusCompanyProps {
		id: number;
		id_Empresa?: number;
		id_Vacancy?: number;
		observaciones?: string;
		id_aprobado?: boolean;
		id_desaprobado?: boolean;
		id_abandonar?: boolean;
	}
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [companyData, setCompanyData] = useState<StatusCompanyProps>({
		id: 0,
		id_aprobado: false,
		id_desaprobado: false,
		id_abandonar: false,
	});
	const [openExperience, setOpenExperience] = useState(true);
	const [openEducation, setOpenEducation] = useState(true);
	const [openOccupationalInterest, setOpenOccupationalInterest] = useState(true);
	const [openKnowledge, setOpenKnowledge] = useState(true);
	const [openLanguages, setOpenLanguages] = useState(true);

	//MODAL
	const [openModal, setOpenModal] = useState(false);
	const handleCloseModal = () => setOpenModal(false);
	const [messageModal, setMessageModal] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		fetchData().catch(console.error);
	}, []);

	const fetchData = async () => {
		console.log(parms);
		if (parms.id !== undefined && parms.id_empresa !== undefined && parms.id_vacancy) {
			const response = await getAgendaCurriculoById(`id_curriculo=${parms.id}&id_empresa=${parms.id_empresa}&id_vacancy=${parms.id_vacancy}`);
			console.log('data', response?.data[0]);
			setResume(response?.data[0]);
			if (parms.id_empresa !== undefined) {
				setCompanyData({
					...companyData,
					id: Number(parms.id),
					id_Empresa: Number(parms.id_empresa),
					id_Vacancy: Number(parms.id_vacancy),

				});
			}
		}
	};
	const handleOpenModal = () => {
		setOpenModal(true);
		setCompanyData({
			...companyData,
			id_abandonar: resume.id_abandonar,
			id_desaprobado: resume.id_desaprobado,
			id_aprobado: resume.id_aprobado,
			observaciones: resume.observaciones,

		});
		if (resume.id_abandonar) {
			setIsSelected(true);
			setCheckList('id_abandonar');
		}
		if (resume.id_desaprobado) {
			setIsSelected(true);
			setCheckList('id_desaprobado');
		}
		if (resume.id_aprobado) {
			setIsSelected(true);
			setCheckList('id_aprobado');
		}
	}
	const handleClose = () => {
		setOpen(false);
	};
	const changeCheckEhtnic = (e: any) => {
		if (e.target.checked) {
			!isSelected && setIsSelected(e.target.checked);
			setCheckList(e.target.name);
		} else {
			setIsSelected(false);
		}
	};

	const handleValidOnSubmit = () => {
		let errors: { field: string; error: string }[] = [];
		if (!companyData['id_aprobado'] && !companyData['id_desaprobado'] && !companyData['id_abandonar'])
			errors.push({ field: 'seleccionar um Status', error: 'Campo requerido' });
		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\Diligencie los campos obligatorios\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			return false;
		}

		return true;
	}

	const changeEnviaEmail = async () => {
		setLoading(true);
		const retorno = handleValidOnSubmit();
		if (retorno) {
			try {
				setCompanyData({
					...companyData,
					id: Number(parms.id),
					id_Empresa: Number(parms.id_empresa),
					id_Vacancy: Number(parms.id_vacancy),
				});

				const retorno = await feedbackCandidate(companyData);
				setAlertMsg(retorno.data.message);
				setResume({
					...resume,
					id_abandonar: companyData.id_abandonar,
					id_aprobado: companyData.id_aprobado,
					id_desaprobado: companyData.id_desaprobado,
					observaciones: companyData.observaciones,
				})
				setOpen(true);
				setAlert(true);
				setTimeout(() => {
					setOpen(false);
					setMessageModal('');
					handleCloseModal();
				}, 2000);
				setLoading(false);
			} catch (error) {
				console.error(error);
				setOpen(true);
				setAlert(false);
				setAlertMsg('Ha ocurrido un error!');
				setTimeout(() => {
					setOpen(false);
				}, 2000);
				setLoading(false);
				handleClose();
			}
		}
	};
	const ddlIdioma = [
		{ id: '1', name: 'Alemán' },
		{ id: '2', name: 'Arabe' },
		{ id: '3', name: 'Búlgaro' },
		{ id: '4', name: 'Catalán' },
		{ id: '5', name: 'Checo' },
		{ id: '6', name: 'Chino' },
		{ id: '7', name: 'Coreano' },
		{ id: '8', name: 'Eslovaco' },
		{ id: '9', name: 'Esloveno' },
		{ id: '10', name: 'Español' },
		{ id: '11', name: 'Estonio' },
		{ id: '12', name: 'Finlandés' },
		{ id: '13', name: 'Francês' },
		{ id: '14', name: 'Griego' },
		{ id: '15', name: 'Hebreo' },
		{ id: '16', name: 'Holandés' },
		{ id: '17', name: 'Húngaro' },
		{ id: '18', name: 'Indonesio' },
		{ id: '19', name: 'Inglés' },
		{ id: '20', name: 'Islandés' },
		{ id: '21', name: 'Italiano' },
		{ id: '22', name: 'Japonés' },
		{ id: '23', name: 'Letonia' },
		{ id: '24', name: 'Lituano' },
		{ id: '25', name: 'Neerlandés' },
		{ id: '26', name: 'Noruego' },
		{ id: '27', name: 'Polaco' },
		{ id: '28', name: 'Portugués' },
		{ id: '29', name: 'Rumano' },
		{ id: '30', name: 'Ruso' },
		{ id: '31', name: 'Serbio' },
		{ id: '32', name: 'Sueco' },
		{ id: '33', name: 'Turco' },
		{ id: '34', name: 'Lengua de señas para sordos' },
		{ id: '35', name: 'Achagua' },
		{ id: '36', name: 'Arhuaco' },
		{ id: '37', name: 'Awa' },
		{ id: '38', name: 'Baniva' },
		{ id: '39', name: 'Barbacoas' },
		{ id: '40', name: 'Barí' },
		{ id: '41', name: 'Bora' },
		{ id: '42', name: 'Cabiyarí' },
		{ id: '43', name: 'Chimila' },
		{ id: '44', name: 'Coconuco' },
		{ id: '45', name: 'Cuaiquer' },
		{ id: '46', name: 'Curripaco' },
		{ id: '47', name: 'Dorasque' },
		{ id: '48', name: 'Guaibo' },
		{ id: '49', name: 'Guatuso' },
		{ id: '50', name: 'Guaymí' },
		{ id: '51', name: 'Jucuna' },
		{ id: '52', name: 'Kamsá' },
		{ id: '53', name: 'Macu' },
		{ id: '54', name: 'Matagalpa' },
		{ id: '55', name: 'Misquito' },
		{ id: '56', name: 'Motilon' },
		{ id: '57', name: 'Paéz' },
		{ id: '58', name: 'Paya' },
		{ id: '59', name: 'Piapoco' },
		{ id: '60', name: 'Puinave' },
		{ id: '61', name: 'Quechua' },
		{ id: '62', name: 'Saliba' },
		{ id: '63', name: 'Sibundoy' },
		{ id: '64', name: 'Talamanca' },
		{ id: '65', name: 'Ticuna' },
		{ id: '66', name: 'Tunebo' },
		{ id: '67', name: 'Wayúu' },
		{ id: '68', name: 'Witoto' },
		{ id: '69', name: 'Yucuna' },
		{ id: '70', name: 'Creole' },
		{ id: '73', name: 'Otro' },
	];

	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<div className='row'>
					<div className='col-sm-1'>
						<button
							onClick={() => navigate(-1)}
							className='btn btn-primary'
							style={{ background: '#C4D2E8', borderRadius: '4px', padding: '6px 15px' }}
						>
							<i
								className='bi bi-arrow-left'
								style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
							></i>
						</button>
					</div>
					<div className='col-sm-4' style={{ lineHeight: '0.5em' }}>
						<h2 style={{ display: 'flex', gap: '10px' }}>{t('Hoja de vida')}</h2>
						{/* <span style={{}}>Todos os dados laborais de lo candidato.</span> */}
					</div>
					{/* <div className='col d-flex justify-content-end'>
						<Link to='/empleador/area' className='btn btn-primary' style={{ padding: '8px 20px' }}>
							Visión general
						</Link>
					</div> */}
				</div>
				<div className='row mt-16'>
					<div className='col-sm-4' style={{ lineHeight: '0.5em' }}>
						<h3 style={{ display: 'flex', gap: '10px' }}>
							{initValue(resume.primer_nome) +
								' ' +
								initValue(resume.segundo_nome)}
						</h3>
						<span style={{}}>
							{initValue(resume.email)} {'    '}{' '}
							{initValue(resume.celular)}
						</span>
					</div>
					<div className='col d-flex justify-content-end'>
						{currentUser?.user?.role !== 3 && (
							<button
								onClick={handleOpenModal}
								className='btn btn-primary'
								style={{ padding: '8px 20px', background: '#153E7B' }}
							>
								Feedback
							</button>
						)}
					</div>
				</div>
				{/* Experiencia */}
				<div className='row mt-10'>
					<Card
						sx={{
							minWidth: 300,
							// border: '1px solid rgba(211,211,211,0.6)',
							border: 'none',
							boxShadow: 'none',
						}}
					>
						<CardHeader
							title={t('Datos de la Entrevista')}
							titleTypographyProps={{ variant: 'h5', fontWeight: 'bold' }}
							action={
								<IconButton
									onClick={() => setOpenExperience(!openExperience)}
									aria-label='expand'
									size='small'
								>
									{openExperience ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								</IconButton>
							}
						/>
						<div>
							<Collapse in={openExperience} timeout='auto' unmountOnExit>
								<CardContent>
									<hr style={{ marginTop: '-10px' }} />
									<div className='mt-10' >
										<h6> </h6>
										<ul className='mt-2'>
											<div className='row'>
												<div className='col-sm-4'>
													<li style={{ margin: '10px 0' }}>
														<b>{t('Nombre de la vacancy')}:</b> {initValue(resume.nombre_vacante)}
													</li>
													<li style={{ margin: '10px 0' }}>
														<b>{t('Observaciones')}:</b> {initValue(resume.observaciones)}
													</li>
												</div>
												<div className='col-sm-4'>
													<li style={{ margin: '10px 0' }}>
														<b>{t('fecha de contacto')}: </b>{initValue(resume.createdAt)}
													</li>

												</div>
												<div className='col-sm-4'>
													<li style={{ margin: '10px 0' }}>
														{resume.id_aprobado !== null && resume.id_aprobado !== 0 && resume.id_aprobado &&
															<b>Estado:   Contratado</b>
														}
														{resume.id_desaprobado !== null && resume.id_desaprobado !== 0 && resume.id_desaprobado && 
															<b>Estado:   Descartado </b>

														}
														{resume.id_abandonar !== null && resume.id_abandonar !== 0 && resume.id_abandonar &&
															<b>Estado:   No compareció </b>
														}
														{!resume.id_aprobado && !resume.id_desaprobado && !resume.id_abandonar &&
															<b>Estado: </b>
														}

													</li>
												</div>
											</div>
										</ul>
									</div>
								</CardContent>
							</Collapse>
						</div>
					</Card>
				</div>

			</div>

			{/* MODAL */}
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				style={{ border: 'none' }}
			>
				<Box
					sx={{
						position: 'absolute' as 'absolute',
						top: '40%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '80%',
						bgcolor: 'background.paper',
						borderRadius: '10px',
						boxShadow: 24,
						p: 4,
					}}
				>
					<Typography id='modal-modal-title' variant='h4' component='h4'>
						Comentarios sobre el candidato
					</Typography>
					<Typography id='modal-modal-description' sx={{ mt: 2, fontSize: '12pt' }}>
						Comentarios sobre la entrevista
					</Typography>
					<FormLabel
						sx={{ fontSize: 14, fontWeight: 'bold', color: '#2B2B2B', marginTop: 2 }}
					>
						Estado
					</FormLabel>
					<FormGroup aria-label='position' row>
						<FormControlLabel
							value='id_aprobado'
							control={
								<Checkbox
									name='id_aprobado'
									color='default'
									checked={initValue(companyData['id_aprobado'])}
									value={initValue(companyData['id_aprobado'])}
									disabled={isSelected ? checkList !== 'id_aprobado' : false}
									onChange={(e) => {
										setCompanyData({
											...companyData,
											id_aprobado: e.target.checked,
										});
										changeCheckEhtnic(e);
									}}
								/>
							}
							label='Contratado'
							labelPlacement='end'
						/>
						<FormControlLabel
							value='id_desaprobado'
							control={
								<Checkbox
									name='id_desaprobado'
									color='default'
									checked={companyData['id_desaprobado']}
									value={companyData['id_desaprobado']}
									disabled={isSelected ? checkList !== 'id_desaprobado' : false}
									onChange={(e) => {
										setCompanyData({ ...companyData, id_desaprobado: e.target.checked });
										changeCheckEhtnic(e);
									}}
								/>
							}
							label='Descartado'
							labelPlacement='end'
						/>
						<FormControlLabel
							value='id_abandonar'
							control={
								<Checkbox
									name='id_abandonar'
									disabled={isSelected ? checkList !== 'id_abandonar' : false}
									color='default'
									checked={companyData['id_abandonar']}
									value={companyData['id_abandonar']}
									onChange={(e) => {
										setCompanyData({ ...companyData, id_abandonar: e.target.checked });
										changeCheckEhtnic(e);
									}}
								/>
							}
							label='No compareció'
							labelPlacement='end'
						/>
					</FormGroup>
					<TextField
						className='mt-5 mb-5'
						fullWidth
						rows={10}
						id='observaciones'
						name='observaciones'
						label='Observaciones'
						value={initValue(companyData['observaciones'])}
						onChange={(e) => setCompanyData({ ...companyData, observaciones: e.target.value })}
						size='medium'
						multiline
						inputProps={{ MaxLength: 500 }}
						disabled={loading}
						helperText={`${companyData['observaciones'] === undefined
							? '0'
							: companyData['observaciones']?.length
							}/${500}`}
					// helperText={`${funcionesLogros === undefined ? '0' : funcionesLogros?.length}/${2000}`}
					/>

					<div className='col d-flex justify-content-end'>
						<button
							className='btn btn-primary'
							onClick={() => changeEnviaEmail()}
							disabled={
								companyData.observaciones && companyData.observaciones.length > 0 && !loading
									? false
									: true
							}
							style={{ padding: '8px 20px', background: '#153E7B' }}
						>
							Salvar
						</button>
						&nbsp;
						<button
							className='btn btn-secondary'
							onClick={() => {
								setMessageModal('');
								handleCloseModal();
							}}
							style={{ padding: '8px 20px' }}
						>
							Cancelar
						</button>
					</div>
				</Box>
			</Modal>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};

const ResumeCandidatoComponent = () => {
	const { t } = useTranslation();
	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Hoja de vida')}</PageTitle>
			<ResumeCandidatoPage />
		</>
	);
};

export default ResumeCandidatoComponent;
