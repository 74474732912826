import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import PaginationComponent from '../../components/Pagination/Index';
import UseDidMountEffect from '../../modules/auth/core/UseDidMountEffect';
import styleScss from './styles.module.scss';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, Link, useParams } from 'react-router-dom';
import {
	getCurriculoAll,
	getUserCurriculoAll,
} from '../../modules/apps/user-management/users-list/core/_requests';
import { useAuth } from '../../modules/auth';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Chip, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CandidateCardComponent from '../../components/CandidateCardComponent';

const CounselorRequestedCandidatosPage = () => {
	const { t } = useTranslation();
	const { currentUser } = useAuth();
	const { id_vacante } = useParams();
	const [data, setData]: any[] = useState([]);
	const [userData, setUserData]: any[] = useState([]);
	const navigate = useNavigate();
	const [total, setTotal] = useState<number>(0);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
		name: '',
	});

	const [loading, setLoading] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);
	const [id, setId] = useState<string>('');
	const id_users = currentUser?.user?.id;
	const id_empresa = currentUser?.user?.empresa_id;
	const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
		setAnchorEl(event.currentTarget);
		setId(id);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		setUserData({ ...userData, id_users: currentUser?.user?.id });
		let arr: any[] = [];
		for (let index = 0; index < 10; index++) {
			let x = {
				id: 1,
				vacancy: `El nombre de la vacante ${index}`,
				employer: `El nombre de lo empleador ${index}`,
				description: `CUIDADOR DE ADULTO MAYOR PREPARACIóN DE ALIMENTACIóN ESPECIAL ${index}`,
				status: 'Fechada',
			};
			arr.push(x);
		}
		setFilter({ ...filter });
		//setData(arr);
		handleSubmit();
	}, []);

	UseDidMountEffect(() => {
		handleSubmit();
	}, [filter]);

	const changePaginate = (pf: any) => {
		const f = {
			...filter, // Copia todas as propriedades existentes do objeto filter
			page: pf.page, // Atualiza a propriedade page com o novo valor
			limit: pf.limit, // Atualiza a propriedade limit com o novo valor
		};
		setFilter(f);
	};

	const handleSubmit = async () => {
		setLoading(true);
		try {
			// const users = await getCurriculoAll(
			// 	`page=${filter.page}&limit=${filter.limit}}&id_vacancy=${id_vacante}&nome=${userData.nombre_candidato}`
			// );

			const users = await getUserCurriculoAll(
				`page=${filter.page}&limit=${filter.limit}&nome=${userData.nombre_candidato}`
			);

			console.log(users.data.data);
			setTotal(users.data.total);
			setTotalPage(users.data.totalPages);
			setData(users.data.data);

			//console.log(userData);
			//const retVacancy = await createVacancy(userData);
			//console.log(retVacancy);
			//alert('¡Retorno da consulta   ' + users.data.total  );
			//navigate('/auth');
			setLoading(false);
		} catch (e: any) {
			console.error(e);
			var erro = e;
			alert(e + ' ' + e.response.data.error);
			setLoading(false);
		}
	};

	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<div className='row d-flex align-items-center'>
					<div className='col-sm-1'>
						<button
							onClick={() => navigate(-1)}
							className='btn btn-primary'
							style={{ background: '#C4D2E8', borderRadius: '4px', padding: '6px 15px' }}
						>
							<i
								className='bi bi-arrow-left'
								style={{ color: 'black', fontSize: '20px', borderRadius: '4px' }}
							></i>
						</button>
					</div>
						<div className={`input-group ${styleScss.titleMobile}`}>
							<h3>{t('Gestión de candidatos')}</h3>
						</div>
					<div className='col-sm-4'>
						<div className='input-group mb-3 mt-3'>
							<input
								type='text'
								className={`form-control ${styleScss.inputSearch}`}
								value={userData['nombre_candidato']}
								onChange={(e) => setUserData({ ...userData, nombre_candidato: e.target.value })}
								placeholder={t('Busca el nombre/email')}
								aria-label='Busca el nombre'
								aria-describedby='basic-addon2'
							/>
							<div className='input-group-append'>
								<span
									className={`input-group-text ${styleScss.inputSearchIcon}`}
									style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
									onClick={handleSubmit}
									id='searchCandidato'
									
								>
									<i className='bi bi-search' style={{ fontSize: '12px' }}></i>
								</span>
							</div>
						</div>
					</div>
					<div className='col-sm-3'>
						<div className='input-group mb-3'>
							<Link
								to='/consejero/nuevo-usuario'
								className={`btn btn-primary btn-lg btn-block ${styleScss.btnNewuserMobile}`}
								style={{ padding: '8px 40px' }}
							>
								{t('Nuevo usuario')}
							</Link>
						</div>
					</div>
				</div>

				<div className={`${styleScss['table2']} ${styleScss.HideTableMobile}`}>
					{loading ? (
						<LinearProgress style={{ marginTop: '10px' }} />
					) : (
						<>
							{data.lenght !== 0 && (
								<div className={styleScss.tableWeb}>
									<table className={`table table-responsive table-striped`}>
										<thead className={`${styleScss['th-custom']}`}>
											<tr>
												<th scope='col'>
													<h4>{t('Candidato')}</h4>
												</th>
												<th scope='col'>
													<h4>{t('Documento')}</h4>
												</th>
												<th scope='col'>
													<h4>{t('Email')}</h4>
												</th>
												<th scope='col'>
													<h4>{t('Teléfono')}</h4>
												</th>
												<th scope='col'>
													<h4>{t('¿Hay CV?')}</h4>
												</th>
												<th scope='col'>
													<h4>{t('¿CV terminado?')}</h4>
												</th>
												<th scope='col'>
													<h4>{t('Acciones')}</h4>
												</th>
											</tr>
										</thead>
										<tbody className={`${styleScss['td-custom']}`}>
											{data.map((x: any, i: number) => (
												<tr key={i} id={i.toString()}>
													<th className='pt-3 pb-3' scope='row'>
														{x.UserComplemento.primer_nome}
													</th>
													<td>{x.UserComplemento.numero_doc}</td>
													<td>{x.email}</td>
													<td>{x.UserComplemento.celular}</td>
													<td>
														{x.UserCurriculo?.id ? (
															<Chip label={t('Sí')} color='success' />
														) : (
															<Chip label={t('No')} color='error' />
														)}
													</td>
													<td>
														{x.UserCurriculo?.finalizado ? (
															<Chip label={t('Sí')} color='success' />
														) : (
															<Chip label={t('No')} color='error' />
														)}
													</td>
													{}

													<td>
														<Button
															id='basic-button'
															aria-controls={openMenu ? 'basic-menu' : undefined}
															aria-haspopup='true'
															aria-expanded={openMenu ? 'true' : undefined}
															onClick={(e) => handleClickMenu(e, x.id)}
														>
															<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
														</Button>

														<Menu
															id='basic-menu'
															anchorEl={anchorEl}
															open={openMenu}
															onClose={handleCloseMenu}
															MenuListProps={{
																'aria-labelledby': 'basic-button',
															}}
														>
															{x.UserCurriculo?.id ? (
																[
																	<MenuItem onClick={handleCloseMenu} key='view'>
																		<Link to={`/consejero/hoja-vida/${id}`}>
																			<VisibilityIcon />
																			&nbsp; {t('Ver hoja de vida')}
																		</Link>
																	</MenuItem>,
																	<MenuItem onClick={handleCloseMenu} key='edit'>
																		<Link to={`/consejero/crear-hoja-vida/${id}`}>
																			<NoteAltIcon />
																			&nbsp; {t('Editar hoja de vida')}
																		</Link>
																	</MenuItem>,
																]
															) : (
																<MenuItem onClick={handleCloseMenu}>
																	<Link to={`/consejero/crear-hoja-vida/${id}`}>
																		<NoteAddIcon />
																		&nbsp; {t('Criar Currículo')}
																	</Link>
																</MenuItem>
															)}
														</Menu>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							)}
						</>
					)}
				</div>
				<div>
					{data.map((x: any, i: any) => (
						<CandidateCardComponent
							key={i}
							data={x}
							anchorEl={anchorEl}
							handleCloseMenu={handleCloseMenu}
							handleClickMenu={handleClickMenu}
						/>
					))}
				</div>
				<PaginationComponent
					totalPage={totalPage}
					total={total}
					customClass={''}
					paginate={changePaginate}
				/>
			</div>
		</>
	);
};

const CounselorRequestedCandidatosWrapper = () => {
	const { t } = useTranslation();
	return (
		<>
			<PageTitle breadcrumbs={[]}>{t('Gestión de candidatos')}</PageTitle>
			<CounselorRequestedCandidatosPage />
		</>
	);
};

export { CounselorRequestedCandidatosWrapper };
